import React from "react"
import PropTypes from 'prop-types';
//classes:
import lang from "../../classes/lang";

//icons:
// import { Icon } from "react-icons-kit";
// import { ic_watch_later as awayIcon } from 'react-icons-kit/md/ic_watch_later';
// import { ic_lens as onlineIcon } from 'react-icons-kit/md/ic_lens';
// import { ic_remove_circle as busyIcon } from 'react-icons-kit/md/ic_remove_circle';

const OnlineStatus = (props) => {
	// const icons = {
	// 	online: { i: onlineIcon, c: "success" },
	// 	busy: { i: busyIcon, c: "alert" },
	// 	away: { i: awayIcon, c: "warn" },
	// 	offline: { i: onlineIcon, c: "grey" },
	// }
	const data = [{ id: 0, c: "online" }, { id: 1, c: "away" }, { id: 2, c: "busy" }, { id: 3, c: "offline" }]
	return (
		<div className={`onlineStatus col_${data[props.id]["c"]}`} title={lang.translate(data[props.id]["c"])}>
		</div>
	);
}
OnlineStatus.propTypes = {
	id: PropTypes.number.isRequired
}
export default OnlineStatus;