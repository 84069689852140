import React, { PureComponent } from "react";
import PropTypes from "prop-types";
// import Decimal from "decimal.js";
//components:
import Digit from "./Digit";

class DigitScreen extends PureComponent {
	constructor(props) {
		super(props);
		this.arr = Array(props.rounding + 2).fill(0);
		this.color = props.color;
		this.calc();
	}
	static get propTypes() {
		return {
			value: PropTypes.number.isRequired,
			rounding: PropTypes.number.isRequired,
			trim: PropTypes.number,
			trimAfterDot: PropTypes.number,
			color: PropTypes.string,
		};
	}
	calc = () => {
		let v = this.props.value.toFixed(this.props.rounding);
		v = this.props.trim ? v.substr(0, this.props.trim).split("") : v;
		v = this.props.trimAfterDot ? v.ToBalanceFixed(this.props.trimAfterDot) : v;
		return v;
	};
	render() {
		this.color = (this.color === this.props.color ? "g" : "") + this.props.color;
		this.arr = this.calc();
		this.o = [];
		for (let i = 0; i < this.arr.length; i++) this.o.push(<Digit key={i} v={this.arr[i]} color={this.color} />);
		return <div className="digitScreen">{this.o}</div>;
	}
}

export default DigitScreen;
