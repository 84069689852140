/* eslint-disable no-unused-vars */
import { useEffect, useCallback, useState } from "react";
import Axios from "../classes/axios";
import { isObject, onResult } from "../classes/utills";

export const useFlappy = (props) => {
	const [betIdsAll, setBetIds] = useState([]);
	const call = useCallback(async () => {
		const result = await Axios.post(`api/flappybet/betIdsGetAll`, {});
		const data = await onResult(result, "unexpected_error");
		if (Array.isArray(data)) {
			setBetIds(data);
		}
	}, []);
	useEffect(() => {
		call();
	}, [call]);
	return { betIdsAll };
};
