import update from "immutability-helper";
// import Storage from "../classes/storage"
//const isClient = typeof document !== "undefined";
const isServer = typeof window === "undefined";
export const initialState = {
	nodeId: -1,
	pathname: isServer ? "/" : window.location.pathname,
	tawkOpened: true,
	initFinished: false,
	token: null,
	fp: null,
	socketConnected: false,
	socketStatusMessage: "",
	modal: null,
	chat: {
		open: false,
		left: true,
		location: "chat", //news, notifications, pm, friens, rooms
	},
	admins: {},
	mods: {},
	isVip: false,
	//chatRooms:
	chatRooms: {},
	chatRoomSelected: null,
	chatInput: "",
	chatWith: null,
	//--
	rainShow: false,
	chatReadMode: false,
	chatUnreadMessagesCount: 0,
	newsReadMode: false,
	newsUnreadMessagesCount: 0,
	notificationsReadMode: false,
	notificationsUnreadMessagesCount: 0,
	pmReadMode: false, //always false
	pmUnreadMessagesCount: 0,
	toReadMode: false,
	toUnreadMessagesCount: 0,
	friends: {
		all: {},
		pendingCount: 0,
	},
	banners: {},
	slideNavigation: {},
	newsSlides: {},

	//--
	menuOpen: false,
	lang: { key: null },
	theme: { key: "dark" },
	langRoute: null,
	serverTime: 0,
	online: { GUESTS: 0, ONLINE: 0, AWAY: 0, BUSY: 0, OFFLINE: 0, uns: {}, rooms: {} },
	offline: {},
	//coins:
	coins: {},
	coin: {
		selected: "usdt",
	},
	rates: {},
	faucets: {
		amount: {},
		limit: 0,
	},
	stable: {},

	// messages: [],
	//player:
	player: {
		userName: "guest",
		uid: -1,
		img: null,
		temp: true,
		_2fa: false,
		onlineStatus: 0,
	},
	permissions: null,
	permissionsChanged: 0,
	ranking: {
		level: "0",
		levels: {
			betting_level: null,
			chat_activity_level: null,
			contest_activity_level: null,
			jackpot_hunting_level: null,
			loyalty_level: null,
			rainmaker_level: null,
			referrals_betting_level: null,
			referring_level: null,
			wagering_level: null,
			warn_level: null,
		},
		title: "",
		totalMax: 1000000,
		totalP: "0",
		totalPoints: 0,
	},
	//seeds:
	seeds: {
		cs: "",
		ssSha: "",
		csPrev: "",
		ssPrev: "",
	},
	//overall stats:
	ostats: {},
	oLastRoll: { _id: null },
	//balance:
	balance: {},
	balancePositive: false,
	balanceFiat: null, //or coin

	//dice:
	diceSettings: {
		statistics: {
			active: true,
			wins: true,
			bets: true,
			losses: true,
			wager: true,
			profit: true,
			maxbet: false,
			maxprofit: false,
			series: true,
			maxseries: true,
		},
		bets: {
			active: true,
			reverse: false,
			quantity: "8",
			my_bets: true,
		},
		slider: {
			active: true,
		},
		hotkeys: {
			active: true,
		},
		sound: {
			active: true,
		},
	},
	mySlotBets: [],
	//billing:
	withdraws: [],
	deposits: [],
	//contests:
	contests: {
		active: [],
		pending: [],
		expired: [],
	},
	//ui
	shortcutsToggle: true,
	playerConfig: {
		shortcut: [],
		shortcutOn: null,
		depBonus: null, //_id of joined bonus
		slotsFav: [],
		siteSettings: null,
	},
	playerConfigDefaults: {
		siteSettings: {
			custom: {
				simple_balance: false,
				privacy_mode: false,
			},
			chat: {
				sound: true,
			},
		},
	},
	depositBonus: {},
	pandora: {
		games: [],
	},
	slots: {
		providers: [],
		games: [],
	},
	slotsNew: [],
	slotsHot: [],
	slotsTrending: [],
	contestsCount: 0,
	myspinsCount: 0,
	rolls: {
		dice: [],
		diceHr: [],
	},
	jackpots: [],
	rainScores: [],
	crashPlayers: {},
	crashEvent: "stop",
	crashStats: {},
	crashHistory: [],
	crashBetCaption: "place_bet",
	//[pathname]: [id1, id2, id3]
	// docIds: {
	// 	a: ["id_001", "id_002", "id_003"],
	// },
	twitterLink: "/assets/twitter32.png",
	wheelPrizes: [],

	battlesO: {},
	battleCount: 0,
	flappyGames: [],
	flappySessions: [],
};

export const action = (o) => {
	if (!o.type) o.type = "";
	return o;
};

export default (state = initialState, action) => {
	if (action.type.charAt(0) === "@") return state;
	return update(state, action.payload);
};
