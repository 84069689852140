import React from "react";
export const BgCity = () => {
	/* <style>.cls-1{fill:#2e3192;stroke:#000;stroke-miterlimit:10;} */
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 74.19">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						className="bgCity"
						d="M.5,60.69h4v11h7v-6h5v-4h6v-6h16v12h3v-4h4v-5h11l3-8,2,7h9v8h2s0-6,5-6c0,0,3-1,4,5h3l2-9,10-1,1,8h5v4h5v-4h7v-4h3v4h2v-18h2s-1-7,2-7v-12h1l1,12s2-1,2,6h2v9l8,1v9h4v-16h2l-1-5h15l-1,5h2v8h6v3h8v4l7-1v-5h3v-11l4-2v-4h10v3l5,2v12h5v3h10v-31s-1-13,9-13h4v4l4,1v6h3v-3h8v6l9,1v13h3v-4h8v13h6v-29s4-6,7-6h16v11s4,1,4,8v17h3v12h4v-5h5v-18l17-1v-4l6-1s7,6,6,15h3v-18h26v-4h4v-5l2-1v-7h2v7l4,2v16h5v-30h26v13h5v5l9,1v8h2v-5l6-1v-16h17v-3h6v18h5v-5h6v-24h33v12h4v17h11v6h14v-21l5-8h6s3,0,4-7c0,0,5-2,6,7v11h8s7-10,13,1v10h20l-2,12h10v5h4v-16l5-8v-16h8v-4l23-1v6l7,1v46h3v-9s-1-7,3-7c0,0-1-16,0-17a3.1,3.1,0,0,1,2-1v18s4-2,4,6v14h4v5h7v4h6v-5h6v-7h11v9h4s0-5,5-5c0,0,3,0,5,4h2v-4h7v-3h3v-8h2v8l9,1v6h4v4h8v-13h13v7h7v5h6v5l-766,2Z"
					/>
				</g>
			</g>
		</svg>
	);
};
