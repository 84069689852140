/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
//redux:
import { storeSet } from "../..";
import { useSelector } from "react-redux";
//classes:
import lang from "../../classes/lang";
import { useReward } from "react-rewards";
import gsap, { Back } from "gsap";
import Axios from "../../classes/axios";
import { isObject, onResult, timeLeft, timeLeftNoDay } from "../../classes/utills";
import ImageCached from "../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
import { lock } from "react-icons-kit/fa/lock";
import { usd } from "react-icons-kit/fa/usd";
import { percent } from "react-icons-kit/fa/percent";
import { iconCaByAction } from "../../classes/iconCa";
import toast from "../../classes/toast";
import HumanDetector from "../../components/humanDetector/HumanDetector";

const bg = (color1, color2) => {
	// return `repeating-conic-gradient(
	// 	at 0% 50%,
	// 	${color1} 0%,
	// 	${color2} 5%,
	// 	${color1} 10%)
	// `;
	return `linear-gradient(60deg, ${color1} 50%, ${color2} 100%)`;
};
// return `repeating-conic-gradient(
// 	at 0% 50%,
// 	${color1} 0%,
// 	${color2} 5%,
// 	${color1} 10%)
// `;
// background-color: #f8f9d2;
// background-image: linear-gradient(315deg, #f8f9d2 0%, #e8dbfc 74%);
// const bgs = [bg("red", "yellow"), bg("lime", "green"), bg("#F0F", "white"), bg("blue", "cyan"), bg("#af0023", "red")];
const bgs = [bg("#D865D2", "#E2946F"), bg("#7957F8", "#B259ED"), bg("#76A8F6", "#A1F7F4"), bg("#748AF9", "#DC70F2"), bg("#382B8F", "#6654da")];
// , bg("#E4A154", "#E9BB82")
const Sector = (props) => {
	Sector.propTypes = {
		height: PropTypes.number,
		angle: PropTypes.number,
		el: PropTypes.object,
		radius: PropTypes.number,
		background: PropTypes.any,
	};
	const renderIcon = (prize) => {
		if (["btc", "usdt", "doge", "eth", "ltc", "shib"].includes(prize)) {
			return <ImageCached width={40} height={40} storageName="coin" url={`/assets/icons/${prize}@2x.png`} alt={prize} title={prize} params={{}} />;
		} else if (prize === "he") {
			return <ImageCached width={40} height={40} storageName="coin" url={`/assets/houseedge.png`} alt={prize} title={prize} params={{}} />;
		} else if (prize === "freespin" || prize.startsWith("flappy")) {
			return <ImageCached width={40} height={40} storageName="coin" url={`/assets/freespin.png`} alt={prize} title={prize} params={{}} />;
		} else if (prize === "rakeback_fs") {
			return <ImageCached width={40} height={40} storageName="coin" url={`/assets/rb_fs.png`} alt={prize} title={prize} params={{}} />;
		} else if (prize === "rakeback") {
			return <ImageCached width={40} height={40} storageName="coin" url={`/assets/rb.png`} alt={prize} title={prize} params={{}} />;
		}
		return null;
	};
	return (
		<div
			className="sector"
			style={{
				height: props.height,
				left: props.radius + "px",
				width: props.radius + "px",
				top: -props.height / 2 + props.radius + "px",
				transform: `rotate(${props.angle}deg)`,
				background: props.background,
			}}
		>
			<div className="text">
				<b>{props.el.caption}</b>
				{renderIcon(props.el.prize)}
			</div>
		</div>
	);
};

const FortuneWheel = (props) => {
	FortuneWheel.propTypes = {
		mgr: PropTypes.any,
		mode: PropTypes.number,
	};
	const isVip = useSelector((state) => state.isVip);
	const [rb, setRb] = useState();
	const [loading, setLoading] = useState(false);
	const { reward, isAnimating } = useReward(`reward_${props.mgr ? "_mgr" : "_popup"}`, "confetti");

	const wonPrize = useRef(null);
	const [seconds, setSeconds] = useState(-1);
	const prizes = useSelector((state) => state.wheelPrizes) ?? [];
	const canShow = {
		easy: props.mgr || prizes.filter((el) => el.level === "easy").length >= 3,
		basic: props.mgr || prizes.filter((el) => el.level === "basic").length >= 3,
		super: props.mgr || prizes.filter((el) => el.level === "super").length >= 3,
		mega: props.mgr || prizes.filter((el) => el.level === "mega").length >= 3,
		rb_easy: props.mgr || prizes.filter((el) => el.level === "rb_easy").length >= 3,
		rb_basic: props.mgr || prizes.filter((el) => el.level === "rb_basic").length >= 3,
		rb_super: props.mgr || prizes.filter((el) => el.level === "rb_super").length >= 3,
		rb_mega: props.mgr || prizes.filter((el) => el.level === "rb_mega").length >= 3,
	};
	const [level, setLevel] = useState(props.mode === 0 ? "easy" : isVip ? "rb_mega" : "rb_easy");
	const isRakeback = level.startsWith("rb_");

	const prizesFiltered = prizes.filter((el) => el.level === level);

	const refSectors = useRef();
	const serverTime = useSelector((store) => store.serverTime);
	const getSeconds = useCallback(async () => {
		const result = await Axios.post("api/fortuneWheel/getSeconds", {});
		const data = await onResult(result, "unexpected_error");
		if (isObject(data) && data !== null && !data.error) {
			setRb(data.rb);
			storeSet({ wheelPrizes: { $set: data.wheelPrizes } });
			setSeconds(data);
		} else {
		}
	}, []);
	useEffect(() => {
		getSeconds();
	}, [getSeconds]);
	const spin = useCallback(async (o) => {
		const result = await Axios.post("api/fortuneWheel/spin", o);
		const data = await onResult(result, "unexpected_error");
		if (data && !data.error && data._id) {
			wonPrize.current = data;
		} else {
			wonPrize.current = null;
		}
	}, []);

	const radius = 150;
	const a = (angle) => {
		const b = radius / Math.cos(angle / 2);
		return (Math.pow(b, 2) * Math.sin(angle)) / radius;
	};
	let angle = 360 / (prizesFiltered ?? []).length;
	if (angle >= 180) angle = 60;
	const onSpin = async () => {
		if (level.startsWith("rb_") && !rb?.claimable) return onDisabled();
		setLoading(true);
		await spin({ level });
		if (!wonPrize.current?._id) {
			setLoading(false);
			return;
		}
		const index = prizesFiltered.findIndex((el) => el._id === wonPrize.current._id);
		gsap.fromTo(
			refSectors.current,
			{
				rotation: 0,
			},
			{
				rotation: -angle * index + 360 * 7,
				duration: 5,
				ease: Back.easeOut.config(1),
				onComplete: () => {
					getSeconds().then(() => {
						reward();
						setLoading(false);
					});
				},
			}
		);
	};
	const onLevelSwitch = (level) => {
		setLevel(level);
	};
	const leftS = (seconds?.[level] || 0) - serverTime;
	const left = timeLeft(leftS, 0);
	const levelLocked = (level) => {
		return !(seconds?.valid || []).includes(level);
	};
	const locked = !(seconds?.valid || []).includes(level);
	const renderLimit = () => {
		const limit = seconds?.limits?.[level];
		if (!limit) return null;
		return (
			<>
				{/* {limit.level > 1 && ( */}
				<div>
					<b>{`${lang.translate("min_level_required")}:`}</b>
					<b className={seconds?.level >= limit.level ? "green" : "red"}>{`${limit.level}`}</b>
					<b>. Current:</b>
					<b className="green">{`${seconds?.level}`}</b>
				</div>
				{/* )} */}
				{/* {limit.wagerPoints > 0 && ( */}
				<div>
					<b>{`WagerPoints:`}</b>
					<b className={seconds?.wagerPoints >= limit.wagerPoints ? "green" : "red"}>{`${limit.wagerPoints}`}</b>
					<b>. Current:</b>
					<b className="green">{`${seconds?.wagerPoints}`}</b>
				</div>
				{/* )} */}
				{/* {limit.minWagerIn24hours > 0 && ( */}
				<div>
					<b>{`Min wager (24h):`}</b>
					<b className={seconds?.wager >= limit.minWagerIn24hours ? "green" : "red"}>{`${limit.minWagerIn24hours}$`}</b>
					<b>. Current:</b>
					<b className="green">{`${seconds?.wager.toFixed(2)}$`}</b>
				</div>
				{/* )} */}
			</>
		);
	};
	const leftUntillReset = timeLeftNoDay(new Date(serverTime).utcCeil().getTime() - serverTime, 0);
	// const leftUntillReset = new Date(serverTime).getMinutes() % 5;
	const onDisabled = () => {
		toast.info(lang.translate("cannot_perform"));
	};
	const [solved, setSolved] = useState(false);
	if (!isRakeback) {
		if (!solved) {
			return (
				<div style={{ padding: "20px", marginTop: "20px" }}>
					<HumanDetector labelContent={""} onSolve={() => setSolved(true)} />
				</div>
			);
		}
	}
	return (
		<div className="fortuneWheel">
			<header>
				{iconCaByAction.rakeback}
				<h2>{lang.translate(props.mode === 0 ? "fortune_wheel" : "rakeback")}</h2>
			</header>
			{props.mode === 1 && rb && (
				<div className="infoTop">
					<div>
						<span>{lang.translate("gross")}:</span>
						<span>{(rb.amountUsd * 1).toFixed(2)}</span>
						<Icon icon={usd} size={18} />
					</div>
					<b className="resetOn">
						{lang.translate("expires_after")}: {leftUntillReset}
					</b>
				</div>
			)}
			<div className={`bar ${loading ? "disabled" : "enabled"}`}>
				{props.mode === 0 && (
					<>
						<button
							disabled={!canShow.easy}
							className={`btnDefault ${level === "easy" ? "on" : "off"}`}
							onClick={(e) => [e.stopPropagation(), onLevelSwitch("easy")]}
						>
							<b>Easy</b>
							{levelLocked("easy") ? <Icon icon={lock} size={18} /> : <></>}
						</button>
						<button
							disabled={!canShow.basic}
							className={`btnDefault ${level === "basic" ? "on" : "off"}`}
							onClick={(e) => [e.stopPropagation(), onLevelSwitch("basic")]}
						>
							<b>Lucky</b>
							{levelLocked("basic") ? <Icon icon={lock} size={18} /> : <></>}
						</button>
						<button
							disabled={!canShow.super}
							className={`btnDefault ${level === "super" ? "on" : "off"}`}
							onClick={(e) => [e.stopPropagation(), onLevelSwitch("super")]}
						>
							<b>Super</b>
							{levelLocked("super") ? <Icon icon={lock} size={18} /> : <></>}
						</button>
						<button
							disabled={!canShow.mega}
							className={`btnDefault ${level === "mega" ? "on" : "off"}`}
							onClick={(e) => [e.stopPropagation(), onLevelSwitch("mega")]}
						>
							<b>Mega</b>
							{levelLocked("mega") ? <Icon icon={lock} size={18} /> : <></>}
						</button>
					</>
				)}
				{props.mode === 1 && (
					<>
						{!isVip && (
							<>
								<button
									disabled={!canShow.rb_easy}
									className={`btnDefault ${level === "rb_easy" ? "on" : "off"}`}
									onClick={(e) => [e.stopPropagation(), onLevelSwitch("rb_easy")]}
								>
									<b>Starter</b>
									{levelLocked("rb_easy") ? <Icon icon={lock} size={18} /> : <></>}
								</button>

								<button
									disabled={!canShow.rb_basic}
									className={`btnDefault ${level === "rb_basic" ? "on" : "off"}`}
									onClick={(e) => [e.stopPropagation(), onLevelSwitch("rb_basic")]}
								>
									<b>Good</b>
									{levelLocked("rb_basic") ? <Icon icon={lock} size={18} /> : <></>}
								</button>
								<button
									disabled={!canShow.rb_super}
									className={`btnDefault ${level === "rb_super" ? "on" : "off"}`}
									onClick={(e) => [e.stopPropagation(), onLevelSwitch("rb_super")]}
								>
									<b>Big</b>
									{levelLocked("rb_super") ? <Icon icon={lock} size={18} /> : <></>}
								</button>
							</>
						)}
						<button
							disabled={!canShow.rb_mega}
							className={`btnDefault ${level === "rb_mega" ? "on" : "off"}`}
							onClick={(e) => [e.stopPropagation(), onLevelSwitch("rb_mega")]}
						>
							<b>VIP</b>
							{levelLocked("rb_mega") ? <Icon icon={lock} size={18} /> : <></>}
						</button>
					</>
				)}
			</div>
			<div className="sectorsWrapper">
				<button onClick={(e) => [e.stopPropagation(), loading || locked ? onDisabled() : onSpin()]} className="diceBtn green btnCenterSpin">
					<b className="txt">{lang.translate("spin")}</b>
				</button>
				<div className="arrow">
					<div className="arrow-left"></div>
					<div className="arrow-left-shadow"></div>
				</div>
				<div key={`sectorsBorder_${level}`} className="sectorsBorder">
					<div ref={refSectors} className="sectors" style={{ width: radius * 2 + "px", height: radius * 2 + "px" }}>
						{prizesFiltered.map((el, i) => {
							const background = bgs[i % bgs.length];
							return <Sector key={i} radius={radius} height={a((angle * Math.PI) / 180)} angle={angle * i} el={el} background={background} />;
						})}
					</div>
				</div>
			</div>
			<span id={`reward_${props.mgr ? "_mgr" : "_popup"}`} />
			{!isRakeback && <div className="info">{renderLimit()}</div>}
			{props.mode === 1 && <div className="info">{lang.translate("wheel_disclaimer")}</div>}
			<div className="info">
				<div>
					<b>
						<a href="/en/faq" rel="noopener noreferrer">
							FAQ
						</a>
					</b>
				</div>
				{level === "easy" && (
					<div>
						<b className="yellow">{`${lang.translate("max_balance")}:`}</b>
					</div>
				)}
			</div>
			<button className="diceBtn red btnSpin" style={{ visibility: `${leftS > 999 ? "visible" : "hidden"}` }}>
				{left}
			</button>
		</div>
	);
};
export default FortuneWheel;
