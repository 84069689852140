import Axios from "./axios";
import { onResult } from "./utills";

export const create = async () => {
  if (typeof document === "undefined") return { error: true };
  const result = await Axios.post("/api/humanDetector/create", {});
  return await onResult(result, "unexpected_error");
};
export const verify = async value => {
  const result = await Axios.post("/api/humanDetector/verify", { value });
  return await onResult(result, "unexpected_error");
};
