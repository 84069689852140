import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../Container";
//redux:
import { connect } from "react-redux";
//classes:
// import { lang } from "../../classes/lang"
//icons:
// import { Icon } from "react-icons-kit"

class PandoraRoll extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			url: PropTypes.string,
		};
	}
	render() {
		return (
			<Container className="pandoraRoll">
				<div className="wrapper">
					<iframe title="Pandora roll" name="ifrmPandoraRoll" src={this.props.url} />
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(PandoraRoll);
