import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//components:
import Loader from "../../components/loader/Loader";
import Input from "../../components/input/Input";
import Lottie from "../../components/lottie/Lottie";
//containers:
import Container from "../Container";
import Balance from "../balance/Balance";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { tryParseFloat } from "../../classes/utills";
import { status, perform } from "../../classes/rain";
import ImageCached from "../../classes/cache";
//other:
import Decimal from "decimal.js";
//icons:
// import { Icon } from "react-icons-kit"
import IconCa, { iconCaName } from "../../classes/iconCa";

Rain.propTypes = {
	lang: PropTypes.string,
	rates: PropTypes.object.isRequired,
	balance: PropTypes.object.isRequired,
	coinSelected: PropTypes.string,
	initFinished: PropTypes.bool,
	coins: PropTypes.object,
};
function Rain(props) {
	const [loading, setLoading] = useState(false);
	const [coinSelected, setCoinSelected] = useState(props.coinSelected);
	const [amount, setAmount] = useState("0.00000000");
	const [count, setCount] = useState("10");
	const [statusData, setStatusData] = useState({
		count: 0,
		maxDailyTotalCount: 0,
		maxDailyTotalUsd: 0,
		minAmountUsd: 0,
		totalUsd: 0,
	});
	const [comment, setComment] = useState("");
	useEffect(() => {
		status().then((o) => {
			if (o.error) {
				setLoading(false);
			} else {
				setStatusData(o);
				// console.log("afterSetStatusData: ", statusData);
				setLoading(false);
			}
		});
	}, []);
	// eslint-disable-next-line no-unused-vars
	const onChange = (e, i, delta) => {
		if (e.target.name === "amount") {
			// this.setState({ [e.target.name]: e.target.value });
			setAmount(e.target.value);
		} else if (e.target.name === "comment") {
			// this.setState({ [e.target.name]: e.target.value });
			setComment(e.target.value);
		} else if (e.target.name === "count") {
			setCount(e.target.value);
		}
	};
	const onBalanceSelect = (o) => {
		setCoinSelected(o.short);
	};
	const onMinAmountClick = (minRainAmount) => {
		try {
			setAmount(parseFloat(minRainAmount).toFixed(8));
		} catch (err) {
			console.log("err: ", err.message);
		}
	};
	const onPerformClick = () => {
		setLoading(true);
		perform(
			{
				short: coinSelected,
				amount,
				count,
				comment: comment.trim() === "" ? undefined : comment.trim(),
			},
			() => {
				setLoading(false);
				setAmount("0.00000000");
				setCount("10");
				setComment("");
			}
		);
	};
	if (typeof document === "undefined")
		return (
			<Container>
				<div></div>
			</Container>
		);
	if (!props.initFinished) return null;
	const _coinSelected = props.coins && coinSelected && props.coins[coinSelected] && props.coins[coinSelected].rain ? coinSelected : null;
	const disabled = loading;
	const minAmount = coinSelected ? new Decimal(statusData.minAmountUsd).dividedBy(props.rates[coinSelected]).toFixed(8) : "0.00000000";
	const minStr = coinSelected
		? `${statusData.minAmountUsd.toFixed(4)}$ / ${props.rates[coinSelected].ToBalanceFixed(4)} = ${minAmount}`
		: "0.00000000";
	let reason = [];
	if (!_coinSelected) reason.push(<b key={"reason_2"}>{lang.translate("coin_not_selected")}</b>);
	if (!loading && _coinSelected && tryParseFloat(minAmount, 0) > tryParseFloat(amount, 0))
		reason.push(<b key={"reason_4"}>{lang.translate("min_amount_not_matched")}</b>);
	if (!loading && _coinSelected && tryParseFloat(amount, 0) > tryParseFloat(props.balance[`balance_${_coinSelected}`], 0))
		reason.push(<b key={"reason_5"}>{lang.translate("insufficient_funds")}</b>);
	if (tryParseFloat(props.balance.lock_tip_withdraw, 0) > 0) reason.push(<b key={"reason_6"}>{lang.translate("deposit_confirmation_pending")}</b>);
	return (
		<Container className="rainModal">
			<div className="wrapper">
				{loading && <Loader />}
				<main>
					<Lottie
						// ref={(el) => (this.anim = el)}
						src={"/assets/ae/rain.json"}
						style={{
							height: "150px",
							width: "100%",
							pointerEvents: "none",
						}}
					/>
					<label className="top50">
						<b>1)</b>
						<b>{lang.translate("select_coin")}</b>
					</label>
					<Balance selected={_coinSelected} rainMode={true} style={{ width: "100%", marginBottom: "10px" }} onSelect={onBalanceSelect} />
					<label>
						<b>2)</b>
						<b>{lang.translate("number_of_players")}</b>
					</label>
					<Input
						id="count"
						className="inpCount"
						name="count"
						value={count}
						placeholder={lang.translate("number_of_players")}
						validation="integer>0"
						min={1}
						max={30}
						toFixed={0}
						onFocus={(e) => e.target.select()}
						onChange={(e) => onChange(e, 0)}
						onBlur={(e) => onChange(e, 1)}
						// onWheel={(e, delta) => onChange(e, 0, delta)}
						disabled={disabled}
					/>
					<label>
						<b>{`${lang.translate("min")}:`}</b>
						<button onClick={(e) => [e.stopPropagation(), setCount(1)]} className="btnLink">
							1
						</button>
						<b className="left10">{`${lang.translate("max")}:`}</b>
						<button onClick={(e) => [e.stopPropagation(), setCount(30)]} className="btnLink">
							30
						</button>
					</label>
					<label>
						<b>3)</b>
						<b>{lang.translate("rain_amount")}</b>
					</label>
					<Input
						id="amount"
						className="inpAmount"
						name="amount"
						value={amount}
						placeholder={lang.translate("amount")}
						validation="amount"
						// min={this.getMin()}
						// max={this.getMax()}
						toFixed={8}
						onFocus={(e) => e.target.select()}
						onChange={(e) => onChange(e, 0)}
						onBlur={(e) => onChange(e, 1)}
						// onWheel={(e, delta) => onChange(e, 0, delta)}
						disabled={disabled || coinSelected === null}
					/>
					<label>
						<b>{`${lang.translate("min")}:`}</b>
						<button onClick={(e) => [e.stopPropagation(), onMinAmountClick(minAmount)]} className="btnLink">
							{minStr}
						</button>
						{coinSelected && (
							// <img
							// 	width={32}
							// 	height={32}
							// 	alt={coinSelected}
							// 	title={coinSelected}
							// 	src={`/assets/icons/${coinSelected}@2x.png`}
							// 	onError={(e) => {
							// 		if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							// 	}}
							// />
							<ImageCached
								width={32}
								height={32}
								storageName="coin"
								url={`/assets/icons/${coinSelected}@2x.png`}
								alt={coinSelected}
								title={coinSelected}
								params={{}}
							/>
						)}
					</label>
					<label>
						<b>{`${lang.translate("max")} ${lang.translate("daily_count")}:`}</b>
						{loading ? (
							<b>0 / 0</b>
						) : (
							<>
								<b>{`${statusData.count} / ${statusData.maxDailyTotalCount}`}</b>
							</>
						)}
					</label>
					<label>
						<b>{`${lang.translate("max")} ${lang.translate("daily_total_amount")}:`}</b>
						{loading ? (
							<b>0$ / 0$</b>
						) : (
							<>
								<b>{`${statusData.totalUsd.toFixed(4)}$ / ${statusData.maxDailyTotalUsd}$`}</b>
							</>
						)}
					</label>
					<label>
						<b>4)</b>
						<b>{`${lang.translate("your_comment")} (${lang.translate("optional")})`}</b>
					</label>
					<Input
						id="comment"
						className="inpComment"
						name="comment"
						type="textarea"
						value={comment}
						placeholder={lang.translate("your_comment")}
						// validation=""
						// min={this.getMin()}
						// max={this.getMax()}
						// toFixed={8}
						onFocus={(e) => e.target.select()}
						onChange={(e) => onChange(e, 0)}
						onBlur={(e) => onChange(e, 1)}
						// onWheel={(e, delta) => this.onChange(e, 0, delta)}
						disabled={disabled}
					/>
				</main>
				<main className="sendWrapper">
					<div className="reason">{reason}</div>
					<button onClick={(e) => [e.stopPropagation(), onPerformClick()]} disabled={disabled || reason.length > 0} className="btnDefault warn">
						<IconCa name={iconCaName.rainOut} />
						<b>{lang.translate("cause_rain")}</b>
					</button>
				</main>
			</div>
		</Container>
	);
}
const a = (store) => {
	return {
		lang: store.lang.key,
		rates: store.rates,
		balance: store.balance,
		coinSelected: store.coin.selected,
		initFinished: store.initFinished,
		coins: store.coins,
	};
};

export default connect(a, null, null, { forwardRef: true })(Rain);
