import React from "react";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import SlotegratorGame from "../../slotegrator/SlotegratorGame";
import useWindowSize from "../../../hooks/useWindowSize";
import lang from "../../../classes/lang";

const LobbyGameSlider = (props) => {
	LobbyGameSlider.propTypes = {
		caption: PropTypes.string,
		games: PropTypes.array,
		sticker: PropTypes.object, //sticker={{ color: "red", caption: "NEW" }}
		perPage: PropTypes.number,
	};
	// eslint-disable-next-line no-unused-vars
	const { width, height, chatOpened } = useWindowSize();
	const games = props.games;
	const w = width - (chatOpened ? 400 : 0);
	let count = 5;
	if (w > 900) count = 5;
	else if (w > 700) count = 4;
	else if (w > 500) count = 3;
	else count = 2;
	return (
		<div className="LobbyGameSlider">
			<div className="wrapper">
				{/* <div className={`ribbon ${props.sticker.backgroundColor} ribbon-top-left scale08`}>
					<span>{lang.translate(props.caption)}</span>
				</div> */}
				<div className={`caption ${props.sticker.backgroundColor}`}>{lang.translate(props.caption)}</div>
				<Splide key={count} aria-label="Slots" data-splide={`{"type":"slide","perPage":${count}}`}>
					{games.map((game, i) => {
						return (
							<SplideSlide key={i}>
								<div className="slotFrame">
									<SlotegratorGame game={game} i={i} />
									{/* <SlotegratorGame game={game} i={i} sticker={props.sticker} /> */}
								</div>
							</SplideSlide>
						);
					})}
				</Splide>
			</div>
		</div>
	);
};

export default LobbyGameSlider;
