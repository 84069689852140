import React, { PureComponent } from "react";
//other:
import { Decimal } from "decimal.js";
import PropTypes from "prop-types";

const validate = (data, patterns) => {
	let test = true;
	let replaceComma = false;
	patterns.forEach((pattern) => {
		switch (pattern) {
			case "amount":
				if (/^[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data) === false) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				replaceComma = true;
				break;
			case "chance":
				if (/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data) === false) test = false;
				//ex: .9898
				if (data.length >= 1 && (data.charAt(0) === "." || data.charAt(0) === ",")) test = false;
				//ex: 00987
				if (data.length >= 2 && data.charAt(0) === "0" && data.charAt(1) !== "." && data.charAt(1) !== ",") test = false;
				if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				if (data === "0.00" || data === "0,00") test = false;
				replaceComma = true;
				break;
			case "coinName":
				if (/^[a-zA-Z\s]{0,20}$/g.test(data) === false && data !== "") test = false;
				break;
			case "searchKeyword":
				if (/^[a-zA-Zа-яА-Я0-9]{0,1}[a-zA-Zа-яА-Я0-9]{0,50}$/g.test(data) === false && data !== "") test = false;
				break;
			case "integer":
				test = data === "" || (parseInt(data) + "" === data && !isNaN(data));
				break;
			case "integer>0":
				test = data === "" || (parseInt(data) + "" === data && !isNaN(data) && parseInt(data) > 0);
				break;
			case "integer>=0":
				test = data === "" || (parseInt(data) + "" === data && !isNaN(data) && parseInt(data) >= 0);
				break;
			case "payout":
				if (/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data) === false) test = false;
				if (data.indexOf(".") > 4 || data.indexOf(",") > 4) test = false;
				if (data.length >= 1 && (data.charAt(0) === "." || data.charAt(0) === ",")) test = false;
				if (data.length >= 2 && data.charAt(0) === "0" && data.charAt(1) !== "." && data.charAt(1) !== ",") test = false;
				if (data.length > 4 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				replaceComma = true;
				break;
			case "password":
				// if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=]{0,12}$/gi.test(data)) test = false;
				if (/^[a-zA-Z0-9!№@#$%^&*()_+\-=[\].,';:"\\/|><?{}]{0,20}$/gi.test(data) === false) test = false;
				break;
			case "salt":
				if (/^[a-zA-Z0-9!@#$%^&*()_+\-=[\].,;:/|><?{}]{0,32}$/gi.test(data) === false) test = false;
				break;
			case "percent":
				if (/^-?[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data) === false) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) test = false; //-, 2342- , -.
				if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) test = false; //-01
				replaceComma = true;
				break;
			case "negativeAmount":
				if (data === "") break;
				if (/^-?[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data) === false) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) test = false; //-, 2342- , -.
				if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) test = false; //-01
				replaceComma = true;
				break;
			case "rollValue":
				if (/^[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data) === false) test = false;
				if (data.indexOf(".") > 2 || data.indexOf(",") > 2) test = false;
				if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				if (data === "0.00") test = false;
				replaceComma = true;
				break;
			case "short":
				if (/^[a-z]{0,5}$/g.test(data) === false && data !== "") test = false;
				break;
			case "roomName":
				if (/^[a-zA-Z]{0,1}[a-zA-Z0-9\-_]{0,4}$/gi.test(data) === false && data !== "") test = false;
				break;
			case "token":
			case "2fa":
				if (/^[0-9]{0,6}$/gi.test(data) === false && data !== "") test = false;
				break;
			case "userName":
				if (/^[a-zA-Z]+[a-zA-Z0-9\-_]{0,11}$/gi.test(data) === false && data !== "") test = false;
				if (data.length > 12) test = false;
				break;
			default:
		}
	});
	return { test, replaceComma };
};

const onEventValidation = (e, validation, f) => {
	if (validation) {
		const patterns = validation.indexOf("|") > -1 ? validation.split("|") : [validation];
		const result = validate(e.target.value, patterns);
		if (result.test) {
			if (result.replaceComma) e.target.value = e.target.value.replace(",", ".");
			if (f) f(e);
			else return true;
		} else {
			return false;
		}
	} else {
		if (f) f(e);
	}
};
const onBlurValidation = (e, f, min, max, toFixed) => {
	//change value
	if (min !== null || max !== null || toFixed !== null) {
		const dValue = new Decimal(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value);
		if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		if (min !== null && dValue.lessThan(min)) {
			e.target.value = toFixed === null ? min : new Decimal(min).toFixed(toFixed);
		} else if (max !== null && dValue.greaterThan(max)) {
			e.target.value = toFixed === null ? max : new Decimal(max).toFixed(toFixed);
		} else {
			if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		}
	}
	if (typeof f === "function") f(e);
};
function onWheelFunc(e, f) {
	if (e.target.id !== document.activeElement.id) return; //scroll only if focused
	if (typeof f === "function") {
		e.preventDefault();
		e.stopPropagation();
		f(e, e.deltaY);
	}
}
const onFocusFunc = (e, f) => {
	if (typeof f === "function") {
		e.preventDefault();
		e.stopPropagation();
		f(e);
	}
};
const disableNewLine = (e, onEnter) => {
	if (e.keyCode === 13 && !e.shiftKey) {
		e.preventDefault();
		if (onEnter) onEnter(e);
		return false;
	}
};
export default class Input extends PureComponent {
	constructor(props) {
		super(props);
		this.inputRef = null;
		this.inputWheelWrapper = null;
	}
	static get propTypes() {
		return {
			id: PropTypes.string,
			autoFocus: PropTypes.any,
			autoComplete: PropTypes.any,
			type: PropTypes.string,
			value: PropTypes.any,
			placeholder: PropTypes.string,
			disabled: PropTypes.bool,
			onChange: PropTypes.func,
			onBlur: PropTypes.func,
			onEnter: PropTypes.func,
			onUp: PropTypes.func,
			onDown: PropTypes.func,
			onWheel: PropTypes.func,
			onFocus: PropTypes.func,
			validation: PropTypes.string,
			maxLength: PropTypes.number,
			min: PropTypes.number,
			max: PropTypes.number,
			toFixed: PropTypes.number,
			name: PropTypes.string,
			className: PropTypes.string,
			style: PropTypes.object,
			lastpass: PropTypes.bool,
			tabIndex: PropTypes.number,
		};
	}
	componentDidMount() {
		if (this.props.onWheel) {
			this.inputWheelWrapper = function (e) {
				onWheelFunc(e, this.props.onWheel);
			}.bind(this);
			this.inputRef.addEventListener("wheel", this.inputWheelWrapper, { passive: false });
		}
	}
	componentWillUnmount() {
		if (this.props.onWheel) {
			this.inputRef && this.inputRef.removeEventListener("wheel", this.inputWheelWrapper);
		}
	}
	render() {
		const {
			id,
			type = "text",
			tabIndex,
			value,
			autoFocus = null,
			autoComplete = "off",
			placeholder = "",
			disabled,
			onChange = null,
			onBlur = null,
			onEnter = null,
			// onWheel = null,
			onFocus = null,
			validation,
			maxLength = 1000000,
			min = null,
			max = null,
			toFixed = null,
			name,
			className,
			style,
			lastpass = false,
			onUp = null,
			onDown = null,
		} = this.props;
		const props = {
			autoFocus,
			tabIndex,
			"data-lpignore": !lastpass,
			autoComplete,
			style: style,
			className,
			value,
			name: name || `${Date.now()}${Math.random()}`,
			maxLength,
			disabled,
			type,
			placeholder,
			// ref: refProp,
			onFocus: (e) => onFocusFunc(e, onFocus),
			onChange: (e) => onEventValidation(e, validation, onChange),
			onBlur: (e) => onBlurValidation(e, onBlur, min, max, toFixed),
		};
		const fKeyDown = (e) => {
			if (e.keyCode === 9) {
				if (e.shiftKey === false && typeof onDown === "function") {
					e.stopPropagation();
					e.preventDefault();
					onDown(e);
				} else if (e.shiftKey === true && typeof onUp === "function") {
					e.stopPropagation();
					e.preventDefault();
					onUp(e);
				}
			} else {
				disableNewLine(e, onEnter);
				if ((e.keyCode === 38 || (e.key === "Tab" && e.shiftKey === false)) && typeof onUp === "function") onUp(e);
				else if ((e.keyCode === 40 || (e.key === "Tab" && e.shiftKey === true)) && typeof onDown === "function") onDown(e);
			}
		};
		if (id) props.id = id;
		if (props.type === "textarea") {
			delete props.type;
			return <textarea autoCorrect="off" autoCapitalize="off" spellCheck="false" {...props} onKeyDown={fKeyDown} />;
		} else {
			return <input ref={(el) => (this.inputRef = el)} {...props} autoCorrect="off" autoCapitalize="off" spellCheck="false" onKeyDown={fKeyDown} />;
		}
	}
}
