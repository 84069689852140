import React, { useEffect, useState } from "react";
//other:
import PropTypes from "prop-types";
const ModalContent = (props) => {
	ModalContent.propTypes = {
		getContent: PropTypes.func,
		closeModal: PropTypes.func,
	};
	const { getContent } = props;
	const [content, setContent] = useState(null);
	useEffect(() => {
		if (typeof getContent === "function") {
			setContent(getContent());
		}
	}, [getContent]);
	return <div className="modalContent">{content}</div>;
};
export default ModalContent;
