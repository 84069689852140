import React, { useCallback } from "react";
// import { Link } from "react-router-dom"
import PropTypes from "prop-types";
//container:
import MenuButton from "../MenuButton/MenuButton";
import Balance from "../balance/Balance";
//classes:
import { go } from "../../classes/routesLazy";
import ImageCached from "../../classes/cache";
import { lang as _lang } from "../../classes/lang";
//icons:
import IconCa, { iconCaName } from "../../classes/iconCa";
import { Icon } from "react-icons-kit";
// import { ic_message as chatIcon } from "react-icons-kit/md/ic_message";
// import { bars } from 'react-icons-kit/fa/bars'
//redux:
import { useSelector, shallowEqual } from "react-redux";
import { storeSet } from "../..";
import { focusNext } from "../../classes/utills";
// import { store, storeSet } from "../../index"

const Header = (props) => {
	Header.propTypes = {
		lang: PropTypes.string,
		token: PropTypes.string,
		chatOpen: PropTypes.bool,
		chatLeft: PropTypes.bool,
		theme: PropTypes.string,
		contestsCount: PropTypes.number,
		myspinsCount: PropTypes.number,
		match: PropTypes.object,
		depBonus: PropTypes.object,
	};
	const translate = _lang.translate;
	const { chatOpen, chatLeft } = props;
	// eslint-disable-next-line no-unused-vars
	const { lang, token, theme, contestsCount, myspinsCount, depBonusActive, isVip } = useSelector(
		(store) => ({
			lang: store.lang.key,
			token: store.token,
			theme: store.theme.key,
			contestsCount: store.contestsCount,
			myspinsCount: store.myspinsCount,
			depBonusActive: store.playerConfig?.depBonus?.active ?? false,
			isVip: store.isVip,
		}),
		shallowEqual
	);
	const renderHeaderContent = useCallback(() => {
		const arr = [
			{
				name: "deposit",
				title: "deposit",
				path: `billing/deposit`,
				iconCa: iconCaName.deposit,
				cl: "diceBtn green",
			},
			{
				name: "faucet",
				title: "faucet",
				path: `billing/faucet`,
				iconCa: iconCaName.faucet,
				cl: "btnDefault",
			},
			{
				name: "promotion",
				title: "promotion",
				iconCa: iconCaName.gift,
				path: "promotion",
				cl: `btnDefault${myspinsCount > 0 ? " on" : ""}`,
			},
			{
				name: "contests",
				title: "contests",
				iconCa: iconCaName.contest,
				path: `promotion/contests`,
				cl: `btnDefault${contestsCount > 0 ? " on" : ""}`,
			},
			{
				name: "withdraw",
				title: "withdraw",
				path: `billing/withdraw`,
				iconCa: iconCaName.withdraw,
				cl: "btnDefault",
			},
		];

		return (
			<div className="space">
				{arr.map((el, i) => {
					return (
						<React.Fragment key={`mn_${i}`}>
							<button onClick={(e) => [e.stopPropagation(), go(el.path)]} className={el.cl}>
								{el.icon && <Icon size={24} icon={el.icon} />}
								{el.img && <img src={el.img} alt="" title="" />}
								{el.iconCa && <IconCa name={el.iconCa} />}
								<b>{translate(el.name)}</b>
							</button>
							{/* <div key={`mn_${i}_sep`} className="sep"></div> */}
						</React.Fragment>
					);
				})}
			</div>
		);
	}, [contestsCount, myspinsCount, translate]);
	const isLobby = typeof window === "undefined" ? false : window.location.pathname.length <= 4;
	const onRegisterClick = () => {
		if (window.location.pathname.includes("registration")) {
			let refcode = null;
			let o = window.location.pathname.split("registration/");
			if (o.length === 2 && o[1].trim().length > 0) refcode = o[1].trim();
			const match = Object.assign({}, props.match);
			match.params = match.params || {};
			match.params.refcode = refcode;
			storeSet({
				modal: {
					$set: { name: "registration", canClose: true, props: { match } },
				},
			});
		} else go("registration");
	};
	const info = {
		menulink_home: {},
		//top buttons:
		btnMenuButton: { right: "btnSiteSettings", Enter: "menulink_home" },
		btnSiteSettings: {},
	};
	return (
		<div className={`header${isLobby ? " transp" : ""}`}>
			<div className="content">
				{(!chatOpen || (chatOpen && !chatLeft)) && <MenuButton focusNext={(e) => focusNext(e, info)} />}
				<button className="btnLogoHome big" onClick={() => go("home")}>
					<ImageCached height={40} width={160} storageName="ui" url={`/assets/${isVip ? "vip_" : ""}${theme}_logo3.png`} params={{}} />
				</button>
				<button className="btnLogoHome small" onClick={() => go("home")}>
					<ImageCached height={40} width={40} storageName="ui" url={`/assets/${isVip ? "vip_" : ""}logo48x48.png`} params={{}} />
				</button>
				{/* <button className="btnLogoHome" onClick={() => go("home")}>
					<ImageCached height={34} width={34} storageName="ui" url={`/assets/logo48x48.png`} params={{}} />
				</button> */}
				{/* {check && <div className="separator"></div>} */}
				{renderHeaderContent()}
				{!token ? (
					<button className="btnDefault success btnSignUp" onClick={(e) => [e.stopPropagation(), onRegisterClick()]}>
						<label>{translate("registration")}</label>
					</button>
				) : (
					<Balance
						save={true}
						customToggleIcon={depBonusActive ? <IconCa name={iconCaName.gift} style={{ color: "gold", transform: "scale(0.7)" }} /> : null}
					/>
				)}
			</div>
		</div>
	);
};

export default Header;
