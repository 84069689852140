import React, { Component } from "react";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { create, verify } from "../../classes/humanDetector";
import { timeLeft } from "../../classes/utills";
//icons:
import { Icon } from "react-icons-kit";
import { repeat } from "react-icons-kit/fa/repeat";

class HumanDetector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			slider: 0,
			loading: false,
			img: null,
			short: null,
			passed: false,
			ttl: 0,
		};
		this.repeatClick();
	}
	static get propTypes() {
		return {
			onSolve: PropTypes.func,
			//redux:
			lang: PropTypes.string,
			serverTime: PropTypes.number,
			labelContent: PropTypes.element,
		};
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onFinish = async () => {
		await this.setState({ loading: true });
		const response = await verify(this.state.slider);
		if (response.error) {
			await this.setState({ loading: false });
			this.repeatClick();
		} else {
			if (response.success)
				await this.setState({
					loading: false,
					passed: true,
					ttl: 0,
					img: null,
					short: null,
				});
			if (typeof this.props.onSolve === "function") this.props.onSolve();
			else {
				this.setState({
					img: response.img,
					short: response.short,
					ttl: response.ttl,
					loading: false,
					slider: 0,
				});
			}
		}
	};
	repeatClick = async (e) => {
		if (e) e.stopPropagation();
		if (this.state.loading) return;
		await this.setState({ loading: true, slider: 0 });
		const response = await create();
		if (!response.error) {
			this.setState({
				img: response.img,
				short: response.short,
				ttl: response.ttl,
				loading: false,
				slider: 0,
			});
		} else {
		}
	};
	render() {
		if (this.state.passed) return null;
		if (this.state.ttl - this.props.serverTime > 0) {
			return (
				<div className="humanDetector">
					<label>{lang.translate("you_tried_too_many_times")}</label>
					<div className="box">
						<label>{timeLeft(this.state.ttl - this.props.serverTime)}</label>
					</div>
				</div>
			);
		} else if (this.state.ttl !== 0) {
			this.repeatClick();
		}
		return (
			<div className={`humanDetector ${this.state.loading ? "off" : "on"}`}>
				<label>{this.props.labelContent || lang.translate("confirm_you_are_human")}</label>
				<div
					className="box"
					style={{
						...(this.state.img ? { backgroundImage: `url(${this.state.img})` } : {}),
					}}
				>
					<button onClick={this.repeatClick} className="btnIcon success btnRepeat">
						<Icon icon={repeat} />
					</button>
					<div className="arrow"><div></div></div>
					<div className="arrow"><div></div></div>
					<div className="arrow"><div></div></div>
					<div className="innerBox">
						<div className="target" style={{ left: `${(this.state.slider / 500) * 100}%` }}>
							<div
								className="img"
								style={
									this.state.short
										? {
												backgroundImage: `url(/assets/icons/${this.state.short}@2x.png`,
										  }
										: {}
								}
							></div>
						</div>
					</div>
					<input
						name="slider"
						min={1}
						max={500}
						value={this.state.slider}
						className="slider"
						type="range"
						onChange={this.onChange}
						onTouchEnd={this.onFinish}
						onMouseUp={this.onFinish}
					/>
				</div>
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		serverTime: store.serverTime,
	};
};

export default connect(a, null, null, { forwardRef: true })(HumanDetector);
