import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
// import Container from "../../Container";
//redux:
// import { connect } from "react-redux";
//classes:
// import { lang } from "../../classes/lang"
//icons:
// import { Icon } from "react-icons-kit"

class Tab extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static get propTypes() {
		return {
			active: PropTypes.string.isRequired,
			items: PropTypes.array.isRequired, //{name:"ragaca", c: Component}
			onClick: PropTypes.func.isRequired,
			showLine: PropTypes.bool,
			className: PropTypes.string,
			classNameToggled: PropTypes.string,
			//---
			lang: PropTypes.string,
		};
	}
	renderTab = (item, i) => {
		return (
			<div key={`tab_${item.name}_${i}`} className={`tab ${item.name}Tab ${this.props.active === item.name ? "toggled" : "untoggled"}`}>
				<button
					onClick={(e) => [e.stopPropagation(), this.props.onClick(item.name, i)]}
					className={`diceBtn  ${item.cl || ""} ${this.props.active === item.name ? `toggled ${this.props.classNameToggled ?? ""}` : "untoggled"}`}
				>
					{item.c}
				</button>
				{this.props.showLine && <div className="tabLine"></div>}
			</div>
		);
	};
	renderTabs = (items) => {
		const arr = [];
		let i = 0;
		for (let item of items) {
			arr.push(this.renderTab(item, i));
			i++;
		}
		return arr;
	};
	render() {
		const items = this.props.items;
		// const i = items.findIndex((a) => a.name === this.props.active);
		// const activeTab = items[i];

		// const offset = 0;
		// let totalFixedCount = 0;
		// let totalFixedWidth = 0;
		// for (let el of items) {
		// 	if (el.width * 1 >= 0) {
		// 		totalFixedWidth += el.width;
		// 		totalFixedCount++;
		// 	}
		// }
		// // const w = 100 / items.length;
		// let leftPx = 0;
		// let leftPCount = 0;
		// for (let k = 0; k < i; k++) {
		// 	if (items[k].width) {
		// 		leftPx += items[k].width;
		// 	} else {
		// 		leftPCount++;
		// 	}
		// }
		return (
			<div className={`tabComponent ${this.props.className || ""}`}>
				{/* {this.props.showLine && i !== -1 && (
					// <div className="line" style={{ width: `calc(${w}% - ${offset * 2}px)`, left: `calc(${i * w}% + ${offset}px)` }}></div>
					<div
						className="line"
						style={{
							width: activeTab.width
								? activeTab.width + "px"
								: `calc((100% - ${offset * 2 + totalFixedWidth}px) / ${items.length - totalFixedCount})`,
							left: `calc(((100% - ${offset * 2 + totalFixedWidth}px) / ${items.length - totalFixedCount}) * ${leftPCount} + ${leftPx}px)`,
						}}
					></div>
				)} */}
				{this.renderTabs(items)}
			</div>
		);
	}
}
// const a = (store) => {
// 	return {
// 		lang: store.lang.key,
// 	};
// };
// export default connect(a, null, null, { forwardRef: true })(Test);
export default Tab;
