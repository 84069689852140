import "../node_modules/bpg-glaho-web-caps/css/bpg-glaho-web-caps.min.css";
import 'react-responsive-modal/styles.css';
import "./index.scss";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";

//import * as serviceWorker from './serviceWorker';

import reducer, { initialState } from "./redux/reducer";

import { Router } from "react-router-dom";
import history from "./classes/history";
//classes:
import * as cache from "./classes/cache";
import routes from "./classes/routesLazy";
import Storage from "./classes/storage";
//components:
import Loader from "./components/loader/Loader";
//containers:
import App from "./containers/App/App";
import { renew } from "./classes/socket";
import "./classes/hotKeys";
import "./classes/sound";

let preloadedState = {};
const isServer = typeof document === "undefined";
if (!isServer) {
	if (window.__PRELOADED_STATE__) {
		preloadedState = Object.assign({}, initialState, window.__PRELOADED_STATE__);
		delete window.__PRELOADED_STATE__;
		preloadedState.token = Storage.get("token", null, "str");
		if (preloadedState.chat.location === "to") preloadedState.chat.location = "pm";
		// console.log("preloadedState: ", preloadedState);
	} else {
		preloadedState = Object.assign({}, initialState, {
			// modal: { name: "Offline", canClose: false, props: {} },
		});
		preloadedState.token = Storage.get("token", null, "str");
	}
}
export const store = createStore(reducer, preloadedState);
const cookieSave = async (save = []) => {
	const d = new Date();
	d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
	const expires = `expires=${d.toUTCString()}`;
	const state = await store.getState();
	if (!Array.isArray(save)) save = [save];
	for (let key of save) {
		if (typeof key !== "string" || typeof state[key] === "undefined") continue;
		document.cookie = `${key}=${JSON.stringify(state[key])};${expires};path=/;SameSite=Lax`;
	}
};
/**
 * set store values:
 * @param {*} o
 * @param {Array || String} save //reducer keys to save to cookies
 */
export const storeSet = async (o, save) => {
	await store.dispatch({ type: "", payload: o });
	save && !isServer && cookieSave(save);
};

const preloader = () => {
	return (
		<div className="loaderWrapper">
			<Loader />
		</div>
	);
};
const run = async () => {
	cache.init();
	const _routes = await routes();
	await renew(preloadedState.token);
	// const state = await store.getState();
	const _loader = preloader();

	const application = (
		<Suspense fallback={_loader}>
			<Provider store={store}>
				<Router history={history}>
					<div className={`theme theme-${preloadedState.theme.key}`}>
						<App routes={_routes} />
					</div>
				</Router>
			</Provider>
		</Suspense>
	);
	// const root = ReactDOM.createRoot(document.getElementById("root"));
	// root.render(application);
	ReactDOM.render(application, document.getElementById("root"));
};

if (!isServer) {
	run();
	// serviceWorker.unregister();
}
