import { lazy } from "react";
import { lang } from "./lang";
import history from "./history";
import Storage from "./storage";
import { storeSet } from "../index";
import { gaPageView } from "../classes/ga";
import SlotGame from "../containers/slotegrator/SlotGame";
import News from "../containers/news/News";
import NewsSlides from "../containers/news/NewsSlides";
import SlotegratorSrv from "../containers/slotegrator/SlotegratorSrv";
import FlappyBet from "../containers/flappyBet/FlappyBet";
import FlappyBetGame from "../containers/flappyBet/FlappyBetGame";
// const App = lazy(() => import("../containers/App/App"));
// const Registration = lazy(() => import("../containers/Registration/Registration"));
const Lobby = lazy(() => import("../containers/Lobby/Lobby"));
// const Test = lazy(() => import("../containers/Test"));
const NotFound = lazy(() => import("../containers/NotFound/NotFound"));
const Profile = lazy(() => import("../containers/Profile/Profile"));
const Password = lazy(() => import("../containers/Password/Password"));
const _2fa = lazy(() => import("../containers/2fa/2fa"));
const FBI = lazy(() => import("../containers/fbi/FBI"));
// const Faucet = lazy(() => import("../containers/faucet/Faucet"));
const Mail = lazy(() => import("../containers/mail/Mail"));
const Dice = lazy(() => import("../containers/games/dice/Dice"));
// const Crash = lazy(() => import("../containers/games/crash/Crash"));
// const Exchange = lazy(() => import("../containers/exchange/Exchange"));
const Pandora = lazy(() => import("../containers/pandora/Pandora"));
const Affiliation = lazy(() => import("../containers/affiliation/Affiliation"));
const AffiliationPro = lazy(() => import("../containers/affiliation/AffiliationPro"));
const Faq = lazy(() => import("../containers/faq/Faq"));
// const Tip = lazy(() => import("../containers/tip/Tip"));
const Billing = lazy(() => import("../containers/billing/Billing"));
// const Contests = lazy(() => import("../containers/contests/Contests"));
// const Contest = lazy(() => import("../containers/contests/Contest"));
const Promotion = lazy(() => import("../containers/promotion/Promotion"));
const History = lazy(() => import("../containers/History/History"));
const Mute = lazy(() => import("../containers/mute/Mute"));
const WhoIsOnline = lazy(() => import("../containers/whoIsOnline/WhoIsOnline"));
// const Slotegrator = lazy(() => import("../containers/slotegrator/Slotegrator"));
const Sportegrator = lazy(() => import("../containers/sportegrator/Sportegrator"));
const Battles = lazy(() => import("../containers/battle/Battles"));
const BattleView = lazy(() => import("../containers/battle/BattleView"));

const { KEYS } = require("../classes/keywords");

export const goPrev = () => {
	history.go(-1);
};
let prevUrl = null;
export const getPrevPathname = () => prevUrl;
export const go = async (where, param, doNotReload = false) => {
	if (typeof where !== "string") return;
	const prevPathname = window.location.pathname || "";
	// console.log("go: ", where, param);
	if (!history) return;
	prevUrl = prevPathname;
	const lng = await lang.getSelected();
	let str;
	if (where === "home" || where === "") {
		str = `/${lng}`;
	} else {
		if (param) {
			str = `/${lng}/${where}/${param}`;
		} else {
			str = `/${lng}/${where}`;
		}
	}
	if (prevPathname.includes("crypto-sport-casino") || (!str.includes("crypto-sport-casino") && !!window.Piwik && !doNotReload)) {
		console.log("refresh page");
		window.location.href = str;
	} else {
		history.push(str);
	}
	if (typeof window !== "undefined") {
		storeSet({ pathname: { $set: window.location.pathname } }).then(() => {
			gaPageView(where);
		});
		window.scrollTo(0, 0);
	}
};
export const regUrlFrequency = (route) => {
	if (route.name) {
		//store how many times we navigated to certain routes:
		const urls = Storage.get("urls", {});
		if (typeof urls[route.name] === "undefined")
			urls[route.name] = {
				name: route.name,
				path: window.location.pathname,
				n: 1,
			};
		else urls[route.name]["n"] += 1;
		const arr = [];
		for (let el in urls) {
			urls[el]["n"] /= 2;
			arr.push(urls[el]);
		}
		arr.sort((a, b) => b.n - a.n);
		arr.slice(0, 11);
		const o = {};
		for (let el of arr) o[el.name] = el;
		Storage.set("urls", o);
	}
};
const FBI_PERMS = [
	"DO_TIP",
	"PLAYER_FIND",
	"PERMISSION_VIEW",
	"PERMISSION_CHANGE",
	"PASSWORD_RESET",
	"_2FA_RESET",
	"COINS_VIEW",
	"COINS_ADD",
	"COINS_EDIT",
	"COINS_DELETE",
	"FAUCET_VIEW",
	"FAUCET_CHANGE",
	"CHAT_GAME_VIEW",
	"CHAT_GAME_CHANGE",
	"JACKPOT_VIEW",
	"JACKPOT_CHANGE",
	"ROOM_VIEW",
	"ROOM_CHANGE",
	"EXCHANGE_VIEW",
	"EXCHANGE_CHANGE",
	"AFFILIATION_VIEW",
	"AFFILIATION_CHANGE",
	"SEE_OVERALL_STATS",
	"TIP_VIEW",
	"TIP_CHANGE",
	"RAIN_VIEW",
	"RAIN_CHANGE",
	"ACTIONS_CHART_VIEW",
	"BALANCE_VIEW",
	"BALANCE_CHANGE",
	"WITHDRAWS_VIEW",
	"WITHDRAWS_APPROVE",
	"WITHDRAWS_REJECT",
	"WITHDRAWS_HOLD",
	"CONTESTS_VIEW",
	"CONTESTS_CREATE",
	"DEPOSIT_BONUS_VIEW",
	"DEPOSIT_BONUS_CHANGE",
	"DEPOSITS_VIEW",
	"PLAYERS_VIEW",
	"FAQ_EDIT",
	"DICE_MONITOR",
	"MUTE",
	"BAN",
	"DESIGN_ALL",
	"SEO_EDIT",
	"SEO_VIEW",
	"SLOT_EDIT",
	"SLOT_VIEW",
	"MOD_ASSIGN",
	"MOD_REVOKE",
];
const routes = async () => {
	const lng = await lang.getSelected();
	const lngPrefix = `:lang(${KEYS.join("|")})`;
	return [
		// {
		// 	path: '/:lang(en|ru)/test',
		// 	component: Test,
		// 	exact: true
		// },
		{
			name: "home",
			path: `/${lngPrefix}`,
			component: Lobby,
			exact: true,
		},
		{
			name: "registration",
			path: `/${lngPrefix}/registration`,
			component: Lobby,
			tokenTo: `/${lng}`,
			exact: true,
		},
		{
			name: "registration",
			path: `/${lngPrefix}/registration/:refcode`,
			component: Lobby,
			tokenTo: `/${lng}`,
			exact: true,
		},
		{
			name: "registration",
			path: `/${lngPrefix}/registration/:refcode/:campaign`,
			component: Lobby,
			tokenTo: `/${lng}`,
			exact: true,
		},
		{
			name: "registration",
			path: `/${lngPrefix}/registration/:refcode/:campaign/:promocode`,
			component: Lobby,
			tokenTo: `/${lng}`,
			exact: true,
		},
		{
			path: `/${lngPrefix}/recover/:uid/:code`,
			component: Lobby,
			tokenTo: `/${lng}`,
			exact: true,
		},
		{
			name: "news",
			path: `/${lngPrefix}/news`,
			component: NewsSlides,
			exact: true,
		},
		{
			name: "news",
			path: `/${lngPrefix}/news/:topic`,
			component: News,
			exact: true,
		},
		{
			name: "tc",
			path: `/${lngPrefix}/terms-and-conditions`,
			component: News,
			exact: true,
		},
		{
			name: "info",
			path: `/${lngPrefix}/info/:topic`,
			component: News,
			exact: true,
		},
		{
			name: "password_management",
			path: `/${lngPrefix}/password`,
			component: Password,
			guestTo: `/${lng}/registration`,
			exact: true,
		},
		{
			name: "gfa_management",
			path: `/${lngPrefix}/2fa`,
			component: _2fa,
			guestTo: `/${lng}/registration`,
			exact: true,
		},
		{
			name: "history",
			path: `/${lngPrefix}/history`,
			component: History,
			guestTo: `/${lng}/history`,
			perms: ["HISTORY_VIEW"],
			exact: true,
		},
		{
			name: "history",
			path: `/${lngPrefix}/history/:targetUserName`,
			component: History,
			guestTo: `/${lng}/history/:targetUserName`,
			perms: ["HISTORY_VIEW"],
			exact: true,
		},
		{
			name: "who_is_online",
			path: `/${lngPrefix}/who_is_online`,
			component: WhoIsOnline,
			exact: true,
		},
		{
			name: "mute",
			path: `/${lngPrefix}/mute`,
			component: Mute,
			guestTo: `/${lng}/mute`,
			exact: true,
		},
		{
			name: "profile",
			path: `/${lngPrefix}/profile/:targetUserName`,
			component: Profile,
			perms: ["AVATAR_UPLOAD"],
			exact: true,
		},
		{
			name: "battle",
			path: `/${lngPrefix}/battle`,
			component: Battles,
			exact: true,
		},
		{
			name: "battle",
			path: `/${lngPrefix}/battle/:battleId`,
			component: BattleView,
			exact: true,
		},
		{
			name: "fbi",
			path: `/${lngPrefix}/fbi/:section/:targetUserName`,
			component: FBI,
			perms: FBI_PERMS,
			exact: true,
		},
		{
			name: "fbi",
			path: `/${lngPrefix}/fbi/:section/id/:id`,
			component: FBI,
			perms: FBI_PERMS,
			exact: true,
		},
		{
			name: "fbi",
			path: `/${lngPrefix}/fbi/:section`,
			component: FBI,
			perms: FBI_PERMS,
			exact: true,
		},
		{
			name: "pandora",
			path: `/${lngPrefix}/pandora-online-casino`,
			component: Pandora,
			exact: true,
		},
		{
			path: `/${lngPrefix}/slot/:game/:provider`,
			component: SlotGame,
		},
		{
			path: `/${lngPrefix}/demo/:game/:provider`,
			component: SlotGame,
		},
		{
			name: "slots",
			path: `/${lngPrefix}/crypto-slots`,
			component: SlotegratorSrv,
			exact: true,
		},
		{
			name: "slots",
			path: `/${lngPrefix}/crypto-slots/:category`,
			component: SlotegratorSrv,
			exact: true,
		},
		{
			name: "slots",
			path: `/${lngPrefix}/crypto-slots/:category/:provider/:page`,
			component: SlotegratorSrv,
			exact: true,
		},
		{
			name: "originals",
			path: `/${lngPrefix}/originals`,
			component: FlappyBet,
			exact: true,
		},
		{
			name: "originals",
			path: `/${lngPrefix}/originals/:gamename/:gameid/:isdemo`,
			component: FlappyBetGame,
			exact: true,
		},
		{
			name: "sport",
			path: `/${lngPrefix}/crypto-sport-casino`,
			component: Sportegrator,
			exact: true,
		},
		{
			name: "affiliation",
			path: `/${lngPrefix}/affiliation`,
			component: Affiliation,
			exact: true,
		},
		{
			name: "affiliation",
			path: `/${lngPrefix}/affiliation-pro`,
			component: AffiliationPro,
			exact: true,
		},
		{
			name: "affiliation",
			path: `/${lngPrefix}/affiliation-pro/:targetUserName`,
			component: AffiliationPro,
			guestTo: `/${lngPrefix}/affiliation-pro/:targetUserName`,
			perms: ["HISTORY_VIEW"],
			exact: true,
		},
		{
			name: "faq",
			path: `/${lngPrefix}/faq`,
			component: Faq,
			exact: true,
		},
		{
			name: "faq",
			path: `/${lngPrefix}/faq/:topic`,
			component: Faq,
			exact: true,
		},
		{
			name: "dice",
			path: `/${lngPrefix}/crypto-bitcoin-dice`,
			component: Dice,
			exact: true,
		},
		// {
		// 	name: "crash",
		// 	path: `/${lngPrefix}/crypto-betting-crash`,
		// 	component: Crash,
		// 	exact: true,
		// },
		// {
		// 	name: "faucet",
		// 	path: `/${lngPrefix}/faucet`,
		// 	component: Faucet,
		// 	exact: true,
		// },
		// {
		// 	name: "exchange",
		// 	path: `/${lngPrefix}/exchange`,
		// 	component: Exchange,
		// 	exact: true,
		// 	perms: ["DO_EXCHANGE"],
		// },
		// {
		// 	name: "tip",
		// 	path: `/${lngPrefix}/tip`,
		// 	component: Tip,
		// 	exact: true,
		// 	perms: ["PLAYER_FIND", "DO_TIP"],
		// },
		// {
		// 	name: "tip",
		// 	path: `/${lngPrefix}/tip/:targetUserName`,
		// 	component: Tip,
		// 	exact: true,
		// 	perms: ["PLAYER_FIND", "DO_TIP"],
		// },
		{
			name: "promotion",
			path: `/${lngPrefix}/promotion`,
			component: Promotion,
			exact: true,
			guestTo: `/${lng}/promotion/deposit_bonus`,
			tokenTo: `/${lng}/promotion/deposit_bonus`,
			perms: [],
		},
		{
			name: "promotion",
			path: `/${lngPrefix}/promotion/:section(deposit_bonus|contests|freespins|promocode)`,
			component: Promotion,
			exact: true,
			perms: [],
		},
		{
			name: "promotion",
			path: `/${lngPrefix}/promotion/:section(contests)/:contestId`,
			component: Promotion,
			exact: true,
			perms: [],
		},
		{
			name: "promotion",
			path: `/${lngPrefix}/promotion/:section(contests)/:contestId/:uid`,
			component: Promotion,
			exact: true,
			perms: [],
		},
		{
			name: "billing",
			path: `/${lngPrefix}/billing`,
			component: Billing,
			exact: true,
			perms: ["PLAYER_FIND", "DO_TIP", "DO_EXCHANGE", "BANK_TO", "BANK_FROM"],
		},
		{
			name: "billing",
			path: `/${lngPrefix}/billing/:section(deposit|depositfiat|withdraw|exchange|tip|faucet|rain|bank|fsbuy|buycrypto|sellcrypto)`,
			component: Billing,
			exact: true,
			perms: ["PLAYER_FIND", "DO_TIP", "DO_EXCHANGE", "BANK_TO", "BANK_FROM"],
		},
		{
			name: "billing",
			path: `/${lngPrefix}/billing/:section(deposit|depositfiat|withdraw|exchange|tip|faucet|rain|bank|fsbuy|buycrypto|sellcrypto)/:targetUserName`,
			component: Billing,
			exact: true,
			perms: ["PLAYER_FIND", "DO_TIP", "DO_EXCHANGE", "BANK_TO", "BANK_FROM"],
		},
		{
			name: "billing",
			path: `/${lngPrefix}/billing/:section(deposit|depositfiat)/coin/:coin`,
			component: Billing,
			exact: true,
			perms: ["PLAYER_FIND", "DO_TIP", "DO_EXCHANGE", "BANK_TO", "BANK_FROM"],
		},
		{
			name: "mail",
			path: `/${lngPrefix}/mail`,
			component: Mail,
			exact: true,
		},
		{
			path: `/${lngPrefix}/verify/:uid/:mailCode`,
			component: Lobby,
			exact: true,
		},
		{
			path: "/",
			to: `/${lng}`,
			component: Lobby,
			exact: true,
		},
		{
			path: `/${lngPrefix}/slot-id/:slotId`,
			component: Lobby,
			exact: true,
		},
		{
			path: `/${lngPrefix}/flappybet-id/:slotId`,
			component: Lobby,
			exact: true,
		},
		{
			path: `/${lngPrefix}/dice-id/:diceId`,
			component: Lobby,
			exact: true,
		},
		{
			path: `/${lngPrefix}/404`,
			component: NotFound,
			status: 404,
		},
		{
			path: `/${lngPrefix}/*`,
			component: NotFound,
			status: 404,
		},
		{
			path: "*",
			to: `/${lng}/404`,
			status: 404,
		},
	];
};

export default routes;
