import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//redux:
// import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
//icons:
// import { Icon } from "react-icons-kit"

class Toggle extends PureComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			loading: false,
			value: typeof props.value === "boolean" ? props.value : false,
			initialValue: typeof props.value === "boolean" ? props.value : false,
		};
	}
	
	static get propTypes() {
		return {
			// lang: PropTypes.string
			name: PropTypes.string,
			on: PropTypes.string,
			off: PropTypes.string,
			value: PropTypes.bool,
			onChange: PropTypes.func,
			disabled: PropTypes.bool,
		};
	}
	clear = () => {
		this.setState({
			value: this.state.initialValue,
		});
	};
	
	setValue = (value) => {
		this.setState({
			value: typeof value === "boolean" ? value : false,
		});
	};

	// componentDidUpdate =(prevProps, prevState) =>{
	// 	if (this.props.value !== this.state.value) {

	// 	}
	// }

	onToggle = async (e, name) => {
		if (e) e.stopPropagation();
		const v = !this.state.value;
		await this.setState({ value: v, loading: true });
		if (typeof this.props.onChange === "function") this.props.onChange(e, name, v);
		await this.setState({ loading: false });
	};
	render() {
		const on = lang.translate(typeof this.props.on === "string" ? this.props.on : "on");
		const off = lang.translate(typeof this.props.off === "string" ? this.props.off : "off");
		const n = on.length > off.length ? on.length : off.length;
		const txt = this.state.value ? `${on}${"".repeat(n - on.length)}` : `${off}${"".repeat(n - off.length)}`;

		return (
			<button
				disabled={this.props.disabled || this.state.loading}
				name={this.props.name}
				className={`btnDefault toggleButton ${this.state.value ? "success" : "alert"}`}
				onClick={(e) => this.onToggle(e, this.props.name)}
			>
				<div className={"toggleSlider"}>
					<div></div>
				</div>
				{n > 0 && (
					<label className="lbl" style={{ width: `${n * 11}px` }}>
						{txt}
					</label>
				)}
			</button>
		);
	}
}
// const a = store => {
//   return {
//     lang: store.lang.key
//   };
// };

//export default connect(a, null, null, { forwardRef: true })(Toggle);
export default Toggle;
