import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import Form from "../../components/form/Form";
import Select from "../../components/select/Select";
import Loader from "../../components/loader/Loader";
//containers:
import Container from "../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
// eslint-disable-next-line no-unused-vars
import { siteSettingsSet } from "../../classes/system";
//icons:
import IconCa from "../../classes/iconCa";
import { Icon } from "react-icons-kit";
import { ic_chat_bubble_outline as chatIcon } from "react-icons-kit/md/ic_chat_bubble_outline";
import { ic_save } from "react-icons-kit/md/ic_save";
class SiteSettings extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMenuItem: this.props.selected ? this.props.selected : "chat",
			loading: false,
		};
		this.menu = [
			{ value: "custom", title: "custom" },
			{ value: "chat", title: "chat", icon: chatIcon },
		];
		this.forms = {};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			siteSettings: PropTypes.object,
			siteSettingsDefaults: PropTypes.object,
			selected: PropTypes.string,
		};
	}
	onSave = (e, o) => {
		delete o.save;
		if (e) e.stopPropagation();
		this.setState({ loading: true });
		siteSettingsSet(this.state.selectedMenuItem, o, () => {
			this.setState({ loading: false });
		});
	};
	onMenuSelect = async (item) => {
		await this.setState({ selectedMenuItem: "" });
		if (this.frm) this.frm.clear();
		await this.setState({ selectedMenuItem: item.id });
	};
	renderMenu = (type = 0) => {
		const selectData = [];
		const o = [];
		for (let el of this.menu) {
			selectData.push({
				id: el.value,
				value: el.value,
				title: lang.translate(el.title),
			});
			o.push(
				<button
					key={`renderMenu_ss_${el.value}`}
					onClick={(e) => [
						e.stopPropagation(),
						this.onMenuSelect({
							id: el.value,
							value: el.value,
							title: lang.translate(el.title),
						}),
					]}
					disabled={this.state.loading}
					className={`btnDefault menuItem ${this.state.selectedMenuItem === el.value ? "selected" : ""}`}
				>
					{el.icon && <Icon icon={el.icon} />}
					{el.iconCa && <IconCa icon={el.iconCa} />}
					<label>{lang.translate(el.title)}</label>
				</button>
			);
		}
		if (type === 1)
			return (
				<Fragment key={`renderMenu_ss_${type}`}>
					<Select
						style={{ width: "100%" }}
						caseSearch={false}
						valueHeader={false}
						titleHeader={true}
						onSelect={this.onMenuSelect}
						multi={false}
						min={1}
						selected={[
							{
								id: this.state.selectedMenuItem,
								title: lang.translate(this.state.selectedMenuItem),
							},
						]}
						data={selectData}
					/>
				</Fragment>
			);
		else return o;
	};
	renderContent = () => {
		if (this.selectedMenuItem === "") return null;
		const data = [];
		for (let el in this.props.siteSettingsDefaults[this.state.selectedMenuItem]) {
			let value = this.props.siteSettingsDefaults[this.state.selectedMenuItem][el]; //default
			if (this.props.siteSettings[this.state.selectedMenuItem] && this.props.siteSettings[this.state.selectedMenuItem][el]) {
				value = this.props.siteSettings[this.state.selectedMenuItem][el];
			}
			let str = lang.translate(el);
			let type;
			if (typeof value === "boolean") {
				type = "toggle";
			} else if (typeof value === "string" || typeof value === "number") {
				type = "input";
			} else {
				type = "component";
			}
			let o = { type, value, label: str, name: el };
			if (type === "toggle") {
				o.on = "on";
				o.off = "off";
			} else if (type === "input") {
				o.placeholder = lang.translate(el);
				o.validation = "integer";
				o.min = 5;
				o.max = 100;
				o.value += "";
			} else continue;
			data.push(o);
		}
		return (
			<Form
				ref={(el) => (this.frm = el)}
				data={data}
				buttons={[
					{
						label: "save",
						icon: ic_save,
						className: "warn",
						onClick: this.onSave,
					},
				]}
			/>
		);
	};
	render() {
		const item = this.menu.filter((el) => el.value === this.state.selectedMenuItem)[0];
		return (
			<Container noDocs={true}>
				<div className="siteSettings">
					<header>
						<h1>{lang.translate(item ? item.title : "settings")}</h1>
					</header>
					{typeof this.props.siteSettings === "undefined" ? (
						<Loader />
					) : (
						<>
							<section className="menuSelect">{this.renderMenu(1)}</section>
							<section>
								<div className="pane lPane">{this.renderMenu(0)}</div>
								<div className="pane rPane">{this.renderContent(this.state.selectedMenuItem)}</div>
							</section>
						</>
					)}
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		siteSettings: store.playerConfig ? store.playerConfig.siteSettings : undefined,
		siteSettingsDefaults: store.playerConfigDefaults.siteSettings,
	};
};

export default connect(a, null, null, { forwardRef: true })(SiteSettings);
