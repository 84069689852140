import Axios from "./axios";
import { onResult } from "./utills";
import { store, storeSet } from "../index";
import { chat } from "./chat";
import { lang } from "./lang";
import { yesNoWrap } from "./utills";

let instance = null;
class Friend {
	constructor() {
		if (instance) return instance;
		instance = this;
		Friend.inst = instance;
		this.time = new Date();
	}
	requestSend = async (f) => {
		const result = await Axios.post("/api/friend/requestSend", { friendUid: f.uid });
		return await onResult(result, "unexpected_error");
	};
	accept = async (f) => {
		const result = await Axios.post("/api/friend/accept", { friendUid: f.uid });
		return await onResult(result, "unexpected_error");
	};
	reject = async (f) => {
		yesNoWrap(`${lang.translate("remove_from_friends")}: ${f.un}`, async (boolResponse) => {
			if (!boolResponse) return;
			const result = await Axios.post("/api/friend/reject", { friendUid: f.uid });
			return await onResult(result, "unexpected_error");
		});
	};
	ignore = async (f) => {
		yesNoWrap(`${lang.translate("ignore")}: ${f.un}`, async (boolResponse) => {
			if (!boolResponse) return;
			const result = await Axios.post("/api/friend/ignore", { uid: f.uid });
			return await onResult(result, "unexpected_error");
		});
	};
	unignore = async (f) => {
		const result = await Axios.post("/api/friend/unignore", { uid: f.uid });
		return await onResult(result, "unexpected_error");
	};
	isFriend = async ({ state = null, uid }) => {
		const friends = (state || (await store.getState())).friends;
		return friends && friends.all && friends.all[uid] && friends.all[uid].pending === false && friends.all[uid].ignored !== true;
	};
	isFriendSync = (state, uid) => {
		const friends = state.friends;
		return friends && friends.all && friends.all[uid] && friends.all[uid].pending === false && friends.all[uid].ignored !== true;
	};
	isPendingFriend = async ({ state = null, uid }) => {
		const friends = (state || (await store.getState())).friends;
		return friends && Array.isArray(friends.pending) && typeof friends.pending.find((el) => el.uid === uid) !== "undefined";
	};
	onIgnore = async (f) => {
		this.onFriend(f);
	};
	onUnignore = async (f) => {
		this.onUnfriend(f);
	};
	getPendingCount = (friends, f = null, add = true) => {
		const o = Object.assign({}, friends);
		if (f) {
			if (add) o[f.uid] = f;
			else delete o[f.uid];
		}
		let n = 0;
		for (let key in o) if (o[key].pending === true) ++n;
		return n;
	};
	onFriend = async (f) => {
		let friends = await store.getState().friends.all;
		await storeSet({
			friends: {
				all: { [f.uid]: { $set: f } },
				pendingCount: { $set: this.getPendingCount(friends, f, true) },
			},
		});
		await chat.reloadChat();
	};
	onUnfriend = async (f) => {
		let friends = await store.getState().friends.all;
		await storeSet({
			friends: {
				all: { $unset: [f.uid] },
				pendingCount: { $set: this.getPendingCount(friends, f, false) },
			},
		});
		await chat.reloadChat();
	};
	isIgnored = async ({ state, uid }) => {
		const friends = state ? state.friends.all : await store.getState().friends.all;
		if (friends && friends[uid]) {
			return friends[uid].ignored === true;
		} else return false;
	};
}
export const friend = new Friend();
