import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDayFromTimestamp } from "../../classes/utills";
import config from "../../config";
import Container from "../Container";

const NewsSlides = () => {
	const folder = "newsSlides";
	const newsSlides = useSelector((store) => store.newsSlides);
	const langRoute = useSelector((store) => store.langRoute || "en");
	const renderNews = () => {
		if (!newsSlides) return null;
		const arr = [];
		let i = 0;
		for (let id in newsSlides) {
			const el = newsSlides[id];
			let description = el.description[langRoute];
			const url = `${config.links.cloud}f_auto,q_auto/v${el.img}/${folder}/${el.id}.${el.type || "jpg"}`;
			if (typeof description !== "string" || description.trim().length === 0) description = `${el.description["en"] ?? ""}`.trim();
			arr.push(
				<Link to={el.url}>
					<div key={id} className="newsPost animScaleFadeIn" style={{ animationDelay: `${0.15 * i}s` }}>
						<h2>{description}</h2>
						<div>
							<img src={url} title={description} alt={description} />
							<div className="time">{getDayFromTimestamp(el.tim)}</div>
						</div>
					</div>
				</Link>
			);
			i++;
		}
		return arr;
	};
	return <Container className={folder}>{renderNews()}</Container>;
};
export default forwardRef(NewsSlides);
