// const createBrowserHistory = require("history").createBrowserHistory

// export const history = createBrowserHistory({
//   basename: '/',             // The base URL of the app (see below)
//   forceRefresh: false,      // Set true to force full page refreshes
// });


let history

if (typeof document !== 'undefined') {
  const createBrowserHistory = require('history').createBrowserHistory

  history = createBrowserHistory({
		basename: '/',             // The base URL of the app (see below)
		forceRefresh: false,      // Set true to force full page refreshes
	})
}

export default history