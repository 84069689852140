import { keywords, KEYS, FULL_KEYS } from "./keywords";
import { store, storeSet } from "../index";
const state = async () => await store.getState();
export const language_default = "en"; //default, used if language not found in keys
let language_selected;

//returns browser lang (only if supported language) or default
export const extractLang = (lng = undefined) => {
	let _lng = lng;
	if (!_lng && typeof navigator === "object" && navigator !== null) {
		_lng = navigator.language || navigator.userLanguage || (Array.isArray(navigator.languages) ? navigator.languages[0] : undefined);
		if (typeof _lng === "string") _lng = _lng.slice(0, 2);
	}
	language_selected = KEYS.indexOf(_lng) !== -1 ? _lng : language_default;
	// console.log("extractLang:", lng, ", lng: ", _lng, KEYS, "language_selected: ", language_selected);
	storeSet({ langRoute: { $set: language_selected } });
	return language_selected;
};
const _getSelectedSync = (state) => {
	return state.lang.key || extractLang(language_selected);
};
export const lang = {
	//call from language change only
	storeLocale: async (lng) => {
		extractLang(lng);
		await storeSet({ lang: { key: { $set: language_selected } } }, "lang"); //saves to cookies also
	},
	//pass match lang param
	setLocale: (lng) => {
		return extractLang(lng);
	},
	getSelected: async () => {
		return _getSelectedSync(await state());
	},
	getSelectedSync: (state) => {
		return _getSelectedSync(state);
	},
	toTitleCase: (str) => {
		return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
	},
	toTitleCaseAll: (str) => {
		return str.replace(/\w\S*/g, (str) => {
			return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
		});
	},
	translate: (key, args) => {
		if (!key) return key;
		try {
			const _language = FULL_KEYS[language_selected] || FULL_KEYS[language_default];
			if (keywords[_language]) {
				let str = keywords[_language][key] ?? keywords[FULL_KEYS[language_default]][key] ?? key;
				if (typeof args !== "undefined") {
					if (!Array.isArray(args)) args = [args];
					for (let i = 0; i < args.length; i++) str = str.replace(`{${i}}`, args[i]);
				}
				return str + "";
			} else {
				return key + "";
			}
		} catch (err) {
			console.log("errr", err);
			return "";
		}
	},
};
export default lang;
