import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import reactStringReplace from "react-string-replace";
import debounce from "lodash/debounce";
//classes:
import { lang } from "../../classes/lang";
import { delay } from "../../classes/utills";
import ImageCached from "../../classes/cache";
//components:
import List from "../list/List";
import Input from "../input/Input";
import DigitScreen from "../digit/DigitScreen";
//redux:
import { connect } from "react-redux";
//icons:
import { Icon } from "react-icons-kit";
// import { caretDown } from 'react-icons-kit/fa/caretDown';
// import { caretUp } from 'react-icons-kit/fa/caretUp';
import { circleDown as toggleIcon } from "react-icons-kit/icomoon/circleDown";
import { textColor as caseIcon } from "react-icons-kit/icomoon/textColor";

class Select extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			open: false,
			value: "",
			// selected: this.getSelectedData(),
			case: false,
		};
		this.listRef = React.createRef();
		this.doSearch("");
		this.id = `multiSelect_${Math.random()}`;
		this.domRef = null;
		this.debouncedSearch = this.debouncedSearch.bind(this);
		this.toggle = this.toggle.bind(this);
	}
	static get propTypes() {
		return {
			id: PropTypes.string,
			className: PropTypes.string,
			langKey: PropTypes.string,
			placeholder: PropTypes.string,
			validation: PropTypes.string,
			onSearch: PropTypes.func,
			onSelect: PropTypes.func,
			onUnselect: PropTypes.func,
			onClear: PropTypes.func,
			sortBy: PropTypes.oneOf(["title", "value"]), //default title
			multi: PropTypes.bool, //default false
			min: PropTypes.number, //default 0 - how many items to remain selected
			max: PropTypes.number, //default Infinity - how many items can be selected
			iconHeader: PropTypes.bool, //show icon in header, default true
			valueHeader: PropTypes.bool, //show value in header, default true
			valueBody: PropTypes.bool, //show value in body, default true
			titleHeader: PropTypes.bool, //show title in header, default true
			caseSearch: PropTypes.bool, //show case toggler button, default true
			digitHeader: PropTypes.bool, //show value as digit screen, default false
			toggleButton: PropTypes.bool, //hide toggle button (only if min(1), single and has selection)
			data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired, //simple string array [...] or [{title, value, icon}, ...]
			selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]), //simple string array [...]
			rounding: PropTypes.number, //defaults to 8,
			trim: PropTypes.number, //header value length, valid only if value is provided
			trimAfterDot: PropTypes.number,
			style: PropTypes.object,
			otherContent: PropTypes.any, //dom
			otherContentTop: PropTypes.any, //dom
			pathname: PropTypes.string,

			headerLabel: PropTypes.any,
			customToggleIcon: PropTypes.any,
		};
	}
	componentDidMount = () => {
		if (typeof document === "undefined") return;
		this.domRef = document.getElementById(this.id);
		document.addEventListener("click", this.onDocumentClick);
	};
	componentWillUnmount() {
		if (typeof document === "undefined") return;
		document.removeEventListener("click", this.onDocumentClick);
	}
	onDocumentClick = (e) => {
		if (this.domRef && !this.domRef.contains(e.target) && this.state.open) this.toggle();
	};
	toggle(e) {
		if (e) e.stopPropagation();
		const o = { open: !this.state.open };
		if (!this.state.open) {
			this.listRef.current && this.listRef.current.scrollToTop(0);
		} else {
			if (this.state.value !== "") this.doSearch("");
			o.value = "";
		}
		this.setState(o);
		this.refreshList();
	}
	toggleCase = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ case: !this.state.case });
		if (this.state.value !== "") this.doSearch(this.state.value);
	};
	doSearch = (pattern) => {
		if (typeof document === "undefined") return;
		if (typeof this.props.onSearch === "function") this.props.onSearch(pattern, this.state.case);
	};
	onType = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		this.debouncedSearch(e.target.value);
	};
	debouncedSearch = debounce((v) => {
		this.doSearch(v);
	}, 300);
	onSelect = (e, item) => {
		if (e) e.stopPropagation();
		let items;
		// const selected = this.props.selected ? this.props.selected : [];
		if (this.props.multi) {
			items = this.selected.concat(item);
			// console.log("item: ", item, items);
			items.sort((a, b) => this.doSort(a, b));
		} else items = [item];
		this.setState({ open: false });
		if (typeof this.props.onSelect === "function") this.props.onSelect(item, items);
	};
	onClear = (e) => {
		if (e) e.stopPropagation();
		this.selected = [];
		this.setState({ open: false });
		if (typeof this.props.onSelect === "function") this.props.onClear();
	};
	onRemove = (e, item) => {
		if (e) e.stopPropagation();
		// const selected = this.props.selected ? this.props.selected : [];
		if (this.selected.length === (this.props.min ? this.props.min : 0)) return;
		const items = this.selected.filter((el) => el.id !== item.id);
		items.sort((a, b) => this.doSort(a, b));
		this.setState({ open: false });
		if (typeof this.props.onUnselect === "function") this.props.onUnselect(item, items);
	};
	renderSelectedItems = (items, multi = false) => {
		const data = [];
		if (items.length === 0) return <label>{lang.translate(this.props.placeholder ? this.props.placeholder : "")}</label>;
		items.sort((a, b) => this.doSort(a, b));
		const digitHeader = typeof this.props.digitHeader === "undefined" ? false : this.props.digitHeader;
		const check = digitHeader || (this.props.min === 1 && multi === false);
		for (let item of items) {
			data.push(
				<button
					key={item.id}
					onClick={(e) => (check ? this.toggle(e, this.id) : this.onRemove(e, item))}
					className={` ${digitHeader ? "digitHeader" : "btnDefault"}`}
				>
					{this.toVisualEntry(item, true)}
				</button>
			);
		}
		return data;
	};
	formatValue = (v) => {
		v = typeof this.props.rounding === "undefined" ? v : parseFloat(v).toFixed(this.props.rounding);
		v = typeof this.props.trim === "undefined" ? v : `${v}`.substr(0, this.props.trim);
		v = typeof this.props.trimAfterDot === "undefined" ? v : v.ToBalanceFixed(this.props.trimAfterDot);
		return v;
	};
	// setValue = (el, value) => {

	// }
	toVisualEntry = (item, header = false) => {
		if (header) {
			const iconHeader = typeof this.props.iconHeader === "undefined" ? true : this.props.iconHeader;
			const valueHeader = typeof this.props.valueHeader === "undefined" ? true : this.props.valueHeader;
			const titleHeader = typeof this.props.titleHeader === "undefined" ? true : this.props.titleHeader;
			const digitHeader = typeof this.props.digitHeader === "undefined" ? false : this.props.digitHeader;
			let color = "white";
			if (digitHeader) {
				if (typeof this.valueBak !== "undefined" && this.idBak === item.id) {
					if (parseFloat(item.value) === this.valueBak) {
						color = "white";
					} else color = parseFloat(item.value) > this.valueBak ? "green" : "red";
				} else {
					color = "white";
				}
				this.valueBak = parseFloat(item.value);
				this.idBak = item.id;
			}
			return (
				<div className="item">
					{/* {iconHeader && item.icon && <img src={item.icon} alt="" />} */}
					{iconHeader && item.icon && <ImageCached storageName={item.icon.indexOf("@2x") !== -1 ? "coin" : "ui"} url={item.icon} params={{}} />}
					{item.c ?? null}
					{valueHeader &&
						(!this.props.pathname.includes("/slot/") && !this.props.pathname.includes("/originals/")) &&
						item.value &&
						(digitHeader ? (
							<DigitScreen
								color={color}
								value={parseFloat(item.value)}
								rounding={this.props.rounding}
								trim={this.props.trim}
								trimAfterDot={this.props.trimAfterDot}
							/>
						) : (
							<b>{this.formatValue(item.value)}</b>
						))}
					{titleHeader && <label className={item.value !== null && valueHeader ? "r" : "l"}>{item.title}</label>}
				</div>
			);
		}
		const valueBody = typeof this.props.valueBody === "undefined" ? true : this.props.valueBody;
		const v = this.state.case ? this.state.value : this.state.value.toLowerCase();
		return (
			<div className="item">
				{/* {item.icon !== null && <img src={item.icon} alt="" />} */}
				{item.icon !== null && <ImageCached storageName={item.icon.indexOf("@2x") !== -1 ? "coin" : "ui"} url={item.icon} params={{}} />}
				{item.c ?? null}
				{item.value !== null && valueBody && <b>{this.formatValue(item.value)}</b>}
				<label className={item.value !== null && valueBody ? "r" : "l"}>
					{v.length === 0
						? item.title
						: reactStringReplace(item.title, new RegExp(`(${v})`, `g${this.state.case ? "" : "i"}`), (match, i) => <b key={i}>{match}</b>)}
				</label>
			</div>
		);
	};
	arrToItems = (arr) => {
		if (!Array.isArray(arr)) arr = [arr];
		const o = [];
		for (let el of arr) o.push(this.elToItem(el));
		return o;
	};
	elToItem = (el) => {
		let item = {};
		if (typeof el === "object" && el !== null) {
			item.id = el.id ?? el.title.replace(/ /g, "_");
			item.title = el.title;
			item.value = typeof el.value === "undefined" || el.value === null ? null : el.value;
			item.icon = el.icon ?? null;
			item.c = el.c ?? null;
		} else {
			el = `${el}`;
			item.id = el.replace(/ /g, "_");
			item.title = el;
			item.value = null;
			item.icon = null;
			item.c = null;
		}
		return item;
	};
	getSelectedData = () => {
		let arr = this.props.data;
		if (!Array.isArray(arr)) arr = [arr];
		const o = [];
		for (let el of arr) o.push(this.elToItem(el));
		//o.sort((a, b) => a.item.title.localeCompare(b.item.title));
		let arr2 = Array.isArray(this.props.selected) ? this.props.selected : [this.props.selected];
		const o2 = [];
		for (let el of arr2) {
			const item = this.elToItem(el);
			const found = o.find((_el) => _el.id === item.id);
			if (found) o2.push(found);
		}
		this.selected = o2;
		return o2;
	};
	doSort = (a, b, prop) => {
		const sortBy = this.props.sortBy === "undefined" ? "title" : this.props.sortBy;
		const _a = prop ? a[prop] : a;
		const _b = prop ? b[prop] : b;
		if (typeof _a[sortBy] === "string" && typeof _b[sortBy] === "string") return _a[sortBy].localeCompare(_b[sortBy]);
		if (typeof _a[sortBy] === "number" && typeof _b[sortBy] === "number") return _a[sortBy] - _b[sortBy];
		return 0;
	};
	arrToData = (arr) => {
		if (!Array.isArray(arr)) arr = [arr];
		const data = [];
		const selected = Array.isArray(this.props.selected) ? this.props.selected : [];
		for (let el of arr) {
			const item = this.elToItem(el);
			let found;
			found = selected.find((_item) => this.elToItem(_item).id === item.id);
			if (found) continue;
			data.push({
				id: item.id,
				item,
				c: (
					<button onClick={(e) => this.onSelect(e, item)} className="btnDefault">
						{this.toVisualEntry(item)}
					</button>
				),
			});
			data.sort((a, b) => this.doSort(a, b, "item"));
		}
		if (typeof document !== "undefined") this.refreshList();
		return data;
	};
	refreshList = async () => {
		await delay(300);
		try {
			this.listRef.current.scroll.refresh();
		} catch (err) {}
	};
	render() {
		const selected = this.props.selected ? this.props.selected : [];
		const disabled = this.state.loading;
		const inputProps = {};
		const showCaseToggler = typeof this.props.caseSearch === "undefined" ? true : this.props.caseSearch;
		if (this.props.validation) inputProps.validation = this.props.validation;
		return (
			<div
				id={this.id}
				className={`multiSelect ${this.state.open ? "opened" : "closed"} ${this.props.className ?? ""}`}
				style={this.props.style ? this.props.style : {}}
			>
				<header
					{...(selected.length === 0 || (selected.length === 1 && selected[0] === null)
						? { onClick: (e) => [e.stopPropagation(), this.toggle(e, this.id)] }
						: {})}
				>
					<div className={`items ${this.props.multi ? "multi" : "single"}`}>
						{/* {this.renderSelectedItems(this.props.selected)} */}
						{this.renderSelectedItems(this.getSelectedData(), this.props.multi)}
					</div>
					{selected.length < (this.props.max ? this.props.max : Infinity) && !(this.props.toggleButton === false) && (
						<button onClick={(e) => this.toggle(e, this.id)} className="btnIcon toggle">
							{/* <Icon icon={this.state.open ? caretUp : caretDown} /> */}
							{this.props.customToggleIcon ?? <Icon icon={toggleIcon} />}
						</button>
					)}
					{this.props.headerLabel && selected.length > 0 && <div className="headerLabel">{this.props.headerLabel}</div>}
				</header>
				<div className="bodyFix">
					<div className="body">
						{typeof this.props.onSearch === "function" && (
							<div className="paneInput">
								<Input
									disabled={disabled}
									name="value"
									placeholder={lang.translate("search")}
									value={this.state.value}
									onChange={this.onType}
									// onEnter={}
									{...inputProps}
								/>
								{showCaseToggler && (
									<button disabled={this.state.loading} onClick={this.toggleCase} className={`btnIcon btnCase ${this.state.case ? "on" : "off"}`}>
										<Icon icon={caseIcon} />
									</button>
								)}
							</div>
						)}
						{this.props.otherContentTop && <div className="otherContentTop">{this.props.otherContentTop}</div>}
						<div className="paneList">
							<List ref={this.listRef} ren={true} data={this.arrToData(this.props.data)} />
						</div>
						{this.props.otherContent && <div className="otherContent">{this.props.otherContent}</div>}
					</div>
				</div>
			</div>
		);
	}
}

const a = (store) => {
	return {
		langKey: store.lang.key,
		pathname: store.pathname,
	};
};

export default connect(a, null, null, { forwardRef: true })(Select);
