import Axios from "./axios";
import Toast from "./toast";
import { limitByTime, onResult, yesNoContentWrap } from "./utills";
import lang from "./lang";
import { storeSet, store } from "../index";
import { delay } from "./utills";
import { firstBy } from "thenby";
import { getFingerprint } from "./player";

export const getCashier = async (o) => {
	// const result = await Axios.post("/api/billingFiat/getCashier", o);
	const result = await Axios.post("/api/billing/getCashier", o);
	return await onResult(result, "unexpected_error");
};

export const getData = async ({ short, network }) => {
	const result = await Axios.post("/api/billing/getData", { short, network });
	return await onResult(result, "unexpected_error");
};
export const generateAddress = async ({ short, network }) => {
	const result = await Axios.post("/api/billing/generateAddress", { short, network });
	return await onResult(result, "unexpected_error", (result) => {
		Toast.success(lang.translate("address_created"));
		return result;
	});
};
export const withdraw = async ({ address, tag, amount, short, network, password, token, comment }) => {
	const { fp, os, browser } = (await limitByTime(getFingerprint(), 5000, [{ os: "", fp: "", browser: "" }]))[0];
	Axios.setHeader("fp", fp);
	Axios.setHeader("os", os);
	Axios.setHeader("browser", browser);
	const result = await Axios.post("/api/billing/withdraw", { address, network, tag, amount, short, password, token, comment });
	return await onResult(result, "withdraw_error", (result) => {
		Toast.success(lang.translate("withdraw_success"));
		return result;
	});
};
export const getWithdraws = async (o) => {
	const result = await Axios.post("/api/billing/getWithdraws", o);
	return await onResult(result, "unexpected_error", async (result) => {
		await storeSet({ withdraws: { $set: Array.isArray(result.data) ? result.data : [] } });
		return result;
	});
};
const withdrawsQueue = [];
export const _onWithdrawChange = (o) => {
	if (!o.error) withdrawsQueue.push(o.entry);
};
const processWithdraws = async () => {
	if (withdrawsQueue.length) {
		const o = withdrawsQueue.shift();
		const state = await store.getState();
		const withdraws = state.withdraws;
		let arr = withdraws.filter((el) => el._id !== o._id); //remove outdated entry
		arr = arr.concat(o); //add changed entry
		arr.sort(
			firstBy(function (a, b) {
				return a._processed - b._processed; //first show pending data, then processed
			}).thenBy(function (a, b) {
				return b.t - a.t; //recent first, older next
			})
		);
		await storeSet({ withdraws: { $set: arr } });
	}
	//---
	await delay(1000);
	processWithdraws();
};
processWithdraws();

export const withdrawAction = async ({ _id, action, amount, userName }) => {
	yesNoContentWrap({
		title: `${lang.translate(action)}: ${userName} - ${amount} $`,
		hasComment: action === "reject" || action === "pause" || action === "confiscate",
		cb: async (boolResponse, { comment }) => {
			if (!boolResponse) return;
			const result = await Axios.post(`/api/billing/${action}`, {
				_id,
				comment: typeof comment !== "string" || comment.trim() === "" ? undefined : comment.trim(),
			});
			return await onResult(result, "unexpected_error");
		},
	});
};
export const getDeposits = async (o) => {
	const result = await Axios.post("/api/billing/getDeposits", o);
	return await onResult(result, "unexpected_error", async (result) => {
		await storeSet({ deposits: { $set: Array.isArray(result.data) ? result.data : [] } });
		return result;
	});
};
export const toBank = async ({ amount, short }) => {
	const result = await Axios.post("/api/bank/toBank", { amount, short });
	return await onResult(result, "unexpected_error", (result) => {
		Toast.success(lang.translate("transaction_complete"));
		return result;
	});
};
export const fromBank = async ({ amount, short }) => {
	const result = await Axios.post("/api/bank/fromBank", { amount, short });
	return await onResult(result, "unexpected_error", (result) => {
		Toast.success(lang.translate("transaction_complete"));
		return result;
	});
};
