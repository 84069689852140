import Decimal from "decimal.js";
import { getSelectedCoinBalance } from "../balance";
import { emitOrDefault } from "../socket";
import { storeSet, store } from "../../index";
import Toast from "../toast";
import lang from "../lang";
import Axios from "../axios";
import { onResult, tokenWrap, isObject, showModal } from "../utills";
import { sound } from "../sound";
import config from "../../config";

// import worker from "./wAuto";
export const settingsSet = async (prop, o, cb) => {
	await storeSet({ diceSettings: { [prop]: { $set: o } } });
	o = await store.getState().diceSettings;
	emitOrDefault("dice", { type: "setSettings", o }, 5000, { error: true, message: "you_are_offline" }, async (_r) => {
		if (_r && _r.error && _r.message) {
			Toast.error(lang.translate(_r.message));
			return typeof cb === "function" ? cb() : null;
		}
		Toast.success(lang.translate("settings_saved"));
		if (typeof cb === "function") cb();
	});
};
export const getMaxBet = () => Infinity; //regulated from back
export const getMinBet = () => 0.00000001;
export const cfg = {
	rollValueMinOver: null, //"1.99",
	rollValueMinUnder: "0.01",
	rollValueMaxOver: "99.98",
	rollValueMaxUnder: null, //"98.00",
	payoutMin: null,
	payoutMax: null,
	chanceMin: "0.01",
	chanceMax: null, //"98.00",
	payoutRounding: 3,
	chanceRounding: 2,
};
export const delay = (ms, response = undefined) => new Promise((resolve) => setTimeout(() => resolve(response), ms));
export const generateValidArray = (houseEdge) => {
	const rtp = new Decimal(100).minus(houseEdge).div(100);
	const arrUnder = [];
	const payouts = [];

	let prevPayout;
	let prevChance;
	for (
		let i = parseFloat(cfg.chanceMin);
		i <= parseFloat(new Decimal(99.99).minus(cfg.chanceMin).toNumber());
		i = new Decimal(i).plus(0.01).toNumber()
	) {
		const chance = new Decimal(i);
		let payout = new Decimal(100).dividedBy(chance).times(rtp).toFixed(12);
		payout = payout.split(".");
		payout = payout[0] + "." + payout[1].substr(0, cfg.payoutRounding);

		if (i === parseFloat(cfg.chanceMin)) cfg.payoutMax = payout;
		if (cfg.rollValueMaxUnder === null && (parseFloat(payout) <= 1 || i === parseFloat(cfg.chanceMax))) {
			cfg.rollValueMaxUnder = prevChance.toFixed(cfg.chanceRounding);
			cfg.chanceMax = cfg.rollValueMaxUnder;
			cfg.rollValueMinOver = new Decimal(99.99).minus(cfg.rollValueMaxUnder).toFixed(cfg.chanceRounding);
			cfg.payoutMin = prevPayout;
		}
		payouts.push(parseFloat(prevPayout));
		arrUnder.push({
			r: chance.toFixed(2),
			p: payout,
			c: chance.toFixed(cfg.chanceRounding),
		});
		prevPayout = payout;
		prevChance = chance;
	}
	cfg.arrUnder = arrUnder;
	cfg.payouts = payouts;
	return { arrUnder, payouts };
};
export const shiftOverUnder = (value) => new Decimal(99.99).minus(value);
const nearEntryByPayout = (arrUnder, payouts, /* string */ payout) => {
	if (typeof payout === "number") payout = payout.toFixed(cfg.payoutRounding);
	let found = arrUnder.find((el) => el.p === payout);
	if (!found) {
		const arr = [...payouts, parseFloat(payout)].sort((a, b) => a - b);
		const i = arr.findIndex((el) => el === parseFloat(payout));
		found = arrUnder.find((el) => el.p === new Decimal(i === arr.length - 1 ? arr[i] : arr[i + 1]).toFixed(3));
	}
	return found;
};
const getValidPayout = (arrUnder, payouts, payout, found) => {
	const _found = nearEntryByPayout(arrUnder, payouts, payout);
	if (_found.c === found.c) {
		return payout;
	} else {
		return found.p;
	}
};
export const rollValueChanged = (arrUnder, payouts, over, payout, rollValue) => {
	const chance = over === 1 ? new Decimal(99.99).minus(rollValue).toFixed(2) : rollValue;
	const found = arrUnder.find((el) => el.c === chance);
	const _payout = getValidPayout(arrUnder, payouts, payout, found);
	return { payout: _payout, rollValue, chance };
};
export const payoutChanged = (arrUnder, payouts, over, chance, payout) => {
	const found = nearEntryByPayout(arrUnder, payouts, payout);
	//check if existing chance valid for found.p
	if (typeof chance === "number") chance = chance.toFixed(cfg.chanceRounding);
	const payoutByExistingChance = arrUnder.find((el) => el.c === chance).p;
	const _chance = payoutByExistingChance === found.p ? chance : found.c;
	const rollValue = over === 1 ? new Decimal(99.99).minus(_chance).toFixed(2) : _chance;
	return { payout, rollValue, chance: _chance };
};
export const chanceChanged = (arrUnder, payouts, over, payout, chance) => {
	if (typeof chance === "number") chance = chance.toFixed(cfg.chanceRounding);
	const found = arrUnder.find((el) => el.c === chance);
	const rollValue = over === 1 ? new Decimal(99.99).minus(found.c).toFixed(2) : found.c;
	const _payout = getValidPayout(arrUnder, payouts, payout, found);
	return { payout: _payout, rollValue, chance };
};
export const betFormat = (bet, fadeZeros = false) => {
	if (isNaN(parseFloat(bet))) bet = 0;
	let betStr = new Decimal(bet).toFixed(14);
	betStr = betStr.substr(0, betStr.length - 6);
	return fadeZeros ? betStr.ReplaceAll("0", "<i>0</i>") : betStr;
};
export const fadeZeros = (str) => `${str}`.ReplaceAll("0", "<i>0</i>");
export const betAdjust = async (bet, max, format = true) => {
	const _max = await getMax(null, max, false);
	bet = parseFloat(bet) > _max ? _max : parseFloat(bet);
	return format ? betFormat(bet) : bet;
};
export const getMin = async (balance = null, format = true) => {
	if (!balance) balance = await getSelectedCoinBalance();
	let amt = parseFloat(balance) >= 0.00000001 ? 0.00000001 : 0;
	return format ? betFormat(amt) : amt;
};
export const getMax = async (balance = null, max, format = true) => {
	if (!balance) balance = await getSelectedCoinBalance();
	let amt = parseFloat(balance) > parseFloat(max) ? parseFloat(max) : parseFloat(balance);
	amt = amt < 0 ? 0 : amt;
	return format ? betFormat(amt) : amt;
};
export const getDouble = async (balance = null, max, bet, format = true) => {
	if (!balance) balance = await getSelectedCoinBalance();
	let amt = parseFloat(bet) * 2;
	amt = balance > 0 && amt < 0.00000001 ? 0.00000001 : amt;
	let _max = await getMax(balance, max, false);
	amt = amt > _max ? _max : amt;
	amt = amt < 0 ? 0 : amt;
	return format ? betFormat(amt) : amt;
};
export const getHalf = async (balance = null, bet, format = true) => {
	if (!balance) balance = await getSelectedCoinBalance();
	let amt = parseFloat(bet) / 2;
	amt = amt < 0.00000001 ? 0.00000001 : amt;
	amt = amt > balance ? balance : amt;
	amt = amt < 0 ? 0 : amt;
	return format ? betFormat(amt) : amt;
};
export const setStartingPoint = async (o, cb) => {
	o.type = "setStartingPoint";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (resp) => {
		cb(resp);
	});
};
export const roll = async (o, cb) => {
	o.type = "roll";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (_r) => {
		if (_r && _r.error && _r.message) Toast.error(lang.translate(_r.message));
		cb(_r);
	});
};
/*
	satoshi											s
	100 satoshi (Bit μBTC)			μ
	10k satoshi (milibit mBTC)	m
	BTC													B
	1k BTC											K
	1m BTC											M
	1b BTC											G
*/
export const toChipFormat = (amount) => {
	const d = new Decimal(amount);
	//1b BTC
	if (d.gte(1000000000)) return [d.dividedBy(1000000000).toFixed(0), "G", 6];
	//1m BTC
	else if (d.gte(1000000)) return [d.dividedBy(1000000).toFixed(0), "M", 5];
	//1k BTC
	else if (d.gte(1000)) return [d.dividedBy(1000).toFixed(0), "K", 4];
	//BTC
	else if (d.gte(1)) return [d.toFixed(0), "B", 3];
	//10k satoshi (milibit mBTC)
	else if (d.gte(0.001)) return [d.mul(1000).toFixed(0), "m", 2];
	//100 satoshi (Bit μBTC)
	else if (d.gte(0.000001)) return [d.mul(1000000).toFixed(0), "μ", 1];
	//satoshi
	else return [d.mul(100000000).toFixed(0), "s", 0];
};
let autoInstance = null;
class Auto {
	#startRoll = null;
	#auto = null;
	#nextRoll = null;
	#cbRenderResult = null;
	#cbStop = null;
	#cycle = 0;
	#stop = false;
	#manual = false;
	#speed = "3x";
	constructor() {
		// console.log("CREATED AUTO");
		if (autoInstance) return autoInstance;
		autoInstance = this;
	}
	speedDelay = (v) => {
		const i = ["1x", "2x", "3x"].indexOf(v);
		return [750, 250, 1][i];
	};
	start(startRoll, auto, manual = false, speed, cbRenderResult) {
		this.#nextRoll = null;
		this.#cbStop = null;
		this.#cycle = 0;
		this.#stop = false;
		//---
		this.#startRoll = startRoll;
		this.#manual = manual;
		this.#auto = auto;
		this.#cbRenderResult = cbRenderResult;
		this.#speed = speed;
		this.run();
	}
	run() {
		const packet = {
			type: "auto",
			m: this.#manual,
			roll: this.#nextRoll ? this.#nextRoll : this.#startRoll,
			auto: this.#auto,
		};
		//promisify emit and cancel on timeout
		// emitOrDefault("dice", packet, 5000, { error: true, next: { stop: "you_are_offline" } }, (_r) => this.onAuto(_r));
		this.emitLoop(packet);
		// emit("dice", packet, (_r) => this.onAuto(_r));
	}
	emitLoop(packet) {
		emitOrDefault("dice", packet, 15000, { error: true, next: { stop: "packet_lost_retrying" } }, async (_r) => {
			if (_r.error && (_r.message === "offline" || (_r.next && _r.next.stop && _r.next.stop === "packet_lost_retrying"))) {
				await delay(1000);
				return this.emitLoop(packet);
			} else {
				this.onAuto(_r);
			}
		});
	}
	async onAuto(_r) {
		const o = typeof _r.balance === "undefined" ? {} : { balance: { $merge: _r.balance } };
		if (_r.balance) {
			const state = await store.getState();
			if (isObject(state.playerConfig.depBonus) && _r.depBonus && _r.depBonus.w * 1 >= 0)
				o.playerConfig = { depBonus: { $merge: { w: _r.depBonus.w * 1 } } };
		}
		if (_r.auto) this.#auto = _r.auto;
		//play sound:
		if (_r.roll) {
			if (_r.auto) _r.roll.num = _r.auto.num;
			o.oLastRoll = { $set: _r.roll };
			if (_r.roll.j) sound.play("dice", `j${_r.roll.j}`);
			else if (_r.roll.win) sound.play("dice", "roll-win");
			else sound.play("dice", "roll");
		}
		if (_r.balance || _r.roll) await storeSet(o);
		//---
		let doReturn = false;
		if (_r.error || this.#stop || _r.next.stop) {
			this.#stop = false; //reset to initial value
			doReturn = true;
			if (typeof this.#cbStop === "function") await this.#cbStop();
			_r.message && Toast.error(lang.translate(_r.message));
			_r.next && _r.next.stop && !this.#manual && Toast.warning(lang.translate(_r.next.stop));
			_r.message = "stop";
		}
		await this.#cbRenderResult(_r);
		if (doReturn) return;
		this.#cycle++;
		delete _r.next.stop;
		delete _r.next.chance;
		this.#nextRoll = Object.assign({}, _r.next);
		// this.#speed !== "3x" && (await delay(this.#speed === "1x" ? 750 : 250));
		await delay(this.speedDelay(this.#speed));
		this.run();
	}
	stop(cbStop) {
		this.#cbStop = cbStop;
		this.#stop = true;
	}
}
export const AutoCl = new Auto();

export const getRollInfo = async (o, cb) => {
	o.type = "getRollInfo";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (resp) => cb(resp));
};
export const showRollInfo = async (betId) => {
	const [id, nonce] = betId.split("-");
	getRollInfo({ id, nonce }, (rollInfo) => {
		if (!isObject(rollInfo) || rollInfo.error) {
			return Toast.error(lang.translate("data_not_found"));
		}
		if (nonce * 1 > 0) rollInfo.filterNonce = nonce;
		showModal("dicerollinfo", true, rollInfo);
	});
};
export const getStats = async (o, cb) => {
	o.type = "getStats";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (resp) => cb(resp));
};
export const statsReset = async (o, cb) => {
	o.type = "statsReset";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (resp) => cb(resp));
};
export const getCs = async (cb) => {
	const o = { type: "getCs" };
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, (resp) => {
		if (resp.error) Toast.error(lang.translate(resp.message));
		cb(resp);
	});
};
export const randomize = async (o, cb) => {
	o.type = "randomize";
	emitOrDefault("dice", o, 5000, { error: true, message: "you_are_offline" }, async (resp) => {
		if (resp.error) {
			Toast.error(lang.translate("randomization_failed"));
		} else {
			await storeSet({ seeds: { $set: resp } });
			Toast.success(lang.translate("randomization_success"));
		}
		cb(resp);
	});
};

export const getJackpotData = async () => {
	const result = await Axios.post("/api/formula/getData", { setting: "dicejackpot" });
	return await onResult(result, "unexpected_error");
};

export const setJackpotData = async (formula = "", cb) => {
	const title = lang.translate("are_you_sure");
	tokenWrap({ title, path: "/api/formula/change", o: { setting: "dicejackpot", formula } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("entry_updated"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const verify = (client_seed, server_seed, nonce) => {
	window.open(`${config.app.diceVerifierUrl}?client_seed=${client_seed}&server_seed=${server_seed}&nonce=${nonce}`, "blank");
};
