import React, { PureComponent } from "react";
//containers:
import Container from "../Container";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
import Input from "../../components/input/Input";
//classes:
import { lang } from "../../classes/lang";
//icons:
import { Icon } from "react-icons-kit";
import { questionCircle } from "react-icons-kit/fa/questionCircle";

class Prompt extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			token: "",
			disableToken: false,
		};
	}
	static get propTypes() {
		return {
			f: PropTypes.func.isRequired,
			title: PropTypes.string,
			disableToken: PropTypes.bool,
			//redux:
			lang: PropTypes.string,
		};
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onSubmit = (e) => {
		if (e) e.stopPropagation();
		this.props.f({ token: this.state.token });
	};
	onCancel = (e) => {
		if (e) e.stopPropagation();
		this.props.f();
	};
	render() {
		const disabled = this.state.loading;
		return (
			<Container noDocs={true}>
				<div className="prompt">
					<div>
						{this.props.title && (
							<div className="title">
								<div className="icon">
									<Icon icon={questionCircle} size={22} />
								</div>
								<h2>{this.props.title}</h2>
							</div>
						)}
						{!this.props.disableToken && (
							<Input
								disabled={disabled}
								className="c"
								name="token"
								placeholder={lang.translate("token")}
								value={this.state.token}
								onChange={this.onChange}
								validation="2fa"
								onEnter={this.onSubmit}
								maxLength={6}
							/>
						)}
						<button onClick={this.onSubmit} className="btnDefault alert">
							<label>{lang.translate("submit")}</label>
						</button>
						<button onClick={this.onCancel} className="btnDefault success">
							<label>{lang.translate("cancel")}</label>
						</button>
					</div>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(Prompt);
