import { useCallback } from "react";
import Axios from "../../classes/axios";
// import lang from "../../classes/lang";
import { store, storeSet } from "../..";

import { isObject, onResult } from "../../classes/utills";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";

import toast from "../../classes/toast";
import lang from "../../classes/lang";

const useBattle = (props) => {
	const { battleId } = props;
	const battle = useSelector((s) => s.battlesO[battleId]);
	const call = useCallback(async (func, o) => {
		const battle = await Axios.post(`api/battle/${func}`, o);
		// const data = await onResult(result, "unexpected_error");
		if (!isObject(battle) || battle.error) storeSet({ battlesO: { $unset: [battle.id] } });
		else storeSet({ battlesO: { [battle.id]: { $set: battle } } });
	}, []);
	useEffect(() => {
		if (!battle && battleId) call("get", { battleId });
	}, [battleId, battle, call]);
	return { battle };
};

export const useSlotTemplate = (props) => {
	const { amountUsd } = props;
	const [templates, setTemplates] = useState([]);
	const [info, setInfo] = useState();
	const call = useCallback(async () => {
		const result = await Axios.post(`api/slotegrator/freespinsTemplatesByAmount`, { amountUsd });
		const data = await onResult(result, "unexpected_error");
		if (isObject(data) && !data.error && data.info && data.templates) {
			if (Array.isArray(data.templates)) setTemplates(data.templates);
			setInfo(data.info);
		}
	}, [amountUsd]);

	useEffect(() => {
		if (!(amountUsd > 0)) setTemplates([]);
		else call();
	}, [call, amountUsd]);

	return { templates, info };
};

export const useGamesByTier = (props) => {
	const { tier } = props;
	const [games, setGames] = useState([]);
	const call = useCallback(async () => {
		const result = await Axios.post(`api/slotegrator/gamesByTier`, { tier });
		const data = await onResult(result, "unexpected_error");
		if (isObject(data) && !data.error && Array.isArray(data.games)) {
			setGames(data.games);
		}
	}, [tier]);
	useEffect(() => {
		call();
	}, [call, tier]);
	return { games };
};

export const join = async (battleId) => {
	const state = await store.getState();
	const short = state.coin?.selected;
	const result = await Axios.post("api/battle/join", { battleId, short });
	if (result && result.error) toast.error(lang.translate(result.message ?? "unexpected_error"));
	return result;
};

export default useBattle;
