/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import lang from "../../classes/lang";
import ImageCached from "../../classes/cache";
import Avatar from "../../components/avatar/Avatar";
import Toast from "../../classes/toast";
import useWindowSize from "../../hooks/useWindowSize";
import { closeModal, copy, getDayTimeFromTimestamp } from "../../classes/utills";
import { go } from "../../classes/routesLazy";

//icons:
import { Icon } from "react-icons-kit";
import { ic_content_copy as iconCopy } from "react-icons-kit/md/ic_content_copy";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import { share } from "react-icons-kit/fa/share";
import { shield } from "react-icons-kit/entypo/shield";

const BetInfo = (props) => {
	BetInfo.propTypes = {
		uid: PropTypes.number,
		userName: PropTypes.string,
		img: PropTypes.number,
		//---
		gameName: PropTypes.string,
		gameProvider: PropTypes.string,
		gameImage: PropTypes.string,
		gameUrl: PropTypes.string,
		shareUrl: PropTypes.string,
		//--
		betId: PropTypes.string,
		betAmount: PropTypes.number,
		profit: PropTypes.number,
		short: PropTypes.string,
		t: PropTypes.number,
	};
	const ref = useRef();
	const { width } = useWindowSize();
	const show = width >= 550;
	const { uid, userName, img, gameName, gameProvider, gameImage, betId, betAmount, profit, short, gameUrl, shareUrl, t } = props;
	let payout = profit > 0 ? profit / betAmount : 0;
	if (isNaN(profit) || payout === Infinity || payout === -Infinity) payout = 0;
	const formatNumber = (num, toFixed = 8) => {
		num = num.toFixed(toFixed);
		num = num.substr(0, num.charAt(0) === "-" ? 11 : 10);
		let i = 0;
		return num.split("").reduce((a, c) => {
			i++;
			switch (c) {
				case "0":
					return a.concat(
						<i key={"rollInfo_" + i} className="z">
							0
						</i>
					);
				default:
					return a.concat(<i key={"rollInfo_" + i}>{c}</i>);
			}
		}, []);
	};
	const renderProfit = () => {
		return (
			<div className="row">
				<div className="col center flex1">
					<label>{lang.translate("profit")}</label>
					<div className="row center">
						<ImageCached width={32} height={32} storageName="coin" url={`/assets/icons/${short}@2x.png`} alt={short} title={short} params={{}} />
						<b className={profit > 0 ? "green" : "red"}>{formatNumber(profit)}</b>
						<b className="f10">{short}</b>
					</div>
				</div>
			</div>
		);
	};
	const renderBetPayout = () => {
		return (
			<div className="row">
				<div className="col center flex1">
					<label>{lang.translate("bet")}</label>
					<div className="row center">
						<ImageCached width={16} height={16} storageName="coin" url={`/assets/icons/${short}@2x.png`} alt={short} title={short} params={{}} />
						<b className={show ? "" : "f12"}>{formatNumber(betAmount)}</b>
					</div>
				</div>
				<div className="col center flex1">
					<label>{lang.translate("payout")}</label>
					<div className="row center">
						<b className={show ? "" : "f12"}>{formatNumber(payout, 4)}</b>
						<b className="f10">x</b>
					</div>
				</div>
			</div>
		);
	};
	const renderPlayer = () => {
		return (
			<div className="row between">
				<div className="col center">
					<Avatar className={show ? "s48" : ""} userName={userName} uid={uid} img={img} size={48} />
				</div>
				<div className="col center">
					<div className="row">
						{show && <label>Bet ID:</label>}
						<Icon size={18} icon={shield} style={{ color: "lime" }} />
						<b className="f12">{betId.StartEndOnly(show ? 6 : 4)}</b>
						<button
							className="btnDefault btnIcon small warn"
							onClick={(e) => [e.stopPropagation(), copy(betId, Toast.success(lang.translate("copied")))]}
						>
							<Icon size={18} icon={iconCopy} />
						</button>
					</div>
					<div className="row">
						{show && <label>{lang.translate("time")}:</label>}
						{t && <b className="f12">{getDayTimeFromTimestamp(t, true, true)}</b>}
					</div>
				</div>
			</div>
		);
	};
	const renderLine = () => {
		return (
			<div className="line">
				<div className="c"></div>
			</div>
		);
	};
	const renderGame = () => {
		return (
			<div className="row between">
				<div className="row">
					<div className="col center imgGame">
						<img
							src={gameImage}
							alt=""
							onError={(e) => {
								if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							}}
							height={60}
							width={80}
						/>
					</div>
					<div className="col center">
						<label>{gameName}</label>
						<b className="f12">{gameProvider}</b>
					</div>
				</div>
				<div className="row center">
					{shareUrl && (
						<button className="btnDefault btnPlay" onClick={(e) => [e.stopPropagation(), copy(shareUrl, Toast.success(lang.translate("copied")))]}>
							<b className="f12">{lang.translate("share")}</b><Icon icon={share} size={18} />
						</button>
					)}
					<button className="btnDefault btnPlay" onClick={(e) => [e.stopPropagation(), closeModal(), setTimeout(() => go(gameUrl), 300)]}>
						<b className="f12">{lang.translate("play")}</b>
						<Icon icon={chevronRight} size={18} />
					</button>
				</div>
			</div>
		);
	};
	return (
		<div ref={ref} className="generalBetInfo">
			<h2>{lang.translate("bet_info")}</h2>
			{renderProfit()}
			{renderLine()}
			{renderBetPayout()}
			{renderLine()}
			{renderPlayer()}
			{renderLine()}
			{renderGame()}
		</div>
	);
};

export default BetInfo;
