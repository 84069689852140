import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import List from "../../../components/list/List";
import Input from "../../../components/input/Input";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../../index";
//classes:
import { lang } from "../../../classes/lang";
// eslint-disable-next-line no-unused-vars
import { getDayTimeFromTimestamp, copy } from "../../../classes/utills";
// import { getPhoto } from "../../../classes/player";
import { chat } from "../../../classes/chat";
import Toast from "../../../classes/toast";
import { verify } from "../../../classes/games/dice";
import ImageCached from "../../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
import { shareSquareO as shareIcon } from "react-icons-kit/fa/shareSquareO";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import BetInfo from "../../betInfo/BetInfo";

class DiceRollInfo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			filterNonce: this.props.filterNonce || "",
		};
		this.listRef = React.createRef();
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			data: PropTypes.object,
			rolls: PropTypes.array.isRequired,
			filterNonce: PropTypes.string,
			me: PropTypes.string,
		};
	}
	componentDidMount() {
		// console.log("props:", this.props);
	}
	formatNumber = (str) => {
		str = str.toFixed(8);
		str = str.substr(0, str.charAt(0) === "-" ? 11 : 10);
		let i = 0;
		return str.split("").reduce((a, c) => {
			i++;
			switch (c) {
				case "0":
					return a.concat(
						<i key={"rollInfo_" + i} className="z">
							0
						</i>
					);
				default:
					return a.concat(<i key={"rollInfo_" + i}>{c}</i>);
			}
		}, []);
	};
	outcomeToStr = (outcome) => {
		let outcomeStr = outcome.toFixed(2);
		if (outcomeStr.length === 4) outcomeStr = `0${outcomeStr}`;
		return outcomeStr;
	};
	shareRollClick = async (e, n) => {
		if (e) e.stopPropagation();
		const str = `b:${this.props.data._id}${this.props.data.num > 1 ? `-${n}` : ""} `;
		await chat.chatInputAppend(str);
	};
	rollsToData = () => {
		const toRoll = (o) => {
			const cl = o.win ? "success" : "alert";
			return (
				<div key={o.n} className="roll">
					<div className="c shareBtn">
						<button onClick={(e) => this.shareRollClick(e, o.n)} className={`diceBtn ${o.win ? "green" : "red"}`}>
							<Icon icon={shareIcon} />
						</button>
					</div>
					<div className="c pa">
						<b>{o.n}</b>
						<b>
							{o.pa}
							<i className="gold">x</i>
						</b>
						<b>
							{o.chance}
							<i className="gold">%</i>
						</b>
					</div>
					<div className="c bars">
						<div className="strip">
							<div style={{ left: `${o.outcome}%` }} className={`outcomeWrapper ${o.win ? "green" : "red"}`}>
								<div className={`outcome ${o.win ? "green" : "red"}`}>{o.outcomeStr}</div>
							</div>
							<div
								style={{ marginLeft: o.over === 1 ? `${o.rv}%` : "0%", marginRight: o.over === 1 ? "0%" : `${100 - o.rv}%` }}
								className={`stripFill ${o.win ? "green" : "red"}`}
							></div>
							<label>{`${lang.translate(o.over === 1 ? ">" : "<")} ${this.outcomeToStr(o.rv)}`}</label>
						</div>
					</div>
					<div className="c bet">
						<div>
							<b>{lang.translate("bet")}:</b>
							<b>{this.formatNumber(o.bet)}</b>
						</div>
						<div>
							<b>{lang.translate("delta")}:</b>
							<b className={cl}>{this.formatNumber(o.delta)}</b>
						</div>
						<div className="sep"></div>
						<div>
							<b>{lang.translate("profit")}:</b>
							<b className={cl}>{this.formatNumber(o.profit)}</b>
						</div>
					</div>
					<div className="c coin">
						{/* <img width={32} height={32} alt={o.short} title={o.short} src={`/assets/icons/${o.short}@2x.png`} /> */}
						<ImageCached
							width={32}
							height={32}
							storageName="coin"
							url={`/assets/icons/${o.short}@2x.png`}
							alt={o.short}
							title={o.short}
							params={{}}
						/>
					</div>
				</div>
			);
		};
		let rolls = this.props.rolls;
		if (!Array.isArray(rolls)) return [];
		const arr = [];
		if (this.state.filterNonce !== "") rolls = rolls.filter((el) => el.n * 1 === this.state.filterNonce * 1);
		for (let el of rolls) arr.push({ id: `n${el.n}`, c: toRoll(el) });
		return arr;
	};
	onFilterChange = (e) => {
		this.setState({ [e.target.name]: e.target.value + "" });
	};
	onCopy = (e, value) => {
		if (e) e.stopPropagation();
		if (!value) return;
		copy(value);
		Toast.success(lang.translate("copied"));
	};
	onRandomizeClick = async () => {
		storeSet({
			modal: {
				$set: { name: "Fairness", canClose: true, props: {} },
			},
		});
	};
	render() {
		const disabled = this.state.loading;
		// eslint-disable-next-line no-unused-vars
		const { _id, num, uid, img, un, short, game, win, wager, income, delta, jwin, rate, tim, cs, ss, ssSha } = this.props.data;
		// const clWin = win ? "success" : "alert";
		return (
			<Container noDocs={true} className={`diceRollInfo ${this.props.rolls[0].num > 1 ? "tall" : ""} ${disabled ? "off" : "on"}`}>
				<Fragment>
					<BetInfo
						betId={_id}
						uid={uid}
						userName={un}
						img={img}
						short={short}
						profit={delta > 0 ? income : delta}
						betAmount={wager}
						t={tim}
						gameName="Dice"
						gameProvider="Wintomato"
						gameImage="https://res.cloudinary.com/wintomato-llc/image/upload/f_auto,q_auto/v1/slideNavigation/74b74e657e9f422c9177999677336285.jpg"
						gameUrl="crypto-bitcoin-dice"
						shareUrl={`${window.location.origin}/en/dice-id/${_id}`}
					/>
					{/* <header>
						<div className="ib">
							<ImageCached width={24} height={24} storageName="coin" url={`/assets/icons/${short}@2x.png`} alt={short} title={short} params={{}} />
							<div className={`win ${clWin}`}>{lang.translate(win ? "win" : "lose")}</div>
						</div>
						<div className="time">{getDayTimeFromTimestamp(tim)}</div>
					</header> */}
					{/* <section className="player">
						<div className="block photo">
							<img
								width={48}
								height={48}
								src={getPhoto(uid, img, 48, 48)}
								alt=""
								onError={(e) => {
									if (e.target.src.indexOf("avatar-default.webp") === -1) e.target.src = "/assets/avatar-default.webp";
								}}
							/>
						</div>
						<div className="block">
							<label>{lang.translate("username")}</label>
							<b>{un}</b>
						</div>
						<div className="block trid">
							<button className="diceBtn">
								<label>{lang.translate("id")}</label>
								<b>{_id}</b>
							</button>
						</div>
					</section> */}
					{/* <section className="info">
						<div className="block num">
							<label>{lang.translate("№")}</label>
							<b>{this.props.rolls.length}</b>
						</div>
						<div className="block">
							<label>+{lang.translate("income")}</label>
							<b className="success">{this.formatNumber(income)}</b>
						</div>
						<div className="block">
							<label>-{lang.translate("wager")}</label>
							<b>{this.formatNumber(wager)}</b>
						</div>
						<div className="block">
							<label>={lang.translate("delta")}</label>
							<b className={clWin}>{this.formatNumber(delta)}</b>
						</div>
						<div className="block">
							<label>{lang.translate("jackpot")}</label>
							<b className="gold">{this.formatNumber(jwin)}</b>
						</div>
					</section> */}
					<section className="seeds">
						<button onClick={(e) => this.onCopy(e, this.props.data.cs)} className="diceBtn">
							<label>{lang.translate("client_seed")}</label>
							<b>{this.props.data.cs.StartEndOnly()}</b>
						</button>
						{this.props.data.ss ? (
							<button onClick={(e) => this.onCopy(e, this.props.data.ss)} className="diceBtn">
								<label>{lang.translate("server_seed")}</label>
								<b>{this.props.data.ss.StartEndOnly()}</b>
							</button>
						) : (
							<button disabled={this.props.me !== un} onClick={(e) => [e.stopPropagation(), this.onRandomizeClick()]} className="diceBtn">
								<label>{lang.translate("server_seed")}</label>
								<b>{lang.translate("randomise_to_reveal")}</b>
							</button>
						)}
					</section>
					<section className="seeds">
						<button onClick={(e) => this.onCopy(e, this.props.data.ssSha)} className="diceBtn">
							<label>{lang.translate("server_sha_512")}</label>
							<b>{this.props.data.ssSha.StartEndOnly()}</b>
						</button>
						<button
							onClick={(e) => [
								e.stopPropagation(),
								verify(btoa(this.props.data.cs), btoa(this.props.data.ss), this.props.rolls[0].num === 1 ? btoa(this.props.rolls[0].n) : ""),
							]}
							disabled={!this.props.data.ss}
							className="diceBtn green"
						>
							<b>{lang.translate("verify")}</b>
							<Icon icon={chevronRight} size={16} />
						</button>
					</section>
					{this.props.rolls[0].num > 1 && (
						<section className="filter">
							<Input
								name="filterNonce"
								onChange={this.onFilterChange}
								onBlur={this.onFilterChange}
								onFocus={(e) => e.target.select()}
								placeholder={lang.translate("nonce")}
								validation={"integer"}
								value={this.state.filterNonce}
							/>
						</section>
					)}
					<section className="trs">
						<List ref={this.listRef} ren={true} data={this.rollsToData()} />
					</section>
				</Fragment>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		me: store.player.userName,
	};
};

export default connect(a, null, null, { forwardRef: true })(DiceRollInfo);
