import { Howl, Howler } from "howler";
let instance = null;
class Sound {
	constructor() {
		if (instance) return instance;
		instance = this;
		if (typeof document !== "undefined") {
			this.time = new Date();
			this.activePrefixes = ["ui", "dice", "rain", "other", "crash"];
			this.loaded = false;
			this.sounds = {
				dice: {
					j1: { snd: null, loop: false },
					j2: { snd: null, loop: false },
					j3: { snd: null, loop: false },
					j4: { snd: null, loop: false },
					j5: { snd: null, loop: false },
					j6: { snd: null, loop: false },
					j7: { snd: null, loop: false },
					j8: { snd: null, loop: false },
					roll: { snd: null, loop: false },
					"roll-win": { snd: null, loop: false },
				},
				crash: {
					loopingEngine: { snd: null, loop: true },
					explode: { snd: null, loop: false },
					win: { snd: null, loop: false },
					launch: { snd: null, loop: false },
					placeBet: { snd: null, loop: false },
					tick: { snd: null, loop: false },
					tickLast: { snd: null, loop: false },
				},
				ui: {
					btn1: { snd: null, loop: false },
				},
				rain: {
					thunder: { snd: null, loop: false },
				},
				other: {
					sms: { snd: null, loop: false },
					info: { snd: null, loop: false },
				},
			};
			// this.init();
		}
	}
	init = () => {
		this.loaded = true;
		this.masterVolumeSet(1);
		for (let prefix in this.sounds) {
			for (let skey in this.sounds[prefix]) {
				this.sounds[prefix][skey]["snd"] = new Howl({
					preload: true,
					loop: this.sounds[prefix][skey]["loop"],
					src: [`/assets/sounds/${prefix}/${skey}.mp3`],
				});
			}
		}
	};
	masterVolumeSet = (v) => {
		v *= 1;
		if (v > 1) v = 1;
		else if (v < 0) v = 0;
		else v = 1;
		Howler.volume(v);
	};
	disablePrefix = (prefix) => {
		this.activePrefixes = this.activePrefixes.filter((el) => el !== prefix);
	};
	enablePrefix = (prefix) => {
		this.activePrefixes = this.activePrefixes.filter((el) => el !== prefix).concat(prefix);
	};
	play = (prefix, snd) => {
		if (!this.loaded) this.init();
		this.activePrefixes.indexOf(prefix) !== -1 && this.sounds[prefix][snd]["snd"].play();
	};
	stop = (prefix, snd) => {
		if (!this.loaded) this.init();
		this.activePrefixes.indexOf(prefix) !== -1 && this.sounds[prefix][snd]["snd"].stop();
	};
}
const _sound = new Sound();
export const sound = _sound;
export default _sound;
