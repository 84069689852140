const hostname = typeof window === "undefined" ? "127.0.0.1" : window.location.hostname;
const port = typeof window === "undefined" ? 80 : +window.location.port;
const isProd =
	!hostname.startsWith("localhost") && !hostname.startsWith("127.0.0.1") && !hostname.startsWith("10.8.") && port !== 3333 && port !== 3535;
const config = {
	title: "WINTOMATO",
	app: {
		isProd,
		url: isProd ? `https://back.${hostname}/` : `http://${hostname}:3535/`,
		urlFront: isProd ? `https://${hostname}/` : `http://${hostname}:${port}/`,
		httpTimeout: 10000,
		recaptcha: "6LfwpAoaAAAAAEz9Wy7MnhdIivSWKXFdneZOyAmm",
		// isProd && hostname.startsWith("dev.")
		// 	? "6Lcal88UAAAAADZ-VpS8aXNH0t3cjAUcjJm1H2aN" //development
		// 	: "6LfwpAoaAAAAAEz9Wy7MnhdIivSWKXFdneZOyAmm", //wintomato
		dolz: true,
		diceVerifierUrl: "https://codepen.io/wintomato/pen/BaQLVgr",
		crashVerifierUrl: "https://codepen.io/wintomato/pen/abywqmN",
	},
	links: {
		// terms: "https://coinfolk.net/category/49/t-c-wintomato-com",
		terms: "/en/terms-and-conditions",
		cloud: "https://res.cloudinary.com/wintomato-llc/image/upload/", //c_fill,h_100,w_100/*.jpg
		responsible_gaming: "/en/info/kyc"
	},
	tawk: {
		propertyId: "607fe2785eb20e09cf351747",
		key: "1f3popaj6",
	},
	googleAnalytics: {
		app: "wintomato.com",
		trackingId: "G-Y213VDVX1W",
	},
};
export default config;
