import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
const getWidth = () =>
	typeof document === "undefined" ? 800 : window.innerWidth || document.documentElement?.clientWidth || document.body.clientWidth;
const getHeight = () =>
	typeof document === "undefined" ? 600 : window.innerHeight || document.documentElement?.clientHeight || document.body.clientHeight;
function useWindowSize() {
	let [width, setWidth] = useState(getWidth());
	let [height, setHeight] = useState(getHeight());
	const chatOpened = useSelector((state) => state.chat && state.chat.open);
	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId);
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout(() => {
				setWidth(getWidth());
				setHeight(getHeight());
			}, 75);
		};
		typeof document !== "undefined" && window.addEventListener("resize", resizeListener);
		return () => typeof document !== "undefined" && window.removeEventListener("resize", resizeListener);
	}, []);
	return { width, height, chatOpened };
}
export default useWindowSize;
