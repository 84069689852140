import { store } from "../index";
import Axios from "./axios";
import Toast from "./toast";
import { onResult, tokenWrap, yesNoWrap } from "./utills";
import lang from "./lang";
import { GA_EVENTS, gaEvent } from "./ga";

export const getTipData = async () => {
	const result = await Axios.post("/api/formula/getData", { setting: "tip" });
	return await onResult(result, "unexpected_error");
};
export const setTipData = async (formula = "", cb) => {
	const title = lang.translate("are_you_sure");
	tokenWrap({ title, path: "/api/formula/change", o: { setting: "tip", formula } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("entry_updated"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
// export const getAffData = async () => {
// 	const result = await Axios.post("/api/tip/getAffData", {});
// 	return await onResult(result, "unexpected_error");
// };
export const status = async () => {
	const result = await Axios.post("/api/tip/status", {});
	return await onResult(result, "unexpected_error");
};
export const perform = async (o, cb = () => {}) => {
	const state = await store.getState();
	if (state.playerConfig && state.playerConfig.depBonus && state.playerConfig.depBonus.active === true) {
		const title = `${state.playerConfig.depBonus.name[state.lang.key]} ${state.playerConfig.depBonus.desc}`;
		await yesNoWrap(`${lang.translate("are_you_sure")} ${lang.translate("all_your_progress_will_be_lost")} [${title}]?`, async (boolResponse) => {
			if (!boolResponse) return cb({});
			return cb(await _perform(o));
		});
	} else {
		return cb(await _perform(o));
	}
};
const _perform = async (o) => {
	const result = await Axios.post("/api/tip/perform", o);
	return await onResult(result, "tip_not_sent", async () => {
		Toast.success(lang.translate("tip_sent"));
		gaEvent(GA_EVENTS.tip_send, {
			amount: o.amount,
			short: o.short,
			targetUserName: o.targetUserName,
			share: o.share,
			// comment: o.comment,
		});
		return result;
	});
};
