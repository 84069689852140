import React, { PureComponent } from "react";
//containers:
import Container from "../Container";
import PropTypes from "prop-types";
import Loader from "../../components/loader/Loader";
//redux:
import { connect } from "react-redux";
import Input from "../../components/input/Input";
//classes:
import { lang } from "../../classes/lang";
import { forgotPass } from "../../classes/player";
import { delay, isValidMail } from "../../classes/utills";
import Toast from "../../classes/toast";
//icons:
// import { Icon } from "react-icons-kit";

class ForgotPass extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			mail: "",
		};
	}
	static get propTypes() {
		return {
			onClose: PropTypes.func,
			//redux:
			lang: PropTypes.string,
			langRoute: PropTypes.string,
		};
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onSubmit = async (e) => {
		if (e) e.stopPropagation();
		if (!isValidMail(this.state.mail)) {
			return Toast.error(lang.translate("invalid_mail"));
		}
		this.setState({ loading: true });
		const result = await forgotPass({ mail: this.state.mail, lang: this.props.langRoute });
		await delay(300);
		this.setState({ loading: false });
		if (!result.error) {
			Toast.success(lang.translate("password_reset_instruction"));
			this.props.onClose();
		}
	};
	render() {
		const disabled = this.state.loading;
		return (
			<Container className="forgotPass" noDocs={true}>
				<div className="wrapper">
					{this.state.loading && <Loader />}
					<header>
						<h3>{lang.translate("forgot_password")}</h3>
					</header>
					<main>
						<Input
							disabled={disabled}
							className="c"
							name="mail"
							placeholder={lang.translate("mail")}
							value={this.state.mail}
							onChange={this.onChange}
							onEnter={this.onSubmit}
							maxLength={512}
						/>
						<div className="btns">
							<button disabled={disabled || !isValidMail(this.state.mail)} onClick={this.onSubmit} className="btnDefault success">
								<label>{lang.translate("submit")}</label>
							</button>
							<button disabled={disabled} onClick={this.props.onClose} className="btnDefault">
								<label>{lang.translate("cancel")}</label>
							</button>
						</div>
					</main>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		langRoute: store.langRoute,
	};
};

export default connect(a, null, null, { forwardRef: true })(ForgotPass);
