import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//components:
import Select from "./Select";
//classes:
import { findUsernames } from "../../classes/player"; //, permsInfo, permsStatus

class SelectPlayer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.selected,
			found: this.props.selected, // ? [this.props.selected] : [],
			min: this.props.min || 0,
		};
	}
	static get propTypes() {
		return {
			id: PropTypes.string,
			permissionsChanged: PropTypes.number,
			onSelect: PropTypes.func,
			onUnselect: PropTypes.func,
			onClear: PropTypes.func,
			selected: PropTypes.array,
			data: PropTypes.array,
			min: PropTypes.number,
		};
	}
	onUnselect = (item, items) => {
		this.setState({ selected: items }, () => {
			if (typeof this.props.onUnselect === "function") this.props.onUnselect(item, items);
		});
	};
	onClear = () => {
		this.setState({ selected: [] }, () => {
			if (typeof this.props.onClear === "function") this.props.onClear();
		});
	};
	onSelect = async (item, items) => {
		await this.setState({ selected: items });
		if (typeof this.props.onSelect === "function") this.props.onSelect(item, items);
	};
	onSearch = async (pattern, _case) => {
		if (!_case) pattern = pattern.toLowerCase();
		const foundResult = await findUsernames(pattern);
		if (foundResult.error || !Array.isArray(foundResult)) return;
		const found = [];
		for (let el of foundResult) {
			found.push(el[1]);
		}
		if (Array.isArray(this.props.selected) && this.props.selected[0] && !found.includes(this.props.selected[0]))
			found.unshift(this.props.selected[0]);
		this.setState({ found });
	};
	render() {
		return (
			<Select
				id={this.props.id}
				multi={false}
				placeholder={"player"}
				caseSearch={false}
				validation="userName"
				onSelect={this.onSelect}
				onUnselect={this.onUnselect}
				onSearch={this.onSearch}
				data={this.state.found}
				selected={this.state.selected}
				min={this.state.min}
				style={{ flex: 1 }}
			/>
		);
	}
}

const a = (store) => {
	return {
		permissionsChanged: store.permissionsChanged,
	};
};

export default connect(a, null, null, { forwardRef: true })(SelectPlayer);
