import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { Icon } from "react-icons-kit";
import { ic_cloud_download as rainIn } from "react-icons-kit/md/ic_cloud_download";
import { ic_cloud_upload as rainOut } from "react-icons-kit/md/ic_cloud_upload";
import { ic_pan_tool as manual } from "react-icons-kit/md/ic_pan_tool";
import { check as withdrawApprove } from "react-icons-kit/fa/check";
import { close as withdrawReject } from "react-icons-kit/fa/close";
import { book as history } from "react-icons-kit/fa/book";
import { square } from "react-icons-kit/fa/square";
import { gamepad } from "react-icons-kit/fa/gamepad";
import { ic_warning } from "react-icons-kit/md/ic_warning";
import { fastForward as bankIn } from "react-icons-kit/fa/fastForward";
import { fastBackward as bankOut } from "react-icons-kit/fa/fastBackward";
import { futbolO as sportegrator } from "react-icons-kit/fa/futbolO";
import { rocket as crash } from "react-icons-kit/icomoon/rocket";
import { u1F424 as flappybet } from "react-icons-kit/noto_emoji_regular/u1F424";
//classes:
const lang = require("./lang").lang;

const icons = {};
icons["exchange"] = { code: "&#xe804;", class: "icon-exchange" };
icons["pie-chart"] = { code: "&#xe805;", class: "icon-pie-chart" };
icons["rocket-1"] = { code: "&#xe806;", class: "icon-rocket-1" };
icons["tip-send"] = { code: "&#xe807;", class: "icon-tip-send" };

icons["investment"] = { code: "&#xe808;", class: "icon-investment" };
icons["open"] = { code: "&#xe809;", class: "icon-open" };
icons["fairness"] = { code: "&#xe80a;", class: "icon-fairness" };
icons["money"] = { code: "&#xe80b;", class: "icon-money" };

icons["bank"] = { code: "&#xe80c;", class: "icon-bank" };
icons["home-1"] = { code: "&#xe80d;", class: "icon-home-1" };
icons["win"] = { code: "&#xe80e;", class: "icon-win" };
icons["target"] = { code: "&#xe80f;", class: "icon-target" };

icons["leaderboard"] = { code: "&#xe810;", class: "icon-leaderboard" };
icons["shield"] = { code: "&#xe811;", class: "icon-shield" };
icons["coin-insert"] = { code: "&#xe812;", class: "icon-coin-insert" };
icons["trophy"] = { code: "&#xe813;", class: "icon-trophy" };

icons["tag"] = { code: "&#xe816;", class: "icon-tag" };
icons["money-bag"] = { code: "&#xe817;", class: "icon-money-bag" };
icons["tip-receive"] = { code: "&#xe818;", class: "icon-tip-receive" };
icons["profit"] = { code: "&#xe819;", class: "icon-profit" };

icons["deposit"] = { code: "&#xe81a;", class: "icon-deposit" };
icons["btc"] = { code: "&#xe81b;", class: "icon-btc" };
icons["safe"] = { code: "&#xe81c;", class: "icon-safe" };
icons["search"] = { code: "&#xe81d;", class: "icon-search" };

icons["dice"] = { code: "&#xe81e;", class: "icon-dice" };
icons["chip"] = { code: "&#xe81f;", class: "icon-chip" };
icons["chest"] = { code: "&#xe821;", class: "icon-chest" };
icons["contest"] = { code: "&#xe822;", class: "icon-reward" };

icons["award"] = { code: "&#xe823;", class: "icon-award" };
icons["podium"] = { code: "&#xe824;", class: "icon-podium" };
icons["medal"] = { code: "&#xe825;", class: "icon-medal" };
icons["rain"] = { code: "&#xe826;", class: "icon-rain" };

icons["faucet"] = { code: "&#xe828;", class: "icon-faucet" };
icons["slot"] = { code: "&#xe829;", class: "icon-slot" };
icons["seven-2"] = { code: "&#xe82a;", class: "icon-seven-2" };
icons["seven"] = { code: "&#xe82b;", class: "icon-seven" };

icons["wallet"] = { code: "&#xe82c;", class: "icon-wallet" };
icons["withdraw"] = { code: "&#xe82d;", class: "icon-withdraw" };
icons["049-piggy-bank"] = { code: "&#xe82e;", class: "icon-049-piggy-bank" };
icons["coins"] = { code: "&#xe82f;", class: "icon-coins" };

icons["rocket"] = { code: "&#xe830;", class: "icon-rocket" };
icons["fortune"] = { code: "&#xe831;", class: "icon-fortune" };
icons["053-poker-cards"] = { code: "&#xe832;", class: "icon-053-poker-cards" };
icons["h24"] = { code: "&#xe836;", class: "icon-h24" };

icons["gift-heart"] = { code: "&#xe837;", class: "icon-gift-heart" };
icons["gift"] = { code: "&#xe838;", class: "icon-gift" };
icons["060-banned"] = { code: "&#xe839;", class: "icon-060-banned" };
icons["mute"] = { code: "&#xe83a;", class: "icon-mute" };

icons["pandora"] = { code: "&#xe83b;", class: "icon-pandora" };
icons["home"] = { code: "&#xe83c;", class: "icon-home" };
icons["064-videogame"] = { code: "&#xe83d;", class: "icon-064-videogame" };
icons["profile"] = { code: "&#xe83e;", class: "icon-profile" };

icons["ticket"] = { code: "&#xe83f;", class: "icon-ticket" };
icons["chart"] = { code: "&#xe841;", class: "icon-chart" };
icons["069-money-bag"] = { code: "&#xe842;", class: "icon-069-money-bag" };
icons["live"] = { code: "&#xe843;", class: "icon-live" };

icons["transaction"] = { code: "&#xe844;", class: "icon-transaction" };
icons["transaction-2"] = { code: "&#xe845;", class: "icon-transaction-2" };
icons["jackpot"] = { code: "&#xe847;", class: "icon-074-jackpot-1" };
icons["joker"] = { code: "&#xe848;", class: "icon-joker" };

icons["roulette"] = { code: "&#xe849;", class: "icon-roulette" };
icons["commission"] = { code: "&#xe84a;", class: "icon-commission" };
icons["dice-6"] = { code: "&#xe84b;", class: "icon-dice-6" };
icons["dice-5"] = { code: "&#xe84c;", class: "icon-dice-5" };

icons["dice-4"] = { code: "&#xe84d;", class: "icon-dice-4" };
icons["dice-3"] = { code: "&#xe84e;", class: "icon-dice-3" };
icons["dice-7"] = { code: "&#xe84f;", class: "icon-dice-7" };
icons["dice-2"] = { code: "&#xe850;", class: "icon-dice-2" };

icons["dice-1"] = { code: "&#xe851;", class: "icon-dice-1" };
icons["rate"] = { code: "&#xe852;", class: "icon-rate" };
icons["network"] = { code: "&#xe853;", class: "icon-network" };

export const botCaName = {
	TIP: "tip-send",
	AFFILIATION: "network",
	JACKPOT: "jackpot",
	BATTLE: "battle",
	DEPOSIT: "deposit",
	WITHDRAW: "withdraw",
	CONTEST: "contest",
	PANDORA: "pandora",
	EXCHANGE: "exchange",
	DICE: "dice",
	CRASH: "crash",
	RAIN: "rain",
	FAUCET: "faucet",
	BONUS: "gift",
	ACTIVITY: "activity",
	WARN: "warn",
};

export const iconCaName = {
	exchange: "exchange",
	"pie-chart": "pie-chart",
	"rocket-1": "rocket-1",
	"tip-send": "tip-send",
	investment: "investment",
	open: "open",
	fairness: "fairness",
	money: "money",
	bank: "bank",
	"home-1": "home-1",
	win: "win",
	target: "target",
	leaderboard: "leaderboard",
	shield: "shield",
	"coin-insert": "coin-insert",
	trophy: "trophy",
	tag: "tag",
	"money-bag": "money-bag",
	"tip-receive": "tip-receive",
	profit: "profit",
	deposit: "deposit",
	btc: "btc",
	safe: "safe",
	search: "search",
	dice: "dice",
	chip: "chip",
	chest: "chest",
	contest: "contest",
	award: "award",
	podium: "podium",
	medal: "medal",
	rain: "rain",
	faucet: "faucet",
	slot: "slot",
	freespin: "slot",
	"seven-2": "seven-2",
	seven: "seven",
	wallet: "wallet",
	withdraw: "withdraw",
	"049-piggy-bank": "049-piggy-bank",
	coins: "coins",
	rocket: "rocket",
	fortune: "fortune",
	"053-poker-cards": "053-poker-cards",
	h24: "h24",
	"gift-heart": "gift-heart",
	gift: "gift",
	"060-banned": "060-banned",
	mute: "mute",
	pandora: "pandora",
	home: "home",
	"064-videogame": "064-videogame",
	profile: "profile",
	ticket: "ticket",
	chart: "chart",
	"069-money-bag": "069-money-bag",
	live: "live",
	transaction: "transaction",
	"transaction-2": "transaction-2",
	jackpot: "jackpot",
	joker: "joker",
	roulette: "roulette",
	commission: "commission",
	"dice-6": "dice-6",
	"dice-5": "dice-5",
	"dice-4": "dice-4",
	"dice-3": "dice-3",
	"dice-7": "dice-7",
	"dice-2": "dice-2",
	"dice-1": "dice-1",
	rate: "rate",
	network: "network",
	//---
	history: "history",
	rainIn: "rainIn",
	rainOut: "rainOut",
	manual: "manual",
	withdrawApprove: "withdrawApprove",
	fortuneWheel: "fortune",
	rakeback: "commission",
};
function IconCa(props) {
	if (typeof icons[props.name] !== "undefined")
		return (
			<i
				title={props.title || ""}
				style={props.style || {}}
				className={`icon-ca ${icons[props.name].class} ${props.className || ""}`}
				dangerouslySetInnerHTML={{ __html: icons[props.name].code }}
			/>
		);
	else if (typeof iconCaByAction[props.name] !== "undefined") return <i dangerouslySetInnerHTML={{ __html: oo[props.name] }} />;
	else return null;
}
IconCa.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
};
export const iconCaByAction = {
	deposit: <IconCa title={lang ? lang.translate("deposit") : ""} name={iconCaName["deposit"]} />,
	withdraw: <IconCa title={lang ? lang.translate("withdraw") : ""} name={iconCaName["withdraw"]} />,
	faucet: <IconCa title={lang ? lang.translate("faucet") : ""} name={iconCaName["faucet"]} />,
	exchange: <IconCa title={lang ? lang.translate("exchange") : ""} name={iconCaName["exchange"]} />,
	affiliation: <IconCa title={lang ? lang.translate("affiliation") : ""} name={iconCaName["network"]} />,
	tipIn: <IconCa title={lang ? lang.translate("tipIn") : ""} name={iconCaName["tip-receive"]} />,
	tipOut: <IconCa title={lang ? lang.translate("tipOut") : ""} name={iconCaName["tip-send"]} />,
	rainIn: <Icon size={24} title={lang ? lang.translate("rainIn") : ""} icon={rainIn} />,
	rainOut: <Icon size={24} title={lang ? lang.translate("rainOut") : ""} icon={rainOut} />,
	dice: <IconCa title={lang ? lang.translate("dice") : ""} name={iconCaName.dice} />,
	pandora: <IconCa title={lang ? lang.translate("pandora") : ""} name={iconCaName.pandora} />,
	jackpot: <IconCa title={lang ? lang.translate("jackpot") : ""} name={iconCaName.jackpot} />,
	manual: <Icon size={24} title={lang ? lang.translate("manual") : ""} icon={manual} />,
	activity: <Icon size={24} title={lang ? lang.translate("activity") : ""} icon={gamepad} />,
	contest: <IconCa title={lang ? lang.translate("contest") : ""} name={iconCaName.contest} />,
	depositBonus: <IconCa title={lang ? lang.translate("bonus") : ""} name={iconCaName.gift} />,
	withdrawApprove: <Icon size={24} title={lang ? lang.translate("withdrawApprove") : ""} icon={withdrawApprove} />,
	withdrawReject: <Icon size={24} title={lang ? lang.translate("withdrawReject") : ""} icon={withdrawReject} />,
	history: <Icon size={24} title={lang ? lang.translate("history") : ""} icon={history} />,
	all: <Icon size={24} title={lang ? lang.translate("all") : ""} icon={square} />,
	warn: <Icon size={24} title={lang ? lang.translate("warn") : ""} icon={ic_warning} />,
	bankIn: <Icon size={24} title={lang ? lang.translate("warn") : ""} icon={bankIn} />,
	bankOut: <Icon size={24} title={lang ? lang.translate("warn") : ""} icon={bankOut} />,
	slotegrator: <IconCa title={lang ? lang.translate("slots") : ""} name={iconCaName.slot} />,
	sportegrator: <Icon size={24} title={lang ? lang.translate("sport") : ""} icon={sportegrator} />,
	crash: <Icon size={24} title={lang ? lang.translate("crash") : ""} icon={crash} />,
	fortuneWheel: <IconCa title={lang ? lang.translate("fortune_wheel") : ""} name={iconCaName["fortuneWheel"]} />,
	// battle: <img alt="" title={lang ? lang.translate("battle") : ""} src="/assets/swords.webp" width={24} height={24} />,
	battle: <img alt="" title={lang ? lang.translate("battle") : ""} src="/assets/icons2/svg/battle.svg" width={24} height={24} />,
	freespin: <IconCa title={lang ? lang.translate("slots") : ""} name={iconCaName.slot} />,
	freespinIn: <IconCa title={lang ? lang.translate("slots") : ""} name={iconCaName.slot} />,
	freespinOut: <IconCa title={lang ? lang.translate("slots") : ""} name={iconCaName.slot} />,
	rakeback: <IconCa title={lang ? lang.translate("rakeback") : ""} name={iconCaName.commission} />,
	flappybet:  <Icon size={24} title={lang ? lang.translate("withdrawReject") : ""} icon={flappybet} />,
	// flappybet: <img alt="" title={lang ? lang.translate("flappybet") : ""} src="/assets/bird.svg" width={24} height={24} />,
};
const oo = {};
for (let el in iconCaByAction) oo[el] = ReactDOMServer.renderToString(iconCaByAction[el]);

export default IconCa;

export const arrActions = [
	{ id: "deposit", keyword: "deposit", index: 0 },
	{ id: "withdraw", keyword: "withdraw", index: 1 },
	{ id: "faucet", keyword: "faucet", index: 2 },
	{ id: "exchange", keyword: "exchange", index: 3 },
	{ id: "affiliation", keyword: "affiliation", index: 4 },
	{ id: "tipIn", keyword: "tips_received", index: 5 },
	{ id: "tipOut", keyword: "tips_sent", index: 6 },
	{ id: "rainIn", keyword: "rain_in", index: 7 },
	{ id: "rainOut", keyword: "rain_out", index: 8 },
	{ id: "dice", keyword: "dice", index: 9 },
	{ id: "pandora", keyword: "pandora", index: 10 },
	{ id: "jackpot", keyword: "jackpot", index: 11 },
	{ id: "manual", keyword: "manual", index: 12 },
	{ id: "activity", keyword: "activity", index: 13 },
	{ id: "contest", keyword: "contest", index: 14 },
	{ id: "depositBonus", keyword: "deposit_bonus", index: 15 },
	{ id: "withdrawApprove", keyword: "withdraw_approved", index: 16 },
	{ id: "withdrawReject", keyword: "withdraw_rejected", index: 17 },
	{ id: "warn", keyword: "warn", index: 18 },
	{ id: "bankIn", keyword: "bank_in", index: 19 },
	{ id: "bankOut", keyword: "bank_out", index: 20 },
	{ id: "slotegrator", keyword: "slots", v: 21 },
	{ id: "sportegrator", keyword: "sport", v: 22 },
	{ id: "crash", keyword: "crash", v: 23 },
	{ id: "fortuneWheel", keyword: "fortuneWheel", v: 24 },
	{ id: "tierGift", keyword: "tierGift", v: 25 },
	{ id: "battle", keyword: "battle", v: 26 },
	{ id: "freespin", keyword: "freespin", v: 27 },
	{ id: "freespinIn", keyword: "freespinIn", v: 28 },
	{ id: "freespinOut", keyword: "freespinOut", v: 29 },
	{ id: "rakeback", keyword: "rakeback", v: 30 },
	{ id: "flappybet", keyword: "flappybet", v: 31 },
];

export const actionToHtmlIcon = (i) => {
	return arrActions[i] && arrActions[i]["id"] && oo[arrActions[i]["id"]] ? oo[arrActions[i]["id"]] : "";
	// return ReactDOMServer.renderToString(<button className="diceBtn yellow">{el}</button>);
};
