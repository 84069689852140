import { toast } from "react-toastify";
// import { play } from "./sound";
// import { store } from "../redux/store"

const options = {
	draggable: false,
	position: "top-right",
	autoClose: 3000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	progressClassName: "toast-progress",
	mute: false,
};
class Toast {
	default = (str, myOptions = {}) => {
		if (!str || str === "") return;
		// if (store.getState().general.soundOn && !myOptions.mute) play("alert");
		toast(str, { ...options, ...myOptions, className: "toast-msg toast-default" });
	};
	info = (str, myOptions = {}) => {
		if (!str || str === "") return;
		// if (store.getState().general.soundOn && !myOptions.mute) play("alert");
		toast.info(str, { ...options, ...myOptions, className: "toast-msg toast-info" });
	};
	success = (str, myOptions = {}) => {
		if (!str || str === "") return;
		// if (store.getState().general.soundOn && !myOptions.mute) play("success");
		toast.success(str, { ...options, ...myOptions, className: "toast-msg toast-success" });
	};
	error = (str, myOptions = {}) => {
		if (!str || str === "") return;
		// if (store.getState().general.soundOn && !myOptions.mute) play("error");
		toast.error(str, { ...options, ...myOptions, className: "toast-msg toast-error" });
	};
	warning = (str, myOptions = {}) => {
		if (!str || str === "") return;
		// if (store.getState().general.soundOn && !myOptions.mute) play("alert");
		toast.warning(str, { ...options, ...myOptions, className: "toast-msg toast-warning" });
	};
}

export default new Toast();
