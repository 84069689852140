export const twitter64 = {
	compressed: true,
	categories: [
		{
			id: "people",
			em: "smiling_face",
			name: "people",
			emojis: [
				"smiling_face_with_heart_eyes",
				"smiling_face_with_hearts",
				"smiling_face",
				"smiling_face_with_horns",
				"smiling_face_with_halo",
				"smiling_face_with_sunglasses",
				"grinning_face_with_smiling_eyes",
				"grinning_squinting_face",
				"upside_down_face",
				"zany_face",
				"hushed_face",
				"partying_face",
				"rolling_on_the_floor_laughing",
				"unamused_face",
				"winking_face_with_tongue",
				"zipper_mouth_face",
				"crying_face",
				"face_blowing_a_kiss",
				"face_with_monocle",
				"face_with_tears_of_joy",
				"loudly_crying_face",
				"pleading_face",
				"shushing_face",
				"smirking_face",
				"woozy_face",
				"dizzy_face",
				"face_screaming_in_fear",
				"face_with_rolling_eyes",
				"face_with_thermometer",
				"lying_face",
				"pouting_face",
				"sleeping_face",
				"sneezing_face",
				"weary_face",
				"yawning_face",
				"drooling_face",
				"face_vomiting",
				"face_with_steam_from_nose",
				"flushed_face",
				"hugging_face",
				"nerd_face",
				"relieved_face",
				"thinking_face",
				"winking_face",
				"money_mouth_face",
				"cowboy_hat_face",
				"hot_face",
				"star_struck",
				"alien",
				"backhand_index_pointing_down",
				"backhand_index_pointing_left",
				"backhand_index_pointing_right",
				"backhand_index_pointing_up",
				"clapping_hands",
				"cold_face",
				"crossed_fingers",
				"crown",
				"eyes",
				"fairy",
				"ghost",
				"handshake",
				"love_you_gesture",
				"man_dancing",
				"man_facepalming",
				"man_fairy",
				"man_juggling",
				"man_raising_hand",
				"man_running",
				"man_shrugging",
				"man_tipping_hand",
				"man_zombie",
				"men_with_bunny_ears",
				"middle_finger",
				"oncoming_fist",
				"people_with_bunny_ears",
				"person_biking",
				"person_facepalming",
				"person_juggling",
				"person_raising_hand",
				"person_running",
				"person_shrugging",
				"person_tipping_hand",
				"pile_of_poo",
				"raising_hands",
				"santa_claus",
				"snowman",
				"speak_no_evil_monkey",
				"thought_balloon",
				"thumbs_down",
				"thumbs_up",
				"waving_hand",
				"woman_biking",
				"woman_dancing",
				"woman_facepalming",
				"woman_fairy",
				"woman_juggling",
				"woman_raising_hand",
				"woman_running",
				"woman_shrugging",
				"woman_tipping_hand",
				"woman_zombie",
				"women_with_bunny_ears",
				"writing_hand",
				"zombie",
			],
		},
		{
			id: "love",
			name: "love",
			em: "growing_heart",
			emojis: ["broken_heart", "growing_heart", "heart_with_arrow", "kiss_mark", "revolving_hearts", "ring", "sparkling_heart"],
		},
		{
			id: "animal",
			name: "animal",
			em: "owl",
			emojis: [
				"butterfly",
				"cat_with_tears_of_joy",
				"christmas_tree",
				"cow_face",
				"desert_island",
				"dog_face",
				"fish",
				"fishing_pole",
				"flag_in_hole",
				"flexed_biceps",
				"frog",
				"globe",
				"hear_no_evil_monkey",
				"honeybee",
				"horse",
				"kangaroo",
				"owl",
				"penguin",
				"pig_face",
				"rabbit_face",
				"rooster",
				"see_no_evil_monkey",
				"sloth",
				"snake",
				"spouting_whale",
				"sun",
				"sunrise",
				"teddy_bear",
				"tornado",
				"umbrella_with_rain_drops",
				"unicorn",
				"volcano",
				//---
			],
		},
		{
			id: "technique",
			name: "technique",
			em: "automobile",
			emojis: [
				"airplane",
				"alarm_clock",
				"automobile",
				"camera_with_flash",
				"clapper_board",
				"clock",
				"fire_engine",
				"flying_saucer",
				"helicopter",
				"light_bulb",
				"locomotive",
				"man_biking",
				"oncoming_police_car",
				"police_car_light",
				"robot",
				"rocket",
				"sport_utility_vehicle",
			],
		},
		{
			id: "food",
			name: "food",
			em: "sandwich",
			emojis: [
				"birthday_cake",
				"bottle_with_popping_cork",
				"clinking_beer_mugs",
				"clinking_glasses",
				"hamburger",
				"hot_beverage",
				"pancakes",
				"popcorn",
				"sandwich",
			],
		},
		{
			id: "party",
			name: "party",
			em: "drum",
			emojis: ["bomb", "bowling", "collision", "confetti_ball", "drum", "ferris_wheel"],
		},
		{
			id: "symbol",
			name: "symbol",
			em: "rainbow_flag",
			emojis: ["check_mark_button", "direct_hit", "hundred_points", "money_with_wings", "peace_symbol", "prohibited", "rainbow_flag", "warning"],
		},
	],
	emojis: {
		// 100: {
		// 	k: [26, 5],
		// },
		// "woman-kiss-man": {
		// 	k: [20, 7],
		// },
		hushed_face: {},
		partying_face: {},
		rolling_on_the_floor_laughing: {},
		smiling_face_with_halo: {},
		smiling_face_with_sunglasses: {},
		unamused_face: {},
		winking_face_with_tongue: {},
		zipper_mouth_face: {},
		crying_face: {},
		face_blowing_a_kiss: {},
		face_with_monocle: {},
		face_with_tears_of_joy: {},
		grinning_face_with_smiling_eyes: {},
		loudly_crying_face: {},
		pleading_face: {},
		shushing_face: {},
		smiling_face_with_heart_eyes: {},
		smirking_face: {},
		upside_down_face: {},
		woozy_face: {},
		dizzy_face: {},
		face_screaming_in_fear: {},
		face_with_rolling_eyes: {},
		face_with_thermometer: {},
		grinning_squinting_face: {},
		lying_face: {},
		pouting_face: {},
		sleeping_face: {},
		smiling_face_with_hearts: {},
		sneezing_face: {},
		weary_face: {},
		yawning_face: {},
		drooling_face: {},
		face_vomiting: {},
		face_with_steam_from_nose: {},
		flushed_face: {},
		hugging_face: {},
		nerd_face: {},
		relieved_face: {},
		smiling_face: {},
		smiling_face_with_horns: {},
		thinking_face: {},
		winking_face: {},
		zany_face: {},
		money_mouth_face: {},
		cowboy_hat_face: {},
		hot_face: {},
		star_struck: {},

		alien: {},
		backhand_index_pointing_down: {},
		backhand_index_pointing_left: {},
		backhand_index_pointing_right: {},
		backhand_index_pointing_up: {},
		clapping_hands: {},
		cold_face: {},
		crossed_fingers: {},
		crown: {},
		eyes: {},
		fairy: {},
		ghost: {},
		handshake: {},
		love_you_gesture: {},
		man_dancing: {},
		man_facepalming: {},
		man_fairy: {},
		man_juggling: {},
		man_raising_hand: {},
		man_running: {},
		man_shrugging: {},
		man_tipping_hand: {},
		man_zombie: {},
		men_with_bunny_ears: {},
		middle_finger: {},
		oncoming_fist: {},
		people_with_bunny_ears: {},
		person_biking: {},
		person_facepalming: {},
		person_juggling: {},
		person_raising_hand: {},
		person_running: {},
		person_shrugging: {},
		person_tipping_hand: {},
		pile_of_poo: {},
		raising_hands: {},
		santa_claus: {},
		snowman: {},
		speak_no_evil_monkey: {},
		thought_balloon: {},
		thumbs_down: {},
		thumbs_up: {},
		waving_hand: {},
		woman_biking: {},
		woman_dancing: {},
		woman_facepalming: {},
		woman_fairy: {},
		woman_juggling: {},
		woman_raising_hand: {},
		woman_running: {},
		woman_shrugging: {},
		woman_tipping_hand: {},
		woman_zombie: {},
		women_with_bunny_ears: {},
		writing_hand: {},
		zombie: {},
		//---
		broken_heart: {},
		growing_heart: {},
		heart_with_arrow: {},
		kiss_mark: {},
		revolving_hearts: {},
		ring: {},
		sparkling_heart: {},
		//---
		butterfly: {},
		cat_with_tears_of_joy: {},
		christmas_tree: {},
		cow_face: {},
		desert_island: {},
		dog_face: {},
		fish: {},
		fishing_pole: {},
		flag_in_hole: {},
		flexed_biceps: {},
		frog: {},
		globe: {},
		hear_no_evil_monkey: {},
		honeybee: {},
		horse: {},
		kangaroo: {},
		owl: {},
		penguin: {},
		pig_face: {},
		rabbit_face: {},
		rooster: {},
		see_no_evil_monkey: {},
		sloth: {},
		snake: {},
		spouting_whale: {},
		sun: {},
		sunrise: {},
		teddy_bear: {},
		tornado: {},
		umbrella_with_rain_drops: {},
		unicorn: {},
		volcano: {},
		//---
		airplane: {},
		alarm_clock: {},
		automobile: {},
		camera_with_flash: {},
		clapper_board: {},
		clock: {},
		fire_engine: {},
		flying_saucer: {},
		helicopter: {},
		light_bulb: {},
		locomotive: {},
		man_biking: {},
		oncoming_police_car: {},
		police_car_light: {},
		robot: {},
		rocket: {},
		sport_utility_vehicle: {},
		//
		birthday_cake: {},
		bottle_with_popping_cork: {},
		clinking_beer_mugs: {},
		clinking_glasses: {},
		hamburger: {},
		hot_beverage: {},
		pancakes: {},
		popcorn: {},
		sandwich: {},
		//---
		bomb: {},
		bowling: {},
		collision: {},
		confetti_ball: {},
		drum: {},
		ferris_wheel: {},
		//---
		check_mark_button: {},
		direct_hit: {},
		hundred_points: {},
		money_with_wings: {},
		peace_symbol: {},
		prohibited: {},
		rainbow_flag: {},
		warning: {},
	},
};
