/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react";
// import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//components:
import SelectPlayer from "../../../components/select/SelectPlayer";
import Balance from "../../../containers/balance/Balance";
import lang from "../../../classes/lang";
import { withPerms } from "../../../classes/player";
import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { go } from "../../../classes/routesLazy";
import Select from "../../../components/select/Select";
import { useGamesByTier } from "../../battle/useBattle";
import IconCa from "../../../classes/iconCa";
import Axios from "../../../classes/axios";
import { onResult, tryParseFloat, tryParseInt } from "../../../classes/utills";
import Toast from "../../../classes/toast";
import Input from "../../../components/input/Input";
import { useSelector } from "react-redux";
import Decimal from "decimal.js";
import { useEffect } from "react";
import { useFlappy } from "../../../hooks/useFlappy";
import Tab from "../../../components/tab/Tab";
// eslint-disable-next-line no-unused-vars
const FSBuy = forwardRef((props, ref) => {
	FSBuy.propTypes = {
		match: PropTypes.object,
		perms: PropTypes.object,
	};

	const [loading, setLoading] = useState(false);
	const me = useSelector((s) => s.player.userName);
	const [playerSelected, setPlayerSelected] = useState(props.match.params?.targetUserName ?? null);
	const coinSelected = useSelector((s) => s.coin?.selected);
	const [quantity, setQuantity] = useState("5");
	const [tier, setTier] = useState(1);
	const [game_uuid, setGame_uuid] = useState();
	const [gamePattern, setGamePattern] = useState();
	const { games } = useGamesByTier({ tier });

	const [fGamePattern, setFGamePattern] = useState();
	const [fGameId, setFGameId] = useState();
	const [fSpinValue, setFSpinValue] = useState("0.1");
	const { betIdsAll } = useFlappy();
	const [activeTab, setActiveTab] = useState("slots");
	const onTabClick = (tab) => {
		setActiveTab(tab);
	};
	useEffect(() => {
		if (me !== "guest" && playerSelected === null) setPlayerSelected(me);
	}, [me, playerSelected]);
	const onQuantity = (e, ev = "change") => {
		let v = e.target.value;
		if (ev === "blur") v = tryParseInt(v, 5);
		setQuantity(`${v}`);
	};
	const onFSpinValue = (e, ev = "change") => {
		let v = e.target.value;
		if (ev === "blur") v = tryParseFloat(v, 5);
		setFSpinValue(`${v}`);
	};
	const onSelect = (item) => {
		go(`billing/fsbuy/${item.id}`);
		setPlayerSelected(item.id);
	};
	const onUnselect = () => {
		// if (this.props.match.params.targetUserName) go("billing/tip");
	};
	const disabledSlots = !(tier > 0 && tryParseInt(quantity, 0) > 0 && game_uuid && coinSelected && playerSelected && loading === false);
	const disabledFlappy = !(tryParseInt(quantity, 0) > 0 && fGameId && coinSelected && playerSelected && loading === false);
	const disabled = {
		slots: disabledSlots,
		flappybet: disabledFlappy,
	}[activeTab];

	const onBuySlots = async () => {
		const o = {
			tier,
			quantity: tryParseInt(quantity, 5),
			game_uuid,
			short: coinSelected,
			un: playerSelected,
		};
		setLoading(true);
		const result = await Axios.post("/api/slotegrator/freespinsBuy", o);
		return await onResult(result, "unexpected_error", () => {
			Toast.success(lang.translate("freespins_acquired"));
			setLoading(false);
		});
	};

	const onBuyFlappy = async () => {
		const o = {
			short: coinSelected,
			gameId: +fGameId.split(":")[0],
			un: playerSelected,
			campaignName: undefined,
			betId: fGameId.split(":")[1],
			amount: tryParseFloat(fSpinValue, 0),
			quantity: tryParseInt(quantity, 0),
		};
		setLoading(true);
		const result = await Axios.post("/api/flappybet/freespinAssign", o);
		return await onResult(result, "unexpected_error", () => {
			Toast.success(lang.translate("freespins_acquired"));
			setLoading(false);
		});
	};

	const onBuy = async () => {
		return await { slots: onBuySlots, flappybet: onBuyFlappy }[activeTab]();
	};

	const totalAmountSlots = new Decimal(quantity === "" ? 0 : quantity ?? 0).mul(["0.2", "0.4", "0.8", "1", "2"][tier - 1]).toFixed(2);
	const totalAmountFlappy = new Decimal(quantity === "" ? 0 : quantity ?? 0).mul(fSpinValue === "" ? 0 : fSpinValue ?? 0).toFixed(2);
	const totalAmount = {
		slots: totalAmountSlots,
		flappybet: totalAmountFlappy,
	}[activeTab];
	return (
		<Container className="FSBuy">
			<div className="wrapper">
				<main>
					<label>
						<b>1)</b>
						<b>{lang.translate("select_player")}</b>
					</label>
					<div className={`selectPlayer ${props.perms && props.perms.PLAYER_FIND ? "on" : "off"}`}>
						{playerSelected && (
							<SelectPlayer
								ref={(el) => withPerms(el, ["PLAYER_FIND"])}
								min={1}
								selected={playerSelected ? [playerSelected] : []}
								onSelect={onSelect}
								onUnselect={onUnselect}
							/>
						)}
					</div>
					<label>
						<b>2)</b>
						<b>{lang.translate("quantity")}</b>
					</label>
					<div className="quantityWrapper">
						<Input
							className="c"
							value={quantity}
							validation={"integer>0"}
							onChange={(e) => onQuantity(e, "change")}
							onBlur={(e) => onQuantity(e, "blur")}
							placeholder={lang.translate("quantity")}
						/>
					</div>
					<label>
						<b>{"3)"}</b>
						<b>{lang.translate("provider")}</b>
					</label>
					<Tab
						items={[
							{
								name: "slots",
								c: <b>{lang.translate("slots")}</b>,
							},
							{
								name: "flappybet",
								c: <b>{lang.translate("flappybet")}</b>,
							},
						]}
						active={activeTab}
						showLine={true}
						onClick={onTabClick}
						classNameToggled="success"
					/>
					{activeTab === "slots" && (
						<>
							<label>
								<b>{"4)"}</b>
								<b>{lang.translate("spin_value")} $</b>
							</label>
							<Select
								caseSearch={false}
								valueHeader={false}
								valueBody={false}
								min={1}
								selected={[tier]}
								data={["0.2", "0.4", "0.8", "1", "2"].map((el, i) => ({
									id: `${i + 1}`,
									value: i + 1,
									title: `${el}`,
								}))}
								onSelect={(item) => {
									setTier(item.value);
								}}
							/>

							<label>
								<b>{"5)"}</b>
								<b>{lang.translate("game")}</b>
							</label>
							<Select
								caseSearch={false}
								valueHeader={false}
								valueBody={false}
								min={1}
								className={"gameSelect"}
								selected={[game_uuid]}
								data={(games ?? [])
									.filter((el) => el.name.toLowerCase().includes(gamePattern))
									.map((el) => ({
										id: `${el.game_uuid}`,
										value: el.game_uuid,
										title: `${el.name}`,
										c: <img src={el.image} alt="" />,
									}))}
								onSearch={(pattern) => {
									setGamePattern(`${pattern ?? ""}`.toLowerCase());
								}}
								onSelect={(item) => {
									setGame_uuid(item.value);
								}}
							/>
						</>
					)}
					{activeTab === "flappybet" && (
						<>
							<label>
								<b>{"4)"}</b>
								<b>{lang.translate("spin_value")} $</b>
							</label>
							<div className="quantityWrapper">
								<Input
									className="c"
									value={fSpinValue}
									validation={"amount"}
									min={0.1}
									onChange={(e) => onFSpinValue(e, "change")}
									onBlur={(e) => onFSpinValue(e, "blur")}
									placeholder={lang.translate("quantity")}
								/>
							</div>
							<label>
								<b>{"5)"}</b>
								<b>{lang.translate("game")}</b>
							</label>
							<Select
								caseSearch={false}
								valueHeader={false}
								valueBody={false}
								min={1}
								className={"gameSelect"}
								selected={[fGameId]}
								data={(betIdsAll ?? [])
									.filter((el) => el.GameName.toLowerCase().includes(fGamePattern))
									.map((el) => ({
										id: `${el.GameId}:${el.BetId}`,
										value: `${el.GameId}:${el.BetId}`,
										title: `${el.GameName}: ${el.BetId}`,
										c: <img src={el.GameImage} alt="" />,
									}))}
								onSearch={(pattern) => {
									setFGamePattern(`${pattern ?? ""}`.toLowerCase());
								}}
								onSelect={(item) => {
									setFGameId(item.value);
								}}
							/>
						</>
					)}
				</main>
				<main>
					{(activeTab === "slots" ? tier : true) && quantity && <div className="divInfo">{`${lang.translate("total")}: ${totalAmount} $`}</div>}
					<div className="sendWrapper">
						<button onClick={(e) => [e.stopPropagation(), onBuy()]} disabled={disabled} className="btnDefault warn">
							<IconCa name={"slot"} />
							<b>{lang.translate("buy")}</b>
						</button>
					</div>
				</main>
			</div>
		</Container>
	);
});
FSBuy.displayName = "FSBuy";
export default FSBuy;
