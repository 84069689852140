import { store, storeSet } from "../index";
import Axios from "./axios";
import Toast from "./toast";
import Storage from "./storage";
import { lang } from "./lang";
import { renew } from "./socket";
import { go } from "./routesLazy";
// import Fingerprint2 from "fingerprintjs2";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import config from "../config";
import { onResult, copy, tokenWrap, limitByTime, yesNoWrap } from "./utills";
import { siteSettingsSet } from "../classes/system";
const { detect } = require("detect-browser");

export const withPerms = (cref, permsArray) => {
	if (!cref || cref.permsArray || !permsArray) return;
	cref.permsArray = permsArray;
	cref.perms = null;
	cref._componentWillUnmount = cref.componentWillUnmount;
	cref._componentDidUpdate = cref.componentDidUpdate;

	cref.permissionInterval = setInterval(() => cref.updatePermissions(), 100);
	cref.componentWillUnmount = () => {
		// console.log("clearInterval");
		clearInterval(cref.permissionInterval);
		typeof cref._componentWillUnmount === "function" && cref._componentWillUnmount();
	};
	cref.updatePermissions = async () => {
		// console.log("tick", Math.random())
		if (!cref.perms) return await permsInfo(cref.permsArray).then((o) => [(cref.perms = o.perms), cref.setState({ perms: o.status })]);
		const { status, changed } = await permsStatus(cref.permsArray, cref.perms);
		if (changed) cref.setState({ perms: status });
	};
	cref.componentDidUpdate = (prevProps, prevState) => {
		const permissionsChanged = prevProps.permissionsChanged !== cref.props.permissionsChanged;
		if (permissionsChanged) cref.perms = null;
		typeof cref._componentDidUpdate === "function" && cref._componentDidUpdate(prevProps, prevState);
	};
};
export const setPrivacy = async (v, cb = () => {}) => {
	const state = await store.getState();
	const siteSettings = state?.playerConfig?.siteSettings || state?.playerConfigDefaults?.siteSettings;
	if (!siteSettings?.custom) return;
	const payload = Object.assign({}, siteSettings.custom);
	payload.privacy_mode = !!v;
	yesNoWrap(lang.translate(payload.privacy_mode ? "on_privacy_on" : "on_privacy_off"), async (boolResponse) => {
		if (!boolResponse) return;
		siteSettingsSet("custom", payload, cb);
	});
};

export const permsStatus = async (permsArray, perms) => {
	const state = await store.getState();
	const o = { changed: false, status: {} };
	for (let type of permsArray) {
		o.status[type] = _can(state, type, perms[type]);
		if (perms[type].can !== o.status[type]) {
			o.changed = true;
			perms[type].can = o.status[type];
		}
	}
	return o;
};
export const simpleCan = async (permision) => {
	const o = await permsInfo([permision]);
	return o.status[permision];
};
export const simpleCanSync = (state, permision) => {
	const o = _permsInfo(state, [permision]);
	return o.status[permision];
};
export const permsInfo = async (permsArray) => {
	const state = await store.getState();
	// console.log("state.permissions: ", state.permissions);
	return _permsInfo(state, permsArray);
};
const _permsInfo = (state, permsArray) => {
	const o = { perms: {}, status: {}, changed: true };
	for (let type of permsArray) {
		o.perms[type] = _can(state, type, null);
		o.status[type] = o.perms[type]["can"];
	}
	return o;
};
const _can = (state, type, obj = null) => {
	// if (obj) console.log("delta: ", state.serverTime - obj.validFrom);
	if (obj) return obj.validFrom < state.serverTime && state.serverTime < obj.validTo;
	const permissions = state.permissions;
	// console.log(permissions);
	const o = {};
	if (typeof permissions !== "object" || permissions === null) {
		o.validFrom = Infinity;
		o.validTo = Infinity;
		o.can = false;
		return o;
	}
	if (typeof permissions[type] === "object" && permissions[type] !== null) {
		if (typeof permissions[type]["REVOKE"] === "object" && permissions[type]["REVOKE"] !== null) {
			if (permissions[type]["REVOKE"]["to"] > state.serverTime) {
				o.validFrom = permissions[type]["REVOKE"]["to"];
			}
		}
		if (typeof permissions[type]["GRANT"] === "object" && permissions[type]["GRANT"] !== null) {
			if (permissions[type]["GRANT"]["to"] > state.serverTime) {
				o.validTo = permissions[type]["GRANT"]["to"];
			}
		}
		//cases when at least one of [validFrom, validTo] is undefined:
		if (typeof o.validFrom !== "undefined" && typeof o.validTo === "undefined") {
			o.validFrom = Infinity;
			o.validTo = Infinity;
		} else if (typeof o.validFrom === "undefined" && typeof o.validTo !== "undefined") {
			o.validFrom = 0;
		} else if (typeof o.validFrom === "undefined" && typeof o.validTo === "undefined") {
			o.validFrom = Infinity;
			o.validTo = Infinity;
		}
		o.can = o.validFrom < state.serverTime && state.serverTime < o.validTo;
		return o;
	} else {
		o.validFrom = Infinity;
		o.validTo = Infinity;
		o.can = false;
		return o; //permission entry not found
	}
};
export const getFingerprintOnly = async () => {
	return new Promise((resolve) => {
		try {
			const fpPromise = FingerprintJS.load({
				apiKey: "6LsCwny0HwRYCOlYXOqI",
			});
			fpPromise
				.then((fp) => fp.get())
				.then((result) => {
					return resolve({
						fp: result?.visitorId || "",
					});
				})
				.catch(() => {
					return resolve({ fp: "" });
				});
		} catch (err) {
			console.log(err);
			return resolve({ fp: "" });
		}
	});
};
export const getFingerprint = async () => {
	return new Promise((resolve) => {
		try {
			const fpPromise = FingerprintJS.load({
				apiKey: "6LsCwny0HwRYCOlYXOqI",
			});
			let browser = detect();
			fpPromise
				.then((fp) => fp.get())
				.then((result) => {
					return resolve({
						fp: result?.visitorId || "",
						os: browser?.os || "",
						browser: browser?.name || "",
					});
				})
				.catch(() => {
					return resolve({
						fp: "",
						os: "",
						browser: "",
					});
				});

			// Fingerprint2.get((components) => {
			// 	let browser = detect();
			// 	if (!browser) browser = {};
			// 	components.unshift({
			// 		key: "browser",
			// 		value: {
			// 			name: browser.name,
			// 			version: browser.version,
			// 			os: browser.os,
			// 		},
			// 	});
			// 	const values = components.map((component) => component.value);
			// 	const fp = Fingerprint2.x64hash128(values.join(""), 31);
			// 	return resolve({ fp, components, values });
			// });
		} catch (err) {
			console.log(err);
			return resolve({ fp: "", os: "", browser: "" });
		}
	});
};
export const getPhoto = (uid, img, w = 200, h = 200) => {
	let url;
	if (uid >= 0 && typeof img === "number") {
		url = `${config.links.cloud}w_${w},h_${h},c_fill/v${img}/profile/${uid}.jpg`;
	} else {
		// url = `https://www.gravatar.com/avatar/${uid}?s=${w}&d=robohash`;
		url = "/assets/avatar-default.webp";
	}
	return url;
};
export const logout = async () => {
	Storage.unset("token");
	await storeSet({
		token: { $set: null },
		player: {
			$set: {
				userName: "guest",
				uid: -1,
				img: null,
				temp: true,
			},
		},
		shortcuts: { $set: [] },
		seeds: {
			$set: {
				cs: "",
				ssSha: "",
				csPrev: "",
				ssPrev: "",
			},
		},
	});
	await renew();
	await go("registration");
};
export const logoutManual = async () => {
	await Axios.post("/api/player/logout", {});
	logout();
	return {};
	// return await onResult(
	// 	result,
	// 	"unexpected_error",
	// 	async (r) => {
	// 		logout();
	// 		return r;
	// 	},
	// 	async (err) => {
	// 		console.log("err: ", err);
	// 		logout();
	// 		return err;
	// 	}
	// );
};
export const getData = async (userName) => {
	if (/^[a-zA-Z]{1,1}[a-zA-Z0-9\-_]{5,11}$/gi.test(userName) === false) {
		Toast.error(lang.translate("invalid_username"));
		return { error: true };
	}
	const result = await Axios.post("/api/player/getData", { userName });
	return await onResult(result, "username_not_found");
};
export const getRandomData = async (userName) => {
	if (/^[a-zA-Z]{1,1}[a-zA-Z0-9\-_]{5,11}$/gi.test(userName) === false) {
		Toast.error(lang.translate("invalid_username"));
		return { error: true };
	}
	const result = await Axios.post("/api/player/getRandomData", { userName });
	return await onResult(result, "username_not_found");
};
export const register = async (o) => {
	// const { recaptcha, un, refcode, campaign, clickid, subid, cost, promo } = o;
	if (!/^[a-zA-Z]{1,1}[a-zA-Z0-9\-_]{5,11}$/gi.test(o.un)) {
		Toast.error(lang.translate("invalid_username"));
		return { error: true };
	}
	const { fp, os, browser } = (await limitByTime(getFingerprint(), 5000, [{ os: "", fp: "", browser: "" }]))[0];
	Axios.setHeader("fp", fp);
	Axios.setHeader("os", os);
	Axios.setHeader("browser", browser);
	const result = await Axios.post("/api/player/register", o);
	return await onResult(result, "unexpected_error", async () => {
		Toast.success(`${lang.translate("welcome")}, ${result.player.userName}`);
		await renew(result.token); //saves token to localstorage and redux
		await storeSet({ player: { $set: result.player } });
		Storage.unset("refcode");
		return result;
	});
};
export const isModAdmin = async ({ state, uid }) => {
	const _state = state || (await store.getState());
	return (_state.admins && !!_state.admins[uid]) || (_state.mods && !!_state.mods[uid]);
};
export const isModAdminSync = ({ mods, admins, uid }) => {
	return (admins && !!admins[uid]) || (mods && !!mods[uid]);
};
export const login = async ({ recaptcha, un, pw, token }) => {
	if (!/^[a-zA-Z]{1,1}[a-zA-Z0-9\-_]{5,11}$/gi.test(un)) {
		Toast.error(lang.translate("invalid_username"));
		return { error: true };
	}
	if (/^[a-zA-Z0-9!№@#$%^&*()_+\-=[\].,';:"\\/|><?{}]{6,20}$/gi.test(pw) === false) {
		Toast.error(lang.translate("invalid_password"));
		return { error: true };
	}
	if (token !== "") {
		if (!/^[0-9]{6,6}$/gi.test(token)) {
			Toast.error(lang.translate("invalid_token"));
			return { error: true };
		}
	} else token = undefined;

	const { fp, os, browser } = (await limitByTime(getFingerprint(), 5000, [{ os: "", fp: "", browser: "" }]))[0];
	Axios.setHeader("fp", fp);
	Axios.setHeader("os", os);
	Axios.setHeader("browser", browser);

	const result = await Axios.post("/api/player/login", {
		recaptcha,
		un,
		pw,
		token,
	});
	return await onResult(result, "unexpected_error", async () => {
		Toast.success(`${lang.translate("welcome")}, ${result.player.userName}`);
		await renew(result.token); //saves token to localstorage and redux
		await storeSet({ player: { $set: result.player } });
		return result;
	});
};
export const passwordChange = async ({ password, oldPassword }) => {
	const result = await Axios.post("api/player/passwordChange", {
		password,
		oldPassword,
	});
	return await onResult(
		result,
		"unexpected_error",
		async () => {
			await renew(result.token); //saves token to localstorage and redux
			await storeSet({ player: { temp: { $set: false } } });
			Toast.success(lang.translate("password_changed"));
			return true;
		},
		async () => false
	);
};
export const passwordSet = async ({ password }) => {
	const result = await Axios.post("api/player/passwordSet", { password });
	return await onResult(
		result,
		"unexpected_error",
		async () => {
			await renew(result.token); //saves token to localstorage and redux
			await storeSet({ player: { temp: { $set: false } } });
			Toast.success(lang.translate("password_set"));
			return true;
		},
		async () => false
	);
};
export const _2faGenerate = async (password) => {
	const result = await Axios.post("api/_2fa/generate", { password });
	return await onResult(result, "unexpected_error", async (secret) => {
		return secret;
	});
};
export const _2faActivate = async (token) => {
	const result = await Axios.post("api/_2fa/activate", { token });
	return await onResult(result, "unexpected_error", async (_result) => {
		if (_result.active) {
			Toast.success(lang.translate("gfa_on"));
			await storeSet({ player: { _2fa: { $set: true } } });
		} else {
			Toast.error(lang.translate("unexpected_error"));
		}
		await renew(_result.token);
		return true;
	});
};
export const _2faReset = async (userName, cb) => {
	const title = `${lang.translate("gfa_reset")}: ${userName}`;
	tokenWrap({ title, path: "api/player/_2faReset", o: { userName } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("gfa_off"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const setAsMod = async ({ un, state }, cb) => {
	const title = `${lang.translate(state === 1 ? "set_as_mod" : "revoke_mod_status")}: ${un}`;
	tokenWrap({ title, path: "api/player/modStateChange", o: { targetPlayer: un, state } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("entry_updated"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return result;
	});
};
export const passwordReset = async (userName, cb) => {
	const title = `${lang.translate("password_reset")}: ${userName}`;
	tokenWrap({ title, path: "api/player/passwordReset", o: { userName } }, async (result) => {
		result = await onResult(result, "unexpected_error", async (_result) => {
			copy(_result.password);
			Toast.success(`${lang.translate("password_set")}. ${lang.translate("copied")}`);
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const _2faDeactivate = async (password, token) => {
	const result = await Axios.post("api/_2fa/deactivate", { password, token });
	return await onResult(result, "unexpected_error", async (_result) => {
		if (_result.active) {
			Toast.success(lang.translate("gfa_off"));
			await storeSet({ player: { _2fa: { $set: false } } });
		} else {
			Toast.error(lang.translate("unexpected_error"));
		}
		await renew(_result.token);
		return true;
	});
};
export const findUsernames = async (pattern) => {
	const result = await Axios.post("api/player/findUsernames", { pattern });
	return await onResult(result, "unexpected_error", async (_result) => {
		return _result;
	});
};
export const mailSet = async (mail, _lang) => {
	const result = await Axios.post("api/player/mailSet", { mail, lang: _lang || "en", origin: window.location?.origin });
	return await onResult(result, "unexpected_error", async (_result) => {
		Toast.success(`${lang.translate("mail_set")}: ${mail}`);
		await storeSet({
			player: {
				mailVerified: { $set: false },
				mail: { $set: mail },
			},
		});
		return _result;
	});
};
export const mailCodeResend = async (mail, _lang) => {
	const result = await Axios.post("api/player/mailCodeResend", { lang: _lang, origin: window.location?.origin });
	return await onResult(result, "unexpected_error", async (_result) => {
		Toast.success(`${lang.translate("mail_set")}: ${mail}`);
		return _result;
	});
};
export const mailVerify = async (uid, mailCode) => {
	const result = await Axios.post("api/player/mailVerify", { uid, mailCode });
	return await onResult(result, "unexpected_error", async (_result) => {
		Toast.success(lang.translate("mail_confirmed"));
		await storeSet({ player: { mailVerified: { $set: true } } });
		return _result;
	});
};
export const setOnlineStatus = async (id = 0, title = "") => {
	if ((await store.getState()).player.onlineStatus === id) return {};
	const result = await Axios.post("api/player/setOnlineStatus", { id, title });
	return await onResult(result, "unexpected_error", async () => {
		await storeSet({ player: { onlineStatus: { $set: id } } });
	});
};
export const muteShow = async ({ userName }) => {
	await storeSet({
		modal: {
			$set: {
				name: "mute",
				canClose: true,
				props: {
					targetUserName: userName || null,
				},
			},
		},
	});
};
export const affToggleShow = async (props) => {
	await storeSet({
		modal: {
			$set: {
				name: "afftoggle",
				canClose: true,
				props,
			},
		},
	});
};
export const mute = async ({ targetUserName, mode, time, reason, notes }, cb) => {
	if (time === null || reason === null || targetUserName === null) {
		Toast.error(lang.translate("field_blank"));
		return cb({ error: true });
	}
	const title = `${lang.translate(mode)}: ${targetUserName}, ${reason}, ${time}?`;
	tokenWrap(
		{
			title,
			path: "api/player/mute",
			o: {
				targetUserName,
				mode,
				time,
				reason,
				notes,
			},
			disableToken: true,
		},
		async (result) => {
			result = await onResult(result, "unexpected_error", async () => {
				Toast.success(lang.translate("entry_updated"));
				return result;
			});
			if (typeof cb === "function") return cb(result);
			else return;
		}
	);
};
export const permissionChange = async (targetPlayer, name, type, time, reason, cb) => {
	if (time === null || type === null || reason === null || name === null || targetPlayer === null) {
		Toast.error(lang.translate("field_blank"));
		return cb({ error: true });
	}
	const title = `${lang.translate("entry_update")}: ${targetPlayer}, ${name}, ${type}, ${reason}, ${time}`;
	tokenWrap(
		{
			title,
			path: "api/player/permissionSet",
			o: {
				targetPlayer,
				name,
				type,
				time,
				reason,
			},
		},
		async (result) => {
			result = await onResult(result, "unexpected_error", async () => {
				Toast.success(lang.translate("entry_updated"));
				return result;
			});
			if (typeof cb === "function") return cb(result);
			else return;
		}
	);
};
export const rankingGetData = async ({ targetUserName }) => {
	const result = await Axios.post("api/ranking/getData", { targetUserName });
	return await onResult(result, "unexpected_error", async (secret) => {
		return secret;
	});
};
export const forgotPass = async ({ mail, lang }) => {
	const result = await Axios.post("api/player/forgotPass", { mail, lang: lang || "en", origin: window.location?.origin });
	return await onResult(result, "unexpected_error", async (result) => {
		return result;
	});
};
export const restoreAccount = async ({ code, lang, uid, pass }) => {
	const result = await Axios.post("api/player/restoreAccount", { code, lang: lang || "en", uid, pass });
	return await onResult(result, "unexpected_error", async () => {
		await renew(result.token); //saves token to localstorage and redux
		await storeSet({ player: { $set: result.player } });
		// Toast.success(`${lang.translate("welcome")}, ${result.player.userName}`); //Toast not works here
		return result;
	});
};
export const getPlayers = async (o) => {
	const result = await Axios.post("api/players/getAll", o);
	return await onResult(result, "unexpected_error", async () => {
		return result;
	});
};
