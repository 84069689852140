import React, { PureComponent } from "react";
//containers:
// import Container from "../Container";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { getUtcTimeFromTimestamp, objToArray } from "../../classes/utills";
import config from "../../config";
import OnlineStatus from "../../components/onlineStatus/OnlineStatus";
import { BgCity } from "./BgCity";
import ImageCached from "../../classes/cache";
import FooterMap from "./FooterMap";
import { go } from "../../classes/routesLazy";
//icons:
// import { Icon } from "react-icons-kit"

class Footer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			match: PropTypes.object,
			serverTime: PropTypes.number,
			online: PropTypes.number,
			away: PropTypes.number,
			busy: PropTypes.number,
			guests: PropTypes.number,
			offline: PropTypes.number,
			coins: PropTypes.object,
			nodeId: PropTypes.any,
			me: PropTypes.number,
		};
	}
	renderCoins = () => {
		const arr = [];
		const o = Object.assign({}, this.props.coins);
		const _arr = objToArray(o);
		_arr.sort((a, b) => (b.priority || 50) - (a.priority || 50));
		for (let coin of _arr) {
			// arr.push(<img key={"footer_" + coin.short} src={`/assets/icons/${coin.short}@2x.png`} alt={coin.short} title={coin.name} />);
			arr.push(
				<ImageCached
					key={"footer_" + coin.short}
					storageName="coin"
					url={`/assets/icons/${coin.short}@2x.png`}
					alt={coin.short}
					title={coin.name}
					params={{}}
				/>
			);
		}
		arr.sort((a, b) => (b.priority || 50) - (a.priority || 50));
		return <figure className="builtCoins">{arr}</figure>;
	};
	renderWhoIsOnline = () => {
		return (
			<figure className="whoIsOnline">
				<div className="entry">
					<label className="tag">{`${lang.translate("players")}:`}</label>
					<label className="num">{this.props.online + this.props.away + this.props.busy + this.props.offline || 0}</label>
				</div>
				<div className="entry">
					<label className="tag">{`${lang.translate("guests")}:`}</label>
					<label className="num">{this.props.guests || 0}</label>
				</div>
				<div className="entry">
					<OnlineStatus id={0} />
					<label className="num">{this.props.online || 0}</label>
				</div>
				<div className="entry">
					<OnlineStatus id={1} />
					<label className="num">{this.props.away || 0}</label>
				</div>
				<div className="entry">
					<OnlineStatus id={2} />
					<label className="num">{this.props.busy || 0}</label>
				</div>
				<div className="entry">
					<OnlineStatus id={3} />
					<label className="num">{this.props.offline || 0}</label>
				</div>
			</figure>
		);
	};
	render() {
		// const disabled = this.state.loading;
		return (
			<div className="footer">
				<div className="bgCityWrapper">
					<BgCity />
				</div>
				<FooterMap lang={this.props.lang} />
				{this.renderCoins()}
				<button className="btnDefault btnBuyCrypto" onClick={(e) => [e.stopPropagation(), go("billing", "buycrypto")]}>
					<img src="/assets/visa.png" width={100} style={{ marginRight: "5px" }} />
					{lang.translate("changelly")}
				</button>
				{this.renderWhoIsOnline()}
				<figure className="review">
					<i>{`Made with love - DEV-TEAM [${this.props.me}]`}</i>
				</figure>
				<figure className="copyright">
					<a href={config.app.diceVerifierUrl} rel="noopener noreferrer" target="_blank">
						{lang.translate("verify")}
					</a>
					<b className="sep">{" | "}</b>
					<a href={config.links.terms} rel="noopener noreferrer" target="_blank">
						{lang.translate("tc")}
					</a>
					<b className="sep">{" | "}</b>
					<a href={config.links.terms} rel="noopener noreferrer" target="_blank">
						{lang.translate("privacy")}
					</a>
					<b className="sep">{" | "}</b>
					<a href={config.links.responsible_gaming} rel="noopener noreferrer">
						{lang.translate("responsible_gaming")}
					</a>
				</figure>
				<div className="row">
					<figure className="serverTime">
						<b className="caption">{`${lang.translate("server_time")}: `}</b>
						<b className="time">{getUtcTimeFromTimestamp(this.props.serverTime) || null}</b>
					</figure>
					<figure className="serverTime">
						<b className="caption">{`${lang.translate("ping")}: `}</b>
						<b id="latency" className="time">
							N/A
						</b>
					</figure>
					<figure className="serverTime">
						<b className="caption">Node ID: </b>
						<b id="latency" className="time">
							{this.props.nodeId}
						</b>
					</figure>
				</div>
				<figure className="entityInfo">
					<p>{new Date().getFullYear()} WinTomato</p>
					<p>Belize, Belize City, 9 Barrack Road</p>
					<p>
						<a href="mailto:support@wintomato.com?subject=WinTomato">support@wintomato.com</a>
					</p>
				</figure>
				<div className="seals">
					<figure className="seal">
						<div
							id="anj-9a81318e-1eb1-4eb0-886c-3ab915bccc2c"
							data-anj-seal-id="9a81318e-1eb1-4eb0-886c-3ab915bccc2c"
							data-anj-image-size="64"
							data-anj-image-type="basic-small"
						></div>
					</figure>
					<img src="/assets/18.png" width={48} height={48} alt="" title="18+ only" />
				</div>
				<figure className="entityInfo" style={{ width: 350 }}>
					<b>
						The above Casino or Sportsbook is licensed and authorized by the The Government of Anjouan under the Licence: ALSI-042402009-FI4 
						issued by Anjouan Licensing Services Inc. for the provision of online games of chance.
					</b>
				</figure>
				{/* <figure>
					<a
						data-pagesection="footer"
						data-navelement="scanalert"
						href="https://www.mcafeesecure.com/RatingVerify?ref=www.wintomato.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							alt="McAfee"
							title="McAfee Secure sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams."
							src="https://images.scanalert.com/meter/www.mcafee.com/55.gif?lang=EN"
						/>
					</a>
				</figure> */}
			</div>
		);
	}
}
const a = (store) => {
	return {
		serverTime: store.serverTime,
		guests: store.online.GUESTS,
		online: store.online.ONLINE,
		away: store.online.AWAY,
		busy: store.online.BUSY,
		offline: store.online.OFFLINE,
		coins: store.coins,
		lang: store.lang.key,
		nodeId: store.nodeId,
		me: store.player?.uid ?? -1,
	};
};

export default connect(a, null, null, { forwardRef: true })(Footer);
