import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import Input from "../../../components/input/Input";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../../classes/lang";
import { randomize, getCs } from "../../../classes/games/dice";
//icons:
import { Icon } from "react-icons-kit";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";

class Fairness extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			csNew: this.props.seeds.dice_cs,
			// //current:
			// cs: "",
			// ssSha: "",
			// n: 0,
			// //prev:
			// csPrev: "",
			// ssPrev: "",
			// nPrev: 0,
			//---
			loading: false,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			seeds: PropTypes.object,
		};
	}
	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onRenewClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		getCs(async (csNew) => {
			if (csNew.error) {
				await this.setState({ loading: false });
			} else await this.setState({ loading: false, csNew });
		});
	};
	onRandomizeClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		randomize({ cs: this.state.csNew }, async (resp) => {
			await this.setState({ loading: false, csNew: resp.dice_cs });
		});
	};
	render() {
		const disabled = this.state.loading;
		return (
			<Container noDocs={true}>
				<div className="fairness">
					<h1>{lang.translate("fairness")}</h1>
					<figure>
						<label>{`${lang.translate("new")} ${lang.translate("client_seed").toLowerCase()}`}</label>
						<Input
							className="inpCsNew"
							name="csNew"
							placeholder={lang.translate("client_seed")}
							value={this.state.csNew}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
							validation="salt"
							maxLength={32}
							disabled={disabled}
						/>
						<button onClick={this.onRenewClick} disabled={disabled} className="btnIcon warn btnRenew">
							<Icon icon={ic_refresh} />
						</button>
						<label>{`${lang.translate("new")} ${lang.translate("server_sha_512").toLowerCase()}`}</label>
						<b className="dice_ssSha_next">{this.props.seeds.dice_ssSha_next || "N/A"}</b>
					</figure>
					<figure>
						<button onClick={this.onRandomizeClick} disabled={disabled} className="btnDefault warn btnRand">
							<label>{lang.translate("randomize")}</label>
						</button>
					</figure>
					<figure className="current">
						<h3>{lang.translate("current_seed_pair")}</h3>
						<label>{lang.translate("client_seed")}</label>
						<b>{this.props.seeds.dice_cs}</b>
						<label>{lang.translate("server_sha_512")}</label>
						<b>{this.props.seeds.dice_ssSha}</b>
						{/* <label>{lang.translate("bets_made_with_pair")}</label>
						<b>{this.props.seeds.dice_n}</b> */}
					</figure>
					<figure className="previous">
						<h3>{lang.translate("previous_seed_pair")}</h3>
						<label>{lang.translate("client_seed")}</label>
						<b>{this.props.seeds.dice_csPrev}</b>
						<label>{lang.translate("server_seed")}</label>
						<b>{this.props.seeds.dice_ssPrev}</b>
						<label>{lang.translate("bets_made_with_pair")}</label>
						<b>{this.props.seeds.dice_nPrev}</b>
					</figure>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		seeds: store.seeds,
	};
};

export default connect(a, null, null, { forwardRef: true })(Fairness);
