import React, { PureComponent } from "react";
//components:
import Loader from "../../components/loader/Loader";
//containers:
import Container from "../Container";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { renew } from "../../classes/socket";
import { isObject } from "../../classes/utills";
import { loadSync } from "../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
import { support } from "react-icons-kit/fa/support";

class Offline extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			socketStatusMessage: PropTypes.string,
			buildOk: PropTypes.bool,
		};
	}
	reconnectClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		await renew();
		await this.setState({ loading: false });
	};
	reloadClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		window.location.reload(true);
	};
	showSupport = () => {
		if (isObject(window.Tawk_API) && typeof window.Tawk_API.showWidget === "function") {
			window.Tawk_API.showWidget();
			window.Tawk_API.maximize();
		}
	};
	render() {
		const disabled = this.state.loading;
		const url = loadSync({ storageName: "ui", url: "/assets/offline.png", params: {} }).url;
		return (
			<Container className="container-offline" noDocs={true}>
				<div className={`offline ${this.props.buildOk ? "buildOk" : "buildIncorrect"}`}>
					{this.state.loading && <Loader />}
					<div style={{ backgroundImage: `url(${url})` }} className={`bg ${this.props.buildOk ? "buildOk" : ""}`}>
						<div className="content">
							<label>{lang.translate(this.props.buildOk ? "you_are_offline" : "reload_page")}</label>
							{this.props.buildOk && <h2>{this.props.socketStatusMessage}</h2>}
							<div className="btns">
								<button onClick={(e) => [e.stopPropagation(), this.showSupport()]} className="btnIcon alert">
									<Icon icon={support} size={24} />
								</button>
								{this.props.buildOk ? (
									<button disabled={disabled} onClick={this.reconnectClick} className="btnDefault btnReconnect">
										{lang.translate("reconnect")}
									</button>
								) : (
									<button disabled={disabled} onClick={this.reloadClick} className="btnDefault  btnReload">
										{lang.translate("reload")}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		socketStatusMessage: store.socketStatusMessage,
		buildOk: store.build === store.buildPresent,
	};
};

export default connect(a, null, null, { forwardRef: true })(Offline);
