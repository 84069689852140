import io from "socket.io-client";
import config from "../config";
import Storage from "./storage";
import { store, storeSet } from "../index";
import { socketHandlers } from "./socketHandlers";
import Axios from "./axios";
import { lang } from "./lang";
import { lz, isObject, stripTrailingSlashes, limitByTime, isMobile } from "./utills";
import EventEmitter from "./eventEmitter";
let timeout = null;

let socket = null;
let socketConnected = false;
export const pingO = {
	el: null,
	latency: -1,
};
const socketSystemEvents = {
	CONNECT: "connect",
	DISCONNECT: "disconnect",
	CONNECT_ERROR: "connect_error",
	RECONNECT_ATTEMPT: "reconnect_attempt",
	RECONNECT: "reconnect",
	RECONNECT_FAILED: "reconnect_failed",
	RECONNECT_ERROR: "reconnect_error",
	RECONNECTING: "reconnecting",
	FORCE_ERROR: "FORCE_ERROR",
	ERROR_TOAST: "ERROR_TOAST",
};
const socketEvents = [
	"t",
	"online",
	"offline",
	"onDepBonus",
	"onInit",
	"onLogout",
	"onMessage",
	"onMessages",
	"onFriend",
	"onUnfriend",
	"onIgnore",
	"onUnignore",
	"onLike",
	"onPmRead",
	"onPermissionsChange",
	"onCoinsUpdate",
	"onRates",
	"onFaucets",
	"onBAll",
	"onB",
	"onStats",
	"onWithdrawChange",
	"onR",
	"onPropsSet",
	"onPropsMerge",
	"onFbiDice",
	"onCrash",
	"onCrashMyBet",
	"onCrashLose",
	"onCrashBet",
	"onCrashCashOut",
	"onCrashClear",
	"onCrashGame", //new history game entry
	"onAffPro_getData",
	"onBattle",
	"onSlotTr",
	"onToast"
];
//--
// const timeoutSeconds = 10;
// let onoffT;
// let t0 = 0;
// let elapsed = 0;
// const onoff = () => {
// 	onoffT = setTimeout(() => {
// 		const t1 = store.getState().serverTime;
// 		if (t0 === t1) {
// 			elapsed++;
// 			if (elapsed > timeoutSeconds) _toggleOnline(false);
// 		} else {
// 			elapsed = 0;
// 			_toggleOnline(true);
// 		}
// 		t0 = t1;
// 		onoff();
// 	}, 1000);
// };
// onoffT && clearTimeout(onoffT);
// typeof document !== "undefined" && onoff();
//--
const getSocket = () => {
	return socketConnected ? socket : null;
};
export const ping = () => {
	const sock = getSocket();
	sock && sock.emit("ping", Date.now());
};
export const emit = (event, o, cb = null) => {
	const sock = getSocket();
	if (sock === null) return typeof cb === "function" ? cb({ error: true, message: "offline" }) : { error: true, message: "offline" };
	const ready = (resp) => {
		if (isObject(resp) && resp.z) return cb(onCallback(event, lz.decompress(resp.z)));
		else return cb(onCallback(event.trim(), resp));
	};
	if (config.app.dolz) sock.emit(event, { z: lz.compress(onEmit(event, o)) }, ready);
	else sock.emit(event, onEmit(event, o), ready);
};
/** cancels emit after {ms} and returns defaultValue */
export const emitOrDefault = (event, o, ms = 5000, defaultValue = { error: true, message: "you_are_offline" }, cb = null) => {
	const pr = new Promise((resolve) => emit(event, o, resolve));
	limitByTime(pr, ms, [defaultValue]).then((result) => cb(result[0]));
};
const onEmit = (event, data) => {
	(window._log || !config.app.isProd) && ["deltas"].indexOf(event) === -1 && console.log("--", event, data);
	return data;
};
const onCallback = (event, data) => {
	(window._log || !config.app.isProd) &&
		["t", "onR", "online", "offline", "onRates", "onStats", "deltas", "onCrash"].indexOf(event) === -1 &&
		console.log("++", event, data);
	return data;
};
export const renew = async (token = null) => {
	if (timeout) clearTimeout(timeout);
	try {
		//reset socket:
		if (socket) {
			console.log("off");
			for (let el in socketSystemEvents) socket.off(el);
			for (let el in socketEvents) socket.off(el);
			socket.disconnect();
			socket = null;
		}
		// const { fp, browser, os } = (await limitByTime(getFingerprint(), 5000, [{ os: "", fp: "", browser: "" }]))[0];
		if (token) {
			//case when we are changing pass:
			Storage.set("token", token);
			await storeSet({
				token: { $set: token },
				// fp: { $set: fp },
			});
		} else {
			token = (await store.getState()).token;
			// await storeSet({ fp: { $set: fp } });
		}
		// Axios.setHeader("fp", fp);
		Axios.setHeader("token", typeof token !== "string" ? "null" : token);
		return await get(token);
	} catch (err) {
		console.log(err);
	}
};
/**
 * This wrapper offsets offline by 3 seconds, cancels timer in case online is back:
 * @param {boolean} status //true - online, false - offline
 */
// let toggleOnlineTim = null;
// let toggleOnlineTimRunning = false;
// export const toggleOnline = async (status, owner) => {
// 	if (status === false) {
// 		if (toggleOnlineTimRunning) return;
// 		toggleOnlineTimRunning = true;
// 		toggleOnlineTim = setTimeout(() => {
// 			_toggleOnline(false, owner);
// 			toggleOnlineTimRunning = false;
// 		}, 5000);
// 	} else {
// 		if (toggleOnlineTim) {
// 			clearTimeout(toggleOnlineTim);
// 			toggleOnlineTim = null;
// 			toggleOnlineTimRunning = false;
// 		}
// 		await _toggleOnline(true, owner);
// 	}
// };
export const toggleOnline = async (status, owner) => {
	console.log("toggleOnline", status, owner);
	const state = await store.getState();
	//override state if builds not match:
	const isDev = typeof document !== "undefined" && window.location.port * 1 === 3333;
	if (state.build !== state.buildPresent && !isDev) status = false;

	socketConnected = status; //local var
	//let's retain old modal value:
	let modalPrev = state.modal;
	if (typeof modalPrev === "object" && modalPrev !== null && modalPrev.name === "Offline") modalPrev = null;
	const o = {
		socketConnected: { $set: status },
		modal: {
			$set: status ? modalPrev : { name: "Offline", canClose: false, props: {} },
		},
	};
	if (status) o.socketStatusMessage = { $set: "con_connection_established" };
	else o.initFinished = { $set: false };
	await storeSet(o);
	if (state.socketConnected !== status) EventEmitter.emit("ON_SOCKET_CONNECTED_CHANGE", { status });
};
const log = (event, msg = null, options = null) => {
	(window._log || +window?.location?.port === 3333) && console.log(event, msg, options);
};
const transports = ["websocket"]; //, "polling"
export const isSocketConnected = () => {
	return !!socket?.connected;
};
const get = async (token) => {
	try {
		const url = `${stripTrailingSlashes(config.app.url)}/sck?token=${token}&isMobile=${isMobile() ? 1 : 0}`;
		socket = io(url, {
			reconnect: true,
			// reconnectionDelay: 3500,
			// reconnectionDelayMax: 10000,
			transports: [...transports],
			allowUpgrades: transports.indexOf("polling") !== -1,
			// pingInterval: 25000,
			// pingTimeout: 63000,
			// pingInterval: 2000,
			// pingTimeout: 5000,
		}); //, "polling"
		!!socket &&
			socket.on(socketSystemEvents.CONNECT, async () => {
				socket.io.opts.transports = [...transports]; //, "polling"
				socketEvents.forEach((el) => {
					if (socketHandlers[el] && !socket.hasListeners(el))
						socket.on(`${el}`, (o) => {
							o = isObject(o) && o.z ? lz.decompress(o.z) : o;
							return socketHandlers[el](onCallback(el, o));
						});
				});
				toggleOnline(true, socketSystemEvents.CONNECT);
				log(socketSystemEvents.CONNECT);
			});
		socket.on(socketSystemEvents.RECONNECT, () => {
			toggleOnline(true, socketSystemEvents.RECONNECT);
			log(socketSystemEvents.RECONNECT);
		});
		socket.on(socketSystemEvents.RECONNECTING, (attemptNumber) => {
			log(socketSystemEvents.RECONNECTING, { attemptNumber });
		});
		socket.on(socketSystemEvents.RECONNECT_ATTEMPT, (attemptNumber) => {
			log(socketSystemEvents.RECONNECT_ATTEMPT, { attemptNumber });
			storeSet({
				socketStatusMessage: {
					$set: lang.translate("con_reconnect_attempt", [attemptNumber]),
				},
			});
		});
		socket.on(socketSystemEvents.RECONNECT_FAILED, () => {
			log(socketSystemEvents.RECONNECT_FAILED);
			storeSet({
				socketStatusMessage: { $set: lang.translate("con_reconnect_failed") },
			});
		});
		socket.on(socketSystemEvents.RECONNECT_ERROR, async (err) => {
			toggleOnline(false, socketSystemEvents.RECONNECT_ERROR);
			log(socketSystemEvents.RECONNECT_ERROR, { err });
			storeSet({
				socketStatusMessage: {
					$set: lang.translate("con_connection_error", err.message),
				},
			});
		});
		socket.on(socketSystemEvents.CONNECT_ERROR, (err) => {
			log(socketSystemEvents.CONNECT_ERROR, { err });
			storeSet({
				socketStatusMessage: {
					$set: lang.translate("con_connection_error", err.message),
				},
			});
		});
		socket.on(socketSystemEvents.DISCONNECT, () => {
			// storeSet({
			// 	socketStatusMessage: { $set: lang.translate("con_connection_lost") },
			// });
			// toggleOnline(false, socketSystemEvents.DISCONNECT);
			timeout = setTimeout(async () => {
				if (socket && !socket.connected) {
					log(socketSystemEvents.DISCONNECT);
					storeSet({
						socketStatusMessage: { $set: lang.translate("con_connection_lost") },
					});
					toggleOnline(false, socketSystemEvents.DISCONNECT);
					socket && socket.off(socketSystemEvents.CONNECT); //remove listeners
				}
			}, 5000);
			console.log("DISCONNECTED, Unsubscribing from listeners...");
		});
		socket.on("pong", function (v) {
			pingO.latency = Date.now() - v;
			if (!pingO.el) pingO.el = document.getElementById("latency");
			if (pingO.el) pingO.el.textContent = `${pingO.latency}ms`;
		});
		return socket;
	} catch (err) {
		console.log("catch socket io: ", err.message);
		return null;
	}
};
