import Decimal from "decimal.js";
import { isBoolean } from "lodash";
import lang from "../../../classes/lang";
import Toast from "../../../classes/toast";
import { isFloat, isString } from "../../../classes/utills";

class CrashAutoClass {
	#running = false;
	#callbacks = null;
	constructor() {
		if (CrashAutoClass.self) return CrashAutoClass.self;
		CrashAutoClass.self = this;
		this.#running = false;
		this.#callbacks = new Map();
		this.configSetBase();
	}
	runningSet = (v) => {
		this.#running = !!v;
		this.#callbacks.forEach((v) => {
			v({
				type: "action",
				isRunning: this.#running,
				t: Date.now(),
			});
		});
	};
	isRunning = () => {
		return this.#running;
	};
	configSetBase = () => {
		this.cfg = {
			short: null,
			bet: null,
			betCur: null,
			num: null,
			numLeft: null,
			L: {
				every: 1,
				streak: null,
				toBase: true,
				incBy: 0.0,
				stop: false,
				n: 0,
				sn: 0,
			},
			W: {
				every: 1,
				streak: null,
				toBase: true,
				incBy: 0.0,
				stop: false,
				n: 0,
				sn: 0,
			},
			stopBetUnderOn: false,
			stopBetOverOn: false,
			stopBalanceUnderOn: false,
			stopBalanceOverOn: false,
			stopBetUnder: 0.00000001,
			stopBetOver: 0.00000001,
			stopBalanceUnder: 0.00000001,
			stopBalanceOver: 0.00000001,
		};
	};
	regCallback = (id, f) => {
		// console.log("callback set");
		this.#callbacks.set(id, f);
	};
	unregCallback = (id) => {
		// console.log("callback unset");
		if (this.#callbacks.has(id)) this.#callbacks.delete(id);
	};
	configGet = () => {
		return this.cfg;
	};
	configSet = (o) => {
		this.cfg = o;
	};
	onEvent = ({ balance, short, bet, win }) => {
		let msg;
		//toBase or in
		this.cfg[win ? "W" : "L"]["n"]++;
		this.cfg[win ? "L" : "W"]["sn"] = 0;
		this.cfg[win ? "W" : "L"]["sn"]++;
		const WL = win ? "W" : "L";
		const check1 = this.cfg[WL].every && this.cfg[WL].n % this.cfg[WL].every === 0;
		const check2 = this.cfg[WL].streak && this.cfg[WL].sn % this.cfg[WL].streak === 0;
		if (check1 || check2) {
			if (this.cfg[WL].stop) {
				msg = win ? "stop_on_win" : "stop_on_loss";
				this.runningSet(false);
			} else {
				if (this.cfg[WL].toBase) {
					this.cfg.betCur = this.cfg.bet; //reset to base
				} else {
					this.cfg.betCur = new Decimal(this.cfg.betCur).mul(this.cfg[WL].incBy).dividedBy(100).plus(this.cfg.betCur).toNumber(); //inc by number
				}
			}
		}
		//num:
		if (this.cfg.num !== null) {
			let n = this.cfg.numLeft - 1;
			this.cfg.numLeft = n < 0 ? 0 : n;
			if (this.cfg.numLeft === 0) {
				msg = "stop_on_number_of_rolls";
				this.runningSet(false);
			}
		}
		//limits:
		if (this.cfg.stopBetOverOn && this.cfg.betCur > this.cfg.stopBetOver) {
			msg = "stop_on_bet_amount_over";
			this.runningSet(false);
		}
		if (this.cfg.stopBetUnderOn && this.cfg.betCur < this.cfg.stopBetUnder) {
			msg = "stop_on_bet_amount_under";
			this.runningSet(false);
		}
		if (this.cfg.stopBalanceOverOn && balance > this.cfg.stopBalanceOver) {
			msg = "stop_on_balance_over";
			this.runningSet(false);
		}
		if (this.cfg.stopBalanceUnderOn && balance < this.cfg.stopBalanceUnder) {
			msg = "stop_on_balance_under";
			this.runningSet(false);
		}
		requestAnimationFrame(() => {
			msg && Toast.warning(lang.translate(msg));
			// console.log("onevent: ", { balance, short, bet, win });
			if (!isFloat(balance) || !isFloat(bet) || !isBoolean(win) || !isString(short)) {
				console.error("Invalid onEvent args:", { balance, short, bet, win });
				return;
			}
			this.#callbacks.forEach((v) => {
				v({
					type: "auto",
					bet: this.cfg.betCur,
					isRunning: this.#running,
					t: Date.now(),
				});
			});
		});
	};
}

export const autoCl = new CrashAutoClass();
