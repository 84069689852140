import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//icons:
import { Icon } from "react-icons-kit";
import { circleDown as toggleIcon } from "react-icons-kit/icomoon/circleDown";
import { storeSet } from "../..";

class SImpleDropDown extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			toggleState: false,
			autoDown: false,
		};
		this.id = `simpleDropDown${Math.random()}`;
		this.domRef = null;
		this.content = null;
	}
	static get propTypes() {
		return {
			showArrow: PropTypes.bool,
			render: PropTypes.bool, //default false
			header: PropTypes.element,
			content: PropTypes.any,
			className: PropTypes.string,
			headerClassname: PropTypes.string,
			down: PropTypes.bool, //true - down, false - up
			auto: PropTypes.array, //default null, detect direction based on mouse position (minY, maxY)
			beforeShow: PropTypes.func,
			onMouseLeave: PropTypes.func,
			modalMode: PropTypes.bool, //default false
			// permissionsChanged: PropTypes.number,
			// onSelect: PropTypes.func,
			// onUnselect: PropTypes.func,
			// selected: PropTypes.array,
			// data: PropTypes.array
		};
	}
	toggle = async (state) => {
		const toggleState = typeof state === "undefined" ? !this.state.toggleState : state;
		let autoDown = false;
		if (Array.isArray(this.props.auto)) {
			const middle = this.props.auto[0] + (this.props.auto[1] - this.props.auto[0]) / 2;
			autoDown = toggleState === false ? this.state.autoDown : typeof document !== "undefined" && document.yPercent < middle;
		}
		if (toggleState) {
			this.content = typeof this.props.beforeShow !== "function" ? this.props.content : await this.props.beforeShow.bind(null, this)();
		}
		if (this.props.modalMode) {
			if (toggleState) {
				this.showModal();
			} else {
				this.closeModal();
			}
			return;
		}
		this.setState({ toggleState, autoDown });
	};
	componentDidMount = () => {
		if (typeof document === "undefined") return;
		this.domRef = document.getElementById(this.id);
		document.addEventListener("click", this.onDocumentClick);
		if (typeof this.props.beforeShow === "function") {
			this.props.beforeShow.bind(null, this);
		}
	};
	componentWillUnmount() {
		if (typeof document === "undefined") return;
		document.removeEventListener("click", this.onDocumentClick);
	}
	onDocumentClick = (e) => {
		if (this.domRef && !this.domRef.contains(e.target) && this.state.toggleState) this.toggle(false);
	};
	closeModal = async () => {
		await storeSet({
			modal: { $set: null },
		});
	};
	showModal = async () => {
		const c = this.props.render ? this.props.content : this.content;
		const getContent = () => {
			return c;
		};
		await storeSet({
			modal: { $set: { name: "modalcontent", canClose: true, props: { getContent, closeModal: this.closeModal } } },
		});
	};
	render() {
		const className = typeof this.props.className === "string" ? this.props.className : "";
		const cl = this.state.toggleState ? " on" : " off";
		const headerClassname = this.props.headerClassname || "";
		const showArrow = typeof this.props.showArrow === "boolean" ? this.props.showArrow : true;
		const content = (
			<div key={0} className={`content${cl}`}>
				{this.props.render ? this.props.content : this.content}
			</div>
		);
		const arrow = <div key={1} className="dropArrow"></div>;
		const arr = [arrow, content];
		const direction = this.props.auto ? this.state.autoDown : this.props.down;

		if (direction === false) arr.reverse();
		return (
			<div
				id={this.id}
				onMouseLeave={() => [typeof this.props.onMouseLeave === "function" && this.props.onMouseLeave()]}
				className={`simpleDropDown${cl} ${className} ${direction === false ? "up" : "down"}`}
			>
				<button
					onClick={(e) => [e.stopPropagation(), this.toggle()]}
					className={`${headerClassname.includes("diceBtn") ? "" : "btnDefault "}btnHeader ${headerClassname}`}
				>
					{this.props.header}
					{showArrow && (
						<div className={`icon ${cl}`}>
							<Icon icon={toggleIcon} />
						</div>
					)}
				</button>
				{!(this.props.modalMode === true) && (
					<div className="dropList">
						<div className={`dropWindow${cl}`} onClick={(e) => e.stopPropagation()}>
							{arr}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default SImpleDropDown;
