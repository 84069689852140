import React, { forwardRef, useCallback, useEffect, useState } from "react";
import Container from "../Container";
import lang from "../../classes/lang";
import PropTypes from "prop-types";
import { getDayTimeFromTimestamp, copy, isObject, onSlotGamePath, gameNameNormalized } from "../../classes/utills";
import Avatar from "../../components/avatar/Avatar";
// import { go } from "../../classes/routesLazy";
import Toast from "../../classes/toast";
import BetInfo from "../betInfo/BetInfo";
//icons:
import { Icon } from "react-icons-kit";
import { ic_content_copy as iconCopy } from "react-icons-kit/md/ic_content_copy";

import Axios from "../../classes/axios";
import Loader from "../../components/loader/Loader";
// eslint-disable-next-line no-unused-vars
const SlotTransaction = forwardRef((props, ref) => {
	SlotTransaction.propTypes = {
		// data: PropTypes.object,
		_id: PropTypes.string,
		type: PropTypes.string,
	};
	// const data = props.data;
	const [data, setData] = useState();
	let url = "/api/slotegrator/getTransaction";
	if (props.type === "flappybet") {
		url = "/api/flappybet/getTransaction";
	}
	const getSlotData = useCallback(async () => {
		const result = await Axios.post(url, { _id: props._id });
		if (isObject(result) && !result.error) {
			setData(result);
		} else {
			setData({ error: true, message: lang.translate("entry_not_found") });
		}
	}, [props._id]);
	useEffect(() => {
		getSlotData();
		// return () => {
		// 	go("");
		// };
	}, [getSlotData]);
	if (!data || data.error) {
		return (
			<Container className="slotTransaction" noDocs={true}>
				<div className="loading">{!data ? <Loader /> : <h2>{data.message}</h2>}</div>
			</Container>
		);
	}
	if (data) {
		let betAmount = data.type === "bet" ? data.amountUsd : data.bet_amountUsd ?? 0;
		let profit = data.type === "win" ? data.amountUsd ?? 0 : 0;

		let gameUrl = onSlotGamePath(data.name, data.provider, false);
		if (props.type === "flappybet") {
			gameUrl = `originals/${gameNameNormalized(data.name)}/${data.game_id}/real`;
			betAmount = betAmount / data.rateUsd;
			profit = profit / data.rateUsd;
		}

		return (
			<Container className="slotTransaction" noDocs={true}>
				<BetInfo
					userName={data.un}
					uid={data.uid}
					img={data.img}
					gameName={data.name}
					gameProvider={data.provider}
					gameImage={data.image}
					gameUrl={gameUrl}
					shareUrl={`${window.location.origin}/en/${props.type === "flappybet" ? "flappybet" : "slot"}-id/${data._id}`}
					betId={data._id}
					betAmount={betAmount}
					profit={profit}
					short={props.type === "flappybet" ? data.short : "usd"}
					t={data.t}
				/>
			</Container>
		);
	}
	return (
		<Container className="slotTransaction" noDocs={true}>
			<>
				<h2>{lang.translate(data.name)}</h2>
				<div className="sharing">
					<div className="betid">
						<b>ID:</b>
						<b>{data._id.StartEndOnly(6)}</b>
					</div>
					<button className="btnDefault btnIcon warn" onClick={(e) => [e.stopPropagation(), copy(data._id, Toast.success(lang.translate("copied")))]}>
						<Icon size={18} icon={iconCopy} />
					</button>
				</div>
				<img src={data.image} alt="" title={data.name} />
				<div>
					<div className="info">
						<div className="row">
							<b>{lang.translate("game_provider")}</b>
							<b className="provider">{data.provider}</b>
						</div>
						<div className="row">
							<b>{lang.translate("by")}</b>
							<Avatar userName={data.un} uid={data.uid} img={data.img} size={24} />
						</div>
						<div className="row c">
							{data.bet_amountUsd > 0 && (
								<div className="col">
									<b>{lang.translate("bet")}</b>
									<b className="small">{(data.bet_amountUsd ?? 0).toFixed(2)}$</b>
								</div>
							)}
							{/* {data.payout > 0 && ( */}
							<div className="col">
								<b>{lang.translate("payout")}</b>
								<b className="small">{(data.payout ?? 0).toFixed(0)}x</b>
							</div>
							{/* )} */}
							<div className="col">
								<b>{lang.translate("win")}</b>
								<b className="success">{(data.amountUsd ?? 0).toFixed(2)}$</b>
							</div>
						</div>
						<div className="row time">{getDayTimeFromTimestamp(data.t)}</div>
					</div>
				</div>
			</>
		</Container>
	);
});
SlotTransaction.displayName = "SlotTransaction";
export default SlotTransaction;
