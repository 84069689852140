import React, { Component } from "react";

import PropTypes from "prop-types";

import BScroll from "@better-scroll/core";
import ObserveDOM from "@better-scroll/observe-dom";
import MouseWheel from "@better-scroll/mouse-wheel";
import ScrollBar from "@better-scroll/scroll-bar";
BScroll.use(ObserveDOM);
BScroll.use(MouseWheel);
BScroll.use(ScrollBar);

class List extends Component {
	constructor(props) {
		super(props);
		/**
		 * data - [row - {id, c}]
		 * ren - boolean
		 */
		this.state = {
			tim: Date.now(),
		};
		this.scrollRect = null;
		this.scrollEnd = null;
		this._op = typeof this.props.options === "object" && this.props.options !== null ? this.props.options : {};
		this.data = this.props.data || [];
		//generate fake data:
		if (!this.props.data) for (let i = 0; i < 100; i++) this.data.push({ id: i, c: <div>{`some text: ${i}`}</div> });
	}
	static get propTypes() {
		return {
			options: PropTypes.object,
			data: PropTypes.array,
			ren: PropTypes.bool,
			onScroll: PropTypes.func,
		};
	}

	delay = (ms) => new Promise((res) => setTimeout(res, ms));
	scrollToEnd = async (t = 300) => {
		//await this.delay(300);
		this.scroll.refresh();
		try {
			this.scroll.scrollToElement(this.scrollEnd, t);
		} catch {}
	};
	scrollToElementId = async (id, t = 300) => {
		const el = document.getElementById(id);
		if (!el) return;
		//await this.delay(300);
		this.scroll.refresh();
		try {
			this.scroll.scrollToElement(el, t);
		} catch {}
	};
	scrollToTop = async (t = 300) => {
		//if(t) await this.delay(t);
		this.scroll.refresh();
		try {
			this.scroll.scrollTo(0, 0, t);
		} catch {}
	};
	update = async (props) => {
		if (typeof props !== "object" && props !== null) return;
		for (let key in props) this[key] = props[key];
		await this.doRender();
	};
	doRender = async () => {
		if (this.props.ren) {
			this.scroll.refresh();
			return;
		}
		await this.setState({ tim: `${Date.now()}-${Math.random()}` }, () => this.scroll.refresh());
	};
	refresh = () => this.scroll.refresh();
	componentDidMount = () => {
		this.initScroll();
	};
	shouldComponentUpdate = (nextProps, nextState) => {
		return this.props.ren ? true : this.state.tim !== nextState.tim;
	};
	initScroll = () => {
		const options = {
			observeDOM: true,
			click: true,
			focus: true,
			stopPropagation: true,
			scrollbar: {
				click: true,
				fade: false,
				interactive: true, // new in 1.8.0
			},
			mouseWheel: {
				speed: 20,
				invert: false,
			},
			// preventDefaultException: { className: /(^|\s)chatItemText(\s|$)/ }
			preventDefaultException: { tagName: /^(LINK|A|TEXTAREA|SELECT|BUTTON)$/, className: /(^|\s)chatItemText(\s|$)/ },
			...this._op,
		};
		if (this.props.onScroll) options.probeType = 3; //this makes onscroll events to fire
		this.scroll = new BScroll(this.scrollRect, options);
		this.props.onScroll && this.scroll.on("scroll", this.props.onScroll);
	};
	componentWillUnmount() {
		this.props.onScroll && this.scroll.off("scroll", this.props.onScroll);
	}

	render() {
		const content =
			(this.props.ren ? this.props.data : this.data).length > 0 ? (
				(this.props.ren ? this.props.data : this.data).map((row, i) => <li key={row.id ? row.id : i}>{row.c}</li>)
			) : (
				<li className="h">
					<label></label>
				</li>
			);
		return (
			<div ref={(el) => (this.scrollRect = el)} className="listScroll">
				<ul>{content}</ul>
				{/* you can put some other DOMs here, it won't affect the scrolling */}
				<div ref={(el) => (this.scrollEnd = el)} />
			</div>
		);
	}
}

export default List;
