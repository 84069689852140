import { store, storeSet } from "../index";
import Axios from "./axios";
// import Toast from "./toast";
import { onResult } from "./utills";
//import Storage from "./storage";
// import { lang } from "./lang";
import config from "../config";
import { sound } from "./sound";

export const onCrashInit = async () => {
	const isGuest = (await store.getState().player.uid) === -1;
	const result = await Axios.post(`/api/gameCrash/onCrashInit${isGuest ? "Guest" : ""}`, {});
	return await onResult(result, "unexpected_error", ({ players, crashEvent, crashHistory }) => {
		// Toast.success(lang.translate(""));
		// console.log("*", players);
		storeSet({ crashPlayers: { $merge: players }, crashEvent: { $set: crashEvent }, crashHistory: { $set: crashHistory } });
		return result;
	});
};
export const onCrashEnd = async () => {
	const isGuest = (await store.getState().player.uid) === -1;
	await Axios.post(`/api/gameCrash/onCrashEnd${isGuest ? "Guest" : ""}`, {});
	if (!isGuest)
		storeSet({
			crashPlayers: { $set: {} },
			crashEvent: { $set: "stop" },
			crashStats: { $set: {} },
			crashHistory: { $set: [] },
			crashBetCaption: { $set: "place_bet" },
		});
};
export const onBet = async (o) => {
	const result = await Axios.post("/api/gameCrash/onBet", o);
	return await onResult(result, "unexpected_error", (result) => {
		// Toast.success(lang.translate(""));
		return result;
	});
};
export const onCashOut = async () => {
	const result = await Axios.post("/api/gameCrash/onCashOut", {});
	return await onResult(result, "unexpected_error", (result) => {
		return result;
	});
};
export const onGetStats = async (o) => {
	const result = await Axios.post("/api/gameCrash/onGetStats", o);
	return await onResult(result, "unexpected_error", (result) => {
		// Toast.success(lang.translate(""));
		return result;
	});
};
export const onStatsReset = async (o) => {
	const result = await Axios.post("/api/gameCrash/onStatsReset", o);
	return await onResult(result, "unexpected_error", (result) => {
		// Toast.success(lang.translate(""));
		return result;
	});
};
export const getGameInfo = async (o) => {
	const result = await Axios.post("/api/gameCrash/getGameInfo", o);
	return await onResult(result, "unexpected_error", (result) => {
		// Toast.success(lang.translate(""));
		return result;
	});
};
export const showCrashGameInfo = async (gh) => {
	const gameInfo = await getGameInfo({ gh });
	if (gameInfo.error) return;
	storeSet({
		modal: {
			$set: { name: "crashgameinfo", canClose: true, props: { data: gameInfo } },
		},
	});
};
export const showCrashAuto = async (props = {}) => {
	sound.play("ui", "btn1");
	storeSet({
		modal: {
			$set: { name: "crashAuto", canClose: true, props },
		},
	});
};
export const verify = (server_seed, block_hash) => {
	server_seed = btoa(server_seed);
	block_hash = btoa(block_hash);
	window.open(`${config.app.crashVerifierUrl}?server_seed=${server_seed}&block_hash=${block_hash}`, "blank");
};
