import React, { Component } from "react";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../index";
//classes:
// import { lang } from "../../classes/lang";
import { getPhoto } from "../../classes/player";
//icons:
import { Icon } from "react-icons-kit";
import { bars } from "react-icons-kit/fa/bars";

class MenuButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			img: PropTypes.any,
			uid: PropTypes.number,
			initFinished: PropTypes.bool,
			focusNext: PropTypes.func,
		};
	}
	menuOpenClick = async (e) => {
		if (e) e.stopPropagation();
		await storeSet({ menuOpen: { $set: true } });
	};
	render() {
		if (!this.props.initFinished) return null;
		const img = getPhoto(this.props.uid, this.props.img, 38, 38);
		return (
			<div className="menuButton">
				<button
					id="btnMenuButton"
					className="btnIcon"
					onClick={this.menuOpenClick}
					style={{ backgroundImage: `url(${img})` }}
					onKeyDown={(e) => this.props.focusNext(e)}
				>
					<div className="icon">
						<Icon icon={bars} size={10} />
					</div>
				</button>
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		img: store.player ? store.player.img : null,
		uid: store.player ? store.player.uid : -1,
		initFinished: store.initFinished,
	};
};

export default connect(a, null, null, { forwardRef: true })(MenuButton);
