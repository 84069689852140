import React from "react";
import PropTypes from "prop-types";

const Digit = (props) => {
	const v = typeof props.v === "undefined" ? "0" : props.v === "." ? "Point" : props.v === "-" ? "Minus" : props.v;
	return (
		<div className={`digit v${v}`}>
			<div className={`all ${props.color ? props.color : ""}`}>
				<div>
					<b></b>
				</div>
				<div>
					<b>-</b>
				</div>
				<div>
					<b>.</b>
				</div>
				<div>
					<b>0</b>
				</div>
				<div>
					<b>1</b>
				</div>
				<div>
					<b>2</b>
				</div>
				<div>
					<b>3</b>
				</div>
				<div>
					<b>4</b>
				</div>
				<div>
					<b>5</b>
				</div>
				<div>
					<b>6</b>
				</div>
				<div>
					<b>7</b>
				</div>
				<div>
					<b>8</b>
				</div>
				<div>
					<b>9</b>
				</div>
				<div>
					<b></b>
				</div>
			</div>
		</div>
	);
};
Digit.propTypes = {
	v: PropTypes.any.isRequired,
	color: PropTypes.string,
};
export default Digit;
