import React, { useCallback, useRef } from "react";
// import { Link } from "react-router-dom";
import lang from "../../classes/lang";
import PropTypes from "prop-types";
import { isObject } from "../../classes/utills";
import { go } from "../../classes/routesLazy";
const FooterMap = (props) => {
	FooterMap.propTypes = {
		lang: PropTypes.string,
	};
	const data = useRef([
		{
			name: "casino",
			list: [
				{
					name: "dice",
					url: "crypto-bitcoin-dice",
				},
				{
					name: "crash",
					url: "crypto-betting-crash",
				},
				{
					name: "pandora",
					url: "pandora-online-casino",
				},
				{
					name: "slots",
					url: "crypto-slots",
				},
			],
		},
		{
			name: "sport",
			list: [
				{
					name: "home",
					url: "crypto-sport-casino",
				},
				{
					name: "live",
					url: "crypto-sport-casino?bt-path=%2Flive",
				},
				{
					name: "soccer",
					url: "crypto-sport-casino?bt-path=%2F1",
				},
				{
					name: "basketball",
					url: "crypto-sport-casino?bt-path=%2F2",
				},
				{
					name: "tennis",
					url: "crypto-sport-casino?bt-path=%2F5",
				},
				{
					name: "icehockey",
					url: "crypto-sport-casino?bt-path=%2F4",
				},
			],
		},
		{
			name: "billing",
			list: [
				{
					name: "deposit",
					url: "billing/deposit",
				},
				{
					name: "withdraw",
					url: "billing/withdraw",
				},
				{
					name: "faucet",
					url: "billing/faucet",
				},
				{
					name: "bank",
					url: "billing/bank",
				},
				{
					name: "tip",
					url: "billing/tip",
				},
				{
					name: "exchange",
					url: "billing/exchange",
				},
				{
					name: "rain",
					url: "billing/rain",
				},
			],
		},
		{
			name: "promotion",
			list: [
				{
					name: "freespins",
					url: "promotion/freespins",
				},
				{
					name: "deposit_bonus",
					url: "promotion/deposit_bonus",
				},
				{
					name: "promotion",
					url: "promotion",
				},
				{
					name: "contests",
					url: "promotion/contests",
				},
				{
					name: "affiliation",
					url: "affiliation",
				},
			],
		},
		{
			name: "community",
			list: [
				{
					target: "_blank",
					name: "telegram",
					url: "https://t.me/win_tomato",
				},
				{
					target: "_blank",
					name: "instagram",
					url: "https://www.instagram.com/wintomatocom",
				},
				{
					target: "_blank",
					name: "facebook",
					url: "https://www.facebook.com/cryptogamblingnet",
				},
				{
					target: "_blank",
					name: "twitter",
					url: "https://twitter.com/Wintomatocom",
				},
				{
					target: "_blank",
					name: "forum",
					url: "https://coinfolk.net/category/45/wintomato-com",
				},
				{
					target: "_blank",
					name: "t&c",
					// url: "https://coinfolk.net/category/49/t-c-wintomato-com",
					url: "terms-and-conditions",
				},
				{
					name: "faq",
					url: "faq",
				},
				{
					name: "news",
					url: "news",
				},
				{
					name: "official_mirror1",
					url: "https://pomidor.live",
				},
				{
					name: "official_mirror1",
					url: "https://wintomato.net",
				},
				{
					name: "official_mirror1",
					url: "https://wintomato1.com",
				},
			],
		},
		{
			name: "support",
			list: [
				{
					name: "support",
				},
				{
					name: "gamble_aware",
					url: "https://www.begambleaware.org",
				},
			],
		},
	]);
	const showSupport = () => {
		if (isObject(window.Tawk_API) && typeof window.Tawk_API.showWidget === "function") {
			window.Tawk_API.showWidget();
			window.Tawk_API.maximize();
		}
	};
	const renderCol = useCallback(
		(col) => {
			return (
				<div key={`fm_col_${col.name}`} className="col">
					<h3>{lang.translate(col.name)}</h3>
					{col.list.map((el, i) => {
						const onClick =
							el.name === "support"
								? { onClick: (e) => [e.preventDefault(), e.stopPropagation(), showSupport()] }
								: el.url?.startsWith("http")
								? {}
								: {
										onClick: (e) => [e.preventDefault(), e.stopPropagation(), go(el.url)],
								  };
						const target = {};
						if (el.target) target.target = el.target;
						return (
							<a
								{...onClick}
								{...target}
								key={`col_${col.name}_${el.name}_${i}`}
								// rel="nofollow"
								href={el.url?.startsWith("http") ? el.url : `${props.lang}/${el.url}`}
							>
								{lang.translate(el.name, el.url ? [el.url] : undefined)}
							</a>
						);
					})}
				</div>
			);
		},
		[props.lang]
	);
	const renderData = useCallback(() => {
		const arr = [];
		for (let el of data.current) {
			arr.push(renderCol(el));
		}
		return arr;
		// eslint-disable-next-line
	}, [props.lang]);

	return <div className="footerMap">{renderData()}</div>;
};
export default FooterMap;
