import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
// import config from "../../config";
//components:
import List from "../../components/list/List";
import SImpleDropDown from "../../components/simpleDropDown/SimpleDropDown";
//containers:
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../index";
//classes:
import { lang } from "../../classes/lang";
import Storage from "../../classes/storage";
import { getPhoto, logoutManual, setOnlineStatus, simpleCan } from "../../classes/player";
import { delay, focusNext, yesNoWrap } from "../../classes/utills";
import { gaPageView } from "../../classes/ga";
import { chat } from "../../classes/chat";
import { go } from "../../classes/routesLazy";
//icons:
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/ikons/close";
import { arrow_down } from "react-icons-kit/ikons/arrow_down";
import { arrow_up } from "react-icons-kit/ikons/arrow_up";
import { signOut } from "react-icons-kit/fa/signOut";
// import { ic_home as homeIcon } from "react-icons-kit/md/ic_home";
import { ic_stars as fbiIcon } from "react-icons-kit/md/ic_stars";
// import { shower as faucetIcon } from "react-icons-kit/fa/shower";
// import { dice as diceIcon } from "react-icons-kit/icomoon/dice";
import { user as profileIcon } from "react-icons-kit/fa/user";
// import { random as exchangeIcon } from "react-icons-kit/fa/random";
import OnlineStatus from "../../components/onlineStatus/OnlineStatus";
// import { userPlus as affiliationIcon } from "react-icons-kit/fa/userPlus";
import { questionCircle as faqIcon } from "react-icons-kit/fa/questionCircle";
import { futbolO as sportIcon } from "react-icons-kit/fa/futbolO";
import IconCa, { iconCaName } from "../../classes/iconCa";

class Menu extends PureComponent {
	constructor(props) {
		super(props);
		this.overlay = null;
		this.state = {
			loading: false,
			PERMISSION_VIEW: false,
			DESIGN_ALL: false,
			frequently_used: false, //make closed on init
		};
		this.listRef = React.createRef();

		this.onlineStatusRef = null;
	}
	static get propTypes() {
		return {
			menuOpen: PropTypes.bool,
			lang: PropTypes.string,
			langRoute: PropTypes.string,
			userName: PropTypes.string,
			uid: PropTypes.number,
			img: PropTypes.number,
			temp: PropTypes.bool,
			onlineStatus: PropTypes.number,
			permissionsChanged: PropTypes.number,
			initFinished: PropTypes.bool,
		};
	}
	componentDidMount = () => {
		if (typeof document !== "undefined") {
			document.addEventListener("keydown", this.onKeyDown, { passive: false });
		}
	};
	onKeyDown = (e) => {
		if (this.props.menuOpen) {
			if (e.keyCode === 27 /*escape */) {
				this.menuCloseClick();
			}
		}
	};
	componentWillUnmount = () => {
		if (typeof document !== "undefined") {
			document.removeEventListener("keydown", this.onKeyDown, { passive: false });
		}
	};
	componentDidUpdate = (prevProps) => {
		if (this.props.initFinished !== prevProps.initFinished || this.props.permissionsChanged !== prevProps.permissionsChanged) {
			simpleCan("PERMISSION_VIEW").then((result) => {
				this.setState({ PERMISSION_VIEW: result });
			});
			simpleCan("DESIGN_ALL").then((result) => {
				this.setState({ DESIGN_ALL: result });
			});
			this.props.uid === -1 && chat.closeChatPane(true);
		}
	};
	menuCloseClick = async (e) => {
		if (e) e.stopPropagation();
		await chat.closeChatPane(true);
		await storeSet({ menuOpen: { $set: false } });
		const btn = document.getElementById("btnMenuButton");
		if (btn) btn.focus();
	};
	toggleFigure = async (e, name) => {
		if (e) e.stopPropagation();
		await this.setState(
			{
				[name]: typeof this.state[name] === "undefined" || this.state[name] === true ? false : true,
			},
			async () => {
				await delay(300);
				this.listRef && this.listRef.current && this.listRef.current.refresh();
			}
		);
	};
	renderBlock = (name, title, items) => {
		const figureState = typeof this.state[name] === "undefined" || this.state[name] === true ? true : false;

		return (
			<figure className={name}>
				<div className="blockHead" onClick={(e) => this.toggleFigure(e, name)}>
					<h3>{lang.translate(title)}</h3>
					<button className="btnIcon toggleFigure">
						<Icon icon={figureState ? arrow_down : arrow_up} />
					</button>
				</div>
				<div
					className={`blockItems ${figureState ? "on" : "off"}`}
					style={{
						maxHeight: `${items.length * 30 * (figureState ? 1 : 0)}px`,
					}}
				>
					<div className="content">{items}</div>
				</div>
			</figure>
		);
	};
	setOnlineStatusClick = async (status) => {
		this.onlineStatusRef.toggle(false);
		await this.setState({ loading: true });
		await setOnlineStatus(status.id, status.c);
		await this.setState({ loading: false });
	};
	logoutManualClick = () => {
		yesNoWrap(`${lang.translate("logout")}`, async (boolResponse) => {
			if (!boolResponse) return;
			logoutManual();
		});
	};
	renderHeader = () => {
		const header = (
			<div className="menuDropHeader">
				<div
					className="avatar"
					style={{
						backgroundImage: `url(${getPhoto(this.props.uid, this.props.img, 38, 38)})`,
					}}
				>
					<OnlineStatus id={this.props.onlineStatus ? this.props.onlineStatus : 0} />
				</div>
				<label>{this.props.userName}</label>
			</div>
		);
		const data = [
			{ id: 0, c: "online" },
			{ id: 1, c: "away" },
			{ id: 2, c: "busy" },
			{ id: 3, c: "offline" },
		].map((el) => {
			return {
				id: el.c,
				c: (
					<Link to="/#" onClick={(e) => [e.preventDefault(), this.setOnlineStatusClick(el), go("")]} className="btnDefault">
						<OnlineStatus id={el.id} />
						<label>{lang.translate(el.c)}</label>
					</Link>
				),
			};
		});
		data.push({
			id: "sep0",
			c: <div className="sep"></div>,
		});
		data.push({
			id: "viewProfile",
			c: (
				<Link to="/#" onClick={(e) => [this.menuCloseClick(e), go(`profile/${this.props.userName}`)]}>
					<Icon icon={profileIcon} />
					<label className="profileLabel">{lang.translate("view_profile")}</label>
				</Link>
			),
		});
		if (this.props.uid !== -1 && this.props.temp === false) {
			data.push({
				id: "sep1",
				c: <div className="sep"></div>,
			});
			data.push({
				id: "logout",
				c: (
					<Link to="/#" onClick={(e) => [e.stopPropagation(), this.logoutManualClick(), this.menuCloseClick()]}>
						<Icon icon={signOut} />
						<label>{lang.translate("logout")}</label>
					</Link>
				),
			});
		}

		const content = (
			<div className="headerContent">
				<List ren={true} data={data} />
			</div>
		);
		return (
			<figure className="header">
				<SImpleDropDown ref={(el) => (this.onlineStatusRef = el)} header={header} content={content} />
				{/* <div className="info">
				<label>{this.props.userName}</label>
				{this.props.uid !== -1 && <Link onClick={this.menuCloseClick} to={`profile/${this.props.userName}`}>{lang.translate("view_profile")}</Link>}
			</div> */}
				<button id="sidemenubtnclose" className="btnIcon btnClose" onClick={this.menuCloseClick} onKeyDown={(e) => focusNext(e, this.info)}>
					<Icon icon={close} />
				</button>
			</figure>
		);
	};
	info = {
		btnMenuButton: {},
		Enter: "btnMenuButton",
		//---
		sidemenubtnclose: { down: ["menulink_fbi", "menulink_home"] },
		menulink_fbi: { down: "menulink_home", up: "sidemenubtnclose" },
		menulink_home: { down: "menulink_profile", up: ["menulink_fbi", "sidemenubtnclose"] },
		menulink_profile: { down: "menulink_faq", up: "menulink_home" },
		menulink_faq: { down: "menulink_history", up: "menulink_profile" },
		menulink_history: { down: "menulink_dice", up: "menulink_faq" },
		menulink_dice: { down: "menulink_flappybet", up: "menulink_history" },
		menulink_flappybet: { down: "menulink_pandora", up: "menulink_dice" },
		menulink_pandora: { down: "menulink_slots", up: "menulink_flappybet" },
		menulink_slots: { down: "menulink_sport", up: "menulink_pandora" },
		menulink_sport: { down: "menulink_affiliation", up: "menulink_slots" },

		menulink_affiliation: { down: "menulink_affiliation (PRO)", up: "menulink_sport" },
		"menulink_affiliation (PRO)": { down: "menulink_my_freespins", up: "menulink_affiliation" },
		menulink_my_freespins: { down: "menulink_deposit_bonus", up: "menulink_affiliation (PRO)" },
		menulink_deposit_bonus: { down: "menulink_rakeback", up: "menulink_my_freespins" },
		menulink_rakeback: { down: "menulink_fortune_wheel", up: "menulink_deposit_bonus" },
		menulink_fortune_wheel: { down: "menulink_battle", up: "menulink_rakeback" },
		menulink_battle: { down: "menulink_contests", up: "menulink_fortune_wheel" },
		menulink_contests: { down: "menulink_deposit", up: "menulink_battle" },

		menulink_deposit: { down: "menulink_withdraw", up: "menulink_contests" },
		menulink_withdraw: { down: "menulink_bank", up: "menulink_deposit" },
		menulink_bank: { down: "menulink_faucet", up: "menulink_withdraw" },
		menulink_faucet: { down: "menulink_tip", up: "menulink_bank" },
		menulink_tip: { down: "menulink_exchange", up: "menulink_faucet" },
		menulink_exchange: { down: "menulink_rain", up: "menulink_tip" },
		menulink_rain: { down: "menulink_fsbuy", up: "menulink_exchange" },
		menulink_fsbuy: { down: "", up: "menulink_rain" },
	};
	render() {
		const urls = Storage.get("urls", {});
		const frequentUrls = [];
		// const hostname = typeof window === "undefined" ? "" :  window.location.hostname;
		for (let el in urls) {
			frequentUrls.push(
				<Link to="/#" onClick={(e) => [this.menuCloseClick(e), go(urls[el]["path"].substr(4))]} key={urls[el]["name"]}>
					{lang.translate(urls[el]["name"])}
				</Link>
			);
			if (frequentUrls.length === 5) break;
		}
		this.data = [];
		this.data.push({
			id: "frequently_used",
			c: this.renderBlock("frequently_used", "frequently_used", frequentUrls),
		});
		const arrays = {
			menuArr: {
				name: "menuBlock",
				title: "menu",
				items: [
					...(this.state.PERMISSION_VIEW || this.state.DESIGN_ALL
						? [
								{
									name: "fbi",
									title: "FBI",
									icon: fbiIcon,
									path: "fbi/statsMgr",
								},
						  ]
						: []),
					{
						name: "home",
						title: "home",
						// icon: homeIcon,
						path: "",
						iconCa: iconCaName.home,
					},
					{
						name: "profile",
						title: "profile",
						// icon: homeIcon,
						path: `profile/${this.props.userName}`,
						iconCa: iconCaName.profile,
					},
					{
						name: "faq",
						title: "FAQ",
						icon: faqIcon,
						path: "faq",
					},
					{
						name: "history",
						title: "history",
						path: "history",
						iconCa: iconCaName.history,
					},
				],
			},
			games: {
				name: "gamesBlock",
				title: "games",
				items: [
					{
						name: "dice",
						title: "dice",
						// icon: diceIcon,
						path: "crypto-bitcoin-dice",
						iconCa: iconCaName.dice,
					},
					{
						name: "flappybet",
						title: "flappybet",
						// icon: crashIcon,
						path: "originals",//"crypto-betting-crash",
						iconCa: "flappybet",
					},
					{
						name: "pandora",
						title: "pandora",
						// img: "/assets/pandora-logo.png",
						path: "pandora-online-casino",
						iconCa: iconCaName.pandora,
					},
					{
						name: "slots",
						title: "slots",
						path: "crypto-slots",
						iconCa: iconCaName.slot,
					},
					{
						name: "sport",
						title: "sport",
						path: "crypto-sport-casino",
						icon: sportIcon,
					},
				],
			},
			promotionArr: {
				name: "promotionBlock",
				title: "promotion",
				items: [
					// {
					// 	name: "promotion",
					// 	title: "promotion",
					// 	path: `promotion`,
					// 	iconCa: iconCaName.gift,
					// },
					{
						name: "affiliation",
						title: "affiliation",
						// icon: affiliationIcon,
						iconCa: iconCaName.network,
						path: "affiliation",
					},
					{
						name: "affiliation",
						suffix: " (PRO)",
						title: "affiliation",
						// icon: affiliationIcon,
						iconCa: iconCaName.network,
						path: "affiliation-pro",
					},
					{
						name: "my_freespins",
						title: "my_freespins",
						// icon: affiliationIcon,
						iconCa: iconCaName.gift,
						path: "promotion/freespins",
					},
					{
						name: "deposit_bonus",
						title: "deposit_bonus",
						// icon: affiliationIcon,
						iconCa: iconCaName.gift,
						path: "promotion/deposit_bonus",
					},
					{
						name: "rakeback",
						title: "rakeback",
						path: null,
						f: () => {
							storeSet({
								modal: {
									$set: { name: "fortunewheel", canClose: true, props: { mode: 1 } },
								},
							});
						},
						iconCa: iconCaName.rakeback,
					},
					{
						name: "fortune_wheel",
						title: "fortune_wheel",
						path: null,
						f: () => {
							storeSet({
								modal: {
									$set: { name: "fortunewheel", canClose: true, props: { mode: 0 } },
								},
							});
						},
						iconCa: iconCaName.fortuneWheel,
					},
					{
						name: "battle",
						title: "battle",
						path: "battle",
						iconCa: "battle",
					},
					{
						name: "contests",
						title: "contests",
						// icon: affiliationIcon,
						iconCa: iconCaName.contest,
						path: "promotion/contests",
					},
				],
			},
			billingArr: {
				name: "billingBlock",
				title: "billing",
				items: [
					{
						name: "deposit",
						title: "deposit",
						path: "billing/deposit",
						iconCa: iconCaName.deposit,
					},
					{
						name: "withdraw",
						title: "withdraw",
						path: "billing/withdraw",
						iconCa: iconCaName.withdraw,
					},
					{
						name: "bank",
						title: "bank",
						path: "billing/bank",
						iconCa: iconCaName.bank,
					},
					{
						name: "faucet",
						title: "faucet",
						path: "billing/faucet",
						iconCa: iconCaName.faucet,
					},
					{
						name: "tip",
						title: "tip",
						path: "billing/tip",
						iconCa: iconCaName["tip-send"],
					},
					{
						name: "exchange",
						title: "exchange",
						path: "billing/exchange",
						iconCa: iconCaName.exchange,
					},
					{
						name: "rain",
						title: "rain",
						path: "billing/rain",
						iconCa: iconCaName.rain,
					},
					{
						name: "fsbuy",
						title: "fsbuy",
						path: this.props.uid > 0 ? `billing/fsbuy/${this.props.userName}` : "billing/fsbuy",
						iconCa: iconCaName.slot,
					},
				],
			},
		};

		for (let arr in arrays) {
			const _arr = arrays[arr];
			_arr.items = _arr.items.map((el) => (
				<Link
					id={`menulink_${el.name + (el.suffix ?? "")}`}
					to="/#"
					key={el.name + (el.suffix ?? "")}
					onClick={(e) => [gaPageView(el.name), this.menuCloseClick(e), el?.f?.(), go(el.path)]}
					onKeyDown={(e) => focusNext(e, this.info)}
				>
					{el.icon && <Icon size={24} icon={el.icon} />}
					{el.img && <img src={el.img} alt="" title="" />}
					{el.iconCa && <IconCa name={el.iconCa} />}
					<label>{lang.translate(el.name).CapitalizeFirstLetter() + (el.suffix ?? "")}</label>
				</Link>
			));
			this.data.push({
				id: _arr.name,
				c: this.renderBlock(_arr.name, _arr.title, _arr.items),
			});
		}
		this.listRef && this.listRef.current && this.listRef.current.refresh();

		return (
			<div
				name="menuOverlay"
				ref={(el) => (this.overlay = el)}
				className={`menuOverlay ${this.props.menuOpen ? "on" : "off"}`}
				onClick={this.menuCloseClick}
			>
				<div name="menu" className="menu" onClick={(e) => e.stopPropagation()}>
					<div className="content">
						{this.renderHeader()}
						<div ref={(el) => (this.scrollable = el)} className="scrollable">
							<List ref={this.listRef} ren={true} data={this.data} />
						</div>
						<figure className="footer">
							{this.props.uid !== -1 && this.props.temp === false && (
								<Link to="/#" onClick={(e) => [e.stopPropagation(), this.logoutManualClick(), this.menuCloseClick()]}>
									<Icon icon={signOut} />
									<label>{lang.translate("logout")}</label>
								</Link>
							)}
						</figure>
					</div>
				</div>
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		langRoute: store.langRoute,
		userName: store.player.userName,
		onlineStatus: store.player.onlineStatus,
		uid: store.player.uid,
		img: store.player.img,
		temp: store.player.temp,
		permissionsChanged: store.permissionsChanged,
		initFinished: store.initFinished,
	};
};

export default connect(a, null, null, { forwardRef: true })(Menu);
