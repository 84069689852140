import React from "react";
import PropTypes from "prop-types";
//components:
import Avatar from "../../components/avatar/Avatar";
//classes:
import { lang } from "../../classes/lang";
import ImageCached from "../../classes/cache";
// import { getPhoto } from "../../classes/player";
import { showRollInfo } from "../../classes/games/dice";
import { go } from "../../classes/routesLazy";
//icons:

const WidgetDice = (props) => {
	const { k_ey, el, un } = props;
	const renderSlider = () => {
		const o = el.other ?? el;
		const isFlash = o.n1 > o.n || o.num > 1;
		return (
			<div className="c bars wrapper">
				<div className="img" onClick={(e) => [e.stopPropagation(), go("crypto-bitcoin-dice")]}>
					<img src="/assets/banners/dice.webp" />
				</div>
				<div className="stripW detail">
					<div className="strip">
						<div
							style={
								isFlash ? ((o.over === 1 && o.win) || (o.over === 0 && !o.win) ? { right: "26px" } : { left: "26px" }) : { left: `${o.outcome}%` }
							}
							className={`outcomeWrapper ${el.win ? "green" : "red"}`}
						>
							<div title={lang.translate("outcome")} className={`outcome ${el.win ? "green" : "red"}`}>
								{isFlash ? "FLSH" : o.outcomeStr}
							</div>
						</div>
						<div
							style={{ marginLeft: o.over === 1 ? `${o.rv}%` : "0%", marginRight: o.over === 1 ? "0%" : `${100 - o.rv}%` }}
							className={`stripFill ${el.win ? "green" : "red"}`}
						></div>
						<label title={lang.translate(o.over === 1 ? "roll_over" : "roll_under")}>
							<b className="under">{`${lang.translate(o.over === 1 ? ">" : "<")} ${o.rv}`}</b>
							<b className="pa">{o.pa}x</b>
						</label>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div key={`${k_ey}_${el._id}`} className={`wDice ${el.win ? "w" : "l"} animSlideDownFadeIn`}>
			<div className="shareBtn">
				<button
					disabled={typeof el._id === "undefined"}
					onClick={(e) => [e.stopPropagation(), showRollInfo(el._id.split("-")[0])]}
					className={`diceBtn ${el.win ? "green" : "red"}`}
				>
					<ImageCached width={24} height={24} storageName="ui" url={`/assets/button_logo.png`} params={{}} />
					{/* <img
						src={`/assets/button_logo.png`}
						alt=""
						width="24"F
						height="24"
						onError={(e) => {
							if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
						}}
					/> */}
				</button>
			</div>
			<Avatar userName={k_ey !== "my_bets" ? el.userName : un} uid={el.uid} img={el.img} size={24} />
			<div className="f1 flex1 info">{renderSlider(el)}</div>
			<div className={`profit ${el.win ? "w" : "l"}`}>
				{el.bet > 0 && <b>{el.bet.toFixed(12).substring(0, 10)}</b>}
				{el.profit > 0 && <b>{el.profit.toFixed(12).substring(0, 10)}</b>}
				{el.bet <= 0 && el.profit <= 0 && (0).toFixed(12).substring(0, 10)}
			</div>
			<ImageCached width={24} height={24} storageName="coin" url={`/assets/icons/${el.short}@2x.png`} alt={el.short} title={el.short} params={{}} />
			{/* <img
					src={`/assets/icons/${el.short}@2x.png`}
					width="24"
					height="24"
					alt={el.short}
					title={el.short}
					onError={(e) => {
						if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
					}}
				/> */}
		</div>
	);
};
WidgetDice.propTypes = {
	k_ey: PropTypes.string,
	el: PropTypes.object,
	un: PropTypes.string,
};
export default WidgetDice;
