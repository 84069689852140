import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import Select from "../../components/select/Select";
import List from "../../components/list/List";
//containers:
import Container from "../Container";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../..";
//classes:
import { lang } from "../../classes/lang";
import Axios from "../../classes/axios";
import { getShortcutsO } from "../../classes/utills";
import Toast from "../../classes/toast";
//icons:
import IconCa, { iconCaName } from "../../classes/iconCa";
import { Icon } from "react-icons-kit";
import { plus } from "react-icons-kit/fa/plus";
import { close } from "react-icons-kit/fa/close";

class ShortcutModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			loading: false,
		};
		this.icons = {};
		this.data = getShortcutsO();
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			shortcut: PropTypes.array,
		};
	}
	onSelectChange = (item) => {
		this.setState({ selected: item });
	};
	getData = () => {
		const arr = [];
		for (let el in this.data) {
			if (this.props.shortcut.includes(el)) continue;
			arr.push({ id: el, title: lang.translate(this.data[el].title) });
		}
		arr.sort((a, b) => a.title < b.title);
		return arr;
	};
	onEntryAdd = async () => {
		this.setState({ loading: true });
		const result = await Axios.post("/api/player/shortcutSet", {
			shortcuts: [...this.props.shortcut, this.state.selected.id],
		});
		this.setState({ loading: false, selected: null });
		if (result && !result.error) await storeSet({ playerConfig: { shortcut: { $set: result.shortcut } } });
		else if (result.message) {
			Toast.error(lang.translate(result.message));
		}
	};
	onEntryRemove = async (name) => {
		this.setState({ loading: true });
		const result = await Axios.post("/api/player/shortcutSet", {
			shortcuts: this.props.shortcut.filter((el) => el !== name),
		});
		this.setState({ loading: false, selected: null });
		if (result && !result.error) await storeSet({ playerConfig: { shortcut: { $set: result.shortcut } } });
		else if (result.message) {
			Toast.error(lang.translate(result.message));
		}
	};
	renderShortcut = (el) => {
		const disabled = this.state.loading;
		return (
			<div className="entry">
				<button disabled={disabled} onClick={(e) => [e.stopPropagation(), this.onEntryRemove(el.name)]} className="btnDefault alert btnRemove">
					<Icon icon={close} size={24} />
				</button>
				<button disabled={disabled} className="btnDefault btnItem">
					{typeof el.icon === "string" && <Icon icon={this.icons[el.icon]} />}
					{el.icon && typeof el.icon !== "string" && <Icon icon={el.icon} />}
					{el.iconCa && <IconCa name={iconCaName[el.iconCa]} />}
					<b>{lang.translate(el.title)}</b>
				</button>
			</div>
		);
	};
	getShortcuts = () => {
		const arr = [];
		for (let name of this.props.shortcut) {
			const el = this.data[name];
			el.name = name;
			arr.push({
				id: el.name,
				c: this.renderShortcut(el),
			});
		}
		return arr;
	};
	render() {
		const disabled = this.state.loading || this.state.selected === null;
		return (
			<Container className="shortcutModal" noDocs={true}>
				<h2>{lang.translate("shortcuts")}</h2>
				<header>
					<Select
						style={{ flex: 1 }}
						caseSearch={false}
						valueHeader={true}
						valueBody={false}
						onSelect={(item) => this.onSelectChange(item)}
						multi={false}
						min={1}
						selected={[this.state.selected]}
						data={this.getData()}
						sortBy="title"
						onSearch={this.onMenuSearch}
					/>
					<button disabled={disabled} onClick={(e) => [e.stopPropagation(), this.onEntryAdd()]} className="btnDefault green btnAdd">
						<Icon icon={plus} size={24} />
					</button>
				</header>
				<main className="myList">
					<List ren={true} data={this.getShortcuts()} />
				</main>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		shortcut: store.playerConfig.shortcut,
	};
};

export default connect(a, null, null, { forwardRef: true })(ShortcutModal);
