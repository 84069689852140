import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Container from "../Container";
import useGames from "./useGames";
import useIframeHeight from "./useIframeHeight";
import { useRef } from "react";
import Loader from "../../components/loader/Loader";
import { useSelector } from "react-redux";
import Widgets from "../Lobby/Widgets";
import { getSetParams } from "../../classes/utills";

// eslint-disable-next-line no-unused-vars
const FlappyBetGame = forwardRef((props, ref) => {
	FlappyBetGame.propTypes = {
		match: PropTypes.object,
	};
	const refIframe = useRef(null);
	const coinSelected = useSelector((store) => store.coin.selected);
	const langRoute = useSelector((store) => store.langRoute);
	const { init, url } = useGames({ coinSelected });
	const [loaded, setLoaded] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [expanded, setExpanded] = useState(false);
	useEffect(() => {
		getSetParams();
	}, []);
	useEffect(() => {
		if (url) {
			refIframe.current.onload = () => {
				setLoaded(true);
			};
		} else {
			setLoaded(false);
		}
	}, [url]);

	const run = async () => {
		const id = +props.match?.params?.gameid;
		const isDemo = props.match?.params?.isdemo !== "real";
		if (!(id > 0)) return;
		await init(id, isDemo);
	};

	useEffect(() => {
		run();
	}, [coinSelected, langRoute]);

	//Request and set iframe height:
	const { iframeHeight, isFixed } = useIframeHeight({ refIframe, url });
	const style = { width: "100%", height: isFixed ? "600px" : iframeHeight };
	return (
		<Container className="flappyBetGame">
			<>
				{!loaded && (
					<div style={{ position: "relative", width: "100%", height: "520px" }}>
						<Loader />
					</div>
				)}
				{url ? (
					<>
						{/* <button className="btnDefault warn btnFlappyExpand" onClick={(e) => [e.stopPropagation(), setExpanded((prev) => !prev)]}>
							<b>+</b>
						</button> */}
						<iframe ref={refIframe} id="iframeFlappy" src={url} style={style} className={`${expanded ? "expanded" : ""}`} />
					</>
				) : (
					<Loader />
				)}
				<Widgets activeTab="my_bets" />
			</>
		</Container>
	);
});
FlappyBetGame.displayName = "FlappyBetGame";
export default FlappyBetGame;
