import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//components:
import SImpleDropDown from "../../../components/simpleDropDown/SimpleDropDown";
import List from "../../../components/list/List";
//redux:
import { connect } from "react-redux";
import { store } from "../../../index";
//classes:
import { lang } from "../../../classes/lang";
import { chat } from "../../../classes/chat";
import { friend } from "../../../classes/friend";
import { isModAdmin, simpleCan, muteShow } from "../../../classes/player";
import { go } from "../../../classes/routesLazy";
//icons:
import { Icon } from "react-icons-kit";
// import { ic_chevron_right } from "react-icons-kit/md/ic_chevron_right";
import { bars } from "react-icons-kit/fa/bars";
import { userTimes as unfriendIcon } from "react-icons-kit/fa/userTimes";
import { ic_file_upload as sendTipIcon } from "react-icons-kit/md/ic_file_upload";
import { ic_textsms as pmIcon } from "react-icons-kit/md/ic_textsms";
import { ic_notifications_off as ignoreIcon } from "react-icons-kit/md/ic_notifications_off";
import { ic_notifications_active as unignoreIcon } from "react-icons-kit/md/ic_notifications_active";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_close } from "react-icons-kit/md/ic_close";
import { ban as banIcon } from "react-icons-kit/fa/ban";
import { ic_volume_off as muteIcon } from "react-icons-kit/md/ic_volume_off";
import { user } from "react-icons-kit/fa/user";

class ChatFriends extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
		this.listRefFriends = React.createRef();
		this.listRefIgnored = React.createRef();
		this.MUTE = false;
		this.BAN = false;
	}
	static get propTypes() {
		return {
			friends: PropTypes.object,
			lang: PropTypes.string,
		};
	}
	renderFriendEntry = (f) => {
		const showDropContent = async (_simpleDropDown, f) => {
			const state = await store.getState();
			const me = state.player.uid;
			const IAmModAdmin = await isModAdmin({ state, uid: me });
			const _isModAdmin = await isModAdmin({ state, uid: f.uid });
			const isFriend = await friend.isFriend({ uid: f.uid, state });
			const isPendingFriend = await friend.isPendingFriend({ uid: f.uid, state });
			const isIgnored = f.ignored === true;
			const canIgnore = !(isFriend || isPendingFriend) && !isIgnored && !_isModAdmin;
			const canUnignore = isIgnored;
			this.MUTE = await simpleCan(["MUTE"]);
			this.BAN = await simpleCan(["BAN"]);
			const canMute = (!isFriend || IAmModAdmin) && this.MUTE;
			const canBan = (!isFriend || IAmModAdmin) && this.BAN;
			const canTip = isFriend || (IAmModAdmin && !isIgnored);
			const canPm = isFriend;
			const canUnfriend = isFriend;

			// const canMute = true;
			// const canBan = true;
			// const canTip = true;
			// const canPm = true;
			// const canUnfriend = true;
			// document.getElementById("root").click(), chat.toggleChatPane(true),
			return (
				<div className="buttons">
					<button onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), go(`profile/${f.un}`)]} className="btnDefault">
						<Icon size={18} icon={user} />
						<b>{lang.translate("profile")}</b>
					</button>
					{canPm && (
						<button className="btnDefault" onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), chat.onPmClick(f)]}>
							<Icon size={18} icon={pmIcon} />
							<b>{lang.translate("send_pm")}</b>
						</button>
					)}
					{canTip && (
						<button onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), chat.toggleChatPane(true), go(`billing/tip/${f.un}`)]} className="btnDefault">
							<Icon size={18} icon={sendTipIcon} />
							<b>{lang.translate("send_tip")}</b>
						</button>
					)}
					{canIgnore && (
						<button className="btnDefault" onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.ignore(f)]}>
							<Icon size={18} icon={ignoreIcon} />
							<b>{lang.translate("ignore")}</b>
						</button>
					)}
					{canUnignore && (
						<button className="btnDefault" onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.unignore(f)]}>
							<Icon size={18} icon={unignoreIcon} />
							<b>{lang.translate("unignore")}</b>
						</button>
					)}
					{canUnfriend && (
						<button onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), friend.reject(f)]} className="btnDefault alert">
							<Icon size={18} icon={unfriendIcon} />
							<b>{lang.translate("remove_from_friends")}</b>
						</button>
					)}
					{canMute && (
						<button className="btnDefault alert" onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), muteShow({ userName: f.un })]}>
							<Icon size={18} icon={muteIcon} />
							<b>{lang.translate("mute")}</b>
						</button>
					)}
					{canBan && (
						<button className="btnDefault alert" onClick={(e) => [e.stopPropagation(), _simpleDropDown.toggle(false), muteShow({ userName: f.un })]}>
							<Icon size={18} icon={banIcon} />
							<b>{lang.translate("ban")}</b>
						</button>
					)}
				</div>
			);
		};
		return (
			<div className="diceBtn grey friend">
				{!f.pending && (
					<SImpleDropDown
						className="actions"
						showArrow={false}
						down={false}
						auto={f.ignored ? [50, 100] : [0, 50]}
						header={<Icon icon={bars} size={18} />}
						beforeShow={(inst) => showDropContent(inst, f)}
						modalMode={true}
					/>
				)}
				<b>{f.un}</b>
				{f.pending && (
					<>
						<button onClick={(e) => [e.stopPropagation(), friend.accept(f)]} className="btnDefault success btnAccept">
							<Icon icon={ic_check} size={18} />
						</button>
						<button onClick={(e) => [e.stopPropagation(), friend.reject(f)]} className="btnDefault alert btnReject">
							<Icon icon={ic_close} size={18} />
						</button>
					</>
				)}
			</div>
		);
	};
	friendsData = () => {
		const arr = {
			arrPending: [],
			arrFriends: [],
			arrIgnored: [],
		};
		if (this.props.friends === null) return arr;
		const addItem = (where, f) => {
			arr[where].push({
				id: f.uid,
				c: this.renderFriendEntry(f),
			});
		};
		//render friends:
		for (let key in this.props.friends) {
			const f = this.props.friends[key];
			let k;
			if (f.pending) k = "arrPending";
			else if (f.ignored) k = "arrIgnored";
			else k = "arrFriends";
			addItem(k, f);
		}
		return arr;
	};
	render() {
		const { arrPending, arrFriends, arrIgnored } = this.friendsData();
		return (
			<Container className="chatFriends" noDocs={true}>
				<>
					<section className="friends">
						<h3>{lang.translate("current_friends")}</h3>
						<List ref={this.listRefFriends} ren={true} data={arrPending.concat(arrFriends)} />
					</section>
					<section className="friends ignored">
						<h3>{lang.translate("ignored_players")}</h3>
						<List ref={this.listRefIgnored} ren={true} data={arrIgnored} />
					</section>
				</>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		friends: store.friends.all,
		lang: store.lang.key,
		permissionsChanged: store.permissionsChanged,
	};
};

export default connect(a, null, null, { forwardRef: true })(ChatFriends);
