import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
// import Container from "../../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { KEYS } from "../../classes/keywords";
import SImpleDropDown from "../simpleDropDown/SimpleDropDown";
import Input from "../input/Input";
//icons:
// import { Icon } from "react-icons-kit";
// import { save } from "react-icons-kit/fa/save";

class MultiLangField extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static get propTypes() {
		return {
			onClick: PropTypes.func,
			placeholder: PropTypes.string,
			onChange: PropTypes.func,
			value: PropTypes.object, //{en:"Test",ru:"Тест"}
			style: PropTypes.object,
			disabled: PropTypes.bool,
			//---
			lang: PropTypes.string,
		};
	}
	componentDidMount = () => {
		const o = {};
		for (let el of KEYS) o[el] = this.props.value && typeof this.props.value[el] === "string" ? this.props.value[el] : "";
		this.setState(o);
	};
	getHeader = () => {
		let test = true;
		for (let key of KEYS) {
			if (typeof this.state[key] !== "string" || this.state[key].trim().length === 0) {
				test = false;
				break;
			}
		}
		return (
			<div className="mHeader">
				<div className={`status ${test ? "success" : "warn"}`}></div>
				<b>{this.state.en || (typeof this.props.placeholder === "string" ? `[${this.props.placeholder}]` : "")}</b>
			</div>
		);
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (typeof this.props.onChange === "function") this.props.onChange(Object.assign({}, this.state));
	};
	getContent = () => {
		return KEYS.map((el) => {
			const stateValue = typeof this.state[el] === "string" ? this.state[el] : "";
			return (
				<div key={el} className="entry">
					<b>{el.toUpperCase()}</b>
					<Input
						style={this.props.style || {}}
						name={el}
						type="text"
						disabled={typeof this.props.disabled === "boolean" ? this.props.disabled : false}
						value={stateValue}
						onChange={this.onChange}
						onBlur={this.onChange}
						placeholder={el}
					/>
				</div>
			);
		});
	};
	render() {
		return (
			<div className="multiLangField">
				<SImpleDropDown render={true} className="" headerClassname="" header={this.getHeader()} content={this.getContent()} showArrow={true} />
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};
export default connect(a, null, null, { forwardRef: true })(MultiLangField);
