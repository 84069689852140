import React, { PureComponent } from "react";
//containers:
import Container from "../Container";
import PropTypes from "prop-types";
import Loader from "../../components/loader/Loader";
//redux:
import { connect } from "react-redux";
import Input from "../../components/input/Input";
//classes:
import { lang } from "../../classes/lang";
import { restoreAccount } from "../../classes/player";
import { delay, passwordStrength } from "../../classes/utills";
import Toast from "../../classes/toast";
import { go } from "../../classes/routesLazy";
//icons:
//icons:
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/icomoon/eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";
import { checkSquareO } from "react-icons-kit/fa/checkSquareO";
import { squareO } from "react-icons-kit/fa/squareO";

class RestoreAccount extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			pass1: "",
			pass2: "",
			pass1_eye: false,
		};
	}
	static get propTypes() {
		return {
			onClose: PropTypes.func,
			//redux:
			lang: PropTypes.string,
			code: PropTypes.string,
			uid: PropTypes.number,
		};
	}
	onPassChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onSubmit = async (e) => {
		if (e) e.stopPropagation();
		if (this.state.pass1.length < 6 || this.state.pass2.length < 6) return Toast.error(lang.translate("password_at_least"));
		if (this.state.pass1 !== this.state.pass2) return Toast.error(lang.translate("passwords_not_match"));
		this.setState({ loading: true });
		const result = await restoreAccount({ uid: this.props.uid, code: this.props.code, lang: this.props.lang || "en", pass: this.state.pass1 });
		await delay(300);
		console.log("result: ", result);
		await this.setState({ loading: false });
		if (!result.error) {
			Toast.success(lang.translate("password_changed"));
			this.props.onClose();
			go("home");
		}
	};
	render() {
		const disabled = this.state.loading;
		const strength1 = passwordStrength(this.state.pass1);
		const strength2 = passwordStrength(this.state.pass2);
		const strength = strength1.strength > strength2.strength ? strength1 : strength2;
		return (
			<Container className="forgotPass">
				<div className="wrapper">
					{this.state.loading && <Loader />}
					<header>
						<h3>{lang.translate("reset_password")}</h3>
					</header>
					<main>
						<div className="block">
							<label>{`${lang.translate("access_code")}:`}</label>
							<Input disabled={disabled} type="textarea" className="code" name="code" value={this.props.code} />
						</div>
						<div className="block">
							<label>{`${lang.translate("min")}: 6, ${lang.translate("max")}: 20`}</label>
						</div>
						<div className="block">
							<button
								onClick={(e) => [e.stopPropagation(), this.setState({ pass1_eye: !this.state.pass1_eye })]}
								className={`btnIcon eye ${this.state.pass1_eye ? "on" : "off"}`}
							>
								<Icon size={14} icon={this.state["pass1_eye"] ? eye : eyeBlocked} />
							</button>
							<Input
								disabled={disabled}
								maxLength={20}
								placeholder={lang.translate("enter_password")}
								className="m5_b"
								name="pass1"
								value={this.state.pass1}
								type={this.state["pass1_eye"] ? "text" : "password"}
								validation="password"
								onChange={(e) => this.onPassChange(e)}
								onBlur={(e) => this.onPassChange(e)}
							/>
						</div>
						<div className="block">
							<button
								onClick={(e) => [e.stopPropagation(), this.setState({ pass1_eye: !this.state.pass1_eye })]}
								className={`btnIcon eye ${this.state.pass1_eye ? "on" : "off"}`}
							>
								<Icon size={14} icon={this.state["pass1_eye"] ? eye : eyeBlocked} />
							</button>
							<Input
								disabled={disabled}
								maxLength={20}
								placeholder={lang.translate("confirm_new_password")}
								className="m5_b"
								name="pass2"
								value={this.state.pass2}
								type={this.state["pass1_eye"] ? "text" : "password"}
								validation="password"
								onChange={(e) => this.onPassChange(e)}
								onBlur={(e) => this.onPassChange(e)}
							/>
						</div>
						<div className="block">
							<label>{lang.translate("password_strength")}</label>
							<div className="validations">
								<div className={strength.min6 ? "on" : "off"}>
									<b>{lang.translate("min6", 6)}</b>
									<Icon size={24} icon={strength.min6 ? checkSquareO : squareO} />
								</div>
								<div className={strength.hasLower ? "on" : "off"}>
									<b>{lang.translate("hasLower")}</b>
									<Icon size={24} icon={strength.hasLower ? checkSquareO : squareO} />
								</div>
								<div className={strength.hasUpper ? "on" : "off"}>
									<b>{lang.translate("hasUpper")}</b>
									<Icon size={24} icon={strength.hasUpper ? checkSquareO : squareO} />
								</div>
								<div className={strength.hasNumber ? "on" : "off"}>
									<b>{lang.translate("hasNumber")}</b>
									<Icon size={24} icon={strength.hasNumber ? checkSquareO : squareO} />
								</div>
								<div className={strength.hasSymbol ? "on" : "off"}>
									<b>{lang.translate("hasSymbol")}</b>
									<Icon size={24} icon={strength.hasSymbol ? checkSquareO : squareO} />
								</div>
								<div className={this.state.pass1 === this.state.pass2 ? "on" : "off"}>
									<b>{lang.translate("passwords_match")}</b>
									<Icon size={24} icon={this.state.pass1 === this.state.pass2 ? checkSquareO : squareO} />
								</div>
							</div>
						</div>
						<div className="block btns">
							<button
								disabled={disabled || strength.strength < 3 || this.state.pass1 !== this.state.pass2 || this.state.pass1.length < 6}
								onClick={this.onSubmit}
								className="btnDefault alert"
							>
								<label>{lang.translate("reset_password")}</label>
							</button>
							{/* <button disabled={disabled} onClick={this.props.onClose} className="btnDefault success">
								<label>{lang.translate("cancel")}</label>
							</button> */}
						</div>
					</main>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(RestoreAccount);
