import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import Input from "../../components/input/Input";
//containers:
import Container from "../Container";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../index";
//classes:
import { lang } from "../../classes/lang";
import Decimal from "decimal.js";
import { changeBalance, changeBank } from "../../classes/balance";
import ImageCached from "../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
import { send } from "react-icons-kit/fa/send";

class BalanceChange extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			token: "",
			comment: "",
			delta: "0.00000000",
			result: this.props.amount,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			short: PropTypes.string.isRequired,
			amount: PropTypes.string.isRequired,
			imgSrc: PropTypes.string,
			uid: PropTypes.number,
			userName: PropTypes.string,
			onRefresh: PropTypes.func,
			balanceKey: PropTypes.string, //balance, bank
		};
	}
	validateValue = (v) => {
		if (["", "-"].indexOf(v) !== -1) return 0;
		return v;
	};
	onChange = (e, blur = false) => {
		const name = e.target.name;
		const value = e.target.value;
		const o = { [name]: value };
		if (name === "delta") {
			o[name] = value;
			const v = new Decimal(this.props.amount).add(this.validateValue(value));
			if (blur && v.lessThan(0)) {
				o.result = "0.00000000";
				o.delta = this.props.amount;
			} else o.result = v.toFixed(12).ToBalanceFixed(8);
		} else if (name === "result") {
			o[name] = value;
			if (blur && new Decimal(this.validateValue(value)).lessThan(0)) {
				o.result = "0.00000000";
				o.delta = this.props.amount;
			} else o.delta = new Decimal(this.validateValue(value)).minus(this.props.amount).toFixed(12).ToBalanceFixed(8);
		}
		this.setState(o);
	};
	onBalanceChange = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		const comment = (this.state.comment || "").trim() === "" ? undefined : (this.state.comment || "").trim();
		let result;
		if (this.props.balanceKey === "balance") {
			result = await changeBalance({
				short: this.props.short,
				uid: this.props.uid * 1,
				delta: this.state.delta * 1,
				token: this.state.token,
				comment,
			});
		} else if (this.props.balanceKey === "bank") {
			result = await changeBank({
				short: this.props.short,
				uid: this.props.uid * 1,
				delta: this.state.delta * 1,
				token: this.state.token,
				comment,
			});
		}
		await this.setState({ loading: false });
		if (!result.error) {
			this.props.onRefresh();
			await storeSet({ modal: { $set: null } });
		}
	};
	render() {
		const disabled = this.state.loading;
		const { short, amount } = this.props;
		return (
			<Container className="balanceChange">
				<h1>{lang.translate("change_balance")}</h1>
				<div className="photo">
					<img src={this.props.imgSrc} alt="" />
					<b>{`${this.props.uid} - ${this.props.userName}`}</b>
				</div>
				<label>
					{`${lang.translate("current_balance")}:`}
					<b>*</b>
				</label>
				<div className="curBalance">
					{/* <img
						src={`/assets/icons/${short}@2x.png`}
						width="24"
						height="24"
						alt={short}
						title={short}
						onError={(e) => {
							if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
						}}
					/> */}
					<ImageCached
							width={24}
							height={24}
							storageName="coin"
							url={`/assets/icons/${short}@2x.png`}
							alt={short}
							title={short}
							params={{}}
						/>
					<b>{amount}</b>
				</div>
				<label>
					{`${lang.translate("enter_delta_amount")}:`}
					<b>*</b>
				</label>
				<div>
					<Input
						disabled={disabled}
						className="c"
						name="delta"
						placeholder={lang.translate("amount")}
						value={this.state.delta}
						onChange={this.onChange}
						onFocus={(e) => e.target.select()}
						onBlur={(e) => this.onChange(e, true)}
						validation="negativeAmount"
						onEnter={this.onBalanceChange}
						toFixed={8}
					/>
				</div>
				<label>
					{`${lang.translate("result")}:`}
					<b>*</b>
				</label>
				<div>
					<Input
						disabled={disabled}
						className="c"
						name="result"
						placeholder={lang.translate("amount")}
						value={this.state.result}
						onChange={this.onChange}
						onFocus={(e) => e.target.select()}
						onBlur={(e) => this.onChange(e, true)}
						validation="negativeAmount"
						onEnter={this.onBalanceChange}
						toFixed={8}
					/>
				</div>
				<label>{`${lang.translate("comment")} (${lang.translate("optional")}):`}</label>
				<div>
					<Input
						disabled={disabled}
						type="textarea"
						name="comment"
						placeholder={lang.translate("comment")}
						value={this.state.comment}
						onChange={this.onChange}
						onFocus={(e) => e.target.select()}
					/>
				</div>
				<label>
					{`${lang.translate("enter_token")}:`}
					<b>*</b>
				</label>
				<div>
					<Input
						disabled={disabled}
						className="c"
						name="token"
						placeholder={lang.translate("token")}
						value={this.state.token}
						onChange={this.onChange}
						validation="2fa"
						onEnter={this.onBalanceChange}
						maxLength={6}
					/>
				</div>
				<button onClick={this.onBalanceChange} className="btnDefault alert">
					<Icon icon={send} />
					<b>{lang.translate("submit")}</b>
				</button>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(BalanceChange);
