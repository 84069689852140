import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//components:
import SelectPlayer from "../../../components/select/SelectPlayer";
import Balance from "../../../containers/balance/Balance";
import Input from "../../../components/input/Input";
//redux:
import { connect } from "react-redux";
//classes:
// import { lang } from "../../../classes/lang"
import { withPerms } from "../../../classes/player";
import { go } from "../../../classes/routesLazy";
import lang from "../../../classes/lang";
import { status, perform } from "../../../classes/tip";
import { tryParseFloat, isObject } from "../../../classes/utills";
import Toggle from "../../../components/toggle/Toggle";
import ImageCached from "../../../classes/cache";
//icons:
// import { Icon } from "react-icons-kit"
import IconCa, { iconCaName } from "../../../classes/iconCa";
import Decimal from "decimal.js";

class Tip extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			playerSelected: this.props.match.params.targetUserName ? this.props.match.params.targetUserName : null,
			coinSelected: null,
			amount: "0.00000000",
			comment: "",
			share: true,
			//---
			allowTip: false,
			maxDailyTotalCount: 0,
			maxDailyTotalUsd: 0,
			minAmountUsd: 0,
			count: 0,
			totalUsd: 0,
		};
	}
	static get propTypes() {
		return {
			match: PropTypes.object,
			lang: PropTypes.string,
			rates: PropTypes.object.isRequired,
			balance: PropTypes.object.isRequired,
			meUn: PropTypes.string.isRequired,
			perms: PropTypes.object,
			depBonus: PropTypes.object,
		};
	}
	componentDidMount = () => {
		status().then((o) => {
			if (o.error) {
				this.setState({ loading: false });
			} else {
				this.setState(Object.assign({ loading: false }, o));
			}
		});
	};
	onSelect = (item) => {
		go(`billing/tip/${item.id}`);
		this.setState({ playerSelected: item.id, headerSection: "player" });
	};
	onUnselect = () => {
		// if (this.props.match.params.targetUserName) go("billing/tip");
	};
	onBalanceSelect = async (o) => {
		this.setState({ coinSelected: o.short });
	};
	// eslint-disable-next-line no-unused-vars
	onChange = (e, i, delta) => {
		if (e.target.name === "amount") {
			// if (i === 1) {
			// this.setState({ [e.target.name]: tryParseFloat(e.target.value, 0).toFixed(8) });
			// return;
			// }
			// this.lastAction = 0;
			// const getAmount = this.calcGetAmount(e.target.value);
			// this.setState({ [e.target.name]: e.target.value, getAmount: getAmount.toFixed(8) });
			this.setState({ [e.target.name]: e.target.value });
		} else if (e.target.name === "comment") {
			this.setState({ [e.target.name]: e.target.value });
		}
	};
	onToggle = (e, name, share) => {
		this.setState({ share });
	};
	onTipSend = async () => {
		await this.setState({ loading: true });
		await perform(
			{
				amount: tryParseFloat(this.state.amount, 0),
				short: this.state.coinSelected,
				comment: this.state.comment.trim() === "" ? undefined : this.state.comment.trim(),
				targetUserName: this.state.playerSelected,
				share: this.state.share,
			},
			(result) => {
				this.setState(Object.assign({ loading: false }, result.error ? {} : result));
			}
		);
	};
	onMinClick = (minAmount) => {
		this.setState({ amount: minAmount });
	};
	render() {
		if (typeof document === "undefined")
			return (
				<Container>
					<div></div>
				</Container>
			);
		if (isObject(this.props.depBonus) && this.props.depBonus.active === true) {
			const myDepBonus = this.props.depBonus.name[this.props.lang];
			const title = `${myDepBonus ?? ""}${myDepBonus ? ": " : ""}[${this.props.depBonus.desc}]`;
			return (
				<Container className="tip">
					<div className="wrapper warning">
						<h3 className="warn">{`${lang.translate("you_must_cancel_running_deposit_bonus_to_proceed_with_the_current_action")}!`}</h3>
						<h3 className="bonusName">{title}</h3>
						<button onClick={(e) => [e.stopPropagation(), go("promotion/deposit_bonus")]} className="btnDefault warn">
							<b>{lang.translate("deposit_bonus")}</b>
						</button>
					</div>
				</Container>
			);
		}
		const disabled = this.state.loading;
		const minAmount = this.state.coinSelected
			? new Decimal(this.state.minAmountUsd).dividedBy(this.props.rates[this.state.coinSelected]).toFixed(8)
			: "0.00000000";
		const minStr = this.state.coinSelected
			? `${this.state.minAmountUsd.toFixed(4)}$ / ${this.props.rates[this.state.coinSelected].ToBalanceFixed(4)} = ${minAmount}`
			: "0.00000000";
		let reason = [];
		if (!this.state.playerSelected) reason.push(<b key={"reason_1"}>{lang.translate("player_not_selected")}</b>);
		if (!this.state.coinSelected) reason.push(<b key={"reason_2"}>{lang.translate("coin_not_selected")}</b>);
		if (!this.state.loading && (!this.state.allowTip || this.state.playerSelected === this.props.meUn))
			reason.push(<b key={"reason_3"}>{lang.translate("not_allowed_by_system")}</b>);
		if (!this.state.loading && this.state.coinSelected && tryParseFloat(minAmount, 0) > tryParseFloat(this.state.amount, 0))
			reason.push(<b key={"reason_4"}>{lang.translate("min_amount_not_matched")}</b>);
		if (
			!this.state.loading &&
			this.state.coinSelected &&
			tryParseFloat(this.state.amount, 0) > tryParseFloat(this.props.balance[`balance_${this.state.coinSelected}`], 0)
		)
			reason.push(<b key={"reason_5"}>{lang.translate("insufficient_funds")}</b>);
		if (tryParseFloat(this.props.balance.lock_tip_withdraw, 0) > 0)
			reason.push(<b key={"reason_6"}>{lang.translate("deposit_confirmation_pending")}</b>);

		return (
			<Container className="tip">
				<div className="wrapper">
					{/* <header>
						<IconCa name={iconCaName["tip-send"]} />
						<h1>{lang.translate("tip")}</h1>
					</header> */}
					<main>
						<label>
							<b>1)</b>
							<b>{lang.translate("select_player")}</b>
						</label>
						<div className={`selectPlayer ${this.props.perms && this.props.perms.PLAYER_FIND ? "on" : "off"}`}>
							<SelectPlayer
								ref={(el) => withPerms(el, ["PLAYER_FIND"])}
								min={1}
								selected={this.state.playerSelected ? [this.state.playerSelected] : []}
								onSelect={this.onSelect}
								onUnselect={this.onUnselect}
							/>
						</div>
						<label>
							<b>2)</b>
							<b>{lang.translate("select_coin")}</b>
						</label>
						<Balance tipMode={true} style={{ width: "100%", marginBottom: "10px" }} onSelect={this.onBalanceSelect} />
						<label>
							<b>3)</b>
							<b>{lang.translate("tip_amount")}</b>
						</label>
						<Input
							id="amount"
							className="inpAmount"
							name="amount"
							value={this.state.amount}
							placeholder={lang.translate("amount")}
							validation="amount"
							// min={this.getMin()}
							// max={this.getMax()}
							toFixed={8}
							onFocus={(e) => e.target.select()}
							onChange={(e) => this.onChange(e, 0)}
							onBlur={(e) => this.onChange(e, 1)}
							// onWheel={(e, delta) => this.onChange(e, 0, delta)}
							disabled={disabled}
						/>
						<label>
							<b>{`${lang.translate("min")}:`}</b>
							<button onClick={(e) => [e.stopPropagation(), this.onMinClick(minAmount)]} className="btnLink">
								{minStr}
							</button>
							{this.state.coinSelected && (
								// <img
								// 	width={32}
								// 	height={32}
								// 	alt={this.state.coinSelected}
								// 	title={this.state.coinSelected}
								// 	src={`/assets/icons/${this.state.coinSelected}@2x.png`}
								// 	onError={(e) => {
								// 		if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
								// 	}}
								// />
								<ImageCached
									width={32}
									height={32}
									storageName="coin"
									url={`/assets/icons/${this.state.coinSelected}@2x.png`}
									alt={this.state.coinSelected}
									title={this.state.coinSelected}
									params={{}}
								/>
							)}
						</label>
						<label>
							<b>{`${lang.translate("max")} ${lang.translate("daily_count")}:`}</b>
							{this.state.loading ? (
								<b>0 / 0</b>
							) : (
								<>
									<b>{`${this.state.count} / ${this.state.maxDailyTotalCount}`}</b>
								</>
							)}
						</label>
						<label>
							<b>{`${lang.translate("max")} ${lang.translate("daily_total_amount")}:`}</b>
							{this.state.loading ? (
								<b>0$ / 0$</b>
							) : (
								<>
									<b>{`${this.state.totalUsd.toFixed(4)}$ / ${this.state.maxDailyTotalUsd}$`}</b>
								</>
							)}
						</label>
						<label>
							<b>4)</b>
							<b>{`${lang.translate("your_comment")} (${lang.translate("optional")})`}</b>
						</label>
						<Input
							id="comment"
							className="inpComment"
							name="comment"
							type="textarea"
							value={this.state.comment}
							placeholder={lang.translate("your_comment")}
							// validation=""
							// min={this.getMin()}
							// max={this.getMax()}
							// toFixed={8}
							onFocus={(e) => e.target.select()}
							onChange={(e) => this.onChange(e, 0)}
							onBlur={(e) => this.onChange(e, 1)}
							// onWheel={(e, delta) => this.onChange(e, 0, delta)}
							disabled={disabled}
						/>
						<label>
							<b>5)</b>
							<b>{lang.translate("let_others_know")}</b>
						</label>
						<div className="toggleWrapper">
							<Toggle disabled={disabled} value={true} onChange={this.onToggle} />
						</div>
					</main>
					<main>
						<div className="reason">{reason}</div>
						<div className="sendWrapper">
							<button onClick={(e) => [e.stopPropagation(), this.onTipSend()]} disabled={disabled || reason.length > 0} className="btnDefault warn">
								<IconCa name={iconCaName["tip-send"]} />
								<b>{lang.translate("send_tip")}</b>
							</button>
						</div>
					</main>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		rates: store.rates,
		balance: store.balance,
		meUn: store.player.userName,
		depBonus: store.playerConfig.depBonus,
	};
};

export default connect(a, null, null, { forwardRef: true })(Tip);
