const prefix = "cr_";
const isServer = typeof document === "undefined";
const helpers = {
	str: (v) => {
		return typeof v === "string";
	},
	arr: (v) => Array.isArray(v),
	bool: (v) => {
		return typeof v === "boolean";
	},
	strMinInt: (v, min = 0) => {
		return typeof v === "string" && v.length > 0 && v * 1 >= min && Math.round(v * 1) === v * 1;
	},
	minInt: (v, min = 0) => {
		return typeof v === "number" && v >= min && Math.round(v) === v;
	},
	int01: (v) => {
		return v === 0 || v === 1;
	},
	strMinAmount: (v, min = 0) => {
		return typeof v === "string" && v.length > 0 && v * 1 >= min;
	},
};
class Storage {
	set = (key, value) => {
		if (isServer) return;
		localStorage.setItem(prefix + key, JSON.stringify({ key: value }));
	};
	_get = (key) => {
		if (isServer) return null;
		const item = localStorage.getItem(prefix + key);
		let result = null;
		try {
			if (item) result = JSON.parse(item).key;
		} catch (err) {
			result = item == null ? null : item;
		}
		return result;
	};
	get = (key, defaultValue = null, checkF/**string */, args) => {
		if (isServer) return null;
		const v = this._get(key);
		if (v == null) {
			return defaultValue;
		} else {
			if (typeof checkF === "string" && typeof helpers[checkF] === "function") {
				// if (key==="lossesSkipCount") {
				//   console.log(v, checkF(v, args))
				// }
				if (helpers[checkF](v, args)) return v;
				else return defaultValue;
			} else {
				return v;
			}
		}
	};
	unset = (key) => {
		if (isServer) return;
		localStorage.removeItem(prefix + key);
	};
	clear = () => {
		if (isServer) return;
		localStorage.clear();
	};
}
const myStorage = new Storage();
export default myStorage;
export const storage = myStorage;
