/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Decimal from "decimal.js";
import Container from "../Container";
import Balance from "../balance/Balance";
import Input from "../../components/input/Input";
import lang from "../../classes/lang";
import ImageCached from "../../classes/cache";
import toast from "../../classes/toast";
import Axios from "../../classes/axios";
import "./_affBuy.scss";
import Loader from "../../components/loader/Loader";
import { closeModal, onResult, tryParseFloat } from "../../classes/utills";
import Toggle from "../../components/toggle/Toggle";
const BonusSpend = (props) => {
	BonusSpend.propTypes = {
		campaignId: PropTypes.number.isRequired,
		campaignName: PropTypes.string.isRequired,
		reload: PropTypes.func.isRequired,
		fsMode: PropTypes.bool.isRequired,
		bonusUsd: PropTypes.number.isRequired,
		wagerX: PropTypes.number.isRequired,
	};
	const { campaignId, campaignName, reload, bonusUsd } = props;
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(bonusUsd.toFixed(8));
	const [wagerX, setWagerX] = useState(props.wagerX.toFixed(2));
	const [fsMode, setFsMode] = useState(props.fsMode);
	const onChange = (e, i, delta) => {
		if (e.target.name === "amount") {
			setAmount(e.target.value);
		} else if (e.target.name === "wagerX") {
			setWagerX(e.target.value);
		}
	};
	const call = useCallback(async (o) => {
		const result = await Axios.post("/api/affPro/updateBonusSpend", o);
		return await onResult(result, "unexpected_error", async () => {
			toast.success(lang.translate("entry_updated"));
			await reload();
			closeModal();
		});
	}, []);
	const onCall = async () => {
		setLoading(true);
		const amt = tryParseFloat(amount, 0);
		await call({ amountUsd: amt, campaignId, fsMode, wagerX: tryParseFloat(wagerX, 0) });
		setLoading(false);
	};
	const onToggle = (e, name, v) => {
		setFsMode(v);
	};
	return (
		<Container className="affBuy" noDocs={true}>
			<h2>{lang.translate("bonus") + " (" + lang.translate("create_an_account") + ")"}</h2>
			<h3>{campaignName}</h3>
			<div className="wrapper">
				{loading && <Loader />}
				<label htmlFor="amount">
					<b>1) {lang.translate("amount")}$</b>
				</label>
				<Input
					id="amount"
					className="inpAmount"
					name="amount"
					value={amount}
					placeholder={lang.translate("amount")}
					validation="amount"
					toFixed={8}
					onFocus={(e) => e.target.select()}
					onChange={(e) => onChange(e, 0)}
					onBlur={(e) => onChange(e, 1)}
					disabled={loading}
				/>
				<label htmlFor="wagerX">
					<b>2) {lang.translate("wager")}X</b>
				</label>
				<Input
					id="wagerX"
					className="inpWagerX"
					name="wagerX"
					value={wagerX}
					placeholder={lang.translate("wager") + "X"}
					validation="amount"
					toFixed={8}
					onFocus={(e) => e.target.select()}
					onChange={(e) => onChange(e, 0)}
					onBlur={(e) => onChange(e, 1)}
					disabled={loading}
				/>
				<label htmlFor="amount">
					<b>3) {lang.translate("freespin")}</b>
				</label>
				<Toggle disabled={loading} value={fsMode} onChange={onToggle} />
				<button disabled={loading || amount === ""} onClick={(e) => [e.stopPropagation(), onCall()]} className="btnDefault warn btnBuy1">
					<b>{lang.translate("entry_update")}</b>
				</button>
			</div>
		</Container>
	);
};
export default BonusSpend;
