import React, { useRef, useEffect } from "react";
//other
import PropTypes from "prop-types";
import List from "../../../components/list/List";
//classes:
// import lang from "../../../classes/lang";
import { delay, getDayTimeFromTimestamp, onCopy } from "../../../classes/utills";
import { verify } from "../../../classes/crash";
import Avatar from "../../../components/avatar/Avatar";
import lang from "../../../classes/lang";
import { firstBy } from "thenby";
import ImageCached from "../../../classes/cache";
import { useSelector } from "react-redux";

const CrashGameInfo = ({ data }) => {
	const myUid = useSelector((store) => store.player.uid);
	// eslint-disable-next-line no-unused-vars
	const { bh, d, gh, o, pp, t, v, _id } = data;
	//pp - {uid: 60, short: 'shib', amt: 1e-8, win: true, pa: 2}
	// console.log("data", { bh, d, gh, o, pp, t, v, _id });
	const listRef = useRef();
	const arr = pp.map((p) => {
		const item = {
			id: `${_id}_${p.uid}`,
			cachedOutTim: p.cachedOutTim,
			tim: p.tim,
			c: (
				<div className={`entry ${myUid === p.uid ? "my" : "other"}`}>
					<Avatar uid={p.uid} img={p.img} userName={p.un} />
					<div className="bet">
						<div className="payout">{`${p.pa.toFixed(2)}x`}</div>
						{/* <img
              width={24}
              height={24}
              src={`/assets/icons/${p.short || ""}@2x.png`}
              alt={p.short}
              title={p.short}
              onError={(e) => {
                if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
              }}
            /> */}
						<ImageCached
							width={24}
							height={24}
							storageName="coin"
							url={`/assets/icons/${p.short}@2x.png`}
							alt={p.short}
							title={p.short}
							params={{}}
						/>
						<div className="amount">{(p.amt || 0).toFixed(12).substr(0, 10)}</div>
						<div className={`profit ${p.pr > 0 ? "green" : ""}`}>{(p.pr || 0).toFixed(12).substr(0, 10)}</div>
					</div>
				</div>
			),
		};
		return item;
	});
	arr.sort(
		firstBy(function (a, b) {
			return b.cachedOutTim - a.cachedOutTim;
		}).thenBy(function (a, b) {
			return b.tim - a.tim;
		})
	);
	useEffect(() => {
		delay(300).then(() => {
			listRef.current && listRef.current.refresh();
		});
	}, [arr]);
	return (
		<div className="crashGameInfo">
			<div className="row info">
				<div>
					<b>{lang.translate("game")}</b>
					<b className={`outcome ${o >= 2 ? "success" : o >= 1.5 ? "white" : "grey"}`}>{`${o.toFixed(2)}x`}</b>
				</div>
				<div>
					<b>{lang.translate("time")}</b>
					<b className="dt">{getDayTimeFromTimestamp(t)}</b>
				</div>
			</div>
			<div className="row pp">
				<List ref={(inst) => (listRef.current = inst)} data={arr} ren={true} />
			</div>
			<div className="row fair">
				<button onClick={(e) => [e.stopPropagation(), onCopy(bh)]} className="diceBtn yellow">
					<label>{lang.translate("block_hash")}</label>
					<b>{bh.StartEndOnly(4)}</b>
				</button>
				<button onClick={(e) => [e.stopPropagation(), onCopy(gh)]} className="diceBtn yellow">
					<label>{lang.translate("server_seed")}</label>
					<b>{gh.StartEndOnly(4)}</b>
				</button>
				<button onClick={(e) => [e.stopPropagation(), verify(gh, bh)]} className="diceBtn yellow">
					{lang.translate("verify")}
				</button>
			</div>
		</div>
	);
};
CrashGameInfo.propTypes = {
	data: PropTypes.object.isRequired,
};
export default CrashGameInfo;
