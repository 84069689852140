import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ToggleV extends PureComponent {
	static get propTypes() {
		return {
			className: PropTypes.string,
			visible: PropTypes.bool,
			onToggle: PropTypes.func,
			disabled: PropTypes.bool,
			value: PropTypes.number, //0,1
			id: PropTypes.string,
			captions: PropTypes.array,
			direction: PropTypes.number //0-vertical, 1-horizontal
		};
	}
	setVisible(visible) {
		if (arguments.length === 0) visible = !this.props.visible;
		this.setState({ visible });
		return this;
	}

	switch = (e, value) => {
		e.stopPropagation();
		if (typeof this.props.onToggle === "function") {
			this.props.onToggle(this.props.id, value);
		}
	};

	render() {
		if (this.props.visible === false) return null;
		return (
			<button
				className={`toggleV v${this.props.value} ${this.props.direction ? "h" : "v"} ${this.props.className ? this.props.className : ""} ${
					this.props.disabled ? "disabled" : ""
				}`}
				onClick={e => this.switch(e, 1 - this.props.value)}
			>
				<b className={`caption ${this.props.value === 0 ? "active" : "pasive"}`}> {this.props.captions[0]} </b>
				<b className={`caption ${this.props.value === 1 ? "active" : "pasive"}`}> {this.props.captions[1]} </b>
			</button>
		);
	}
}
