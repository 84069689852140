import React from "react";
import { useRef } from "react";
import lang from "../../classes/lang";
import Avatar from "../../components/avatar/Avatar";
import Form from "../../components/form/Form";
import Container from "../Container";
import PropTypes from "prop-types";
import Axios from "../../classes/axios";
import { onResult } from "../../classes/utills";
import toast from "../../classes/toast";
const AffToggle = (props) => {
	AffToggle.propTypes = {
		uid: PropTypes.number,
		userName: PropTypes.string,
		isPro: PropTypes.bool,
		affRate: PropTypes.number,
		carryForward: PropTypes.bool, 
	};
	const toggleProMode = async (o) => {
		o.targetUid = props.uid;
		o.affRate *= 1;
		const result = await Axios.post("/api/affPro/toggleProMode", o);
		return await onResult(result, "unexpected_error", () => {
			toast.success(lang.translate("entry_updated"));
		});
	};
	const frmRef = useRef();
	return (
		<Container className="afftoggle">
			<>
				<header>
					<h2>{lang.translate("affiliation")}</h2>
				</header>
				<main>
					<Avatar userName={props.userName} uid={props.uid} />
				</main>
				<main>
					<Form
						ref={(el) => (frmRef.current = el)}
						className="frmRef"
						data={[
							{
								type: "toggle",
								label: "isPro",
								on: "on",
								off: "off",
								value: props.isPro,
								tip: "Pro switches player from wager mode to the (deposit-withdraw-balance) * % affiliation mode",
							},
							{
								type: "input",
								value: props.affRate,
								min: 0,
								validation: "amount",
								label: "affRatePro",
								name: "affRate",
								required: true,
								style: { width: "100px" },
								tip: "Only valid for PRO mode",
							},
							{
								type: "toggle",
								label: "carryForward",
								on: "on",
								off: "off",
								value: props.carryForward,
								tip: "Carry Forward option takes into account all deposits/withdraws and deducts total claims, while Non Carry F. calculates from scratch after every claim",
							},
						]}
						buttons={[
							{
								label: "set",
								className: "warn",
								onClick: (e, o) => [e.stopPropagation(), toggleProMode(o)],
							},
						]}
					/>
				</main>
			</>
		</Container>
	);
};
export default AffToggle;
