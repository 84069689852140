import React from "react";
import { logout } from "./player";
import Toast from "./toast";
import lang from "./lang";
import { storeSet, store } from "../index";
import { chat } from "./chat";
import { isObject, updateOnlineMarks, getBuildVersion, logBanner, tryParseFloat } from "./utills";
import { friend } from "./friend";
import { _onWithdrawChange } from "./billing";
import { ping, toggleOnline } from "./socket";
import { gaInit } from "./ga";
import EventEmitter from "./eventEmitter";
import { sound } from "./sound";
import { autoCl } from "../containers/games/crash/CrashAutoClass";
import Decimal from "decimal.js";
import toast from "./toast";
import { BtnToast } from "../containers/App/App";
// import { cloneJsonMode } from "./utills";
const fixInfinity = (o) => {
	for (let key in o)
		if (typeof o[key] !== "undefined") {
			if (o[key]["GRANT"]) if (o[key]["GRANT"]["to"] === null) o[key]["GRANT"]["to"] = Infinity;
			if (o[key]["REVOKE"]) if (o[key]["REVOKE"]["to"] === null) o[key]["REVOKE"]["to"] = Infinity;
		}
	return o;
};
const modify = (o, defaultValue) => {
	if (isObject(o) && o.error) return defaultValue;
	return o;
};
let serverTim = null;
// eslint-disable-next-line no-unused-vars
let myUid = -1;
export const socketHandlers = {
	t: (o) => {
		if (typeof o === "object" && o !== null && typeof o.t !== "undefined") {
			storeSet({ serverTime: { $set: o.t } });
			if (serverTim) clearInterval(serverTim);
			serverTim = setInterval(() => {
				storeSet({
					serverTime: {
						$apply: function (x) {
							return x + 1000;
						},
					},
				});
			}, 1000);
			Math.round(o.t / 1000) % 5 === 0 && ping();
		}
	},
	online: (o) => {
		if (
			typeof o === "object" &&
			o !== null &&
			typeof o["GUESTS"] !== "undefined" &&
			typeof o["ONLINE"] !== "undefined" &&
			typeof o["AWAY"] !== "undefined" &&
			typeof o["BUSY"] !== "undefined" &&
			typeof o["OFFLINE"] !== "undefined" &&
			typeof o["uns"] !== "undefined" &&
			typeof o["rooms"] !== "undefined"
		) {
			storeSet({ online: { $set: o } });
			updateOnlineMarks({ online: o });
		}
	},
	offline: (o) => {
		storeSet({ offline: { $set: o } });
	},
	onInit: async (o) => {
		myUid = o.player.uid;
		const roomStrArr = (o.player.room || "0-en").split("-");
		const vipStr = roomStrArr[0] * 1 ? "vip" : "standard";
		const abbrevation = roomStrArr[1];
		const chatRoom = { ...o.chatRooms[vipStr][abbrevation] };
		const buildPresent = getBuildVersion();
		const toStore = {
			nodeId: { $set: o.nodeId },
			build: { $set: buildPresent }, // { $set: o.build },
			buildPresent: { $set: buildPresent },
			serverTime: { $set: o.t },
			myspinsCount: { $set: o.myspinsCount },
			contestsCount: { $set: o.contestsCount },
			admins: { $set: o.admins },
			mods: { $set: o.mods },
			online: { $set: o.online },
			offline: { $set: o.offline },
			player: { $set: o.player },
			coins: { $set: o.coins },
			chatRooms: { $set: o.chatRooms },
			chatRoomSelected: { $set: chatRoom },
			rates: { $set: o.rates },
			stable: { $set: o.stable },
			faucets: { $set: o.faucets },
			permissions: { $set: fixInfinity(o.permissions) },
			permissionsChanged: { $set: Date.now() },
			balance: { $set: o.balance },
			seeds: { $set: o.seeds },
			chatUnreadMessagesCount: { $set: o.chatUnreadMessagesCount },
			newsUnreadMessagesCount: { $set: o.newsUnreadMessagesCount },
			pmUnreadMessagesCount: { $set: o.pmUnreadMessagesCount },
			notificationsUnreadMessagesCount: { $set: o.notificationsUnreadMessagesCount },
			friends: { $set: modify(o.friends, null) },
			ranking: { $set: o.ranking },
			banners: { $set: o.banners },
			slideNavigation: { $set: o.slides },
			newsSlides: { $set: o.newsSlides },
			depositBonus: { $set: o.depBonus || {} },
			pandora: { $set: o.pandora },
			battleCount: { $set: o.battleCount },
			slots: { $set: o.slots },
			mySlotBets: { $set: [...(Array.isArray(o.mySlotBets) ? o.mySlotBets : []), ...(Array.isArray(o.myFlappyBets) ? o.myFlappyBets : [])] },
			flappySessions: { $set: Array.isArray(o.flappySessions) ? o.flappySessions : [] },
			rolls: { $set: o.rolls || [] },
			jackpots: { $set: o.jackpots },
			rainScores: { $set: o.rainScores },
			wheelPrizes: { $set: o.wheelPrizes },
			slotsNew: { $set: o.slots_new?.games ?? [] },
			slotsHot: { $set: o.slots_hot?.games ?? [] },
			slotsTrending: { $set: o.slots_trending?.games ?? [] },
			slotsChoice: { $set: o.slots_choice?.games ?? [] },
			flappyChoice: { $set: o.flappy_choice ?? [] },
			isVip: { $set: o.isVip ?? false },
			slotHR: {
				$set: {
					t: o.slotHR.t,
					arr: [...(o.slotHR.arr ?? []), ...(o.flappyHR.arr ?? [])],
				},
			},
			diceHR: { $set: o.diceHR },
			//---
			initFinished: { $set: true },
		};
		if (isObject(o.withdraws) && Array.isArray(o.withdraws.data)) toStore.withdraws = { $set: o.withdraws.data };
		if (isObject(o.cfg)) {
			// if (o.cfg.shortcut) toStore.shortcut = { $set: o.cfg.shortcut };
			toStore.playerConfig = { $set: o.cfg };
		}

		await storeSet(toStore);
		EventEmitter.emit("ON_CRASH", o.crash);
		chat.gotMessages({ chat: o.chat, news: o.news, notifications: o.notifications, pm: o.pm });
		const state = await store.getState();
		if (state.chat.location === "to") {
			await chat.setChatLocation("pm");
		} else {
			// console.log("updateOnlineMarks: ", state.online);
			updateOnlineMarks(state);
		}
		/* Identify a visitor */
		gaInit({
			user_id: o.player.uid,
			user_name: o.player.userName === "guest" ? `guest_${o.player.ip}` : o.player.userName,
			ip: o.player.ip,
			mail: o.player.mail || "N/A",
			level: o.player.level,
			aff_rate: o.player.affRate,
			dep_ratio: o.player.ratio_deposit,
			room: o.player.room,
			ref_count: o.player.refCount || 0,
		});
		const isDev = typeof document !== "undefined" && window.location.port * 1 === 3333;
		if (state.build !== state.buildPresent && !isDev) {
			toggleOnline(false, "socketHandlers>onInit");
		} else {
			toggleOnline(true, "socketHandlers>onInit");
		}
		// logBanner(`WINTOMATO build:${state.buildPresent}`, { color: "green", fontSize: 35 });
		logBanner(`WARNING! Do not use bot or any other script! You will be banned!`, { color: "yellow", background: "black", fontSize: 25 });
	},
	onR: (o) => {
		storeSet({
			rolls: { $set: o },
		});
	},
	onMessages: async (o) => {
		await storeSet({
			chatUnreadMessagesCount: { $set: o.chatUnreadMessagesCount },
			newsUnreadMessagesCount: { $set: o.newsUnreadMessagesCount },
			notificationsUnreadMessagesCount: { $set: o.notificationsUnreadMessagesCount },
			pmUnreadMessagesCount: { $set: o.pmUnreadMessagesCount },
		});
		chat.gotMessages({ chat: o.chat, news: o.news, notifications: o.notifications });
	},
	onLike: (o) => chat.onLike(o),
	onPermissionsChange: (permissions) => {
		fixInfinity(permissions);
		storeSet({
			permissions: { $set: permissions },
			permissionsChanged: { $set: Date.now() },
		});
	},
	onCoinsUpdate: (coins) => storeSet({ coins: { $set: coins } }),
	onRates: (o) => {
		typeof o === "object" &&
			o !== null &&
			storeSet({
				rates: { $set: o.rates },
				faucets: { $set: o.faucets },
				stable: { $set: o.stable },
			});
	},
	onFaucets: (o) => {
		typeof o === "object" &&
			o !== null &&
			storeSet({
				faucets: { $set: o },
			});
	},
	onLogout: (o) => {
		myUid = -1;
		if (o !== null && typeof o === "object" && o.message) Toast.warning(lang.translate(o.message));
		if (!location.hostname === "localhost") logout();
	},
	onMessage: async (o) => {
		// const clone = cloneJsonMode(o);
		// const state = await store.getState();
		chat.onMessage(o);
		// if (
		// 	clone.location === "notifications" ||
		// 	(clone.location !== "to" && Array.isArray(o.tags) && o.tags.find((el) => el.uid === state.player.uid))
		// ) {
		// 	clone.location = "notifications";
		// 	chat.onMessage(clone);
		// }
	},
	onFriend: async (o) => await friend.onFriend(o),
	onUnfriend: async (o) => await friend.onUnfriend(o),
	onIgnore: async (o) => await friend.onIgnore(o),
	onUnignore: async (o) => await friend.onUnignore(o),
	onPmRead: (o) => chat.onPmRead(o),
	onToast: ({ id, type = "success", message, options = {} }) => {
		message = Array.isArray(message) ? message : [message];
		message = message.map((el) => lang.translate(el));
		message = message.join("");
		if (id === "freespins_received") {
			toast[type](<BtnToast text={message} btnText={"freespins"} goLocation={"promotion/freespins"} />, options);
		} else {
			toast[type](message, options);
		}
	},
	onPropsSet: (o) => {
		const _o = {};
		for (let key in o) _o[key] = { $set: o[key] };
		storeSet(_o);
	},
	onSlotTr: (o) => {
		storeSet({
			mySlotBets: {
				$apply: function (arr) {
					return [o, ...arr].slice(0, 25);
				},
			},
		});
	},
	onDepBonus: (o) => {
		storeSet({
			playerConfig: {
				depBonus: {
					w: { $set: o.w },
				},
			},
		});
	},
	onPropsMerge: (o) => {
		const _o = {};
		for (let key in o) _o[key] = { $merge: o[key] };
		storeSet(_o);
	},
	onBAll: (o) => {
		storeSet({
			balance: { $set: o },
		});
	},
	onB: (o) => {
		storeSet({
			balance: { $merge: o },
		});
	},
	onStats: (o) => {
		storeSet({
			ostats: { $set: o },
		});
	},
	onFbiDice: (o) => EventEmitter.emit("FBI_DICE", o),
	onWithdrawChange: (o) => _onWithdrawChange(o),
	onCrash: (o) => {
		EventEmitter.emit("ON_CRASH", o);
		if (o.changed) {
			storeSet({ crashEvent: { $set: o.event } });
		}
	},
	onCrashClear: () => {
		storeSet({ crashPlayers: { $set: {} } });
	},
	onCrashBet: (o) => {
		storeSet({ crashPlayers: { [o.uid]: { $set: o } } });
	},
	onCrashMyBet: (o) => {
		EventEmitter.emit("ON_CRASH_BET", o); //{uid, short, amount, tim, win = false}
	},
	onCrashCashOut: (o) => {
		//here come also other in game players' cashouts:
		const state = store.getState();
		typeof window !== "undefined" &&
			window.location.pathname.includes("-crash") &&
			state.playerConfig?.crashSettings?.sound?.active &&
			sound.play("crash", "win");
		const prevBalance = tryParseFloat(state.balance?.[`balance_${o.short}`], 0);
		const balance = new Decimal(prevBalance).add(o.profit).toNumber();
		if (o.uid === state.player.uid) autoCl.onEvent({ balance, short: o.short, bet: o.amount, win: o.win });
		// EventEmitter.emit("ON_CRASH_CASH_OUT", o); //{uid, short, amount, tim, win = true}
		storeSet({ crashPlayers: { [o.uid]: { $set: o } } });
	},
	onCrashLose: (o) => {
		const state = store.getState();
		const prevBalance = tryParseFloat(state.balance?.[`balance_${o.short}`], 0);
		if (o.uid === state.player.uid) autoCl.onEvent({ balance: prevBalance, short: o.short, bet: o.amount, win: false });
	},
	onCrashGame: async (game) => {
		const crashHistory = (await store.getState()).crashHistory;
		crashHistory.unshift(game);
		storeSet({ crashHistory: { $set: crashHistory.slice(0, 35) } });
	},
	onAffPro_getData: (o) => EventEmitter.emit("ON_AFFPRO_GETDATA", o),
	onAffPro_claim: (o) => EventEmitter.emit("ON_AFFPRO_CLAIM", o),
	onBattle: (battle) => {
		//upsert battle entry or remove on error:
		if (!battle || battle.error) storeSet({ battlesO: { $unset: [battle.id] } });
		else storeSet({ battlesO: { [battle.id]: { $set: battle } } });
	},
};
