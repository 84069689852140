import React, { forwardRef } from "react";
import Container from "../Container";
const News = () => {
	return (
		<Container className="news">
			<div></div>
		</Container>
	);
};
export default forwardRef(News);
