import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import RotationWidget from "../../components/rotationWidget/RotationWidget";
import Tab from "../../components/tab/Tab";
import Avatar from "../../components/avatar/Avatar";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { getDayTimeFromTimestamp } from "../../classes/utills";
import ImageCached from "../../classes/cache";
//icons:
// import { Icon } from "react-icons-kit";
// eslint-disable-next-line no-unused-vars
import IconCa from "../../classes/iconCa";
import WidgetDice from "./WidgetDice";
import WidgetSlots from "./WidgetSlots";
// import WidgetCrash from "./WidgetCrash";
import { getStats } from "../../classes/games/dice";
// eslint-disable-next-line no-unused-vars
import BetList from "../../containers/BetList/BetList";
// import { infoCircle } from "react-icons-kit/fa/infoCircle";

class Widgets extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			tab: props.activeTab ?? "all_bets",
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			rolls: PropTypes.object,
			jackpots: PropTypes.array,
			coinSelected: PropTypes.string,
			initFinished: PropTypes.bool,
			mySlotBets: PropTypes.array,
			pathname: PropTypes.string,
			un: PropTypes.string,
			activeTab: PropTypes.string,
		};
	}
	manageArr = () => {
		const arr = [...(this.props.mySlotBets ?? []), ...(Widgets.rolls ?? [])];
		arr.sort((a, b) => b.tim - a.tim);
		return arr.slice(0,25);
	};
	componentDidUpdate(prevProps) {
		const check1 = this.props.initFinished !== prevProps.initFinished && this.props.initFinished;
		const check2 = this.props.pathname !== prevProps.pathname;
		if (check1 || check2) {
			getStats({ short: this.props.coinSelected }, async (data) => {
				if (data && !data.error) {
					Widgets.rolls = data.rolls.slice(0, 25);
					// Widgets.rolls.reverse();
				} else {
					getStats({ short: this.props.coinSelected }, async (data) => {
						if (data && !data.error) Widgets.rolls = data.rolls.slice(0, 25);
					});
				}
			});
		}
	}
	getJackpots = (arr) => {
		if (!Array.isArray(arr)) return [];
		return arr.map((el) => {
			return (
				<div key={`j_${el._id}`} className="wJackpot">
					<Avatar userName={el.un} uid={el.uid} img={el.img} size={24} />
					<div className="f1"></div>
					<div className="amount">
						<b>{el.amount.toFixed(12).substring(0, 10)}</b>
						{/* <img
							src={`/assets/icons/${el.short}@2x.png`}
							width="24"
							height="24"
							alt={el.short}
							title={el.short}
							onError={(e) => {
								if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							}}
						/> */}
						<ImageCached
							width={24}
							height={24}
							storageName="coin"
							url={`/assets/icons/${el.short}@2x.png`}
							alt={el.short}
							title={el.short}
							params={{}}
						/>
					</div>
					<div className="t">{getDayTimeFromTimestamp(el.t)}</div>
				</div>
			);
		});
	};
	getDiceRolls = (key, arr) => {
		if (!Array.isArray(arr)) return [];
		return arr.map((el) => {
			switch (el.game) {
				case "slots": {
					return (
						<Fragment key={`${key}__${el._id}`}>
							<WidgetSlots k_ey={key} el={el} un={this.props.un} />
						</Fragment>
					);
				}
				case "flappybet": {
					return (
						<Fragment key={`${key}__${el._id}`}>
							<WidgetSlots k_ey={key} el={el} un={this.props.un} />
						</Fragment>
					);
				}
				// case "crash": {
				// 	return (
				// 		<Fragment key={`${key}__${el._id}`}>
				// 			<WidgetCrash k_ey={key} el={el} un={this.props.un} />
				// 		</Fragment>
				// 	);
				// }
				default: {
					return (
						<Fragment key={`${key}__${el._id}`}>
							<WidgetDice k_ey={key} el={el} un={this.props.un} />
						</Fragment>
					);
				}
			}
		});
	};
	tabButton = (icon, title) => {
		return (
			<>
				{/* <IconCa name={icon} /> */}
				<b>{lang.translate(title)}</b>
			</>
		);
	};
	onTabClick = (tab) => {
		this.setState({ tab });
	};
	render() {
		const tabs = [
			{
				name: "my_bets",
				c: this.tabButton("", "my_bets"),
			},
			{
				// cl: "green",
				name: "all_bets",
				c: this.tabButton("dice", "all_bets"),
			},
			{
				// cl: "green",
				name: "high_rollers",
				c: this.tabButton("dice", "high_rollers"),
			},
			{
				name: "jackpots",
				c: this.tabButton("jackpot", "jackpots"),
			},
		];
		return (
			<div className="widgets">
				<Tab className="tabSimple" onClick={this.onTabClick} items={tabs} active={this.state.tab} showLine={false} />
				{/* {this.state.tab === "my_bets" &&  <BetList rolls={this.rolls.current ?? []} />} */}
				{this.state.tab === "my_bets" && (
					<div className="rotationWidget static">
						<main>{this.getDiceRolls("my_bets", this.manageArr())}</main>
					</div>
				)}
				{this.state.tab === "all_bets" && (
					<RotationWidget num={8} itemHeight={70} duration={10} dataArray={this.getDiceRolls("all", this.props.rolls.dice)} />
				)}
				{this.state.tab === "high_rollers" && (
					<RotationWidget num={8} itemHeight={70} duration={10} dataArray={this.getDiceRolls("hr", this.props.rolls.diceHr)} />
				)}
				{/* {this.state.tab === "jackpots" && <RotationWidget num={8} itemHeight={70} duration={10} dataArray={this.getJackpots(this.props.jackpots)} />} */}

				{this.state.tab === "jackpots" && (
					<div className="rotationWidget static">
						<main style={{padding: "0 30px"}}>{this.getJackpots(this.props.jackpots)}</main>
					</div>
				)}
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		rolls: store.rolls?.dice && store.rolls?.diceHr ? store.rolls : { dice: [], diceHr: [] },
		jackpots: store.jackpots,
		coinSelected: store.coin.selected,
		initFinished: store.initFinished,
		mySlotBets: store.mySlotBets,
		pathname: store.pathname,
		un: store.player.userName,
	};
};

export default connect(a, null, null, { forwardRef: true })(Widgets);
