/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useSelector } from "react-redux";
import Axios from "../../classes/axios";
import { onResult, isMobile, replaceAll, tryParseInt } from "../../classes/utills";
import lang from "../../classes/lang";
import Container from "../Container";
import SlotegratorGame from "./SlotegratorGame";
import Loader from "../../components/loader/Loader";
import Icon from "react-icons-kit";
import IconCa from "../../classes/iconCa";
import { star } from "react-icons-kit/fa/star";
import { ic_replay as iconLoadMore } from "react-icons-kit/md/ic_replay";
import Tab from "../../components/tab/Tab";
import Select from "../../components/select/Select";
import Input from "../../components/input/Input";
import LobbyGameSlider from "../Lobby/lobbyGames/LobbyGameSlider";
import { go } from "../../classes/routesLazy";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const throttle = require("lodash.throttle");
// eslint-disable-next-line no-unused-vars
const SlotegratorSrv = (props, ref) => {
	SlotegratorSrv.propTypes = {
		match: PropTypes.object,
	};
	const langRoute = useSelector((store) => store.langRoute);
	const tabNames = ["favourite", "all", "slots", "live", "board", "virt.sport"];
	const categoryUrl = props.match?.params?.category ?? "all";
	const providerUrl = props.match?.params?.provider ?? "all";
	const pageUrl = tryParseInt(props.match?.params?.page, 0);

	const slotsNew = useSelector((state) => state.slotsNew);
	const slotsHot = useSelector((state) => state.slotsHot);
	const slotsTrending = useSelector((state) => state.slotsTrending);
	const me = useSelector((state) => state.player.uid);
	const initFinished = useSelector((state) => state.initFinished);
	const pages = useRef(0);
	const params = useRef({ page: pageUrl, mobile: isMobile(), category: categoryUrl, provider: providerUrl });
	const games = useRef([]);
	const [game, setGame] = useState("");
	const providers = useRef([]);
	const [loading, setLoaing] = useState(false);
	const gamesGet = useCallback(
		async (o) => {
			const args = Object.assign(o, { page: pageUrl, category: categoryUrl, provider: providerUrl });
			if (args.category === "all") args.category = undefined;
			if (args.provider === "all") args.provider = undefined;

			setLoaing(true);
			const result = await Axios.post(`api/slotegrator/${me === -1 ? "gamesAllNotRestrictedPublic" : "gamesAllNotRestricted"}`, args);
			const data = await onResult(result, "unexpected_error");
			if (data && !data.error) {
				pages.current = data.pages;
				games.current = [...games.current, ...data.games];
				providers.current = data.providers;
				setLoaing(false);
			}
		},
		[me, pageUrl, categoryUrl, providerUrl]
	);

	useEffect(() => {
		if (!initFinished) return;
		gamesGet(params.current);
	}, [initFinished, categoryUrl, providerUrl, pageUrl]);
	// useEffect(() => {
	// 	if (!initFinished) return;
	// 	go(`crypto-slots/${categoryUrl}/${providerUrl}/${pageUrl}`);
	// }, [categoryUrl, pageUrl, initFinished]);

	function renderTabButton(icon, name) {
		return (
			<>
				<IconCa name={icon} />
				<b>{lang.translate(name)}</b>
			</>
		);
	}
	const tabs = useRef([
		{
			cl: "",
			width: 48,
			name: "favourite",
			c: <Icon icon={star} size={24} />,
		},
		{
			cl: "",
			name: "all",
			c: renderTabButton("home", "all"),
		},
		{
			cl: "",
			name: "slots",
			c: renderTabButton("slot", "slots"),
		},
		{
			cl: "",
			name: "live",
			c: renderTabButton("live", "live"),
		},
		{
			cl: "",
			name: "board",
			c: renderTabButton("chip", "board"),
		},
		{
			cl: "",
			name: "virt.sport",
			c: renderTabButton("064-videogame", "virtual_sport"),
		},
	]);

	const throttled = useCallback(throttle(gamesGet, 500), [me, gamesGet]);
	const [patternProviders, setPatternProviders] = useState("");
	const dataProviders = providers.current
		.filter((el) => el.name.toLowerCase().indexOf((patternProviders || "").toLowerCase()) >= 0)
		.map((el) => {
			const str = replaceAll(el.name, " ", "_");
			return { id: str, title: el.name, value: el.name };
		});
	const onGameSearch = (e) => {
		setGame(e.target.value);
		const v = e.target.value.length >= 3 ? e.target.value : undefined;
		const callFunc = params.current.name !== v;
		params.current.name = v;
		if (callFunc) {
			games.current = [];
			throttled(params.current);
		}
	};
	const showModeDisabled = typeof window === "undefined" ? false : loading || pages.current - 1 <= params.current.page;
	let nextPage = showModeDisabled ? pageUrl : pageUrl + 1;
	if (nextPage > 516) nextPage = 516;
	if (nextPage < 0) nextPage = 0;
	return (
		<Container className="slots">
			<>
				{slotsNew.length > 0 && (
					<LobbyGameSlider
						perPage={5}
						games={slotsNew}
						caption="new_games"
						sticker={{ backgroundColor: "limegreen", color: "black", caption: "NEW" }}
					/>
				)}
				<div className="slotTabs2 tt">
					<Tab
						onClick={(_tab) => [(games.current = []), go(`crypto-slots/${_tab}/${providerUrl}/0`)]}
						items={tabs.current}
						active={categoryUrl}
						showLine={true}
					/>

					<div className="slotSearch">
						<Select
							placeholder={lang.translate("game_provider")}
							style={{ minWidth: "170px" }}
							caseSearch={false}
							valueHeader={false}
							valueBody={false}
							onSearch={(el) => setPatternProviders(el)}
							onSelect={(item) => [(games.current = []), go(`crypto-slots/${categoryUrl}/${item.value}/0`)]}
							onUnselect={() => [(games.current = []), go(`crypto-slots/${categoryUrl}/all/0`)]}
							multi={false}
							selected={providerUrl}
							data={dataProviders}
						/>
						<Input
							className="inpGameSearch"
							placeholder={lang.translate("search")}
							disabled={false}
							type="text"
							name="game"
							value={game}
							onChange={onGameSearch}
						/>
					</div>
				</div>
				{categoryUrl === "favourite" && <h2 className="h2games">{lang.translate("my_favourite_games")}</h2>}
				<div className="wrapper">
					{loading && <Loader />}
					<div className="games" data-name="slots">
						{games.current.map((game, i) => (
							<SlotegratorGame key={i} game={game} i={i} />
						))}
					</div>
				</div>
				<div className="showMore">
					<Link to={`/${langRoute}/crypto-slots/${categoryUrl}/${providerUrl}/${nextPage}`}>
						<button
							// onClick={(e) => [e.stopPropagation(), onShowMore()]}
							className="diceBtn yellow"
							disabled={showModeDisabled}
						>
							<Icon icon={iconLoadMore} size={24} />
							<b>{lang.translate("show_more")}</b>
						</button>
					</Link>
				</div>
				{slotsHot.length > 0 && (
					<LobbyGameSlider perPage={6} games={slotsHot} caption="hot_games" sticker={{ backgroundColor: "red", color: "white", caption: "HOT" }} />
				)}
				{slotsTrending.length > 0 && (
					<LobbyGameSlider
						perPage={6}
						games={slotsTrending}
						caption="trending_games"
						sticker={{ backgroundColor: "goldenrod", color: "black", caption: "TRENDING" }}
					/>
				)}
			</>
		</Container>
	);
};
export default forwardRef(SlotegratorSrv);
