import { useEffect, useState, useCallback, useMemo, useRef } from "react";

const useIframeHeight = ({ refIframe, url }) => {
	const interval = useRef(null);
	const isFixed = useMemo(() => {
		if (!url) return true;
		return url.indexOf("fixed=1") !== -1;
	}, [url]);
	const [iframeHeight, setIframeHeight] = useState("0px");
	const onGameEvent = useCallback((event) => {
		if (event.data && event.data.EventName === "H" && event.data.Data.H) setIframeHeight(event.data.Data.H + "px");
	}, []);
	useEffect(() => {
		url && window.addEventListener("message", onGameEvent, false);
		return () => {
			window.removeEventListener("message", onGameEvent, false);
		};
	}, [onGameEvent, url]);
	useEffect(() => {
		if (!isFixed) {
			interval.current = setInterval(() => {
				refIframe?.current?.contentWindow?.postMessage({ EventName: "ON_H", Data: {} }, "*");
			}, 100);
		}
		return () => interval.current && clearInterval(interval.current);
	}, [isFixed]);

	return {
		iframeHeight,
		isFixed,
	};
};

export default useIframeHeight;
