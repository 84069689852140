import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//redux
import { connect } from "react-redux";
//icons
import { Icon } from "react-icons-kit";
import { ic_chat } from "react-icons-kit/md/ic_chat";
//classes
import { chat } from "../../classes/chat";
class ChatButton extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}
	static get propTypes() {
		return {
			chatLeft: PropTypes.bool,
			chatOpen: PropTypes.bool,
			chatUnreadMessagesCount: PropTypes.number,
		};
	}
	toggleChatPaneClick = async (e) => {
		if (e) e.stopPropagation();
		await chat.toggleChatPane();
	};
	render() {
		return (
			<div className={`chatButton ${this.props.chatOpen ? "off" : "on"}`}>
				<button onClick={this.toggleChatPaneClick} className="btnIcon large transparent01">
					<div className={`count ${this.props.chatUnreadMessagesCount ? "on" : "off"}`}>
						<b>{this.props.chatUnreadMessagesCount}</b>
					</div>
					<Icon icon={ic_chat} />
				</button>
			</div>
		);
	}
}

const a = (store) => {
	return {
		lang: store.lang.key,
		chatUnreadMessagesCount: store.chatUnreadMessagesCount,
		theme: store.theme.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(ChatButton);
