import { storeSet } from "../index";
import Toast from "./toast";
import lang from "./lang";
// import Axios from "./axios";
import { tokenWrap, onResult } from "./utills";
let instance = null;
class Room {
	constructor() {
		if (instance) return instance;
		instance = this;
		this.time = new Date();
	}
	action(event, o, cb) {
		const message = {
			title: {
				add: "entry_add",
				update: "entry_update",
				remove: "entry_remove",
			},
			response: {
				add: "entry_added",
				update: "entry_updated",
				remove: "entry_removed",
			},
		};

		if (event !== "remove" && (!o.abbrevation || !o.room)) {
			Toast.error(lang.translate("field_blank"));
			if (typeof cb === "function") return cb({ error: true });
		}
		const title = `${lang.translate(message.title[event])} ${o.abbrevation}${o.vip ? " vip" : ""} ?`;
		tokenWrap({ title, path: `api/chatRoom/${event}`, o }, async (result) => {
			result = await onResult(result, "unexpected_error", async (chatRooms) => {
				Toast.success(lang.translate(message.response[event]));
				await storeSet({ chatRooms: { $set: chatRooms } });
				return result;
			});
			if (typeof cb === "function") return cb(result);
			else return;
		});
	}
}
export const getRoomStr = (vip, abbrevation) => `${vip ? 1 : 0}-${abbrevation.toLowerCase()}`;
export const room = new Room();
