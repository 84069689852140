import React, { Component } from "react";
import PropTypes from "prop-types";
//components:
import Select from "../../components/select/Select";
import { RadialProgress } from "react-radial-progress-indicator";
//providers:
// import Storage from "../../classes/storage";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../index";
//classes:
import { lang } from "../../classes/lang";
// import { chooseValue } from "../../classes/utills";
import { clickOutside } from "../../classes/utills";
import { go } from "../../classes/routesLazy";
import Decimal from "decimal.js";
//icons:
import { Icon } from "react-icons-kit";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import { checkSquareO } from "react-icons-kit/fa/checkSquareO";
import { squareO } from "react-icons-kit/fa/squareO";
import Tab from "../../components/tab/Tab";

class Balance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pattern: "",
			coinSelected: this.props.selected || null, //not for balance type
		};
	}
	static get propTypes() {
		return {
			selected: PropTypes.string,
			save: PropTypes.bool, //default false
			faucetMode: PropTypes.bool, //default false (only show faucet enabled coins)
			tipMode: PropTypes.bool, //default false (only show tip enabled coins)
			depositMode: PropTypes.bool, //default false (only show deposit enabled coins)
			withdrawMode: PropTypes.bool, //default false (only show withdraw enabled coins)
			rainMode: PropTypes.bool, //default false (only show rain enabled coins)
			bankMode: PropTypes.bool,

			onSelect: PropTypes.func,
			lang: PropTypes.string,
			coins: PropTypes.object,
			coinSelected: PropTypes.string,
			style: PropTypes.object,
			balance: PropTypes.object,
			balancePositive: PropTypes.bool, //default false
			depBonus: PropTypes.object,
			theme: PropTypes.string,
			rates: PropTypes.object,
			balanceFiat: PropTypes.string,
			serverTime: PropTypes.number,
			siteSettings: PropTypes.object,
			simpleBalanceDefault: PropTypes.bool,
			customToggleIcon: PropTypes.any,
		};
	}
	componentDidMount = () => {
		this.setState({ data: this.toCoinArr() });
	};
	onSelect = (item) => {
		if (this.props.save === true) {
			storeSet({ coin: { selected: { $set: item.id } } }, ["coin"]);
		} else {
			//storeSet({ coin: { selected: { $set: item.id } } });
			this.setState({ coinSelected: item.id });
			if (typeof this.props.onSelect === "function") this.props.onSelect({ short: item.id });
		}
	};
	onSearch = (pattern) => {
		this.setState({ pattern });
	};
	getSelected = () => {
		if (this.props.save) return this.props.coinSelected;
		return this.state.coinSelected;
	};
	toCoinArr = () => {
		const data = [];
		for (let key in this.props.coins) {
			const coin = this.props.coins[key];
			if (!coin.active) continue;
			if (this.props.faucetMode === true && !coin.faucet) continue;
			if (this.props.tipMode === true && !coin.tips_send) continue;
			if (this.props.depositMode === true && !coin.deposits) continue;
			if (this.props.withdrawMode === true && !coin.withdraws) continue;
			if (this.props.rainMode === true && !coin.rain) continue;
			let inlineKey = "balance";
			if (this.props.bankMode || this.state.balanceTab === "bank") inlineKey = "bank";
			const amt = this.props.balance[`${inlineKey}_${coin.short}`] || "0.00000000"; //balance_btc, bank_btc
			data.push({
				title: coin.short,
				icon: `/assets/icons/${this.props.balanceFiat || coin.short}@2x.png`,
				coinValue: typeof amt === "undefined" ? "0.0" : amt,
				priority: coin.priority || 50,
				value:
					typeof amt === "undefined" ? "0.0" : this.props.balanceFiat ? new Decimal(amt).mul(this.props.rates[coin.short] || 0).toFixed(12) : amt,
			});
			data.sort((a, b) => b.priority - a.priority);
		}
		return data.filter(
			(el) =>
				el.title === this.getSelected() ||
				(el.title.indexOf(this.state.pattern) !== -1 && (this.props.balancePositive ? parseFloat(el.coinValue) > 0.000000009 : true))
		);
	};
	onTabClick = (name) => {
		this.setState({ balanceTab: name });
	};
	renderOtherContentTop = () => {
		let containsFiat = false;
		for (let el in this.props.coins) {
			if (this.props.coins[el].isFiat && this.props.coins[el].active) {
				containsFiat = true;
			}
		}
		const tabs = [
			{
				// cl: "green",
				name: "balance",
				c: <b>{lang.translate("crypto")}</b>,
			},
			{
				name: "bank",
				c: <b>{lang.translate("bank")}</b>,
			},
		];
		if (containsFiat) {
			tabs.unshift({
				name: "cash",
				c: <b>{lang.translate("cash")}</b>,
			});
		}
		let tabActive = this.props.bankMode
			? "bank"
			: this.state.balanceTab || (this.props.coinSelected && this.props.coins[this.props.coinSelected]?.isFiat ? "cash" : "balance");

		return <Tab onClick={this.onTabClick} items={tabs} active={tabActive} />;
	};
	renderOtherContent = () => {
		const o = this.props.depBonus;
		let progress = 0;
		if (o?.wagerUsd === 0) {
			progress = 100;
		} else {
			if (!!o && typeof o.w !== "undefined" && o.wagerUsd * 1 > 0) progress = (o.w / o.wagerUsd) * 100;
		}
		return (
			<>
				{this.props.depBonus?.active ? (
					<button onClick={(e) => [e.stopPropagation(), clickOutside(), go("promotion/deposit_bonus")]} className="diceBtn black progress">
						<RadialProgress
							text={function text(steps, proportion) {
								const step = Math.floor(steps * proportion);
								return "".concat(step * 2, "/").concat(steps * 2);
							}}
							fontRatio={4.5}
							// ringBgColour={chooseValue(this.props.theme, { dark: "lime" }, "#fff")}
							// ringFgColour={chooseValue(this.props.theme, { dark: "lightgreen" }, "#000")}
							ringBgColour="#000"
							ringFgColour="#00c108"
							width={60}
							height={60}
							steps={50}
							duration={0}
							step={o.active ? Math.floor(progress / 2) : 0}
						/>
						<div className="vvv">
							<b className="pending">{`${lang.translate("pending")}: ${o.bonusUsd}$`}</b>
							<b>{o.desc}</b>
							<b className="gold">{(o.w * 1 || 0).toFixed(4)}$</b>
						</div>
						<div className="iconArrow">
							<Icon icon={chevronRight} size={16} />
						</div>
					</button>
				) : null}
				<button
					onClick={(e) => [e.stopPropagation(), storeSet({ balancePositive: { $set: !this.props.balancePositive } })]}
					className="diceBtn onlyWithBalance"
				>
					<b>{lang.translate("only_with_balance")}</b>
					<Icon icon={this.props.balancePositive ? checkSquareO : squareO} size={24} />
				</button>
				<button
					onClick={(e) => [e.stopPropagation(), storeSet({ balanceFiat: { $set: this.props.balanceFiat ? null : "usd" } })]}
					className="diceBtn inUsd"
				>
					<b>{lang.translate("show_in", "$")}</b>
					<Icon icon={this.props.balanceFiat ? checkSquareO : squareO} size={24} />
				</button>
			</>
		);
	};
	renderTimeLeft = () => {
		const start = this.props.rates._t;
		const now = this.props.serverTime;
		if (!start || !now) return null;
		const duration = 60000;
		const steps = 100;
		const cycle = duration / steps;
		const v = Math.round((now - start) / cycle);
		const w = ((v > steps ? steps : v) / steps) * 100 + "%";
		// return <progress value={v > steps ? steps : v} max={steps}></progress>;
		return <div className="fill" style={{ width: w }}></div>;
	};
	render() {
		if (!this.props.coins) return null;
		// console.log("b:", this.props.balance);
		const st = this.props.style ? this.props.style : {};
		const simpleBalance =
			(this.props.siteSettings && this.props.siteSettings.custom && this.props.siteSettings.custom.simple_balance) || this.props.simpleBalanceDefault;
		let coinArr = this.toCoinArr(); //all coins
		if (
			this.state.balanceTab === "cash" ||
			(!this.state.balanceTab && this.props.coinSelected && this.props.coins?.[this.props.coinSelected]?.isFiat)
		) {
			//cash mode:
			coinArr = coinArr.filter(
				(el) =>
					this.props.coins[el.title]?.isFiat || this.state.coinSelected === el.title || (this.props.save && this.props.coinSelected === el.title)
			);
		} else if (!this.props.bankMode && this.state.balanceTab !== "bank") {
			//balance (coin) mode:
			coinArr = coinArr.filter(
				(el) =>
					!this.props.coins[el.title]?.isFiat || this.state.coinSelected === el.title || (this.props.save && this.props.coinSelected === el.title)
			);
		}
		let headerLabel;
		if (this.props.save) {
			//main balance:
			headerLabel = this.props.coinSelected && this.props.coins ? this.props.coins?.[this.props.coinSelected]?.name : undefined;
		} else {
			//balances in other parts of pages
			headerLabel = this.state.coinSelected && this.props.coins ? this.props.coins?.[this.state.coinSelected]?.name : undefined;
		}
		return (
			<div className="balance">
				{this.props.balanceFiat && <div className="rateSlider">{this.renderTimeLeft()}</div>}
				{/* width: "216px", */}
				<Select
					style={{ minWidth: "270px", ...st }}
					placeholder={lang.translate("coin")}
					min={this.props.save ? 1 : 0}
					multi={false}
					titleHeader={false}
					caseSearch={false}
					rounding={12}
					trimAfterDot={8}
					digitHeader={!simpleBalance}
					selected={[this.getSelected()]}
					onSelect={this.onSelect}
					// onUnselect={this.onUnselect}
					onSearch={this.onSearch}
					data={coinArr}
					otherContent={this.renderOtherContent()}
					otherContentTop={this.renderOtherContentTop()}
					headerLabel={headerLabel}
					customToggleIcon={this.props.customToggleIcon}
				/>
			</div>
		);
	}
}
const a = (store) => {
	const o = {
		coins: store.coins,
		coinSelected: store.coin.selected,
		lang: store.lang.key,
		balance: store.balance,
		balancePositive: store.balancePositive,
		balanceFiat: store.balanceFiat,
		depBonus: store.playerConfig.depBonus,
		rates: store.rates,
		theme: store.theme.key,
		siteSettings: store.playerConfig.siteSettings,
		simpleBalanceDefault: store.playerConfigDefaults.siteSettings.custom.simple_balance,
	};
	if (store.balanceFiat) o.serverTime = store.serverTime;
	return o;
};

export default connect(a, null, null, { forwardRef: true })(Balance);
