/* eslint-disable no-unused-vars */
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//containers:
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import GSAP, { Linear } from "gsap";
// import { delay } from "../../classes/utills";
//icons:
// import { Icon } from "react-icons-kit"
class RotationWidget extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.dataArray = [];
		this.num = props.num || 10;
		this.itemHeight = props.itemHeight || 40;
		this.items = [];
		this.tweens = [];
		this.ref = React.createRef();
		this.tmt = null;
	}
	static get propTypes() {
		return {
			duration: PropTypes.number,
			num: PropTypes.number,
			itemHeight: PropTypes.number,
			caption: PropTypes.string,
			dataArray: PropTypes.array,
			//---
			lang: PropTypes.string,
		};
	}
	onMouseEnter = () => {
		for (let tween of this.tweens) {
			tween.pause();
		}
		this.tmt = setTimeout(() => {
			for (let tween of this.tweens) {
				tween.resume();
			}
		}, 2000);
	};
	onMouseLeave = () => {
		for (let tween of this.tweens) tween.resume();
	};
	componentWillUnmount = () => {
		this.tmt && clearTimeout(this.tmt);
		for (let tween of this.tweens) {
			tween.kill();
		}
		this.ref.current?.removeEventListener("mouseenter", this.onMouseEnter);
		this.ref.current?.removeEventListener("mouseleave", this.onMouseLeave);
	};
	componentDidMount = () => {
		this.dataArray = [...this.props.dataArray];
		let duration = this.props.duration;
		for (let i = 0; i < this.num + 1; i++) {
			this.takeData(i);
			this.tweens[i] = GSAP.fromTo(
				this.items[i].c,
				{
					y: -this.itemHeight,
				},
				{
					duration,
					repeat: -1,
					ease: Linear.easeNone,
					y: this.num * this.itemHeight,
					modifiers: {
						y: function (y) {
							return Math.round(parseFloat(y)) + "px";
						},
					},
					onRepeat: () => {
						this.takeData(i);
					},
				}
			);
			this.tweens[i].pause((duration / (this.num + 1)) * i);
		}
		for (let tween of this.tweens) tween.resume();

		this.ref.current?.addEventListener("mouseenter", this.onMouseEnter);
		this.ref.current?.addEventListener("mouseleave", this.onMouseLeave);
	};
	takeData = (i) => {
		const el = this.dataArray.length > 0 ? this.dataArray.pop() : null;
		if (!el) return;
		this.items[i].content = el;
		ReactDOM.render(el, this.items[i].c); // this.items[i].content;
	};
	renderMain = () => {
		return Array(this.num + 1)
			.fill()
			.map((_, i) => {
				return (
					<div
						ref={(el) => (this.items[i] = { c: el, content: `` })}
						id={`i${i}`}
						key={i}
						className="item"
						style={{
							height: `${this.itemHeight}px`,
							transform: `translateY(${(i - 1) * this.itemHeight}px)`,
							// backgroundColor: `rgb(50,${0 + i * 10},50)`,
						}}
					></div>
				);
			});
	};
	componentDidUpdate = (prevProps) => {
		const arrChanged = this.props.dataArray !== prevProps.dataArray;
		if (arrChanged) {
			this.dataArray = [...this.props.dataArray];
			if (prevProps.dataArray.length === 0) {
				for (let i = 0; i < this.num + 1; i++) this.takeData(i);
			}
		}
	};
	render() {
		return (
			<section ref={this.ref} className="rotationWidget">
				{this.props.caption && this.props.caption.length > 0 && <header>{lang.translate(this.props.caption || "")}</header>}
				<main style={{ height: `${this.num * this.itemHeight}px` }}>{this.renderMain()}</main>
			</section>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(RotationWidget);
