/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Decimal from "decimal.js";
import Container from "../Container";
import Balance from "../balance/Balance";
import Input from "../../components/input/Input";
import lang from "../../classes/lang";
import ImageCached from "../../classes/cache";
import toast from "../../classes/toast";
import Axios from "../../classes/axios";
import "./_affBuy.scss";
import Loader from "../../components/loader/Loader";
import { closeModal, onResult, tryParseFloat } from "../../classes/utills";
const AffBuy = (props) => {
	AffBuy.propTypes = {
		campaignId: PropTypes.number.isRequired,
		campaignName: PropTypes.string.isRequired,
		reload: PropTypes.func.isRequired,
	};
	const { campaignId, campaignName, reload } = props;
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState("");
	const [coinSelected, setCoinSelected] = useState();
	const onBalanceSelect = ({ short }) => {
		setCoinSelected(short);
	};
	const onChange = (e, i, delta) => {
		if (e.target.name === "amount") {
			setAmount(e.target.value);
		} 
	};
	const buy = useCallback(async (o) => {
		const result = await Axios.post("/api/affPro/addBonusBalance", o);
		return await onResult(result, "unexpected_error", async () => {
			toast.success(lang.translate("entry_updated"));
			await reload();
			closeModal();
		});
	}, []);
	const onBuy = async () => {
		setLoading(true);
		var amt = tryParseFloat(amount, 0);
		await buy({ short: coinSelected, amount: amt, campaignId });
		setLoading(false);
	};
	return (
		<Container className="affBuy" noDocs={true}>
			<h2>{lang.translate("add_balance")}</h2>
			<h3>{campaignName}</h3>
			<div className="wrapper">
				{loading && <Loader />}
				<Balance style={{ width: "100%", marginBottom: "10px" }} onSelect={onBalanceSelect} />
				<label htmlFor="amount">
					<b>{lang.translate("amount")}</b>
				</label>
				<Input
					id="amount"
					className="inpAmount"
					name="amount"
					value={amount}
					placeholder={lang.translate("amount")}
					validation="amount"
					toFixed={8}
					onFocus={(e) => e.target.select()}
					onChange={(e) => onChange(e, 0)}
					onBlur={(e) => onChange(e, 1)}
					disabled={loading}
				/>
				{/* <label>
					<b>{`${lang.translate("min")}:`}</b>
					<button onClick={(e) => [e.stopPropagation(), this.onMinClick(minAmount)]} className="btnLink">
						{minStr}
					</button>
					{coinSelected && (
						<ImageCached
							width={32}
							height={32}
							storageName="coin"
							url={`/assets/icons/${coinSelected}@2x.png`}
							alt={coinSelected}
							title={coinSelected}
							params={{}}
						/>
					)}
				</label> */}
				<button disabled={loading || amount < 0.00000001} onClick={(e) => [e.stopPropagation(), onBuy()]} className="btnDefault warn btnBuy1">
					<b>{lang.translate("buy")}</b>
				</button>
			</div>
		</Container>
	);
};
export default AffBuy;
