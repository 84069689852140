import React, { PureComponent } from "react";
//containers:
import Container from "../Container";
import PropTypes from "prop-types";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
//icons:
import { Icon } from "react-icons-kit";
import { questionCircle } from "react-icons-kit/fa/questionCircle";
import Input from "../../components/input/Input";

class Prompt extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			comment: "",
		};
	}
	static get propTypes() {
		return {
			f: PropTypes.func.isRequired,
			title: PropTypes.string,
			content: PropTypes.element,
			hasComment: PropTypes.bool,
			yesHide: PropTypes.bool,
			yesTitle: PropTypes.string,
			noTitle: PropTypes.string,
			icon: PropTypes.element,
			//redux:
			lang: PropTypes.string,
		};
	}
	onSubmit = (e) => {
		if (e) e.stopPropagation();
		const other = {};
		if (this.props.hasComment) other.comment = this.state.comment;
		this.props.f(true, other);
	};
	onCancel = (e) => {
		if (e) e.stopPropagation();
		this.props.f(false);
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	render() {
		return (
			<Container noDocs={true}>
				<div className="prompt">
					<div>
						{this.props.title && (
							<div className="title">
								<div className="icon">
									<Icon icon={this.props.icon || questionCircle} size={22} />
								</div>
								<h2>{this.props.title}</h2>
							</div>
						)}
						{this.props.content && <div className="row">{this.props.content}</div>}
						{this.props.hasComment && (
							<div className="row">
								<Input name="comment" type="textarea" onChange={this.onChange} placeholder={lang.translate("comment")} />
							</div>
						)}
						<div className="row">
							{!(this.props.yesHide === true) && (
								<button onClick={this.onSubmit} className="btnDefault success">
									<label>{lang.translate(typeof this.props.yesTitle === "string" ? this.props.yesTitle : "yes")}</label>
								</button>
							)}
							<button onClick={this.onCancel} className="btnDefault">
								<label>{lang.translate(typeof this.props.noTitle === "string" ? this.props.noTitle : "no")}</label>
							</button>
						</div>
					</div>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(Prompt);
