import Axios from "./axios";
import { onResult, tokenWrap } from "./utills";
import Toast from "./toast";
import lang from "./lang";
import { store } from "../index";

export const changeBalance = async ({ short, uid, delta, comment, token }) => {
	const result = await Axios.post("/api/balance/changeBalance", { short, uid, delta, comment, token });
	return await onResult(result, "unexpected_error", () => {
		Toast.success(lang.translate("balance_changed"));
		return result;
	});
};
export const changeBank = async ({ short, uid, delta, comment, token }) => {
	const result = await Axios.post("/api/balance/changeBank", { short, uid, delta, comment, token });
	return await onResult(result, "unexpected_error", () => {
		Toast.success(lang.translate("balance_changed"));
		return result;
	});
};
export const claimFaucet = async (short) => {
	const result = await Axios.post("/api/faucet/claim", { short });
	return await onResult(result, "unexpected_error", () => {
		Toast.success(lang.translate("faucet_claimed"));
		return result;
	});
};
export const getFaucetData = async () => {
	const result = await Axios.post("/api/formula/getData", { setting: "faucet" });
	return await onResult(result, "unexpected_error");
};
export const getFaucetStatus = async (short) => {
	if (typeof document === "undefined") return;
	const result = await Axios.post("/api/faucet/status", { short });
	return await onResult(result, "unexpected_error");
};
// export const setFaucetData = async (formula = "") => {
//   const result = await Axios.post("/api/faucet/change", { formula });
//   return await onResult(result, "unexpected_error", () => {
//     Toast.success(lang.translate("entry_updated"));
//     return result;
//   });
// };
export const setFaucetData = async (formula = "", cb) => {
	const title = lang.translate("are_you_sure");
	tokenWrap({ title, path: "/api/formula/change", o: { setting: "faucet", formula } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("entry_updated"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const getSelectedCoinBalance = async () => {
	const state = await store.getState();
	let coin = state.coin.selected;
	if (typeof state.balance[`balance_${coin}`] === "string") return parseFloat(state.balance[`balance_${coin}`]);
	return 0;
};
export const getActions = async ({ userName }) => {
	const result = await Axios.post("/api/balance/getActions", { userName });
	return await onResult(result, "unexpected_error");
};
export const getExchangeData = async () => {
	const result = await Axios.post("/api/formula/getData", { setting: "exchange" });
	return await onResult(result, "unexpected_error");
};
export const setExchangeData = async (formula = "", cb) => {
	const title = lang.translate("are_you_sure");
	tokenWrap({ title, path: "/api/formula/change", o: { setting: "exchange", formula } }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			Toast.success(lang.translate("entry_updated"));
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const doExchange = async ({ from, to, amount, calc }) => {
	const state = await store.getState();
	if (state.player.uid === -1) {
		Toast.error(lang.translate("not_logged_in"));
		return { error: true, message: "not_logged_in" };
	}
	const result = await Axios.post("/api/exchange/doExchange", { from, to, amount, calc });
	return await onResult(result, "unexpected_error", (result) => {
		if (!calc) Toast.success(`${lang.translate("exchange_success")} - [${from}] > [${to}]`);
		return result;
	});
};
