import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
import { storeSet } from "../../..";
//components:
import List from "../../../components/list/List";
import Input from "../../../components/input/Input";
import Lottie from "../../../components/lottie/Lottie";

//classes:
import { lang } from "../../../classes/lang";
import { chat } from "../../../classes/chat";
import { isMobile } from "../../../classes/utills";
//icons:
import { Icon } from "react-icons-kit";
import { sendO } from "react-icons-kit/fa/sendO";
import { ic_delete_forever as clear } from "react-icons-kit/md/ic_delete_forever";
// import { command } from "react-icons-kit/icomoon/command";
// import { at } from "react-icons-kit/fa/at";
import { ic_face as smileIcon } from "react-icons-kit/md/ic_face";
import { getPhoto } from "../../../classes/player";

class ChatMessages extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
		this.textarea = null;
		this.listRef = React.createRef();
		this.anim = null;
	}
	static get propTypes() {
		return {
			toggleOverlay: PropTypes.func.isRequired,
			chatInput: PropTypes.string.isRequired,
			//--
			chatReadMode: PropTypes.bool.isRequired,
			chatUnreadMessagesCount: PropTypes.number.isRequired,
			newsReadMode: PropTypes.bool.isRequired,
			newsUnreadMessagesCount: PropTypes.number.isRequired,
			notificationsReadMode: PropTypes.bool.isRequired,
			notificationsUnreadMessagesCount: PropTypes.number.isRequired,
			pmReadMode: PropTypes.bool.isRequired,
			pmUnreadMessagesCount: PropTypes.number.isRequired,
			toReadMode: PropTypes.bool.isRequired,
			toUnreadMessagesCount: PropTypes.number.isRequired,
			chatWith: PropTypes.object,
			rainShow: PropTypes.bool,
			//--
			location: PropTypes.string.isRequired,
			lang: PropTypes.string,
			theme: PropTypes.string.isRequired,
			tawkOpened: PropTypes.bool,
			chatOpen: PropTypes.bool,
		};
	}
	onSendClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		this.props.toggleOverlay(false);
		await chat.send(this.props.chatInput, this.props.location, this.props.chatWith ? this.props.chatWith.uid : undefined);
		await storeSet({ chatInput: { $set: "" } });
		await chat.onReadingModeOff();
		await this.setState({ loading: false });
		await chat.editCancel();
		document.getElementById("chatInput").focus();
	};
	onMessageChange = async (e, v) => {
		await storeSet({ chatInput: { $set: e.target.value } });
		if (v) this.onSendClick();
	};
	onShowSmileOverlayClick = (e) => {
		if (e) e.stopPropagation();
		this.props.toggleOverlay(true, () => {
			const node = chat.getEmojiListRef();
			node && node.scrollToTop();
		});
	};
	componentDidMount = () => {
		chat.setMessagesListRef(this.listRef.current);
		chat.setChatLocation(this.props.location);
		this.makeItRain();
	};
	componentDidUpdate = (prevProps) => {
		for (let location of ["chat", "news", "notifications", "to"]) {
			if (this.props[`${location}ReadMode`] !== prevProps[`${location}ReadMode`]) {
				this.anim[this.props[`${location}ReadMode`] ? "play" : "stop"]();
				if (!this.props[`${location}ReadMode`]) chat.onReadingModeOff();
			}
			if (!this.props.chatOpen) {
				this.listRef.current.update({ data: [] });
			}
		}
	};
	onChatInputFocus = (e) => {
		try {
			e.target.select();
			const length = this.props.chatInput.length;
			e.target.setSelectionRange(length, length);
			this.props.toggleOverlay(false);
		} catch (err) {
			console.error(err);
		}
	};
	onScroll = async (o) => {
		const offset = 50;
		const max = -this.listRef.current.scroll.maxScrollY;
		const y = -o.y;
		let v = chat.isReversed(this.props.location) ? y > offset : y < max - offset;
		if (this.props.location === "pm") v = false;
		await storeSet({ [`${this.props.location}ReadMode`]: { $set: v } });
	};
	renderMessagesArrivedAlert = () => {
		if (this.props.location === "pm") return null;
		const reversedCl = chat.isReversed(this.props.location) ? "reversed" : "";
		return (
			<figure className={`messagesArrived ${reversedCl} ${this.props[`${this.props.location}UnreadMessagesCount`] > 0 ? "on" : "off"}`}>
				<button onClick={(e) => [e.stopPropagation(), chat.onReadingModeOff()]} className="diceBtn green btnShowUnread">
					<Lottie
						ref={(el) => (this.anim = el)}
						src={`/assets/ae/new_message_${this.props.theme ? "white" : "black"}.json`}
						style={{
							height: "35px",
							width: "35px",
							pointerEvents: "none",
						}}
					/>
					<b className="count">{this.props[`${this.props.location}UnreadMessagesCount`]}</b>
				</button>
			</figure>
		);
	};
	renderRainHolder = () => {
		return (
			<div id="rain_holder" className={this.props.rainShow ? "on" : "off"}>
				<div id="rain_front_row"></div>
				<div id="rain_back_row"></div>
			</div>
		);
	};
	makeItRain = () => {
		var increment = 0;
		var drops = "";
		var backDrops = "";
		while (increment < 100) {
			var randoHundo = Math.floor(Math.random() * (98 - 1 + 1) + 1);
			var randoFiver = Math.floor(Math.random() * (5 - 2 + 1) + 2);
			increment += randoFiver;
			drops +=
				'<div class="drop" style="left: ' +
				increment +
				"%; bottom: " +
				(randoFiver + randoFiver - 1 + 100) +
				"%; animation-delay: 0." +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"><div class="stem" style="animation-delay: 0.' +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"></div><div class="splat" style="animation-delay: 0.' +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"></div></div>';
			backDrops +=
				'<div class="drop" style="right: ' +
				increment +
				"%; bottom: " +
				(randoFiver + randoFiver - 1 + 100) +
				"%; animation-delay: 0." +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"><div class="stem" style="animation-delay: 0.' +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"></div><div class="splat" style="animation-delay: 0.' +
				randoHundo +
				"s; animation-duration: 0.5" +
				randoHundo +
				's;"></div></div>';
		}
		const el1 = document.getElementById("rain_front_row");
		const el2 = document.getElementById("rain_back_row");
		if (el1 && el2) {
			el1.innerHTML = drops;
			el2.innerHTML = backDrops;
		}
	};
	focusNext = (id, dir = "next") => {
		const btnComChat = document.getElementById("btnComChat");
		const btnChatClear = document.getElementById("btnChatClear");
		const btnChatSmile = document.getElementById("btnChatSmile");
		const btnChatSend = document.getElementById("btnChatSend");
		const chatInput = document.getElementById("chatInput");

		const el = {
			btnComChat,
			chatInput,
			btnChatClear,
			btnChatSmile,
			btnChatSend,
		};
		const info = {
			chatInput: { next: "btnChatClear", prev: "btnComChat" },
			btnChatClear: { next: "btnChatSmile", prev: "chatInput" },
			btnChatSmile: { next: "btnChatSend", prev: "btnChatClear" },
			btnChatSend: { next: "chatInput", prev: "btnChatSmile" },
		};
		let i = 0;
		while (i < 20) {
			i++;
			id = info[id][dir];
			if (!el[id].disabled) break;
		}
		el[id].focus();
	};
	render() {
		const disabled = this.state.loading;
		let hideInpCl = ["notifications", "pm"].indexOf(this.props.location) !== -1 ? "off" : "on";
		if (this.props.location === "news") {
			hideInpCl = this.state.perms && this.state.perms.NEWS_SEND ? "on" : "off";
		}
		// const chatData = [];// this.props.chatOpen ? chat.getMessages() : [];

		return (
			<Container className="chatMessages" noDocs={true}>
				<>
					<section className={`messages${hideInpCl === "on" ? " withFooter" : ""}${this.props.chatWith ? " chatWith" : ""}`}>
						<List
							ref={this.listRef}
							ren={false}
							data={[]}
							onScroll={this.onScroll}
							options={{ bounce: true, momentum: true, useTransition: isMobile() ? false : true }}
						/>
						{this.renderMessagesArrivedAlert()}
						{this.renderRainHolder()}
					</section>

					<section className={`footer ${this.state.loading ? "loading" : "ready"} ${hideInpCl}${this.props.chatWith ? " chatWith" : ""}`}>
						{this.props.chatWith && (
							<div className="toWrapper">
								<button className="btnDefault btnTo">
									<img
										src={getPhoto(this.props.chatWith.uid, this.props.chatWith.img, 24, 24)}
										alt=""
										onError={(e) => {
											if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
										}}
									/>
									<b>{this.props.chatWith.un}</b>
								</button>
							</div>
						)}
						<div className="wrapper">
							<div className="type">
								<Input
									id="chatInput"
									ref={(el) => (this.textarea = el)}
									type="textarea"
									placeholder={lang.translate("type_a_message")}
									name="chatInput"
									// autoFocus="autoFocus"
									value={this.props.chatInput}
									// validation="payout"
									onFocus={(e) => this.onChatInputFocus(e)}
									onChange={(e) => this.onMessageChange(e, 0)}
									onBlur={(e) => this.onMessageChange(e, 0)}
									onEnter={(e) => this.onMessageChange(e, 1)}
									disabled={disabled}
									onDown={() => this.focusNext("chatInput", "next")}
									onUp={() => this.focusNext("chatInput", "prev")}
								/>
							</div>

							<div className="tools">
								<div className={`sideButtons ${this.props.tawkOpened ? "tawkOpened" : "tawkClosed"}`}>
									<button
										id="btnChatClear"
										disabled={this.props.chatInput.trim().length === 0 || disabled}
										className="btnIcon alert small btnClear"
										onClick={(e) => [
											e.stopPropagation(), 
											chat.editCancel(), 
											storeSet({ chatInput: { $set: "" } }),
											this.focusNext("btnChatClear", "prev")
										]}
										onKeyDown={(e) => {
											if (["ArrowDown", "ArrowRight", "Tab"].includes(e.key)) {
												e.preventDefault();
												this.focusNext("btnChatClear", "next");
											} else if (["ArrowUp", "ArrowLeft"].includes(e.key)) {
												e.preventDefault();
												this.focusNext("btnChatClear", "prev");
											}
										}}
									>
										<Icon icon={clear} size={22} />
									</button>
									{/* <button disabled={disabled} className="btnIcon warn small btnCommand">
										<Icon icon={command} size={22} />
									</button>
									<button disabled={disabled} className="btnIcon warn small btnAt">
										<Icon icon={at} size={22} />
									</button> */}
									<button
										id="btnChatSmile"
										onClick={this.onShowSmileOverlayClick}
										className="btnIcon warn small btnSmile"
										onKeyDown={(e) => {
											if (["ArrowDown", "ArrowRight", "Tab"].includes(e.key)) {
												e.preventDefault();
												this.focusNext("btnChatSmile", "next");
											} else if (["ArrowUp", "ArrowLeft"].includes(e.key)) {
												e.preventDefault();
												this.focusNext("btnChatSmile", "prev");
											}
										}}
									>
										<Icon icon={smileIcon} size={22} />
									</button>
								</div>
								<button
									id="btnChatSend"
									disabled={this.props.chatInput.trim().length === 0 || disabled}
									onClick={this.onSendClick}
									className="btnIcon success btnSend"
									onKeyDown={(e) => {
										if (["ArrowDown", "ArrowRight", "Tab"].includes(e.key)) {
											e.preventDefault();
											this.focusNext("btnChatSend", "next");
										} else if (["ArrowUp", "ArrowLeft"].includes(e.key)) {
											e.preventDefault();
											this.focusNext("btnChatSend", "prev");
										}
									}}
								>
									<Icon icon={sendO} size={22} />
								</button>
							</div>
						</div>
					</section>
				</>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		chatInput: store.chatInput,
		lang: store.lang.key,
		//---
		chatReadMode: store.chatReadMode,
		chatUnreadMessagesCount: store.chatUnreadMessagesCount,
		newsReadMode: store.newsReadMode,
		newsUnreadMessagesCount: store.newsUnreadMessagesCount,
		notificationsReadMode: store.notificationsReadMode,
		notificationsUnreadMessagesCount: store.notificationsUnreadMessagesCount,
		pmReadMode: store.pmReadMode,
		pmUnreadMessagesCount: store.pmUnreadMessagesCount,
		toReadMode: store.toReadMode,
		toUnreadMessagesCount: store.toUnreadMessagesCount,
		chatWith: store.chatWith,
		rainShow: store.rainShow,
		//---
		location: store.chat.location,
		theme: store.theme.key,
		tawkOpened: store.tawkOpened,
		//--
		permissionsChanged: store.permissionsChanged,
		chatOpen: store.chat && store.chat.open,
	};
};

export default connect(a, null, null, { forwardRef: true })(ChatMessages);
