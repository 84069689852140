import React, { useEffect, useState, useCallback, forwardRef } from "react";
import Container from "../Container";
import PropTypes from "prop-types";
import Loader2 from "../../components/loader/Loader2";
import Axios from "../../classes/axios";
import { getSetParams, onResult } from "../../classes/utills";
import { shallowEqual, useSelector } from "react-redux";
import { GA_EVENTS, gaEvent } from "../../classes/ga";
import { go, goPrev } from "../../classes/routesLazy";
//icons:
import { Icon } from "react-icons-kit";
import { close } from "react-icons-kit/fa/close";
import { expand } from "react-icons-kit/fa/expand";
import { compress } from "react-icons-kit/fa/compress";
import Toggle from "../../components/toggle/Toggle";
import lang from "../../classes/lang";
import LobbyGamesSlider from "../Lobby/lobbyGames/LobbyGameSlider";
import Widgets from "../Lobby/Widgets";
const SlotGame = (props, ref) => {
	SlotGame.propTypes = {
		match: PropTypes.object,
	};
	useEffect(() => {
		getSetParams(props.match?.params);
	}, []);
	const slotsNew = useSelector((store) => store.slotsNew ?? []);
	const slotsTrending = useSelector((state) => state.slotsTrending ?? []);
	// const uuid = props.match.params.id;
	// const isDemo = props.match.params.mode === "demo";

	const name = props.match.params.game;
	const provider = props.match.params.provider;
	const [loading, setLoading] = useState(true);
	const [expanded, setExpanded] = useState(false);
	const [url, setUrl] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const { un, coinSelected, langRoute } = useSelector(
		(store) => ({
			un: store.player.userName,
			coinSelected: store.coin.selected,
			langRoute: store.langRoute,
		}),
		shallowEqual
	);
	const getReal = () => {
		if (typeof window === "undefined") return null;
		if (un === "guest") return null;
		if (window.location.pathname.indexOf("/slot/") === -1) return false;
		return true;
	};

	const isReal = typeof window === "undefined" ? false : window.location.pathname.indexOf("/slot/") !== -1 && un !== "guest";
	const isDemo = typeof window === "undefined" ? true : !isReal;
	const getGameData = useCallback(async () => {
		setLoading(true);
		setUrl(null);
		const result = await Axios.post(`/api/slotegrator/gameInit${isDemo ? "Demo" : ""}`, {
			// gid: uuid,
			name,
			short: coinSelected,
			language: langRoute,
			origin: window.location.origin,
			provider,
		});
		return await onResult(
			result,
			"unexpected_error",
			async ({ url }) => {
				gaEvent(GA_EVENTS.slotegrator, {
					mode: isDemo ? "demo" : "real",
					game: result.game.name,
					coin: coinSelected,
					action: "play",
					url,
				});
				setLoading(false);
				setUrl(url);
				return { url };
			},
			async (err) => {
				setLoading(false);
				return err;
			}
		);
	}, [isDemo, name, coinSelected, langRoute, provider]);
	useEffect(() => {
		getGameData();
	}, [getGameData]);
	const onCloseGame = () => {
		goPrev();
		// go("crypto-slots");
	};
	const onSwitchMode = () => {
		if (loading) return;
		// go(`crypto-slots/${!isDemo ? "demo" : "real"}/${uuid}/${name}`);
		go(`${!isDemo ? "demo" : "slot"}/${name}/${provider}`);
	};
	const onExpandToggle = () => {
		setExpanded((v) => !v);
	};
	// eslint-disable-next-line no-unused-vars
	const onValidate = async () => {
		await Axios.post("/api/slotegrator/selfValidate", {});
	};
	const receiveMessage = useCallback((event) => {
		if (event?.data?.message === "leaveTheGame") onCloseGame();
		else if (event && event.data && ['{"event":"close"}', "closeGame", "closeInnerFrame", '{"type":"gameClose"}'].includes(event.data)) {
			onCloseGame();
		} else {
			// console.log(event);
		}
	}, []);
	useEffect(() => {
		typeof window !== "undefined" && window.addEventListener("message", receiveMessage, false);
		return () => {
			typeof window !== "undefined" && window.removeEventListener("message", receiveMessage, false);
		};
	}, [receiveMessage]);

	return (
		<Container ref={ref} className="slot_game">
			<>
				<div className={`gameFrame ${expanded ? "expanded" : "original"}`} onClick={(e) => e.stopPropagation()}>
					{loading && <Loader2 />}
					{url && <iframe title="slot-game" className={`${url ? "on" : "off"}`} {...(url ? { src: url } : {})}></iframe>}
					<div className="btns">
						{/* {un === "admini" && (
							<button onClick={(e) => [e.stopPropagation(), onValidate()]} className="btnDefault btnValidate">
								SELF-VALIDATE
							</button>
						)} */}
						{!expanded && (
							<div className="toggleWrapper">
								<b>{lang.translate("demo")}</b>
								{typeof getReal() === "boolean" && (
									<Toggle disabled={loading || un === "guest"} on={""} off={""} value={getReal()} onChange={onSwitchMode} />
								)}
								<b>{lang.translate("real")}</b>
							</div>
						)}
						<button onClick={(e) => [e.stopPropagation(), onExpandToggle()]} className="btnIcon small btnExpand">
							<Icon icon={expanded ? compress : expand} size={24} />
						</button>
						{!expanded && (
							<button onClick={(e) => [e.stopPropagation(), onCloseGame()]} className="btnIcon small btnClose">
								<Icon icon={close} size={24} />
							</button>
						)}
					</div>
				</div>
				<Widgets activeTab="my_bets" />
				{slotsNew && (
					<LobbyGamesSlider perPage={5} games={slotsNew} caption="new_games" sticker={{ backgroundColor: "red", color: "white", caption: "NEW" }} />
				)}
				{slotsNew && (
					<LobbyGamesSlider
						perPage={5}
						games={slotsTrending}
						caption="trending_games"
						sticker={{ backgroundColor: "goldenrod", color: "black", caption: "TRENDING" }}
					/>
				)}
			</>
		</Container>
	);
};
export default forwardRef(SlotGame);
