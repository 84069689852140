import axios from "axios";
import config from "../config";
import Storage from "./storage";
// axios.defaults.withCredentials = true;
let player;
const stripTrailingSlashes = (url) => (typeof url === "string" ? url.trim().replace(/^(.+?)\/*?$/, "$1") : url);
class Service {
	constructor() {
		const service = axios.create({
			baseURL: `${stripTrailingSlashes(config.app.url)}/`,
			timeout: config.app.httpTimeout,
			// withCredentials: true
		});
		service.interceptors.response.use(this.handleSuccess, this.handleError);
		if (typeof window !== "undefined") {
			const token = Storage.get("token", null);
			if (token) service.defaults.headers.common["token"] = token;
			// service.defaults.withCredentials = true;
			// service.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
			// service.defaults.headers.common['Access-Control-Allow-Origin'] = window.location.hostname;
			// service.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
		}
		this.service = service;
	}
	setHeader = (key, value) => {
		this.service.defaults.headers.post[key] = value;
	};
	getHeader = (key) => {
		return this.service.defaults.headers.post[key];
	};
	handleSuccess(response) {
		if (typeof response === "object" && response !== null && response.data && response.data.messageArgs === "token_expired") {
			player = player || require("./player");
			player.logout();
		}
		return response.data;
	}
	handleError(error) {
		if (typeof error === "object" && error !== null && error.response && error.response.data && error.response.data.error) {
			if (error.response.data.messageArgs === "token_expired") {
				player = player || require("./player");
				player.logout();
				return Object.assign({}, error.response.data, { message: "token_expired" });
			}
			return error.response.data;
		}
		// if (error.response.data.message === "access_denied") return socketHandlers.onTokenExpired();
		return { error: true, message: error.message || "unexpected_error" };
	}
	get(path) {
		return this.service.get(path);
	}
	post(path, payload) {
		// const tim = Date.now();
		// const headers = { "x-nonce": nonce, "x-tim": tim };
		// const o = sortObject(payload);
		// const str = `${JSON.stringify(headers)}&${JSON.stringify(o)}`;
		// const sign = hmacSha1(`${config.app.credentials.sign}.${tim}:${nonce}`, str);

		// const token = store.getState().general.token;
		// this.setHeader("token", token);
		// this.setHeader("x-nonce", nonce);
		// this.setHeader("x-tim", tim);
		// this.setHeader("x-sign", sign);
		// const crypted = encrypt(JSON.stringify(o));
		return this.service.post(path, payload);
	}
}
const myService = new Service();
export default myService;
