import { onResult, tokenWrap } from "./utills";
import { storeSet, store } from "../index";
import { emitOrDefault } from "./socket";
import Toast from "./toast";
import Axios from "./axios";
import lang from "./lang";

export const shutdown = async (cb) => {
	const title = "shutdown";
	tokenWrap({ title, path: "api/system/shutdown", o: {} }, async (result) => {
		result = await onResult(result, "unexpected_error", async () => {
			return result;
		});
		if (typeof cb === "function") return cb(result);
		else return;
	});
};
export const siteSettingsSet = async (prop, o, cb) => {
	await storeSet({ playerConfig: { siteSettings: { [prop]: { $set: o } } } });
	const state = await store.getState();
	const defaults = state.playerConfigDefaults.siteSettings;
	const current = state.playerConfig.siteSettings || {};
	const obj = {};
	for (let el in defaults) {
		obj[el] = current[el] || defaults[el];
	}

	emitOrDefault("system", { type: "setSettings", o: obj }, 5000, { error: true, message: "you_are_offline" }, async (_r) => {
		if (_r && _r.error && _r.message) {
			Toast.error(lang.translate(_r.message));
			return typeof cb === "function" ? cb() : null;
		}
		Toast.success(lang.translate("settings_saved"));
		if (typeof cb === "function") cb();
	});
};
export const setTrace = async (o, cb) => {
	let result = await Axios.post("api/system/setTrace", o);
	result = await onResult(result, "unexpected_error", async () => {
		return result;
	});
	if (typeof cb === "function") return cb(result);
	else return result;
};
export const getTraceFilter = async (o, cb) => {
	let result = await Axios.post("api/system/getTraceFilter", o);
	result = await onResult(result, "unexpected_error", async () => {
		return result;
	});
	if (typeof cb === "function") return cb(result);
	else return result;
};
export const getCommands = async (o, cb) => {
	let result = await Axios.post("api/system/getCommands", o);
	result = await onResult(result, "unexpected_error", async () => {
		return result;
	});
	if (typeof cb === "function") return cb(result);
	else return result;
};
export const runCommand = async (o, cb) => {
	let result = await Axios.post("api/system/runCommand", o);
	result = await onResult(result, "unexpected_error", async () => {
		return result;
	});
	if (typeof cb === "function") return cb(result);
	else return result;
};
