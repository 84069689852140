import React from "react";
import PropTypes from "prop-types";
//classes:
import { getPhoto } from "../../classes/player";
import { go } from "../../classes/routesLazy";
import { chat } from "../../classes/chat";
//icons:
import { Icon } from "react-icons-kit";
import { user } from "react-icons-kit/fa/user";

const Avatar = (props = {}) => {
	const { key, className, status, uid, img, userName, size = 24, showUid = false } = props;
	const cls = ["col_online", "col_away", "col_busy", "col_offline"];
	const str = showUid ? `${uid} - ` : "";
	return (
		<div key={key || `avatar_${userName}`} className={`avatarComponent ${className || ""}`}>
			{status >= 0 && status <= 3 && <div data-un={userName} data-status={status} className={`onlineMark ${cls[status]}`}></div>}
			<button className="btnTag" onClick={(e) => [e.stopPropagation(), chat.closeChatPane(true), go(`profile/${userName}`)]}>
				{uid ? (
					<img
						src={getPhoto(uid, img, size, size)}
						alt=""
						onError={(e) => {
							if (e.target.src.indexOf("avatar-default.png") === -1) e.target.src = "/assets/avatar-default.webp";
						}}
					/>
				) : (
					<Icon icon={user} size={24} />
				)}
			</button>
			<button className="btnA" onClick={(e) => [e.stopPropagation, chat.onTaggedClick(userName)]}>
				{str}{userName}
			</button>
		</div>
	);
};
Avatar.propTypes = {
	key: PropTypes.string,
	userName: PropTypes.string,
	uid: PropTypes.number,
	img: PropTypes.number,
	size: PropTypes.number,
	status: PropTypes.number,
	className: PropTypes.string,
	showUid: PropTypes.bool
};

export default Avatar;
