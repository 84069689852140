import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import lang from "../../../classes/lang";
import Input from "../../../components/input/Input";
import ToggleV from "../../../components/toggle/ToggleV";
import Decimal from "decimal.js";
import { sound } from "../../../classes/sound";
import { tryParseFloat, tryParseInt } from "../../../classes/utills";
import { autoCl } from "./CrashAutoClass";
import { useSelector } from "react-redux";
import { storeSet } from "../../..";
import myStorage from "../../../classes/storage";
//icons:
import { Icon } from "react-icons-kit";
import { cog } from "react-icons-kit/fa/cog";
import { ic_expand_less } from "react-icons-kit/md/ic_expand_less";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
const CrashAuto = (props) => {
	CrashAuto.propTypes = {
		onBetClick: PropTypes.func,
		isDisabled: PropTypes.func,
		getBetAmount: PropTypes.func,
		runAutoLogic: PropTypes.func,
		set_r: PropTypes.func,
	};
	const cfg = autoCl.configGet();
	const [autoVisible, setAutoVisible] = useState(() => {
		return myStorage.get("crashAutoVisible", false, "bool");
	});
	//NUMROLLS
	const [toggleNumRolls, set_toggleNumRolls] = useState(0);
	const [inpNumRolls, set_inpNumRolls] = useState("1");
	//LOSE
	const [inpEveryL, set_inpEveryL] = useState(cfg.L.every === null ? "1" : cfg.L.every + "");
	const [inpBetL, set_inpBetL] = useState(tryParseFloat(cfg.L.incBy, 1) + "");
	const [toggleEveryL, set_toggleEveryL] = useState(cfg.L.every !== null ? 0 : 1);
	const [toggleStopL, set_toggleStopL] = useState(cfg.L.stop ? 1 : 0);
	const [toggleBetL, set_toggleBetL] = useState(cfg.L.toBase ? 0 : 1);
	//WIN
	const [inpEveryW, set_inpEveryW] = useState(cfg.W.every === null ? "1" : cfg.W.every + "");
	const [inpBetW, set_inpBetW] = useState(tryParseFloat(cfg.W.incBy, 1) + "");
	const [toggleEveryW, set_toggleEveryW] = useState(cfg.W.every !== null ? 0 : 1);
	const [toggleStopW, set_toggleStopW] = useState(cfg.W.stop ? 1 : 0);
	const [toggleBetW, set_toggleBetW] = useState(cfg.W.toBase ? 0 : 1);
	//LIMITS
	const [toggleStopBetUnder, set_toggleStopBetUnder] = useState(cfg.stopBetUnderOn ? 1 : 0);
	const [toggleStopBalanceUnder, set_toggleStopBalanceUnder] = useState(cfg.stopBalanceUnderOn ? 1 : 0);
	const [toggleStopBetOver, set_toggleStopBetOver] = useState(cfg.stopBetOverOn ? 1 : 0);
	const [toggleStopBalanceOver, set_toggleStopBalanceOver] = useState(cfg.stopBalanceOverOn ? 1 : 0);
	//LIMITS VALUES:
	const [inpStopBetUnder, set_inpStopBetUnder] = useState(cfg.stopBetUnder * 1 > 0 ? cfg.stopBetUnder.toFixed(8) : "0.00000001");
	const [inpStopBetOver, set_inpStopBetOver] = useState(cfg.stopBetOver * 1 > 0 ? cfg.stopBetOver.toFixed(8) : "0.00000001");
	const [inpStopBalanceUnder, set_inpStopBalanceUnder] = useState(cfg.stopBalanceUnder * 1 > 0 ? cfg.stopBalanceUnder.toFixed(8) : "0.00000001");
	const [inpStopBalanceOver, set_inpStopBalanceOver] = useState(cfg.stopBalanceOver * 1 > 0 ? cfg.stopBalanceOver.toFixed(8) : "0.00000001");
	const coinSelected = useSelector((state) => state.coin.selected);
	const crashBetCaption = useSelector((state) => state.crashBetCaption);
	const [resp, set_resp] = useState({ isRunning: autoCl.isRunning(), t: Date.now() });
	const [, set_r] = useState();
	useEffect(() => {
		if (cfg.num) {
			set_toggleNumRolls(true);
		}
		if (!resp.isRunning) {
			set_inpNumRolls(tryParseFloat(cfg.num, 1) + "");
		} else {
			set_inpNumRolls(tryParseFloat(cfg.numLeft, 1) + "");
		}
	}, [resp, cfg.num, cfg.numLeft]);
	useEffect(() => {
		autoCl.regCallback("crashAuto", (v) => {
			set_resp(v);
			const btn = document.getElementById("btnCrashAutoRefresh");
			if (btn) btn.click();
		});
		return () => autoCl.unregCallback("crashAuto");
	}, []);

	const onAutoClick = () => {
		const bet = props.getBetAmount();
		if (autoCl.isRunning()) {
			autoCl.runningSet(false);
			set_r(Date.now());
			return;
		}
		const o = autoCl.configGet();
		o.short = coinSelected;
		o.bet = bet;
		o.betCur = bet;
		const _num = tryParseInt(inpNumRolls, 0);
		if (toggleNumRolls && _num > 0) {
			o.num = _num;
			o.numLeft = _num;
		} else {
			o.num = null;
			o.numLeft = null;
		}
		//Lose, Win:
		for (let el of ["L", "W"]) {
			const isL = el === "L";

			if (isL ? !toggleEveryL : !toggleEveryW) {
				o[el].every = tryParseInt(isL ? inpEveryL : inpEveryW, 1);
				o[el].streak = null;
			} else {
				o[el].every = null;
				o[el].streak = tryParseInt(isL ? inpEveryL : inpEveryW, 1);
			}
			if (isL ? toggleStopL : toggleStopW) {
				o[el].stop = true;
			} else {
				o[el].stop = false;
				if (isL ? !toggleBetL : !toggleBetW) {
					o[el].toBase = true;
					o[el].incBy = 0.0;
				} else {
					o[el].toBase = false;
					o[el].incBy = tryParseFloat(isL ? inpBetL : inpBetW, 1);
				}
			}
		}
		//limits:
		o.stopBetUnderOn = !!toggleStopBetUnder;
		o.stopBetUnder = tryParseFloat(inpStopBetUnder, 0.00000001);
		o.stopBetOverOn = !!toggleStopBetOver;
		o.stopBetOver = tryParseFloat(inpStopBetOver, 0.00000001);
		o.stopBalanceUnderOn = !!toggleStopBalanceUnder;
		o.stopBalanceUnder = tryParseFloat(inpStopBalanceUnder, 0.00000001);
		o.stopBalanceOverOn = !!toggleStopBalanceOver;
		o.stopBalanceOver = tryParseFloat(inpStopBalanceOver, 0.00000001);
		autoCl.configSet(o);
		autoCl.runningSet(true);
		props.set_r(`${Date.now()}_${Math.random()}`);
		props.runAutoLogic();
	};
	const onSettingsClick = (e) => {
		if (e) e.stopPropagation();
		sound.play("ui", "btn1");
		storeSet({
			modal: {
				$set: { name: "crashsettings", canClose: true, props: { selected: "bets" } },
			},
		});
	};
	const clr = (v, el) => (v ? (el === "L" ? "red" : "green") : "lightgrey");
	const clrY = (v) => (v ? "yellow" : "lightgrey");
	const onAutoToggle = (id, v) => {
		sound.play("ui", "btn1");
		if (id === "toggleEveryL") {
			set_toggleEveryL(v);
		} else if (id === "toggleEveryW") {
			set_toggleEveryW(v);
		} else if (id === "toggleBetL") {
			set_toggleBetL(v);
		} else if (id === "toggleBetW") {
			set_toggleBetW(v);
		} else if (id === "toggleNumRolls") {
			set_toggleNumRolls(v);
		} else if (id === "toggleStopL") {
			set_toggleStopL(v);
		} else if (id === "toggleStopW") {
			set_toggleStopW(v);
		} else if (id === "toggleStopBetUnder") {
			set_toggleStopBetUnder(v);
		} else if (id === "toggleStopBetOver") {
			set_toggleStopBetOver(v);
		} else if (id === "toggleStopBalanceUnder") {
			set_toggleStopBalanceUnder(v);
		} else if (id === "toggleStopBalanceOver") {
			set_toggleStopBalanceOver(v);
		}
	};
	const onAutoInp = (id, v) => {
		v += "";
		if (id === "inpStopBetUnder") set_inpStopBetUnder(v);
		else if (id === "inpStopBetOver") set_inpStopBetOver(v);
		else if (id === "inpStopBalanceUnder") set_inpStopBalanceUnder(v);
		else if (id === "inpStopBalanceOver") set_inpStopBalanceOver(v);
		else if (id === "inpNumRolls") set_inpNumRolls(v);
		else if (id === "inpEveryL") set_inpEveryL(v);
		else if (id === "inpEveryW") set_inpEveryW(v);
		else if (id === "inpBetL") set_inpBetL(v);
		else if (id === "inpBetW") set_inpBetW(v);
	};
	const onAutoInpW = (id, v, delta, min = -Infinity, max = 1000000000000, op = "plus", toFixed) => {
		v = new Decimal(v)[op](delta).toNumber();
		v = parseFloat(v.toFixed(8));
		if (v < min) v = min;
		if (v > max) v = max;
		if (typeof toFixed !== "undefined") v = v.toFixed(toFixed);
		onAutoInp(id, v);
	};
	const onAutoInpWPercent = (id, v, delta, min = -100, max = 1000000000000) => {
		v = parseFloat(v);
		if (v === 0) v = 1;
		if (delta > 0) v = new Decimal(v).mul(2).toNumber();
		else v = new Decimal(v).dividedBy(2).toNumber();
		v = parseFloat(v.toFixed(["inpBetL", "inpBetW"].indexOf(id) === -1 ? 8 : 4));
		if (v < min) v = min;
		if (v > max) v = max;
		onAutoInp(id, v);
	};
	const renderLimits = () => {
		const chk = (sec, j) =>
			sec === "Bet" ? (j === 0 ? toggleStopBetUnder : toggleStopBetOver) : j === 0 ? toggleStopBalanceUnder : toggleStopBalanceOver;
		return (
			<div className={`wrapper limits ${autoCl.isRunning() ? "disabled" : ""} ${autoVisible ? "" : "off"}`}>
				<div className="headers">
					<h3>{lang.translate("stop_if_under")}</h3>
					<h3>{lang.translate("stop_if_over")}</h3>
				</div>
				{["Bet", "Balance"].map((sec) => {
					return (
						<section key={`stopOn${sec}`} className={"autoStopOver"}>
							{[`${sec}Under`, `${sec}Over`].map((el, j) => {
								return (
									<figure className="fLimits" key={el}>
										<div className={chk(sec, j) === 1 ? "off" : "on"}>
											<button
												onClick={(e) => [e.stopPropagation(), onAutoToggle(`toggleStop${el}`, 1 - chk(sec, j))]}
												className={`diceBtn ${clrY(chk(sec, j))}`}
											>
												<b>{lang.translate(sec.toLowerCase())}</b>
											</button>
										</div>
										<Input
											disabled={chk(sec, j) === 0}
											onChange={(e) => onAutoInp(`inpStop${el}`, e.target.value)}
											onBlur={(e) => onAutoInp(`inpStop${el}`, e.target.value)}
											onWheel={(e, delta) => onAutoInpW(`inpStop${el}`, e.target.value, delta < 0 ? 0.5 : 2, 0.00000001, 1000000000000, "mul", 8)}
											id={`inpStop${el}`}
											value={sec === "Bet" ? (j === 0 ? inpStopBetUnder : inpStopBetOver) : j === 0 ? inpStopBalanceUnder : inpStopBalanceOver}
											validation={"amount"}
											min={0.00000001}
											toFixed={8}
										/>
									</figure>
								);
							})}
						</section>
					);
				})}
			</div>
		);
	};
	const toggleAutoClick = () => {
		setAutoVisible((v) => {
			myStorage.set("crashAutoVisible", !v);
			return !v;
		});
	};
	return (
		<div className="crashAuto">
			<button id="btnCrashAutoRefresh" className="hidden btnRefresh" onClick={() => set_r(`${Date.now()},${Math.random()}`)}></button>
			{/* <h3 className="hd">{lang.translate("auto")}:</h3> */}
			{/* {autoCl.isRunning() && (
				<div className={"wrapper crashInfo"}>
					<div>
						<b>{`${lang.translate("bet")} (${lang.translate("initial")}):`}</b>
						<b>{(cfg.bet ?? 0).toFixed(8)}</b>
					</div>
					<div>
						<b>{`${lang.translate("bet")} (${lang.translate("current")}):`}</b>
						<b>{(cfg.betCur ?? 0).toFixed(8)}</b>
					</div>
				</div>
			)} */}
			<figure className="roll">
				<button onClick={(e) => [e.stopPropagation(), onSettingsClick()]} className="diceBtn btnIcon yellow btnSettings">
					<Icon icon={cog} />
				</button>
				<button
					id="crashGame_onBet"
					disabled={props.isDisabled()}
					onClick={(e) => [e.stopPropagation(), props.onBetClick()]}
					className="diceBtn green btnBet"
				>
					{lang.translate(crashBetCaption)}
				</button>
				<button className="diceBtn yellow btnToggleAuto" onClick={(e) => [e.stopPropagation(), toggleAutoClick()]}>
					<b>{lang.translate("auto_roll")}</b>
					<Icon icon={!autoVisible ? ic_expand_more : ic_expand_less} size={24} />
				</button>
			</figure>
			<div className="autoLogic">
				<div className={`wrapper numRolls ${autoCl.isRunning() ? "disabled" : ""} ${autoVisible ? "" : "off"}`}>
					<figure className="fNumRolls">
						<div className="on">
							<button
								onClick={(e) => [e.stopPropagation(), onAutoToggle("toggleNumRolls", 1 - toggleNumRolls)]}
								className={`diceBtn ${clrY(toggleNumRolls)}`}
							>
								<b>{lang.translate("number_of_rolls")}</b>
							</button>
						</div>
						{
							<Input
								disabled={toggleNumRolls === 0}
								onChange={(e) => onAutoInp("inpNumRolls", e.target.value)}
								onBlur={(e) => onAutoInp("inpNumRolls", e.target.value)}
								onWheel={(e, delta) => onAutoInpW("inpNumRolls", e.target.value, delta < 0 ? -1 : 1, 1, 1000000000000)}
								id={"inpNumRolls"}
								value={inpNumRolls}
								validation={"integer"}
								min={1}
								max={1000000000000}
							/>
						}
					</figure>
				</div>
				<div className={`wrapper ${autoCl.isRunning() ? "disabled" : ""} ${autoVisible ? "" : "off"}`}>
					{["L", "W"].map((el) => {
						return (
							<section key={el} className={`auto${el}`}>
								<h3>{lang.translate(el === "L" ? "losses" : "wins")}</h3>
								<figure>
									<ToggleV
										onToggle={onAutoToggle}
										className={`diceBtn ${el === "L" ? "red" : "green"}`}
										id={`toggleEvery${el}`}
										value={el === "L" ? toggleEveryL : toggleEveryW}
										captions={[lang.translate("every"), lang.translate("streak")]}
									/>
									<Input
										onChange={(e) => onAutoInp(`inpEvery${el}`, e.target.value)}
										onBlur={(e) => onAutoInp(`inpEvery${el}`, e.target.value)}
										onWheel={(e, delta) => onAutoInpW(`inpEvery${el}`, e.target.value, delta < 0 ? -1 : 1, 1)}
										id={`inpEvery${el}`}
										value={el === "L" ? inpEveryL : inpEveryW}
										validation="integer"
										min={1}
									/>
								</figure>
								<figure className={(el === "L" ? toggleStopL : toggleStopW) * 1 === 1 ? "off" : "on"}>
									<ToggleV
										onToggle={onAutoToggle}
										className={`diceBtn ${clr(el === "L" ? toggleBetL : toggleBetW, el)}`}
										id={`toggleBet${el}`}
										value={el === "L" ? toggleBetL : toggleBetW}
										captions={[lang.translate("to_base"), lang.translate("inc_by")]}
									/>
									<Input
										disabled={(el === "L" ? toggleBetL : toggleBetW) * 1 === 0}
										onChange={(e) => onAutoInp(`inpBet${el}`, e.target.value)}
										onBlur={(e) => onAutoInp(`inpBet${el}`, ["", "-"].indexOf(e.target.value) !== -1 ? "0" : e.target.value)}
										onWheel={(e, delta) => onAutoInpWPercent(`inpBet${el}`, e.target.value, delta < 0 ? -1 : 1)}
										id={`inpBet${el}`}
										value={el === "L" ? inpBetL : inpBetW}
										validation="negativeAmount"
										toFixed={4}
									/>
								</figure>
								<figure className="checks">
									<div>
										<button
											onClick={(e) => [e.stopPropagation(), onAutoToggle(`toggleStop${el}`, 1 - (el === "L" ? toggleStopL : toggleStopW))]}
											className={`diceBtn ${clr(el === "L" ? toggleStopL : toggleStopW, el)}`}
										>
											<b>{lang.translate("stop")}</b>
										</button>
									</div>
								</figure>
							</section>
						);
					})}
				</div>
				{renderLimits()}
				<figure className={`roll ${autoVisible ? "" : "off"}`}>
					<button
						disabled={!autoCl.isRunning() && (crashBetCaption !== "place_bet" || props.getBetAmount() <= 0)}
						onClick={(e) => [e.stopPropagation(), onAutoClick()]}
						className="diceBtn green btnRoll"
					>
						{lang.translate(autoCl.isRunning() ? "stop" : "auto_roll")}
					</button>
				</figure>
				{/* {autoVisible && (
					<div className="btnToggleAutoWrapper">
						<button className="diceBtn lightgrey btnToggleAuto" onClick={(e) => [e.stopPropagation(), toggleAutoClick()]}>
							<Icon icon={!autoVisible ? ic_expand_more : ic_expand_less} size={24} />
						</button>
					</div>
				)} */}
			</div>
		</div>
	);
};
export default CrashAuto;
