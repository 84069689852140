import { lang } from "./lang";
import Toast from "./toast";
import { storeSet } from "../index";
import Axios from "./axios";
import * as _lz from "lz-string";
import React from "react";
import Decimal from "decimal.js";
import { rocket as crash } from "react-icons-kit/icomoon/rocket";
import { futbolO as sportegrator } from "react-icons-kit/fa/futbolO";
import { u1F424 as flappyIcon } from "react-icons-kit/noto_emoji_regular/u1F424";
import Storage from "./storage";
const mobile = require("is-mobile");
export const isMobile = () => {
	return mobile({ tablet: true });
};
let tawkOpened = true;
export const logBanner = (str, { color = "red", fontSize = 30, background = "white" }) => {
	console.log(`%c${str}`, `color: ${color}; background: ${background}; font-size: ${fontSize}px; font-weight: bold;`);
};
export const getBuildVersion = () => {
	if (typeof document === "undefined") return;
	var ss = document.getElementsByTagName("script");
	let build;
	for (let s of ss) {
		const src = s.getAttribute("src");
		if (typeof src !== "string" || !src.startsWith("/static/js/main.")) continue;
		build = src.substring("/static/js/main.".length, src.length - "chunk.js".length - 1);
		break;
	}
	return build;
};
// eslint-disable-next-line no-unused-vars
export const tawkOpen = ({ by }) => {
	if (tawkOpened) return;
	if (isObject(window.Tawk_API) && typeof window.Tawk_API.hideWidget === "function") {
		window.Tawk_API.showWidget();
		tawkOpened = true;
		storeSet({ tawkOpened: { $set: true } });
	}
};
// eslint-disable-next-line no-unused-vars
export const tawkClose = ({ by }) => {
	if (tawkOpened === false) return;
	if (isObject(window.Tawk_API) && typeof window.Tawk_API.hideWidget === "function") {
		window.Tawk_API.hideWidget();
		tawkOpened = false;
		storeSet({ tawkOpened: { $set: false } });
	}
};
export const lz = {
	compress: (o) => _lz.compressToBase64(JSON.stringify(o)),
	decompress: (o) => JSON.parse(_lz.decompressFromBase64(o)),
};
export const cloneJsonMode = (o) => JSON.parse(JSON.stringify(o));
export const getTimeFromTimestamp = (unix_timestamp) => {
	const d = new Date(unix_timestamp);
	return `${d.getHours() * 1 < 10 ? "0" : ""}${d.getHours()}:${d.getMinutes() * 1 < 10 ? "0" : ""}${d.getMinutes()}:${`00${d.getSeconds()}`.slice(
		-2
	)}`;
};
export const getUtcTimeFromTimestamp = (unix_timestamp) => {
	const d = new Date(unix_timestamp);
	return `${d.getUTCHours() * 1 < 10 ? "0" : ""}${d.getUTCHours()}:${
		d.getUTCMinutes() * 1 < 10 ? "0" : ""
	}${d.getUTCMinutes()}:${`00${d.getUTCSeconds()}`.slice(-2)}`;
};
export const getDayFromTimestamp = (unix_timestamp) => {
	const d = new Date(unix_timestamp);
	return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0" : ""}${d.getMonth() + 1}-${d.getDate() < 10 ? "0" : ""}${d.getDate()}`;
};
export const getDayTimeFromTimestamp = (unix_timestamp, textMode = false, month = false) => {
	const d = new Date(unix_timestamp);
	const day = `${d.getDate() < 10 ? "0" : ""}${d.getDate()}`;
	const time = getTimeFromTimestamp(unix_timestamp);
	const m = (d.getMonth() + 1 < 10 ? "0" : "") + (d.getMonth() + 1);
	const dStr = month ? `${day}/${m}` : day;
	if (textMode) return `${dStr}, ${time}`;
	return (
		<>
			<b className="d">{dStr}</b>
			<b className="t">{time}</b>
		</>
	);
};
/*
	const info = {
		chatInput: {},
		//top buttons:
		btnMenuButton: { right: "btnSiteSettings", down: "btnComChat" },
		btnSiteSettings: { right: "btnWhoIsOnline", left: "btnMenuButton", down: "btnComChat" },
		btnWhoIsOnline: { right: "btnRainManual", left: "btnSiteSettings", down: "btnComChat" },
		btnRainManual: { right: "btnChatReposition", left: "btnWhoIsOnline", down: "btnComChat" },
		btnChatReposition: { right: "btnChatClose", left: "btnRainManual", down: "btnComChat" },
		btnChatClose: { left: "btnChatReposition", down: "btnComChat" },

		//com buttons:
		btnComNews: { right: "btnComChat", left: "btnComRooms", down: "chatInput", up: "btnMenuButton" },
		btnComChat: { right: "btnComNotifications", left: "btnComNews", down: "chatInput", up: "btnMenuButton" },
		btnComNotifications: { right: "btnComPm", left: "btnComChat", down: "chatInput", up: "btnMenuButton" },
		btnComPm: { right: "btnComFriends", left: "btnComNotifications", down: "chatInput", up: "btnMenuButton" },
		btnComFriends: { right: "btnComRooms", left: "btnComPm", down: "chatInput", up: "btnMenuButton" },
		btnComRooms: { right: "btnComNews", left: "btnComFriends", down: "chatInput", up: "btnMenuButton" },
	};
*/
export const focusNext = async (e, info = {}) => {
	e.preventDefault();
	let id = e.target.id;
	if (typeof id !== "string") return;
	let dir;
	if (["ArrowLeft"].includes(e.key)) dir = "left";
	else if (["ArrowRight"].includes(e.key)) dir = "right";
	else if (["ArrowDown"].includes(e.key) || (e.key === "Tab" && e.shiftKey === false)) dir = "down";
	else if (["ArrowUp"].includes(e.key) || (e.key === "Tab" && e.shiftKey === true)) dir = "up";
	else if (e.key === "Enter") dir = "Enter";
	else return e;
	if (dir === "Enter") {
		e.target.click();
		await delay(500);
	}

	if (!info[id][dir] && !info[dir]) return e;
	//extract elements:
	const el = {};
	for (let key in info) el[key] = document.getElementById(key);
	//decide focus:
	let i = 0;
	while (i < 20) {
		i++;
		if (!info[id]) return e;
		let arr = info[id][dir] ?? info[dir];
		if (!Array.isArray(arr)) arr = [arr];
		for (let _id of arr) {
			// console.log(_id);
			if (_id && el[_id] && !el[_id].disabled) {
				i = 10000;
				id = _id;
				break;
			}
		}
		if (i === 10000) break;
	}
	el[id] && el[id].focus();
	return e;
};
export const minutesPassed = (serverTime, from) => {
	if (!isInt(from) || !isInt(serverTime)) return 0;
	return Math.round((serverTime - from) / 1000 / 60);
};
export const daysPassed = (serverTime, from) => {
	if (!isInt(from) || !isInt(serverTime)) return 0;
	return Math.round((serverTime - from) / 1000 / 60 / 60 / 24);
};
// eslint-disable-next-line no-extend-native
Date.prototype.clone = function () {
	return new Date(this.getTime());
};
// eslint-disable-next-line no-extend-native
Date.prototype.addMinutes = function (m) {
	this.setTime(this.getTime() + m * 60 * 1000);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
	this.setTime(this.getTime() + h * 60 * 60 * 1000);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (d) {
	// this.setDate(this.getDate() + d * 1); incorrect way
	this.setTime(this.getTime() + d * 24 * 60 * 60 * 1000);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.toHourMinute = function (m = 0, utc = false) {
	let minutes = (utc ? this.getUTCMinutes() : this.getMinutes()) * 1;
	let rem = m === 0 ? 0 : minutes % m;
	minutes = minutes - rem + m * (rem === 0 ? 0 : 1); //ceil
	let hours = utc ? this.getUTCHours() : this.getHours();
	if (minutes > 59) {
		minutes = 0;
		hours++;
	}
	if (hours > 23) {
		hours = 0;
	}
	return `${hours * 1 < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};
// eslint-disable-next-line no-extend-native
Date.prototype.toString = function () {
	return `${this.getFullYear()}-${this.getMonth() + 1 < 10 ? "0" : ""}${
		this.getMonth() + 1 + "-" + (this.getDate() < 10 ? "0" : "")
	}${this.getDate()}`;
};
// eslint-disable-next-line no-extend-native
Date.prototype.floor = function () {
	this.setHours(0);
	this.setMinutes(0);
	this.setSeconds(0);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.ceil = function () {
	this.setHours(23);
	this.setMinutes(59);
	this.setSeconds(59);
	return this;
};
Date.prototype.utcCeil = function () {
	this.setUTCHours(23);
	this.setUTCMinutes(59);
	this.setUTCSeconds(59);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.ceilByMinutes = function (m = 0) {
	const t = this.getTime();
	let minutes = this.getMinutes() * 1;
	let rem = minutes % m;
	this.setTime(t + (m * (rem === 0 ? 0 : 1) - rem) * 60 * 1000);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.utcCeilByMinutes = function (m = 0) {
	const t = this.getTime();
	let minutes = this.getUTCMinutes() * 1;
	let rem = minutes % m;
	this.setTime(t + (m * (rem === 0 ? 0 : 1) - rem) * 60 * 1000);
	return this;
};
// eslint-disable-next-line no-extend-native
Date.prototype.yesterdayString = function () {
	return this.addDays(-1).toString();
};
/** Remove element from array */
// eslint-disable-next-line no-extend-native
Array.prototype.Remove = function (el) {
	const index = this.indexOf(el);
	if (index > -1) this.splice(index, 1);
	return this;
};
// Number.prototype.ToFixedSafe = function (v) {
// 	return tryParseFloat(this, 0).toFixed(tryParseInt(v, 0));
// };
// String.prototype.ToFixedSafe = function (v) {
// 	return tryParseFloat(this, 0).toFixed(tryParseInt(v, 0));
// };

//convert base64 to binary:
export const b64toBlob = (b64Data, contentType = "image/jpeg", url = false) => {
	try {
		const sliceSize = 512;
		const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) byteNumbers[i] = slice.charCodeAt(i);
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, { type: contentType });
		return url ? URL.createObjectURL(blob) : blob;
	} catch (err) {
		console.error("b64toBlob: ", err);
		return;
	}
};
export function preloadImages(sources, callback = () => {}) {
	if (typeof document === "undefined") return;
	if (!Array.isArray(sources)) return console.error("invalid args in preloadImages, expected array found: ", sources);
	let counter = 0;
	function onLoad() {
		counter++;
		if (counter === sources.length) callback();
	}
	for (let source of sources) {
		let img = document.createElement("img");
		img.onload = img.onerror = onLoad;
		img.src = source;
	}
}
export function passwordStrength(pass) {
	const min6 = pass.length >= 6;
	const hasUpper = (pass.match(/[A-Z]/g) || []).length > 0;
	const hasLower = (pass.match(/[a-z]/g) || []).length > 0;
	const hasSymbol = (pass.match(/[!№@#$%^&*()_+\-=[\].,';:"\\/|><?{}]/g) || []).length > 0;
	const hasNumber = (pass.match(/[0-9]/g) || []).length > 0;
	return {
		min6,
		hasUpper,
		hasLower,
		hasSymbol,
		hasNumber,
		strength: (min6 ? 1 : 0) + (hasUpper ? 1 : 0) + (hasLower ? 1 : 0) + (hasSymbol ? 1 : 0) + (hasNumber ? 1 : 0),
	};
}
export async function httpGet(url) {
	return new Promise((resolve) => {
		let xmlhttp;
		if (window.XMLHttpRequest) xmlhttp = new XMLHttpRequest();
		else return resolve({ error: true });
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) return resolve(xmlhttp.responseText);
		};
		xmlhttp.open("GET", url, false);
		xmlhttp.send();
	});
}
export const onResult = async (result, message = "unexpected_error", onSuccess, onError) => {
	if (typeof result !== "object" || result === null) {
		Toast.error(lang.translate(message));
		return { error: true };
	}
	if (result.error) {
		if (result.message !== "cancelled") Toast.error(lang.translate(result.message ? result.message : message, result.messageArgs));
	} else {
		//success:
		if (typeof onSuccess === "function") return await onSuccess(result);
		return result;
	}
	if (typeof onError === "function") return await onError(result);
	return result;
};
export const showModal = (name, canClose = true, props) => {
	storeSet({
		modal: {
			$set: {
				name,
				canClose,
				props,
			},
		},
	});
};
export const tokenWrap = async ({ title, path, o, disableToken = false }, cb) => {
	const ready = async (response) => {
		await storeSet({ modal: { $set: null } });
		if (typeof response === "undefined") {
			if (typeof cb === "function") return cb({ error: true, message: "cancelled" });
		} else {
			const result = await Axios.post(path, { ...o, token: response.token });
			if (typeof cb === "function") return cb(result);
		}
		return;
	};
	storeSet({
		modal: {
			$set: {
				name: "prompt",
				canClose: false,
				props: {
					f: ready,
					title,
					disableToken,
				},
			},
		},
	});
};
export const balanceActions = {
	deposit: { shortName: "deposit", name: "deposit", v: 0 },
	withdraw: { shortName: "withdraw", name: "withdraw", v: 1 },
	faucet: { shortName: "faucet", name: "faucet", v: 2 },
	exchange: { shortName: "exchange", name: "exchange", v: 3 },
	affiliation: { shortName: "aff.", name: "affiliation", v: 4 },
	tipIn: { shortName: "tipIn", name: "tipIn", v: 5 },
	tipOut: { shortName: "tipOut", name: "tipOut", v: 6 },
	rainIn: { shortName: "rainIn", name: "rainIn", v: 7 },
	rainOut: { shortName: "rainOut", name: "rainOut", v: 8 },
	dice: { shortName: "dice", name: "dice", v: 9 },
	pandora: { shortName: "pandora", name: "pandora", v: 10 },
	jackpot: { shortName: "jackpot", name: "jackpot", v: 11 },
	manual: { shortName: "manual", name: "manual", v: 12 },
	activity: { shortName: "activity", name: "activity", v: 13 },
	contest: { shortName: "contest", name: "contest", v: 14 },
	depositBonus: { shortName: "depBonus", name: "depositBonus", v: 15 },
	withdrawApprove: { shortName: "approve", name: "withdrawApprove", v: 16 },
	withdrawReject: { shortName: "reject", name: "withdrawReject", v: 17 },
	warn: { shortName: "warn", name: "warn", v: 18 },
	bankIn: { shortName: "bankIn", name: "bankIn", v: 19 },
	bankOut: { shortName: "bankOut", name: "bankOut", v: 20 },

	slotegrator: { shortName: "slotegrator", v: 21 },
	sportegrator: { shortName: "sportegrator", v: 22 },
	crash: { shortName: "crash", v: 23 },
	fortuneWheel: { shortName: "fortuneWheel", v: 24 },
	tierGift: { shortName: "tierGift", v: 25 },
	battle: { shortName: "battle", v: 26 },
	freespin: { shortName: "freespin", v: 27 },
	freespinIn: { shortName: "freespinIn", v: 28 },
	freespinOut: { shortName: "freespinOut", v: 29 },

	rakeback: { name: "rakeback", v: 30 },
	flappybet: { name: "flappybet", v: 31 },
};
export const getShortcutsO = () => {
	return {
		//menu:
		home: { title: "home", iconCa: "home", url: "home" },
		profile: { title: "profile", iconCa: "profile", url: "profile/{me}" },
		affiliation: { title: "affiliation", iconCa: "network", url: "affiliation" },
		rakeback: {
			title: "rakeback",
			iconCa: "rakeback",
			url: () =>
				storeSet({
					modal: {
						$set: { name: "fortunewheel", canClose: true, props: { mode: 1 } },
					},
				}),
		},
		history: { title: "history", iconCa: "history", url: "history" },
		//games:
		dice: { title: "dice", iconCa: "dice", url: "crypto-bitcoin-dice" },
		pandora: { title: "pandora", iconCa: "pandora", url: "pandora-online-casino" },
		slots: { title: "slots", iconCa: "slot", url: "crypto-slots" },
		sport: { title: "sport", icon: sportegrator, url: "crypto-sport-casino" },
		// crash: { title: "crash", icon: crash, url: "crypto-betting-crash" },
		crash: { title: "crash", icon: crash, url: "" },
		flappybet: { title: "flappybet", icon: flappyIcon, url: "originals" },
		fortuneWheel: {
			title: "fortune_wheel",
			iconCa: "fortune",
			url: () =>
				storeSet({
					modal: {
						$set: { name: "fortunewheel", canClose: true, props: { mode: 0 } },
					},
				}),
		},
		//promotion:
		contest: { title: "contest", iconCa: "contest", url: "promotion/contests" },
		deposit_bonus: { title: "deposit_bonus", iconCa: "gift", url: "promotion/deposit_bonus" },
		//billing:
		withdraw: { title: "withdraw", iconCa: "withdraw", url: "billing/withdraw" },
		faucet: { title: "faucet", iconCa: "faucet", url: "billing/faucet" },
		tip: { title: "tip", iconCa: "tip-send", url: "billing/tip" },
		exchange: { title: "exchange", iconCa: "exchange", url: "billing/exchange" },
		rain: { title: "rain", iconCa: "rain", url: "billing/rain" },
		bank: { title: "bank", iconCa: "bank", url: "billing/bank" },
	};
};
export const yesNoWrap = async (title, cb) => {
	await yesNoContentWrap({ title, cb });
};
export const closeModal = () => {
	storeSet({ modal: { $set: null } });
};
export const yesNoContentWrap = async ({
	title,
	content = null,
	hasComment = false,
	icon = null,
	yesHide = false,
	yesTitle = "yes",
	noTitle = "no",
	cb,
}) => {
	const ready = async (boolResponse, other) => {
		await storeSet({ modal: { $set: null } });
		if (typeof cb === "function") return cb(boolResponse, other);
		return;
	};
	storeSet({
		modal: {
			$set: {
				name: "yesno",
				canClose: false,
				props: {
					f: ready,
					title,
					content,
					hasComment,
					yesHide,
					yesTitle,
					noTitle,
					icon,
				},
			},
		},
	});
};
export const objToArray = (o) => {
	const arr = [];
	for (let key in o) arr.push(o[key]);
	return arr;
};
export const stripTrailingSlashes = (url) => (typeof url === "string" ? url.trim().replace(/^(.+?)\/*?$/, "$1") : url);
export const copy = (text) => {
	if (typeof document === "undefined") return;
	const input = document.createElement("input");
	input.setAttribute("value", text);
	document.body.appendChild(input);
	input.select();
	document.execCommand("copy");
	document.body.removeChild(input);
};
export const onCopy = (value) => {
	copy(value);
	Toast.success(lang.translate("copied"));
};
export const delay = (ms, response = undefined) => new Promise((resolve) => setTimeout(() => resolve(response), ms));

const prefixZero = (num, count = 2) => {
	return (`${num}`.length < count ? "0".repeat(count - 1) : "") + num;
};
export const timeLeftO = (t, valueOnExpire = "") => {
	if (t <= 0)
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			str: valueOnExpire,
		};
	// Time calculations for days, hours, minutes and seconds
	const days = Math.floor(t / (1000 * 60 * 60 * 24));
	const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((t % (1000 * 60)) / 1000);
	const str = `${days}d ${hours}h ${prefixZero(minutes, 2)}m ${prefixZero(seconds, 2)}s`;
	return { days, hours, minutes, seconds, str };
};
export const timeLeft = (t, valueOnExpire = "") => {
	if (t <= 0) return valueOnExpire;
	// Time calculations for days, hours, minutes and seconds
	const days = Math.floor(t / (1000 * 60 * 60 * 24));
	const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((t % (1000 * 60)) / 1000);
	return `${days}d ${hours}h ${prefixZero(minutes, 2)}m ${prefixZero(seconds, 2)}s`;
};
export const timeLeftNoDay = (t, valueOnExpire = "") => {
	if (t <= 0) return valueOnExpire;
	// Time calculations for days, hours, minutes and seconds
	const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((t % (1000 * 60)) / 1000);
	return `${hours}h ${prefixZero(minutes, 2)}m ${prefixZero(seconds, 2)}s`;
};
export const timeLeftS = (t, valueOnExpire = "") => {
	if (t <= 0) return valueOnExpire;
	return Math.floor(t / 1000);
};
export const limitByTime = (promises, timeLimitMs, timeLimitResponse) => {
	return Promise.race([delay(timeLimitMs, timeLimitResponse), Promise.all(Array.isArray(promises) ? promises : [promises])]);
};
/**
 * returns object after promiseAll on array with keys and modifiers
 * @param {*} arrKeyPromise [["a", promise1, ?func], ["b", promise2]]
 * @param {*} f
 */
export const promiseAllO = async (arrKeyPromise, f = (r) => r) => {
	const o = {};
	const arr = [];
	for (let i = 0; i < arrKeyPromise.length; i++) arr.push(arrKeyPromise[i][1]);
	const data = await Promise.all(arr);
	for (let i = 0; i < arrKeyPromise.length; i++) {
		let v = data[i];
		if (arrKeyPromise[i].length === 3 && typeof arrKeyPromise[i][2] === "function") v = arrKeyPromise[i][2](v);
		else v = f(v);
		o[arrKeyPromise[i][0]] = v;
	}
	return o;
};
export const randomBetween = (min, max) => {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
};
export const replaceAll = (str, regexString, func) => {
	return str.replace(new RegExp(regexString, "g"), func);
};

export const replaceFromTo = (str, charFrom, charTo = "\\s", func) => {
	const regex = `${charFrom}.*?(?=${charTo})`;
	return str.replace(new RegExp(regex, "gi"), func);
};

export const isDefined = (v) => {
	return typeof v !== "undefined" && v !== null && !(typeof v === "number" && isNaN(v));
};
export const isObjectPropsDefined = (o) => {
	for (let key in o) {
		if (!isDefined(o[key])) return false;
	}
	return true;
};
//example: choose(v, {"dark": 1, "light":2})
export const chooseValue = (v, propsO, _default = null) => propsO[v] || _default;
export const isServer = () => typeof window === "undefined";
export const isInt = (n) => parseInt(n) === n;
export const isFloat = (f) => parseFloat(f) === f;
export const isDecimal = (d) => Decimal.isDecimal(d);
export const isString = (str) => typeof str === "string";
export const isObject = (o) => typeof o === "object" && o !== null;
export const isValidMail = (mail) => {
	const regexMail = RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
	return regexMail.test(mail);
};
export const tryParseFloat = (v, defaultValue) => {
	if (isFloat(v)) return v;
	else if (isString(v) && v.length) {
		const a = parseFloat(v);
		return isNaN(a) ? defaultValue : a;
	} else if (isDecimal(v)) return tryParseFloat(v.toNumber(), defaultValue);
	return defaultValue;
};
export const tryParseInt = (v, defaultValue) => {
	if (isInt(v)) return v;
	else if (isString(v) && v.length) {
		const a = parseInt(v);
		return isNaN(a) ? defaultValue : a;
	} else if (isDecimal(v)) return tryParseInt(v.toNumber(), defaultValue);
	return defaultValue;
};
export const balanceToFixed = (v, n) => {
	v += "";
	if (v.indexOf(".") === -1) return `${v}.${"0".repeat(n)}`;
	const arr = v.split(".");
	arr[1] = arr[1].length > n ? arr[1].substring(0, n) : `${arr[1]}${"0".repeat(n - arr[1].length)}`;
	return `${arr[0]}.${arr[1]}`;
};
export const validAddress = (short, hash) => {
	const cryptoRegExps = {
		btc: "[13][a-km-zA-HJ-NP-Z1-9]{25,34}",
		bch: "((bitcoincash|bchreg|bchtest):)?(q|p)[a-z0-9]{41}",
		eth: "0x[a-fA-F0-9]{40}",
		ltc: "[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}",
		doge: "D{1}[5-9A-HJ-NP-U]{1}[1-9A-HJ-NP-Za-km-z]{32}",
		dash: "X[1-9A-HJ-NP-Za-km-z]{33}",
		xmr: "4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}",
		neo: "A[0-9a-zA-Z]{33}",
		xrp: "r[0-9a-zA-Z]{33}",
	};
	if (!cryptoRegExps[short]) return true;
	const regex = new RegExp(cryptoRegExps[short], "g");
	return regex.test(hash);
};
export const clickOutside = () => {
	if (typeof document === "undefined") return;
	document.getElementById("root").click();
};
export const fCase = (...arr) => {
	if (arr.length % 2 === 1) return;
	for (let i = 0; i < arr.length; i += 2) if ([undefined, null].indexOf(arr[i]) === -1 && !isNaN(arr[i])) return arr[i + 1];
};
// eslint-disable-next-line no-extend-native
String.prototype.ReplaceAll = function (regexString, func) {
	return this.replace(new RegExp(regexString, "g"), func);
};
// eslint-disable-next-line no-extend-native
String.prototype.ToBalanceFixed = function (n = 8) {
	let v = this;
	if (v.indexOf(".") === -1) return `${v}.${"0".repeat(n)}`;
	const arr = v.split(".");
	arr[1] = arr[1].length > n ? arr[1].substring(0, n) : `${arr[1]}${"0".repeat(n - arr[1].length)}`;
	return `${arr[0]}.${arr[1]}`;
};
// eslint-disable-next-line no-extend-native, react/display-name
String.prototype.ToWaveText = function () {
	const o = (
		<div className={`wavetext${isMobile() ? " mobile" : ""}`}>
			{this.split("").map((el, i) => (
				<b key={i}>{el}</b>
			))}
		</div>
	);
	return o;
};
// eslint-disable-next-line no-extend-native
String.prototype.Capitalize = function () {
	if (this === "") return "";
	return this.charAt(0).toUpperCase() + this.slice(1);
};
// eslint-disable-next-line no-extend-native
String.prototype.CapitalizeFirstLetter = function () {
	if (this === "") return "";
	return `${this.substring(0, 1).toUpperCase()}${this.substring(1)}`;
};
// eslint-disable-next-line no-extend-native
String.prototype.StartEndOnly = function (n = 4) {
	n = n * 1;
	if (!(n > 0)) n = 4;
	if (this.length <= n * 2) return this;
	return `${this.substring(0, n)}...${this.slice(-n)}`;
};
// eslint-disable-next-line no-extend-native
String.prototype.RemoveLineBreaks = function () {
	return this.replace(/(\r\n|\n|\t|\r)/gm, "");
};
// eslint-disable-next-line no-extend-native
String.prototype.ReplaceFromToInclusive = function (stringFrom, stringTo = "\\s", func) {
	const regex = `(?:${stringFrom})(.*?)(?<=${stringTo})`;
	return this.replace(new RegExp(regex, "gi"), func);
};
export const updateOnlineMarks = (state) => {
	const cls = ["col_online", "col_away", "col_busy", "col_offline"];
	const online = state.online;
	const els = document.getElementsByClassName("onlineMark");
	for (let el of els) {
		const un = el.getAttribute("data-un");
		const status = el.getAttribute("data-status") * 1;
		const newStatus = (typeof online.uns[un] === "undefined" ? 3 : online.uns[un]) * 1; //0 "online", 1 "away", 2 "busy", 3 "offline"
		if (status !== newStatus) {
			el.setAttribute("data-status", newStatus);
			el.classList.remove(...cls);
			el.classList.add(cls[newStatus]);
		}
	}
};
export const gameNameNormalized = (name) => {
	name = replaceAll(name.toLowerCase(), " ", "-");
	name = replaceAll(name, "%", "");
	name = replaceAll(name, "$", "");
	name = replaceAll(name, "@", "");
	name = replaceAll(name, ":", "");
	name = replaceAll(name, "#", "");
	name = replaceAll(name, "'", "");
	name = replaceAll(name, ",", "");
	name = replaceAll(name, "&", "-");
	name = replaceAll(name, "---", "-");
	name = replaceAll(name, "--", "-");
	return name;
};
export const onSlotGamePath = (gameName, gameProvider, demo) => {
	let _name = gameNameNormalized(gameName);
	let _provider = gameNameNormalized(gameProvider);
	return `${demo ? "demo" : "slot"}/${_name.toLowerCase()}/${_provider.toLowerCase()}`;
};

export const removeNulls = (obj) => {
	const o = {};
	for (let key in obj) if (obj[key] != null) o[key] = obj[key];
	return o;
};
export const getStorageParams = () => {
	const o = {};
	o.clickid = Storage.get("clickid", null, "str");
	o.subid = Storage.get("subid", null, "str");
	o.cost = Storage.get("cost", null, "str");
	o.refcode = tryParseInt(Storage.get("refcode", null), null);
	o.campaign = tryParseInt(Storage.get("campaign", null), null);
	o.promocode = Storage.get("promocode", null, "str");
	return o;
};
export const getSetParams = (obj) => {
	const _get = (params, prop) => params.get(prop) ?? Storage.get(prop, null) ?? obj?.[prop] ?? null;
	if (isServer()) return;
	const params = new URLSearchParams(window.location.search);
	let clickid = _get(params, "clickid");
	let subid = _get(params, "subid");
	let cost = _get(params, "cost");
	const promocode = _get(params, "promocode");
	let refcode = tryParseInt(_get(params, "refcode"), null); //number
	let campaign = tryParseInt(_get(params, "campaign"), null); //number
	refcode = refcode > 0 ? refcode : null;
	campaign = campaign > 0 ? campaign : null;
	if (!(clickid?.length > 0 && subid?.length > 0 && cost?.length > 0)) {
		clickid = null;
		subid = null;
		cost = null;
	}
	const o = {
		clickid,
		subid,
		cost,
		refcode,
		campaign,
		promocode,
	}
	Storage.set("clickid", clickid);
	Storage.set("subid", subid);
	Storage.set("cost", cost);
	Storage.set("refcode", refcode);
	Storage.set("campaign", campaign);
	Storage.set("promocode", promocode);
	return o;
};
