import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { matchPath, Route, Switch, Redirect } from "react-router-dom";

// import history from "../../classes/history"
//other:
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
//redux:
import { connect } from "react-redux";
// import { storeSet } from "../..";
//classes:
import { lang } from "../../classes/lang";
import { regUrlFrequency } from "../../classes/routesLazy";
import { withPerms } from "../../classes/player";
// import tawkTo from "tawkto-react";
import { sound } from "../../classes/sound";
import Toast from "../../classes/toast";
import { go } from "../../classes/routesLazy";
import * as cache from "../../classes/cache";
// import { logBanner } from "../../classes/utills";
// import { isObject } from "../../classes/utills";
//containers:
import ChatButton from "../ChatButton/ChatButton";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Modal from "../Modal/Modal";
import Menu from "../Menu/Menu";
import Chat from "../chat/Chat";
import Shortcuts from "../Shortcuts/Shortcuts";
import { renew, isSocketConnected } from "../../classes/socket";
import { storeSet } from "../..";

//icons:
// import { Icon } from "react-icons-kit";
const GoToPassword = () => {
	return (
		<div className="goToPassword">
			<p>{lang.translate("recommend_set_pass")}</p>
			<div className="btns">
				<button onClick={(e) => [e.stopPropagation(), go("password")]} className="diceBtn">
					<b>{lang.translate("set_password")}</b>
				</button>
			</div>
		</div>
	);
};

export const BtnToast = ({ text, btnText, goLocation }) => {
	return (
		<div className="btnToast">
			<p>{text}</p>
			<div className="btns">
				<button onClick={(e) => [e.stopPropagation(), go(goLocation)]} className="diceBtn">
					<b>{lang.translate(btnText)}</b>
				</button>
			</div>
		</div>
	);
};
BtnToast.propTypes = {
	text: PropTypes.string,
	btnText: PropTypes.string,
	goLocation: PropTypes.string,
};

GoToPassword.propTypes = {
	userName: PropTypes.string,
};

const GoToMail = () => {
	return (
		<div className="goToPassword">
			<p>{lang.translate("recommend_set_mail")}</p>
			<div className="btns">
				<button onClick={(e) => [e.stopPropagation(), go("mail")]} className="diceBtn">
					<b>{lang.translate("set_mail")}</b>
				</button>
			</div>
		</div>
	);
};
GoToMail.propTypes = {
	userName: PropTypes.string,
};

class App extends PureComponent {
	constructor(props) {
		super(props);
		this.refApp = null;
		if (typeof document === "undefined") {
			//server side:
			lang.setLocale(props.match.params.lang ? props.match.params.lang : props.lang); //if url doesnot contain lang, see redux
		} else {
			//client side:
			// cache.load({ storageName: "em", url: "/assets/twitter32.png", params: {} }).then((o) => {
			// 	storeSet({ twitterLink: { $set: o.url } });
			// });
			cache.load({ storageName: "ui", url: "/assets/offline.png", params: {} });
			////tawkTo(config.tawk.propertyId, config.tawk.key);
			//as router initialized in child of path, let's extraxt match.params manually:
			let activeRoute = {};
			for (let route of props.routes) {
				const params = matchPath(window.location.pathname, route);
				if (params) {
					Object.assign(activeRoute, route, { match: params });
					break;
				}
			}
			this.lang = lang.setLocale(activeRoute.match.params.lang ? activeRoute.match.params.lang : props.lang); //if url doesnot contain lang, see redux
		}
	}
	static get propTypes() {
		return {
			routes: PropTypes.array,
			match: PropTypes.object,
			initialData: PropTypes.any,
			chatLeft: PropTypes.bool,
			chatOpen: PropTypes.bool,
			lang: PropTypes.string,
			token: PropTypes.string,
			menuOpen: PropTypes.bool,
			siteSettings: PropTypes.object,
			siteSettingsDefault: PropTypes.object,
			me: PropTypes.string,
			temp: PropTypes.bool,
			mailVerified: PropTypes.bool,
			initFinished: PropTypes.bool,
			twitterLink: PropTypes.string,
			docIds: PropTypes.any,
		};
	}
	tawkF = async () => {
		if (typeof window.Tawk_API !== "undefined") {
			window.Tawk_API.onLoad = function () {
				window.Tawk_API.hideWidget();
			};
			window.Tawk_API.onChatMinimized = function () {
				window.Tawk_API.hideWidget();
			};
			window.Tawk_API.onChatHidden = function () {};
		}
	};
	componentDidUpdate = (prevProps) => {
		if (this.props.siteSettings) {
			let active = this.props.siteSettingsDefault.chat.active;
			if (this.props.siteSettings && this.props.siteSettings.chat) {
				active = this.props.siteSettings.chat.active;
			}
			const _sound = this.props.siteSettings !== prevProps.siteSettings;
			if (_sound) {
				if (active) {
					sound.enablePrefix("other");
					sound.enablePrefix("rain");
				} else {
					sound.disablePrefix("other");
					sound.disablePrefix("rain");
				}
			}
		}
		if (this.props.initFinished !== prevProps.initFinished || this.props.me !== prevProps.me) {
			if (this.props.me !== "guest") {
				if (!this.props.mailVerified) {
					Toast.warning(<GoToMail userName={this.props.me} />, {
						toastId: "toast_GoToMail",
						autoClose: false,
						// position: "top-center",
					});
				}
				if (this.props.temp) {
					Toast.warning(<GoToPassword userName={this.props.me} />, {
						toastId: "toast_GoToPassword",
						autoClose: false,
						// position: "top-center",
					});
				}
			}
		}
	};
	componentDidMount = () => {
		this.registerEvents();
		if (typeof document !== "undefined") {
			//Tawk init:
			this.tawkF();
			//logBanner(`WINTOMATO v:1.0.0`, { color: "green", fontSize: 40 });
			//logBanner(`WARNING! Do not use bot or any other script! You will be banned!`, { color: "yellow", background: "black", fontSize: 25 });
			try {
				window.anj_9a81318e_1eb1_4eb0_886c_3ab915bccc2c.init();
			} catch (err) {
				console.error(err);
			}
		}
		if (location.pathname.startsWith("/pt")) {
			storeSet({
				coin: { selected: { $set: "brl" } },
			});
		}
	};
	getMousePosition = (e) => {
		if (e.touches && e.touches.length > 1) e.preventDefault(); //prevent pinch-zoom
		if (e.originalEvent && e.originalEvent.touches && e.originalEvent.touches.length > 1) e.preventDefault();
		let touch;
		if (e.type === "touchstart" || e.type === "touchmove" || e.type === "touchend" || e.type === "touchcancel") {
			if (e && e.originalEvent) touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
			else if (e && e.touches) touch = e.touches[0];
			else touch = { pageX: 0, pageY: 0 };
		} else touch = e;
		if (!touch) {
			return;
		}
		document.xPercent = parseInt((touch.pageX / window.innerWidth) * 100);
		document.yPercent = parseInt((touch.pageY / window.innerHeight) * 100);
	};
	eventLogger = (event) => {
		switch (event.type) {
			case "visibilitychange": {
				// console.log("isSocketConnected", document.visibilityState, isSocketConnected());
				if (document.visibilityState === "visible") {
					if (!isSocketConnected()) renew();
				}
				break;
			}
			default:
				console.log(`Event: ${event.type}`);
				break;
		}
	};

	registerEvents = () => {
		if (typeof document === "undefined") return;
		// let scrollTim;
		document.addEventListener("mousemove", (e) => this.getMousePosition(e));
		document.addEventListener("touchstart", (e) => this.getMousePosition(e), { passive: false });
		document.addEventListener("touchmove", (e) => this.getMousePosition(e), { passive: false });
		document.addEventListener("touchend", (e) => this.getMousePosition(e), { passive: false });
		document.addEventListener("touchcancel", (e) => this.getMousePosition(e), { passive: false });
		document.addEventListener("visibilitychange", (e) => this.eventLogger(e), { passive: false });
		//on safari only:
		// document.addEventListener("gesturestart", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("gesturechange", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("gestureend", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("dragstart", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("drag", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("dragend", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("dragover", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("dragenter", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("dragleave", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("drop", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("transitionstart", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("transitionrun", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("transitionend", (e) => e.preventDefault(), { passive: false });
		// document.addEventListener("transitioncancel", (e) => e.preventDefault(), { passive: false });
		// window.isScrolling = false;
		// window.addEventListener(
		// 	"scroll",
		// 	function () {
		// 		window.clearTimeout(scrollTim);
		// 		window.isScrolling = true;
		// 		scrollTim = setTimeout(function () {
		// 			window.isScrolling = false;
		// 		}, 66);
		// 	},
		// 	false
		// );
	};
	render() {
		// if (typeof document !== "undefined") {
		// 	const data = {}
		// 	let arr = document.cookie.split("; ");
		// 	arr.forEach(el => {
		// 		let item = el.split("=");
		// 		try {
		// 			data[item[0]] = JSON.parse(item[1])
		// 		} catch (err) { }
		// 	})
		// 	console.log(data);
		// }
		// if (typeof document !== "undefined") {
		// 	console.log("SSSS", this.props.routes);
		// }
		return (
			<div id="app" ref={(el) => (this.refApp = el)} className="app">
				{this.props.docIds && <ToastContainer className="toast-container" />}
				{this.props.docIds && <Modal />}
				{this.props.docIds && <ChatButton chatOpen={this.props.chatOpen} chatLeft={this.props.chatLeft} />}
				{this.props.docIds && <Shortcuts chatOpen={this.props.chatOpen} chatLeft={this.props.chatLeft} />}
				{this.props.twitterLink && <Chat />}
				{this.props.docIds && <Menu menuOpen={this.props.menuOpen} />}
				<div className={`bodyPane ${this.props.chatOpen ? "chatOn" : "chatOff"} ${this.props.chatLeft ? "right" : "left"}`}>
					{this.props.docIds && <Header match={this.props.match} chatOpen={this.props.chatOpen} chatLeft={this.props.chatLeft} />}
					<Switch>
						{this.props.routes.map((route) => {
							// if (route.to) return <Route
							// 	key={route.path.replace("/", "_")}
							// 	path={route.to}
							// 	exact={route.exact}
							// 	render={() => {
							// 		window.location.reload();
							// 	}}
							// />;
							return (
								<Route
									key={route.path.replace("/", "_")}
									path={route.path}
									exact={route.exact}
									render={(props) => {
										typeof document !== "undefined" && regUrlFrequency(route);
										return route.to ? (
											<Redirect to={route.to} />
										) : this.props.token && route.tokenTo ? (
											<Redirect to={route.tokenTo} />
										) : !this.props.token && route.guestTo ? (
											<Redirect to={route.guestTo} />
										) : (
											<route.component ref={(el) => withPerms(el, route.perms)} initialData={this.props.initialData} {...props} route={route} />
										);
									}}
								/>
							);
						})}
					</Switch>
					{this.props.docIds && <Footer chatOpen={this.props.chatOpen} chatLeft={this.props.chatLeft} lang={this.lang} />}
				</div>
			</div>
		);
	}
}
const a = (store) => {
	return {
		chatLeft: store.chat && store.chat.left,
		chatOpen: store.chat && store.chat.open,
		lang: store.lang.key,
		token: store.token,
		menuOpen: store.menuOpen,
		siteSettings: store.playerConfig.siteSettings,
		siteSettingsDefault: store.playerConfigDefaults.siteSettings,
		me: store.player.userName,
		mailVerified: store.player.mailVerified,
		temp: store.player.temp,
		initFinished: store.initFinished,
		twitterLink: store.twitterLink,
		pathname: store.pathname,
		docIds: typeof window !== "undefined" && window.location.port * 1 === 3333 ? [] : store.docIds,
	};
};
export default connect(a, null, null, { forwardRef: true })(App);
