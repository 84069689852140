import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { delay } from "../../classes/utills";

const Loader2 = (props = {}) => {
	const [tickPassed, setTickPassed] = useState(false);
	const ms = props.ms;
	useEffect(() => {
		delay(ms || 0).then(() => setTickPassed(true));
	}, [ms, tickPassed]);
	return tickPassed ? (
		<div className={`loader2 ${props.className ? props.className : ""}`}>
			<div className="lds-fb">
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	) : null;
};
Loader2.propTypes = {
	className: PropTypes.string,
	ms: PropTypes.number,
};

export default Loader2;
