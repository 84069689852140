import React, { Component } from "react";
import PropTypes from "prop-types";
import * as lottie from "lottie-web";

class Lottie extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stopped: false,
		};
		this.ref = null;
		this.anim = null;
		this.speed = 1;
		this.paused = false;
		this.src = props.src;
		this.tim = null;
	}
	static get propTypes() {
		return {
			src: PropTypes.string.isRequired,
			loop: PropTypes.bool,
			style: PropTypes.object,
			playOnClick: PropTypes.bool, //default false
			stopAfterSeconds: PropTypes.number, //default 30
			contentAfterSeconds: PropTypes.element,
			renderer: PropTypes.oneOf(["svg", "canvas"]),
		};
	}

	componentDidMount = () => {
		this.load();
	};
	shouldComponentUpdate = (nextProps, nextState) => {
		if (this.state.stopped !== nextState.stopped) return true;
		return false;
	};
	load = () => {
		this.anim = lottie.loadAnimation({
			container: this.ref, // the dom element that will contain the animation
			renderer: this.props.renderer || "svg",
			loop: typeof this.props.loop === "boolean" ? this.props.loop : true,
			autoplay: true,
			className: "lottieAnim",
			path: this.props.src,
		});
	};
	play = () => {
		this.anim.play();
		this.anim.setSpeed(this.speed);
		this.state.stopped && this.setState({ stopped: false });
	};
	pause = () => {
		this.paused = !this.paused;
		if (this.paused) {
			this.anim.setSpeed(0);
			this.setState({ stopped: true });
		} else {
			this.play();
		}
	};
	stop = () => {
		this.anim.stop();
	};
	reverse = () => {
		this.speed *= -1;
		this.anim.setSpeed(this.speed);
	};
	onClick = () => {
		if (this.props.playOnClick === true) {
			this.stop();
			this.play();
		}
	};
	onStopAfter = () => {
		if (this.props.stopAfterSeconds === Infinity) return;
		if (this.tim) return;
		const ms = typeof this.props.stopAfterSeconds === "number" ? this.props.stopAfterSeconds * 1000 : 60000;
		if (ms > 0) this.tim = setTimeout(() => [this.pause()], ms);
	};
	componentWillUnmount = () => {
		if (this.tim) clearInterval(this.tim);
	};
	render() {
		return (
			<div
				className={this.state.stopped ? "off" : "on"}
				onClick={this.onClick}
				ref={(el) => [(this.ref = el), this.onStopAfter()]}
				style={this.props.style ? this.props.style : { width: "128px", height: "128px" }}
				// className="lottie"
				// data-animation-path="/data.json"
				// data-anim-loop="true"
			>
				{this.state.stopped && (this.props.contentAfterSeconds || null)}
			</div>
		);
	}
}

export default Lottie;
