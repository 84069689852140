import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import SImpleDropDown from "../../components/simpleDropDown/SimpleDropDown";
//containers:
// import Container from "../../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { KEYS } from "../../classes/keywords";
import { go } from "../../classes/routesLazy";
import { getShortcutsO, isObject } from "../../classes/utills";
import { chat } from "../../classes/chat";
import ImageCached from "../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
// import { close } from "react-icons-kit/fa/close";
import IconCa, { iconCaName } from "../../classes/iconCa";
import { gears } from "react-icons-kit/fa/gears";
// import { support } from "react-icons-kit/fa/support";
import { ic_support_agent as support } from "react-icons-kit/md/ic_support_agent";

import { storeSet } from "../..";

import Axios from "../../classes/axios";
import Toast from "../../classes/toast";

class Shortcuts extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			items: [
				{ cl: "red", icon: support, title: "support", url: this.showSupport, type: "button" },
				{ cl: "green", icon: null, iconCa: "deposit", title: "deposit", url: "billing/deposit", type: "button" },
			],
			system: [
				{ type: "func", cl: "blue", f: this.langs },
				{ sysName: "lang", cl: "blue", icon: gears, iconCa: null, name: null, url: this.showShortcutModal, type: "button" },
			],
		};
		this.data = getShortcutsO();
	}
	static get propTypes() {
		return {
			langO: PropTypes.object,
			chatOpen: PropTypes.bool,
			chatLeft: PropTypes.bool,
			langRoute: PropTypes.any,
			me: PropTypes.string,
			shortcut: PropTypes.array,
			shortcutOn: PropTypes.bool,
		};
	}
	getImg = (name) => {
		return (
			// <img
			// 	className="imgFlag"
			// 	src={`/assets/flags/${name}.png`}
			// 	alt=""
			// 	// alt={chunk.short}
			// 	// title={chunk.short}
			// 	onError={(e) => {
			// 		if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
			// 	}}
			// />
			<ImageCached storageName="flag" className="imgFlag" url={`/assets/flags/${name}.png`} alt="" params={{}} />
		);
	};
	showSupport = () => {
		if (isObject(window.Tawk_API) && typeof window.Tawk_API.showWidget === "function") {
			window.Tawk_API.showWidget();
			window.Tawk_API.maximize();
		}
	};
	showShortcutModal = async () => {
		await storeSet({
			modal: {
				$set: {
					name: "shortcutmodal",
					canClose: true,
					props: {},
				},
			},
		});
	};
	onToggleShortcuts = async (v) => {
		this.setState({ loading: true });

		const result = await Axios.post("/api/player/shortcutSet", {
			shortcutOn: !!v,
		});
		this.setState({ loading: false });
		if (result && !result.error) await storeSet({ playerConfig: { shortcutOn: { $set: result.shortcutOn ?? true } } });
		else if (result.message) {
			Toast.error(lang.translate(result.message));
		}
	};
	langs = () => {
		const onLangSelect = (locale) => {
			lang.storeLocale(locale);
			let str = window.location.pathname;
			if (str.charAt(3) === "/") str = str.substring(4);
			else if (str.length === 3) str = "";
			go(str);
		};
		const selectedLang = lang.getSelectedSync({ lang: this.props.langO, langRoute: this.props.langRoute });
		return (
			<SImpleDropDown
				onMouseLeave={() => [document.getElementById("root").click()]}
				render={true}
				// className="calendarDropDown"
				// headerClassname= "calendarHeaderDropDown"
				headerClassname="diceBtn blue ddHeader p5_left_i"
				header={<div>{this.getImg(selectedLang)}</div>}
				content={
					<div className="btns">
						{KEYS.filter((el) => el !== selectedLang).map((el, i) => (
							<button onClick={() => [document.getElementById("root").click(), onLangSelect(el)]} className="btnDefault" key={i}>
								{this.getImg(el)}
								<b>{el}</b>
							</button>
						))}
					</div>
				}
				showArrow={true}
			/>
		);
	};
	onShortcutClick = (o) => {
		if (o.url) {
			if (typeof o.url === "string") {
				o.url = o.url.replaceAll("{me}", this.props.me);
				chat.closeChatPane(true).then(() => {
					go(o.url);
				});
			} else if (typeof o.url === "function") o.url();
		}
	};
	renderShortcut = (i, o) => {
		if (!o) return null;
		return (
			<div key={`shortcut_${i}`} className="shortcut">
				<div className="content">
					{(typeof o.type === "undefined" || o.type === "button") && (
						<button onClick={(e) => [e.stopPropagation(), this.onShortcutClick(o)]} className={`diceBtn btnShortcut ${o.cl || "yellow"}`}>
							{o.icon && <Icon icon={o.icon} size={24} />}
							{o.iconCa && <IconCa name={iconCaName[o.iconCa]} />}
							{o.title && <b>{lang.translate(o.title)}</b>}
						</button>
					)}
					{o.type === "func" && o.f()}
				</div>
			</div>
		);
	};
	renderShortcuts = () => {
		const arr = [];
		for (let [i, shortcut] of this.state.system.entries()) arr.push(this.renderShortcut(i, shortcut));
		for (let [i, shortcut] of this.state.items.entries()) arr.push(this.renderShortcut(1000 + i, shortcut));
		for (let [i, shortcut] of this.props.shortcut.entries()) arr.push(this.renderShortcut(2000 + i, this.data[shortcut]));
		return (
			<div className="wrapper">
				<button
					className="diceBtn black btnToggleShortcuts"
					onClick={(e) => e[(e.stopPropagation(), this.onToggleShortcuts(!this.props.shortcutOn))]}
				>
					{this.props.shortcutOn ? "-" : "+"}
				</button>
				{this.props.shortcutOn ? arr : arr.slice(0,3)}
			</div>
		);
	};
	render() {
		// if (typeof this.props.shortcutOn !== "boolean") return null;
		return (
			<div className={`shortcuts ${this.props.chatOpen ? "chatOpen" : "chatClosed"} ${this.props.chatLeft ? "chatLeft" : "chatRight"}`}>
				{this.renderShortcuts()}
			</div>
		);
	}
}
const a = (store) => {
	return {
		langO: store.lang,
		langRoute: store.langRoute,
		me: store.player.userName,
		shortcut: store.playerConfig.shortcut || [],
		shortcutOn: store.playerConfig.shortcutOn,
	};
};

export default connect(a, null, null, { forwardRef: true })(Shortcuts);
