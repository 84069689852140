// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { delay } from "./utills";
// import { delay } from "./utills";

const storages = {
	banners: null,
};
const notSupported = () => {
	return typeof window === "undefined" || typeof caches === "undefined" || typeof caches.open === "undefined";
};
//1) get storage or 2) open, cache and return storage
const storageGet = async (storageName) => {
	if (!storages[storageName]) storages[storageName] = await caches.open(storageName);
	return storages[storageName];
};
const queue = {};
let running = false;
const queueProcess = async () => {
	running = true;
	await queueUpdate();
};
const queueUpdate = async () => {
	if (Object.keys(queue).length === 0) {
		running = false;
		return;
	}
	const firstKey = Object.keys(queue)[0];
	const o = queue[firstKey];
	await save(o);
	delete queue[firstKey];
	await queueUpdate();
};
const save = async ({ storageName, url, params }) => {
	if (notSupported()) return;
	console.log(`saving: ${url}`);
	if (typeof params !== "object" || params === null) params = {};
	try {
		const storage = await storageGet(storageName);
		const request = new Request(url);
		const _response = await fetch(request);
		const blob = await _response.blob();
		const response = new Response(blob, { headers: { all: JSON.stringify(params) } });
		await storage.put(request, response);
	} catch (err) {
		console.error(`caching failed: ${storageName}, ${url}, ${JSON.stringify(params)}, ${err.message}`);
	}
};
const dAll = {};
//get synced url:
export const loadSync = ({ storageName, url, params }) => {
	if (dAll[url]) return dAll[url];
	else {
		const ret = { storageName, url, params, cached: false };
		load({ storageName, url, params }); //do not wait here (this will cache in background uncached urls)
		return ret;
	}
};
const matched = {};
const wait = async (url) => {
	if (dAll[url]) return;
	await delay(100);
	await wait(url);
};
//return blob url and params:
export const load = async ({ storageName, url, params }) => {
	if (dAll[url] && (dAll[url].cached || dAll[url].error)) return dAll[url];
	const ret = { storageName, url, params, cached: false };
	if (typeof window === "undefined") return ret;
	if (notSupported()) {
		ret.cached = true;
		dAll[url] = ret;
		return dAll[url];
	}
	try {
		if (matched[url]) {
			await wait(url);
			return dAll[url];
		}
		// console.log(`matching: ${url}`);
		matched[url] = true; //other request already trying to check
		const storage = await storageGet(storageName);
		const cachedResponse = await storage.match(url);
		if (!cachedResponse || !cachedResponse.ok) {
			// save(Object.assign({}, ret));
			queue[url] = Object.assign({}, ret);
			!running && queueProcess();
			dAll[url] = { url, params, cached: false };
			return ret;
		}
		const blob = await cachedResponse.blob();
		const blobUrl = URL.createObjectURL(blob);
		dAll[url] = { url: blobUrl, params: JSON.parse(cachedResponse.headers.get("all")), cached: true };
		return dAll[url];
	} catch (err) {
		console.error(`cache load failed: ${storageName}, ${url}, ${err.message}`);
		ret.error = true;
		dAll[url] = ret; //onerror do not cache this url anymore
		return ret;
	}
};
// var blobToBase64 = function(blob, callback) {
// 	var reader = new FileReader();
// 	reader.onload = function() {
// 			var dataUrl = reader.result;
// 			var base64 = dataUrl.split(',')[1];
// 			callback(base64);
// 	};
// 	reader.readAsDataURL(blob);
// };
export const init = async () => {
	if (notSupported()) return;
	storages.banners = await storageGet("banners");
};
const ImageCached = (props) => {
	// eslint-disable-next-line no-unused-vars
	const { storageName, url, params, className, alt = "", title = "", onLoad = () => {}, urlError, width, height } = props;
	return (
		<img
			src={url}
			className={className}
			alt={alt}
			title={title}
			onLoad={onLoad}
			width={width}
			height={height}
			onError={(e) => {
				if (e.target.src.indexOf(urlError || "") === -1) e.target.src = urlError || "";
			}}
		/>
	);
};

// const ImageCached = (props) => {
// 	const { storageName, url, params, className, alt = "", title = "", onLoad = () => {}, urlError, width, height } = props;
// 	const [loading, setLoading] = useState(true);
// 	const img = useRef(null);
// 	const mountParams = useRef(params);
// 	//error happened:
// 	const finish = useCallback(() => {
// 		const src = typeof urlError === "string" ? urlError : "/assets/icons/error@2x.png";
// 		if (img.current.src !== src) img.current.src = src;
// 		img.current.setAttribute("data-loaded", 1);
// 		setLoading(false);
// 	}, [urlError]);
// 	const ev = useCallback(() => {
// 		if (!img.current) return;
// 		img.current.setAttribute("data-loaded", 0);
// 		setLoading(true);
// 		if (url.indexOf("undefined") !== -1 || url.indexOf("null") !== -1) {
// 			finish();
// 			return;
// 		}
// 		load({ storageName, url, params: mountParams.current }).then((o) => {
// 			if (!img.current) return;
// 			if (o.error) finish();
// 			else {
// 				img.current.src = o.url;
// 				img.current.setAttribute("data-loaded", 1);
// 				setLoading(false);
// 			}
// 		});
// 	}, [finish, storageName, url]);
// 	useEffect(() => {
// 		ev();
// 	}, [ev]);
// 	const other = {};
// 	if (typeof width === "number") other.width = width;
// 	if (typeof height === "number") other.height = height;
// 	if (!url) {
// 		img.current = null;
// 		return null;
// 	}
// 	return (
// 		<img
// 			ref={img}
// 			alt={alt}
// 			title={title}
// 			className={`imgCached ${loading ? "loading" : "loaded"} ${className || ""}`}
// 			data-loaded="0"
// 			onLoad={onLoad}
// 			{...other}
// 		/>
// 	);
// };
ImageCached.propTypes = {
	storageName: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	urlError: PropTypes.string,
	params: PropTypes.object,
	className: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	onLoad: PropTypes.func,
	onError: PropTypes.func,
	width: PropTypes.number,
	height: PropTypes.number,
};
export default ImageCached;
