import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Input from "../../../components/input/Input";

const JParams = (props) => {
	JParams.propTypes = {
		usd: PropTypes.string,
		pa: PropTypes.string,
		onSetParams: PropTypes.func,
	};
	const [usd, setUsd] = useState(`${props.usd}`);
	const [pa, setPa] = useState(`${props.pa}`);
	const onChange = (e) => {
		if (e.target.name === "usd") {
			setUsd(e.target.value);
		} else if (e.target.name === "pa") {
			setPa(e.target.value);
		}
	};
	return (
		<div className="JParams">
			<b>J.Params</b>
			<div>
				<b>Only Over NET USD amount:</b>
				<Input
					disabled={false}
					className="c"
					name="usd"
					placeholder="Over USD"
					value={usd}
					onChange={onChange}
					onFocus={(e) => e.target.select()}
					onBlur={(e) => onChange(e, true)}
					validation="amount"
					toFixed={4}
				/>
			</div>
			<div>
				<b>Only Over Payout:</b>
				<Input
					disabled={false}
					className="c"
					name="pa"
					placeholder="Over PA"
					value={pa}
					onChange={onChange}
					onFocus={(e) => e.target.select()}
					onBlur={(e) => onChange(e, true)}
					validation="amount"
					min={1}
					toFixed={2}
				/>
			</div>
			<div className="btns">
				<button onClick={(e) => [e.stopPropagation(), props.onSetParams({ pa, usd })]} className="diceBtn btnApplyJParams red">
					Apply J.Params
				</button>
			</div>
		</div>
	);
};
export default JParams;
