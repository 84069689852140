import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
//classes:
// import { lang } from "../../classes/lang"
import { chat } from "../../../classes/chat";
import List from "../../../components/list/List";
import { delay, isMobile } from "../../../classes/utills";
//icons:
// import { Icon } from "react-icons-kit"
// import twitter64 from "./data/twitter-64.png";

class ChatEmoji extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.listRef = React.createRef();
		ChatEmoji.inst = this;
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
		};
	}
	componentDidMount = async () => {
		await delay(1000);
		this.listRef.current.scroll.refresh();
		chat.setEmojiListRef(this.listRef.current);
	};
	render() {
		return (
			<Container className="chatEmoji" noDocs={true}>
				<>
					<header>{chat.getEmojiHeaderButtons()}</header>
					<List
						ref={this.listRef}
						ren={true}
						data={chat.getEmojis()}
						options={{ bounce: true, momentum: true, useTransition: isMobile() ? false : true }}
					/>
				</>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(ChatEmoji);
