import React from "react";
import PropTypes from "prop-types";

const Loader = (props = {}) => {
	return (
		// <div className={`loader ${props.className ? props.className : ""}`}>
		// 	<div className="dot" />
		// 	<div className="dot" />
		// 	<div className="dot" />
		// 	<div className="dot" />
		// 	<div className="dot" />
		// </div>
		<div className={`loader loader-2 ${props.className ? props.className : ""}`} style={props.style ?? {}}>
			<svg className="loader-star" xmlns="http://www.w3.org/2000/svg" version="1.1">
				<polygon points="29.8 0.3 22.8 21.8 0 21.8 18.5 35.2 11.5 56.7 29.8 43.4 48.2 56.7 41.2 35.1 59.6 21.8 36.8 21.8 " fill="#18ffff" />
			</svg>
			<div className="loader-circles"></div>
		</div>
	);
};
Loader.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
};

export default Loader;
