import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
// import Container from "../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { showRollInfo } from "../../classes/games/dice";
import ImageCached from "../../classes/cache";
//icons:
// import { Icon } from "react-icons-kit";
// import { shareSquareO as shareIcon } from "react-icons-kit/fa/shareSquareO";
// import { infoCircle } from "react-icons-kit/fa/infoCircle";

class BetList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			rolls: null,
		};
		this.N = 15;
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			rolls: PropTypes.array,
			lastRoll: PropTypes.object,
		};
	}
	// shouldComponentUpdate = (nextProps, nextState) => {
	// 	const rolls = this.state.rolls !== nextProps.rolls;
	// 	const lastRoll = this.props.lastRoll._id !== nextProps.lastRoll._id;
	// 	const r = this.state.r !== nextState.r;
	// 	return rolls || lastRoll || r;
	// };
	onData = () => {
		if (this.state.rolls === null && Array.isArray(this.props.rolls)) {
			let rolls = [...this.props.rolls];
			rolls = rolls.length > this.N ? rolls.slice(0, this.N) : rolls;
			this.setState({ rolls });
		}
	};
	componentDidMount = () => {
		this.onData();
	};
	componentDidUpdate = (prevProps) => {
		this.onData();
		if (prevProps.lastRoll._id !== this.props.lastRoll._id) {
			let rolls = [this.props.lastRoll, ...(this.state.rolls || [])];
			rolls = rolls.length > this.N ? rolls.slice(0, this.N) : rolls;
			this.setState({ rolls });
		}
	};

	formatNumber = (str) => {
		if (typeof str === "undefined") return <></>;
		str = str.toFixed(8);
		str = str.substr(0, str.charAt(0) === "-" ? 11 : 10);
		let i = 0;
		return str.split("").reduce((a, c) => {
			i++;
			switch (c) {
				case "0":
					return a.concat(
						<i key={"fmt_n_" + i} className="z">
							0
						</i>
					);
				default:
					return a.concat(<i key={"fmt_n_" + i}>{c}</i>);
			}
		}, []);
	};
	outcomeToStr = (outcome) => {
		if (typeof outcome === "undefined") return "--.--";
		let outcomeStr = outcome.toFixed(2);
		if (outcomeStr.length === 4) outcomeStr = `0${outcomeStr}`;
		return outcomeStr;
	};
	toRoll = (o) => {
		const cl = o.win ? "success" : "alert";
		const isFlash = o.n1 > o.n || o.num > 1;
		return (
			<div key={o._id} className="roll">
				<div className="c shareBtn">
					{/* <button onClick={(e) => this.shareRollClick(e, o.n)} className={`diceBtn ${o.win ? "green" : "red"}`}>
						<Icon icon={shareIcon} />
					</button> */}
					<button onClick={(e) => [e.stopPropagation(), showRollInfo(o._id.split("-")[0])]} className={`diceBtn ${o.win ? "green" : "red"}`}>
						{/* <Icon icon={infoCircle} size={24} /> */}
						<ImageCached width={24} height={24} storageName="ui" url={`/assets/button_logo.png`} params={{}} />
						{/* <img
							alt=""
							src={`/assets/button_logo.png`}
							width="24"
							height="24"
							onError={(e) => {
								if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
							}}
						/> */}
					</button>
				</div>
				<div className="c pa">
					<b title={lang.translate("nonce")}>{o.n}</b>
					<b title={lang.translate("payout")}>
						{o.pa}
						<i className="gold">x</i>
					</b>
					<b title={lang.translate("chance")}>
						{o.chance}
						<i className="gold">%</i>
					</b>
				</div>
				<div className="c bars">
					{/* <div className="img">bb</div> */}
					<div className="stripW">
						<div className="strip">
							<div
								style={
									isFlash ? ((o.over === 1 && o.win) || (o.over === 0 && !o.win) ? { right: "26px" } : { left: "26px" }) : { left: `${o.outcome}%` }
								}
								className={`outcomeWrapper ${o.win ? "green" : "red"}`}
							>
								<div title={lang.translate("outcome")} className={`outcome ${o.win ? "green" : "red"}`}>
									{isFlash ? "FLSH" : o.outcomeStr}
								</div>
							</div>
							<div
								style={{ marginLeft: o.over === 1 ? `${o.rv}%` : "0%", marginRight: o.over === 1 ? "0%" : `${100 - o.rv}%` }}
								className={`stripFill ${o.win ? "green" : "red"}`}
							></div>
							<label title={lang.translate(o.over === 1 ? "roll_over" : "roll_under")}>
								<b className="under">{`${lang.translate(o.over === 1 ? ">" : "<")} ${this.outcomeToStr(o.rv)}`}</b>
								<b className="pa">{o.pa}x</b>
							</label>
						</div>
					</div>
				</div>
				<div className="c bet">
					<div>
						<b>{lang.translate("bet")}:</b>
						<b>{this.formatNumber(o.bet)}</b>
					</div>
					<div>
						<b>{lang.translate("delta")}:</b>
						<b className={cl}>{this.formatNumber(o.delta)}</b>
					</div>
					<div className="sep"></div>
					<div>
						<b>{lang.translate("profit")}:</b>
						<b className={cl}>{this.formatNumber(o.profit)}</b>
					</div>
				</div>
				<div className="c coin">
					{/* <img width={32} height={32} alt={o.short} title={o.short} src={`/assets/icons/${o.short}@2x.png`} /> */}
					<ImageCached width={32} height={32} storageName="coin" url={`/assets/icons/${o.short}@2x.png`} alt={o.short} title={o.short} params={{}} />
				</div>
			</div>
		);
	};
	render() {
		//test
		if (!Array.isArray(this.state.rolls)) return null;
		return (
			<div className="betList">
				<div className="wrapper">
					<div className="_rolls">
						{this.state.rolls.map((el) => {
							return this.toRoll(el);
						})}
					</div>
				</div>
			</div>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		lastRoll: store.oLastRoll,
	};
};

export default connect(a, null, null, { forwardRef: true })(BetList);
