import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
import List from "../../../components/list/List";
import lang from "../../../classes/lang";
//classes:
// import { lang } from "../../classes/lang"
import { chat } from "../../../classes/chat";
import ImageCached from "../../../classes/cache";
//icons:
import { Icon } from "react-icons-kit";
import { ic_chevron_right } from "react-icons-kit/md/ic_chevron_right";
import { focusNext } from "../../../classes/utills";

class ChatRooms extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}
	static get propTypes() {
		return {
			chatRooms: PropTypes.object,
			chatRoomSelected: PropTypes.object,
			roomsOnline: PropTypes.object,
			lang: PropTypes.string,
		};
	}
	onChatRoomChangeClick = async (e, vip, abbrevation) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		await chat.chatRoomChange(vip, abbrevation);
		await this.setState({ loading: false });
		await chat.setChatLocation("chat");
	};
	info = {
		Enter: "btnComChat",
		btnComRooms: {},
		btnComChat: {},
		btn_room_standard_0: { down: ["btn_room_standard_1", "btn_room_vip_0"], up: "btnComRooms" },
		btn_room_standard_1: { down: ["btn_room_standard_2", "btn_room_vip_0"], up: ["btn_room_standard_0"] },
		btn_room_standard_2: { down: ["btn_room_standard_3", "btn_room_vip_0"], up: ["btn_room_standard_1"] },
		btn_room_standard_3: { down: ["btn_room_standard_4", "btn_room_vip_0"], up: ["btn_room_standard_2"] },
		btn_room_standard_4: { down: ["btn_room_standard_5", "btn_room_vip_0"], up: ["btn_room_standard_3"] },
		btn_room_standard_5: { down: ["btn_room_standard_6", "btn_room_vip_0"], up: ["btn_room_standard_4"] },
		btn_room_standard_6: { down: ["btn_room_standard_7", "btn_room_vip_0"], up: ["btn_room_standard_5"] },
		btn_room_standard_7: { down: ["btn_room_standard_8", "btn_room_vip_0"], up: ["btn_room_standard_6"] },
		//vip:
		btn_room_vip_0: {
			down: ["btn_room_vip_1", "btn_room_vip_0"],
			up: [
				"btn_room_standard_6",
				"btn_room_standard_5",
				"btn_room_standard_4",
				"btn_room_standard_3",
				"btn_room_standard_2",
				"btn_room_standard_1",
				"btn_room_standard_0",
			],
		},
		btn_room_vip_1: { down: ["btn_room_vip_2", "btn_room_vip_0"], up: ["btn_room_vip_0"] },
		btn_room_vip_2: { down: ["btn_room_vip_3", "btn_room_vip_0"], up: ["btn_room_vip_1"] },
		btn_room_vip_3: { down: ["btn_room_vip_4", "btn_room_vip_0"], up: ["btn_room_vip_2"] },
		btn_room_vip_4: { down: ["btn_room_vip_5", "btn_room_vip_0"], up: ["btn_room_vip_3"] },
		btn_room_vip_5: { down: ["btn_room_vip_6", "btn_room_vip_0"], up: ["btn_room_vip_4"] },
		btn_room_vip_6: { down: ["btn_room_vip_7", "btn_room_vip_0"], up: ["btn_room_vip_5"] },
		btn_room_vip_7: { down: ["btn_room_vip_8", "btn_room_vip_0"], up: ["btn_room_vip_6"] },
	};
	renderRoomEntry = (room, vipMode, i) => {
		const selected =
			(this.props.chatRoomSelected ? this.props.chatRoomSelected.abbrevation : "en") === room.abbrevation &&
			(this.props.chatRoomSelected ? this.props.chatRoomSelected.vip : false) === room.vip;
		return (
			<button
				id={`btn_room_${vipMode}_${i}`}
				disabled={this.state.loading || !room.active}
				onClick={(e) => this.onChatRoomChangeClick(e, !!room.vip, room.abbrevation)}
				className={`diceBtn ${selected ? "green" : "grey"}`}
				onKeyDown={(e) => focusNext(e, this.info)}
			>
				<div className="entry">
					{/* <img
						width={22}
						height={22}
						alt={room.abbrevation}
						title={`${room ? `${room.room} ${room.vip ? "VIP" : ""}` : "en"}`}
						src={`/assets/flags/${room ? room.abbrevation : "en"}.png`}
						onError={(e) => {
							if (e.target.src.indexOf("error@2x.png") === -1) e.target.src = "/assets/icons/error@2x.png";
						}}
					/> */}
					<ImageCached
						width={22}
						height={22}
						storageName="flag"
						alt={room.abbrevation}
						title={`${room ? `${room.room} ${room.vip ? "VIP" : ""}` : "en"}`}
						url={`/assets/flags/${room ? room.abbrevation : "en"}.png`}
						params={{}}
					/>
					<label>{room.abbrevation}</label>
					<b>{room.room}</b>
				</div>
				<div className="roomOnline">{this.props.roomsOnline?.[`${room.vip ? 1 : 0}-${room.abbrevation}`] ?? "-"}</div>
				<Icon icon={ic_chevron_right} />
			</button>
		);
	};
	roomsData = (vipMode) => {
		const arr = [];
		let i = 0;
		if (!this.props.chatRooms[vipMode]) return [];
		for (let key in this.props.chatRooms[vipMode]) {
			const room = this.props.chatRooms[vipMode][key];
			arr.push({
				id: room.abbrevation,
				c: this.renderRoomEntry(room, vipMode, i),
			});
			i++;
		}
		return arr;
	};
	render() {
		return (
			<Container className="chatRooms" noDocs={true}>
				<>
					<section className="standard">
						<h3>{lang.translate("public_chat_rooms")}</h3>
						<List ren={true} data={this.roomsData("standard")} />
					</section>
					<section className="vip">
						<h3>{lang.translate("vip_chat_rooms")}</h3>
						<List ren={true} data={this.roomsData("vip")} />
					</section>
				</>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		chatRooms: store.chatRooms,
		chatRoomSelected: store.chatRoomSelected,
		roomsOnline: store.online.rooms,
	};
};

export default connect(a, null, null, { forwardRef: true })(ChatRooms);
