import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
// import Table from "../../components/table/Table";
import Select from "../../components/select/Select";
//containers:
import Container from "../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { mute } from "../../classes/player";
//icons:
import { Icon } from "react-icons-kit";
import { ic_volume_off as muteIcon } from "react-icons-kit/md/ic_volume_off";
// import {ic_volume_up as unmuteIcon} from 'react-icons-kit/md/ic_volume_up'
import { ic_block as banIcon } from "react-icons-kit/md/ic_block";
import Input from "../../components/input/Input";
import SelectPlayer from "../../components/select/SelectPlayer";

class Mute extends PureComponent {
	constructor(props) {
		super(props);
		this.permissionTimeData = [
			{ value: 0, title: lang.translate("forever") },
			{ value: 1, title: lang.translate("n_minutes", [1]) },
			{ value: 5, title: lang.translate("n_minutes", [5]) },
			{ value: 15, title: lang.translate("n_minutes", [15]) },
			{ value: 30, title: lang.translate("n_minutes", [30]) },
			{ value: 60, title: lang.translate("n_hours", [1]) },
			{ value: 60 * 3, title: lang.translate("n_hours", [3]) },
			{ value: 60 * 6, title: lang.translate("n_hours", [6]) },
			{ value: 60 * 12, title: lang.translate("n_hours", [12]) },
			{ value: 60 * 24, title: lang.translate("n_days", [1]) },
			{ value: 60 * 24 * 7, title: lang.translate("n_days", [7]) },
			{ value: 60 * 24 * 14, title: lang.translate("n_days", [14]) },
			{ value: 60 * 24 * 30, title: lang.translate("n_days", [30]) },
		];
		this.permissionReasonData = [
			{ value: "multi_account", title: lang.translate("multi_account") }, //Мульти аккаунт
			{ value: "rude_words", title: lang.translate("rude_words") }, //Ругательство, мат
			{ value: "cheating", title: lang.translate("cheating") }, //Мошенничество
			{ value: "faucet_farming", title: lang.translate("faucet_farming") }, //Злоупотребление краном
			{ value: "rain_farming", title: lang.translate("rain_farming") }, //Злоупотребление дождём
			{ value: "player_insulting", title: lang.translate("player_insulting") }, //Оскорбление игрока
			{
				value: "moderator_insulting",
				title: lang.translate("moderator_insulting"),
			}, //Оскорбление мода
			{
				value: "ignoring_moderator_warning",
				title: lang.translate("ignoring_moderator_warning"),
			}, //игнорирование предупреждений модератора
			{ value: "asking_for_tips", title: lang.translate("asking_for_tips") }, //Требования чаевых в чате
			{ value: "flooding_spaming", title: lang.translate("flooding_spaming") }, //Флуд, спам
			{ value: "self_referral", title: lang.translate("self_referral") }, //самореферрал
			{ value: "other", title: lang.translate("other") }, //Другое
		];
		this.state = {
			loading: false,
			permissionTimeSelected: null,
			permissionTimeData: this.permissionTimeData,
			permissionReasonSelected: null,
			permissionReasonData: this.permissionReasonData,
			notes: "",
			targetUserName: this.props.targetUserName || null,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			targetUserName: PropTypes.string,
		};
	}
	onSelectPermissionTimeEdit = (item) => {
		this.setState({ permissionTimeSelected: [item] });
	};
	onSelectPermissionReasonEdit = (item) => {
		this.setState({ permissionReasonSelected: [item] });
	};
	onSelectPermissionTimeSearch = (pattern) => {
		const data = this.permissionTimeData.filter((el) => el.title.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);
		this.setState({ permissionTimeData: data });
	};
	onSelectPermissionReasonSearch = (pattern) => {
		const data = this.permissionReasonData.filter((el) => el.title.toLowerCase().indexOf(pattern.toLowerCase()) !== -1);
		this.setState({ permissionReasonData: data });
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onMuteClick = async (mode = 0) => {
		await this.setState({ loading: true });
		mode = ["mute", "ban"][mode];
		const o = {
			mode,
			reason: this.state.permissionReasonSelected[0].value,
			time: this.state.permissionTimeSelected[0].value || 0,
			notes: this.state.notes,
			targetUserName: this.state.targetUserName,
		};
		mute(o, (result) => {
			if (!result.error) {
				this.setState({
					loading: false,
					permissionTimeSelected: null,
					permissionReasonSelected: null,
					notes: "",
				});
			} else {
				this.setState({ loading: false });
			}
		});
	};
	onSelect = (item) => {
		this.setState({ targetUserName: item.id, headerSection: "player" });
	};
	onUnselect = () => {
		this.setState({ targetUserName: null });
	};
	render() {
		const disabled = this.state.loading || this.state.permissionReasonSelected === null || this.state.permissionTimeSelected === null;
		return (
			<Container className="mute">
				<section className="wrapper">
					<h3>{`${lang.translate("mute").Capitalize()} / ${lang.translate("ban")}`}</h3>
					<main>
						<SelectPlayer
							// ref={(el) => withPerms(el, ["PLAYER_FIND"])}
							selected={this.state.targetUserName ? [this.state.targetUserName] : []}
							onSelect={this.onSelect}
							onUnselect={this.onUnselect}
						/>
					</main>
					<main>
						<Select
							style={{ width: "150px" }}
							placeholder={lang.translate("duration")}
							caseSearch={false}
							valueHeader={false}
							onSelect={this.onSelectPermissionTimeEdit}
							onSearch={this.onSelectPermissionTimeSearch}
							multi={false}
							min={1}
							selected={this.state.permissionTimeSelected}
							sortBy="value"
							data={this.state.permissionTimeData}
						/>
						<Select
							style={{ width: "250px" }}
							placeholder={lang.translate("reason")}
							caseSearch={false}
							valueHeader={false}
							valueBody={false}
							sortBy="title"
							onSelect={this.onSelectPermissionReasonEdit}
							onSearch={this.onSelectPermissionReasonSearch}
							multi={false}
							min={1}
							selected={this.state.permissionReasonSelected}
							data={this.state.permissionReasonData}
						/>
					</main>
					<main>
						<div className="block">
							<label>{`${lang.translate("notes")}:`}</label>
							<Input
								disabled={disabled}
								type="textarea"
								className="c"
								name="notes"
								placeholder={lang.translate("notes")}
								value={this.state.notes}
								onChange={this.onChange}
								onBlur={this.onChange}
								onFocus={(e) => e.target.select()}
							/>
						</div>
					</main>
					<footer>
						<button disabled={disabled} onClick={(e) => [e.stopPropagation(), this.onMuteClick(0)]} className="btnDefault warn">
							<Icon icon={muteIcon} size={24} />
							<b>{lang.translate("mute")}</b>
						</button>
						<button disabled={disabled} onClick={(e) => [e.stopPropagation(), this.onMuteClick(1)]} className="btnDefault alert">
							<Icon icon={banIcon} size={24} />
							<b>{lang.translate("ban")}</b>
						</button>
					</footer>
				</section>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(Mute);
