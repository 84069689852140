import React, { PureComponent } from "react";
import PropTypes from "prop-types";
//components:
import Form from "../../../components/form/Form";
import Select from "../../../components/select/Select";
//containers:
import Container from "../../Container";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../../classes/lang";
import Axios from "../../../classes/axios";
// eslint-disable-next-line no-unused-vars
// import { settingsSet } from "../../../classes/games/dice";
//icons:
import { Icon } from "react-icons-kit";
import { ic_timeline as iconStats } from "react-icons-kit/md/ic_timeline";
import { dice as iconBets } from "react-icons-kit/icomoon/dice";
import { ic_save } from "react-icons-kit/md/ic_save";
import { ic_keyboard as iconHotkeys } from "react-icons-kit/md/ic_keyboard";
import { ic_music_note as iconSound } from "react-icons-kit/md/ic_music_note";
import { store, storeSet } from "../../..";
import { onResult } from "../../../classes/utills";
import toast from "../../../classes/toast";

class CrashSettings extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMenuItem: this.props.selected ? this.props.selected : "statistics",
			loading: false,
		};
		this.menu = [
			{ value: "statistics", title: "statistics", icon: iconStats },
			{ value: "bets", title: "bets", icon: iconBets },
			{ value: "hotkeys", title: "hotkeys", icon: iconHotkeys },
			{ value: "sound", title: "sound", icon: iconSound },
		];
		this.forms = {};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			crashSettings: PropTypes.object,
			selected: PropTypes.string,
		};
	}
	// settingsSet = async (prop, o, cb) => {
	// 	await storeSet({ diceSettings: { [prop]: { $set: o } } });
	// 	o = await store.getState().diceSettings;
	// 	emitOrDefault("dice", { type: "setSettings", o }, 5000, { error: true, message: "you_are_offline" }, async (_r) => {
	// 		if (_r && _r.error && _r.message) {
	// 			Toast.error(lang.translate(_r.message));
	// 			return typeof cb === "function" ? cb() : null;
	// 		}
	// 		Toast.success(lang.translate("settings_saved"));
	// 		if (typeof cb === "function") cb();
	// 	});
	// };
	settingsSet = async (prop, o, cb) => {
		await storeSet({ playerConfig: { crashSettings: { [prop]: { $set: o } } } });
		const crashSettings = await store.getState().playerConfig.crashSettings;
		const result = await Axios.post("/api/gameCrash/saveCrashSettings", crashSettings);
		await onResult(result, "unexpected_error", () => {
			toast.success(lang.translate("settings_saved"));
		});
		cb();
	};
	onSave = (e, o) => {
		delete o.save;
		if (e) e.stopPropagation();
		this.setState({ loading: true });
		this.settingsSet(this.state.selectedMenuItem, o, () => {
			this.setState({ loading: false });
		});
	};
	onMenuSelect = async (item) => {
		await this.setState({ selectedMenuItem: "" });
		if (this.frm) this.frm.clear();
		await this.setState({ selectedMenuItem: item.id });
	};
	renderMenu = (type = 0) => {
		const selectData = [];
		const o = [];
		for (let el of this.menu) {
			selectData.push({
				id: el.value,
				value: el.value,
				title: lang.translate(el.title),
			});
			o.push(
				<button key={`renderMenu_${el.value}`}
					onClick={(e) => [
						e.stopPropagation(),
						this.onMenuSelect({
							id: el.value,
							value: el.value,
							title: lang.translate(el.title),
						}),
					]}
					disabled={this.state.loading}
					className={`btnDefault menuItem ${this.state.selectedMenuItem === el.value ? "selected" : ""}`}
				>
					<Icon icon={el.icon} />
					<label>{lang.translate(el.title)}</label>
				</button>
			);
		}
		if (type === 1)
			return (
				<Select
					style={{ width: "100%" }}
					caseSearch={false}
					valueHeader={false}
					titleHeader={true}
					onSelect={this.onMenuSelect}
					multi={false}
					min={1}
					selected={[
						{
							id: this.state.selectedMenuItem,
							title: lang.translate(this.state.selectedMenuItem),
						},
					]}
					data={selectData}
				/>
			);
		else return o;
	};
	renderContent = () => {
		if (this.selectedMenuItem === "") return null;
		const data = [];
		for (let el in this.props.crashSettings?.[this.state.selectedMenuItem]) {
			const value = this.props.crashSettings?.[this.state.selectedMenuItem][el];
			let str = el.startsWith("max") ? `${lang.translate("max")} ${lang.translate(el.substr(3))}` : lang.translate(el);
			let type;
			if (typeof value === "boolean") {
				type = "toggle";
			} else if (typeof value === "string" || typeof value === "number") {
				type = "input";
			} else {
				type = "component";
			}
			let o = { type, value, label: str, name: el };
			if (type === "toggle") {
				o.on = "on";
				o.off = "off";
			} else if (type === "input") {
				o.placeholder = lang.translate(el);
				o.validation = "integer";
				o.min = 5;
				o.max = 35;
				o.value += "";
			} else continue;
			data.push(o);
		}
		if (this.state.selectedMenuItem === "hotkeys") {
			data.push({
				type: "component",
				value: (
					<div className="hotkeysInfo">
						<div className="row">
							<b>{lang.translate("bet")}</b>
							<button className="diceBtn yellow">SPACE (32)</button>
						</div>
						<div className="row">
							<b>{lang.translate("min")}</b>
							<button className="diceBtn yellow">D (68)</button>
						</div>
						<div className="row">
							<b>0.5x</b>
							<button className="diceBtn yellow">A (65)</button>
						</div>
						<div className="row">
							<b>2x</b>
							<button className="diceBtn yellow">S (83)</button>
						</div>
					</div>
				),
			});
		}
		return (
			<Form
				ref={(el) => (this.frm = el)}
				data={data}
				buttons={[
					{
						label: "save",
						icon: ic_save,
						className: "warn",
						onClick: this.onSave,
					},
				]}
			/>
		);
	};
	render() {
		const item = this.menu.filter((el) => el.value === this.state.selectedMenuItem)[0];
		return (
			<Container noDocs={true}>
				<div className="diceSettings">
					<header>
						<h1>{lang.translate(item ? item.title : "settings")}</h1>
					</header>
					<section className="menuSelect">{this.renderMenu(1)}</section>
					<section>
						<div className="pane lPane">{this.renderMenu(0)}</div>
						<div className="pane rPane">{this.renderContent(this.state.selectedMenuItem)}</div>
					</section>
				</div>
			</Container>
		);
	}
}
const a = (store) => {
	return {
		lang: store.lang.key,
		crashSettings: store.playerConfig?.crashSettings || {
			bets: { active: true, reverse: false, quantity: 35, my_bets: true },
			hotkeys: { active: true },
			sound: { active: true },
			statistics: { active: true },
		},
	};
};

export default connect(a, null, null, { forwardRef: true })(CrashSettings);
