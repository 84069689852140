import React from "react";
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
	}
	static get propTypes() {
		return {
			children: PropTypes.element
		};
	}
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log("LOG: ", error, info);
  }
  render() {
    if (this.state.hasError) return null;
    else return this.props.children;
  }
}

export default ErrorBoundary;
