import { useCallback } from "react";
import Axios from "../../classes/axios";
import { isObject, onResult } from "../../classes/utills";
import { shallowEqual, useSelector } from "react-redux";
import { useState } from "react";
import { storeSet } from "../..";

const useGames = ({ coinSelected }) => {
	const [url, setUrl] = useState();
	const { langRoute, flappyGames } = useSelector(
		(store) => ({
			langRoute: store.langRoute,
			flappyGames: store.flappyGames,
		}),
		shallowEqual
	);
	const getGames = useCallback(async () => {
		const result = await Axios.post(`/api/flappybet/gamesGet`, {});
		if (Array.isArray(result)) {
			await storeSet({ flappyGames: { $set: result } });
		}
	}, []);
	const init = useCallback(
		async (gameId, demo = true) => {
			const language =
				{
					en: "en-GB",
					ru: "ru-RU",
					fr: "fr-FR",
					de: "de-DE",
					bn: "bn-IN",
					es: "es-ES",
					id: "id-ID",
					pt: "pt-PT",
					tr: "tr-TR",
				}[langRoute] ?? "en-GB";
			const result = await Axios.post(`/api/flappybet/${demo ? "initDemo" : "init"}`, {
				gameId,
				short: coinSelected,
				language,
				homeUrl: `${window.location.origin}/${langRoute}/originals`,
			});
			await onResult(result, "unexpected_error", (r) => {
				if (isObject(r) && r.url) {
					setUrl(r.url);
					return r.url;
				} else {
					return null;
				}
			});
		},
		[coinSelected, langRoute]
	);

	return {
		url,
		games: flappyGames,
		init,
		getGames,
	};
};

export default useGames;
