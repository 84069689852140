let instance = null;
class HotKeys {
	constructor() {
		if (instance) return instance;
		instance = this;
		this.time = new Date();
		this.hooks = {};
		this.keysDown = {};
		this.activePrefixes = [];
		if (typeof document !== "undefined") {
			document.addEventListener("keydown", this.onKeyDown, { passive: false });
			document.addEventListener("keyup", this.onKeyUp, { passive: false });
		}
		return instance;
	}
	hasKeyInPath = (arr) => {
		for (let el of arr) if (window.location.pathname.indexOf(el) !== -1) return true;
		return false;
	};
	onKeyDown = (e) => {
		if (["text", "textarea", "search", "file", "password"].indexOf(e.target.type) >= 0 || e.target.contentEditable === "true") return;
		// if (this.hasKeyInPath(["/sport", "/slots", "/pandora"])) return;
		if (!this.hasKeyInPath(["/crypto-bitcoin-dice", "/crypto-betting-crash"])) return;
		if ([38, 40, 123].indexOf(e.keyCode) !== -1) return; //up, down, f12
		e.preventDefault();
		e.stopPropagation();
		if (this.keysDown[`key_${e.keyCode}`] === true) return;
		this.keysDown[`key_${e.keyCode}`] = true;
		this.fireEvent(e);
	};
	onKeyUp = (e) => {
		if (["text", "textarea", "search", "file", "password"].indexOf(e.target.type) >= 0 || e.target.contentEditable === "true") return;
		// if (this.hasKeyInPath(["/sport", "/slots", "/pandora"])) return;
		if (!this.hasKeyInPath(["/crypto-bitcoin-dice", "/crypto-betting-crash"])) return;

		if ([38, 40, 123].indexOf(e.keyCode) !== -1) return; //up, down, f12
		e.preventDefault();
		e.stopPropagation();
		this.keysDown[`key_${e.keyCode}`] = false;
	};
	fireEvent = (e) => {
		for (let prefix of this.activePrefixes) {
			if (!this.hooks[prefix]) {
				console.log(`You forgot to register '${prefix}' prefix`);
				continue;
			}
			if (typeof this.hooks[prefix][`key_${e.keyCode}`] !== "object" || this.hooks[prefix][`key_${e.keyCode}`] === null) continue;
			const ids = this.hooks[prefix][`key_${e.keyCode}`];
			for (let id in ids) ids[id](e, id);
		}
	};
	pauseHooks = (prefix) => {
		this.activePrefixes = this.activePrefixes.filter((el) => el !== prefix);
	};
	resumeHooks = (prefix) => {
		this.activePrefixes = this.activePrefixes.filter((el) => el !== prefix).concat(prefix);
	};
	createHook = (prefix, id, keyCode, cb) => {
		if (typeof this.hooks[prefix] !== "object" || this.hooks[prefix] === null) {
			this.hooks[prefix] = {};
			this.activePrefixes.push(prefix);
		}
		if (typeof this.hooks[prefix][`key_${keyCode}`] !== "object" || this.hooks[prefix][`key_${keyCode}`] === null) {
			this.hooks[prefix][`key_${keyCode}`] = {};
		}
		if (typeof cb === "function") {
			this.hooks[prefix][`key_${keyCode}`][id] = cb;
		}
	};
}
export const hotkeys = new HotKeys();
