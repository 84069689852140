import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
// import debounce from "lodash/debounce";
//components:
import Input from "../input/Input";
import Toggle from "../toggle/Toggle";
import Select from "../select/Select";
import SelectPlayer from "../select/SelectPlayer";
//redux:
import { connect } from "react-redux";
//classes:
import { lang } from "../../classes/lang";
import { withPerms } from "../../classes/player";
//icons:
import { Icon } from "react-icons-kit";
// import { certificate as reqIcon } from "react-icons-kit/fa/certificate";
import { star as reqIcon } from "react-icons-kit/fa/star";
import MultiLangField from "../multiLangField/MultiLangField";
//EXAMPLES:
// {
// 	type: "select",
// 	label: lang.translate("permissions"),
// 	name: "permission",
// 	value: "player",
// 	required: true,
// 	data: [
// 		{ id: "player", value: "player", title: lang.translate("player") },
// 		{ id: "moderator", value: "moderator", title: lang.translate("moderator") },
// 		{ id: "administrator", value: "administrator", title: lang.translate("administrator") },
// 	],
// }
class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			r: 1,
		};
		this.items = {};
		this.name = `frm_${Math.random()}`;
	}
	static get propTypes() {
		return {
			className: PropTypes.string,
			data: PropTypes.array,
			buttons: PropTypes.array,
			//REDUX:
			lang: PropTypes.string,
		};
	}
	// debouncedRefresh = debounce(() => {
	//   this.setState({ r: this.state.r + 1 });
	// }, 100);
	getValues = () => {
		const o = {};
		for (let el in this.items) {
			let v = this.items[el]["value"];
			switch (this.items[el]["type"]) {
				case Form.types.input:
					v = `${v}`.trim();
					if (v === "") v = null;
					o[el] = this.items[el]["getValueFunc"](v);
					break;
				case Form.types.multiLangField:
					for (let key in v) {
						if (v[key] === "") v[key] = null;
						v[key] = this.items[el]["getValueFunc"](v[key]);
					}
					o[el] = v;
					break;
				case Form.types.component:
				case Form.types.button:
					break;
				default:
					o[el] = this.items[el]["getValueFunc"](v);
					break;
			}
		}
		return o;
	};
	clear = () => {
		for (let el in this.items) {
			if (this.items[el] && this.items[el]["_ref"] && typeof this.items[el]["_ref"]["clear"] === "function") {
				this.items[el]["_ref"]["clear"]();
			}
			if (this.items[el] && this.items[el]["_ref"] && typeof this.items[el]["_ref"]["onClear"] === "function") {
				this.items[el]["_ref"]["onClear"]();
			}
		}
		this.items = {};
		this.setState({ r: this.state.r + 1 });
	};
	onInputChange = (e) => {
		this.items[e.target.name]["value"] = e.target.value;
		this.setState({ r: this.state.r + 1 });
	};
	onMultiLangFieldChange = (name, value) => {
		this.items[name]["value"] = value;
		this.setState({ r: this.state.r + 1 });
	};
	onToggleChange = (e, name, value) => {
		this.items[name]["value"] = value;
		this.setState({ r: this.state.r + 1 });
	};
	onSelectChange = (name, item) => {
		this.items[name]["sysvalue"] = typeof item !== "undefined" ? [item] : [];
		this.items[name]["value"] = typeof item !== "undefined" ? item.value : undefined;
		this.setState({ r: this.state.r + 1 });
	};
	renderItem = (el, i) => {
		const { type, label, className, placeholder, value, getValueFunc } = el;
		let o;
		let name;
		if (typeof el.name !== "string") el.name = label ? label : `${this.name}_${type}_${i}`;
		name = el.name;
		el.id = name;
		if (!this.items[name]) this.items[name] = {};
		this.items[name]["getValueFunc"] = typeof getValueFunc === "function" ? getValueFunc : (v) => v;
		if (typeof className !== "string") el.className = "";
		if (typeof label !== "string") el.label = lang.translate("label");
		else el.label = lang.translate(label);
		if (typeof placeholder !== "string") el.placeholder = el.label;
		const st = {};
		if (el.style && el.style.width) {
			st.width = el.style.width;
			st.flex = "none";
		}
		if (type === Form.types.hidden) {
			const item = this.items[name];
			item.type = "hidden";
			this.items[name]["value"] = value;
		}
		if (type === Form.types.input) {
			const item = this.items[name];
			item.type = "input";
			//assign initial values from props if they exist or fallback to default value (""):
			if (typeof item["value"] === "undefined") item["value"] = value;
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					{el.tip && (
						<div className="itemTip">
							<div className="itemTipContent">{el.tip}</div>
						</div>
					)}
					{el.required && (
						<div className="required" title={lang.translate("required_field")}>
							<Icon icon={reqIcon} size={12} />
						</div>
					)}
					<label>{`${lang.translate(label)}:`}</label>
					<Input
						{...el}
						style={{}}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						// ref={_el => (item["_ref"] = _el)}
						value={`${item["value"]}`}
					/>
				</div>
			);
		} else if (type === Form.types.multiLangField) {
			const item = this.items[name];
			item.type = Form.types.multiLangField;
			//assign initial values from props if they exist or fallback to default value (""):
			if (typeof item["value"] === "undefined") item["value"] = value;
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					{el.tip && (
						<div className="itemTip">
							<div className="itemTipContent">{el.tip}</div>
						</div>
					)}
					{el.required && (
						<div className="required" title={lang.translate("required_field")}>
							<Icon icon={reqIcon} size={12} />
						</div>
					)}
					<label>{`${lang.translate(label)}:`}</label>
					<MultiLangField
						style={typeof el.style === "object" && el.style !== null ? el.style : {}}
						disabled={typeof el.disabled === "boolean" ? el.disabled : false}
						placeholder={el.placeholder || el.label}
						onChange={(v) => this.onMultiLangFieldChange(name, v)}
						value={item["value"]}
					/>
				</div>
			);
		} else if (type === Form.types.toggle) {
			//assign initial values from props if they exist or fallback to default value (false):
			const item = this.items[name];
			item.type = Form.types.toggle;
			if (typeof item["value"] === "undefined") item["value"] = typeof value !== "boolean" ? false : value;
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					{el.tip && (
						<div className="itemTip">
							<div className="itemTipContent">{el.tip}</div>
						</div>
					)}
					{el.required && (
						<div className="required" title={lang.translate("required_field")}>
							<Icon icon={reqIcon} size={12} />
						</div>
					)}
					<label>{`${lang.translate(label)}:`}</label>
					<Toggle
						{...el}
						style={{}}
						onChange={this.onToggleChange}
						value={item["value"]}
						ref={(_el) => [(item["_ref"] = _el), (item["type"] = "toggle")]}
					/>
				</div>
			);
		} else if (type === Form.types.button) {
			const item = this.items[name];
			item.type = "button";
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					<button {...el} onClick={(e) => el.onClick(e, this.getValues())} className={`btnDefault ${el.className}`} style={{}}>
						{el.icon && <Icon icon={el.icon} />}
						{el.img && <img alt="" src={el.img} width={24} height={24} />}
						<label>{lang.translate(el.label)}</label>
					</button>
				</div>
			);
		} else if (type === Form.types.component) {
			o = <Fragment key={"Form_" + el.id}>{el["value"]}</Fragment>;
		} else if (type === Form.types.select) {
			const item = this.items[name];
			if (!Array.isArray(el.data)) el.data = [];
			item.type = type;
			if (!Array.isArray(item["sysvalue"])) {
				//extract "value", as value here is "id":
				const found = el.data.find((a) => a.id === value);
				if (found) item.value = found.value;
				else item.value = value; //default value on start
			}
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					{el.tip && (
						<div className="itemTip">
							<div className="itemTipContent">{el.tip}</div>
						</div>
					)}
					{el.required && (
						<div className="required" title={lang.translate("required_field")}>
							<Icon icon={reqIcon} size={12} />
						</div>
					)}
					<label>{`${lang.translate(label)}:`}</label>
					<Select
						{...(el.onSearch ? { onSearch: el.onSearch } : {})}
						{...(el.className ? { className: el.className } : {})}
						// style={{ width: "150px" }}
						caseSearch={false}
						valueHeader={false}
						valueBody={false}
						onSelect={(item) => [this.onSelectChange(el.name, item), typeof el.onSelect === "function" && el.onSelect(item)]}
						multi={false}
						min={1}
						selected={Array.isArray(item["sysvalue"]) ? item["sysvalue"] : [value]}
						data={Array.isArray(el.data) ? el.data : []}
					/>
				</div>
			);
		} else if (type === Form.types.selectPlayer) {
			const item = this.items[name];
			if (!Array.isArray(el.data)) el.data = [];
			item.type = type;
			if (Array.isArray(item["sysvalue"])) {
				item.value = item["sysvalue"].length > 0 ? item["sysvalue"][0].id : undefined;
			}
			o = (
				<div key={"Form_" + el.id} className={`item ${type}`} style={st}>
					{el.required && (
						<div className="required" title={lang.translate("required_field")}>
							<Icon icon={reqIcon} size={12} />
						</div>
					)}
					<label>{`${lang.translate(label)}:`}</label>
					<SelectPlayer
						ref={(el) => [withPerms(el, ["PLAYER_FIND"]), (item["_ref"] = el), (item["type"] = type)]}
						onSelect={(item) => this.onSelectChange(el.name, item)}
						onUnselect={() => this.onSelectChange(el.name, undefined)}
						selected={Array.isArray(item["sysvalue"]) ? [item["sysvalue"].id] : [value]}
					/>
				</div>
			);
		}
		return o;
	};
	render() {
		let data = this.props.data;
		data = data ? (Array.isArray(data) ? data : [data]) : null;
		if (data === null) return null;
		return (
			<div className={`formGeneral ${this.props.className ? this.props.className : ""}`}>
				<main>{(data ?? []).map((el, i) => this.renderItem(el, i))}</main>
				<footer>
					{(this.props.buttons ?? []).map((el, i) => {
						if (typeof el.type === "undefined") el.type = "button";
						return this.renderItem(el, i);
					})}
				</footer>
			</div>
		);
	}
}
Form.types = {
	button: "button",
	hidden: "hidden",
	input: "input",
	multiLangField: "multiLangField",
	select: "select",
	selectPlayer: "selectPlayer",
	toggle: "toggle",
	component: "component",
};
const a = (store) => {
	return {
		lang: store.lang.key,
	};
};

export default connect(a, null, null, { forwardRef: true })(Form);
