//classes:
import config from "../config";
import GA4React from "ga-4-react";
import { isObject } from "./utills";

let ga;
let loadData;
export const GA_EVENTS = {
	load: "load",
	page_view: "page_view",
	pandora: "pandora",
	slotegrator: "slotegrator",
	sportegrator: "sportegrator",
	chat: "chat",
	room_change: "room_change",
	rain_send: "rain_send",
	tip_send: "tip_send",
};
Object.freeze(GA_EVENTS);
export const gaInit = (o) => {
	if (ga) return;
	loadData = o;
	const ga4react = new GA4React(
		config.googleAnalytics.trackingId,
		{
			/* ga custom config, optional */
			// debug_mode: true,
		},
		[
			/* additional code, optional */
		],
		5000 /* timeout, optional, defaults is 5000 */,
		{
			/* { nonce: ['first-script-is-async','second-script'] } */
		}
	);
	try {
		ga4react.initialize().then(
			(ga4) => {
				ga = ga4;
				ga.gtag("set", "user_properties", loadData);
				gaEvent(GA_EVENTS.load);
			},
			(err) => console.error(err)
		);
	} catch {}
};
export const gaPageView = (title) => {
	try {
		typeof window !== "undefined" &&
			ga.gtag("event", GA_EVENTS.page_view, {
				page_location: `${window.location.pathname}${window.location.search}`,
				page_path: window.location.pathname,
				...(title ? { page_title: title } : {}),
			});
	} catch {}
};
export const gaEvent = (event, data) => {
	if (!isObject(data)) data = { data };
	try {
		ga.gtag("event", event, data);
	} catch {}
};
