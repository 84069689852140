/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Doc from "./doc/Doc";
//redux:
// import { connect } from "react-redux"
// import { store } from "../index"
//classes:
// import { lang } from "../classes/lang"
//icons:
// import { Icon } from "react-icons-kit"

class Container extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showMore: false,
		};
	}
	static get propTypes() {
		return {
			lang: PropTypes.string,
			match: PropTypes.object,
			children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
			noDocs: PropTypes.bool,
		};
	}
	onShowMore = () => {
		this.setState({ showMore: !this.state.showMore });
	};
	render() {
		return (
			<div className={`container ${this.props.className ? this.props.className : ""}`}>
				{/* eslint-disable-next-line */}
				{!this.props.noDocs && <h1 />}
				{this.props.children}
				{!this.props.noDocs && <Doc showMore={this.state.showMore} onShowMore={this.onShowMore} />}
			</div>
		);
	}
}

export default Container;
